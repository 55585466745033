import React, { useState, useEffect } from 'react'
import './preturiServ.css'
import { IoIosSearch } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import Sidebar from '../sidebar/sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import Loader from "react-js-loader";

import { APIurl } from '../../ApiService';

function PreturiServ() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [hidePage, setHidePage] = useState(true);

    const [showLoader, setShowLoader] = useState(false);
    const [preturi_servicii, setPreturiServicii] = useState({
        angle_cut: 0,
        angle_cut_edgebanding: 0,
        concealed_hinge_drilling: 0,
        adjustable_shelf_drilling: 0,
        bonding_under_05_sqm: 0,
        bonding_under_1_sqm: 0,
        bonding_under_2_sqm: 0,
        bonding_under_3_sqm: 0,
        bonding_over_3_sqm: 0,
        white_primer: 0,
        solid_color_satin: 0,
        solid_color_matt: 0,
        clear_lac_satin: 0,
        clear_lac_matt: 0,
        clear_sealant: 0
    });

    const fetchPrices = async(userToken) =>{
        setShowLoader(true);
        const tokenData = {
            token: userToken
        };
        try {
            const response = await axios.post(`${APIurl}/api/adminprices`, tokenData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
    
            if (responseStatus === 'error') {
                setShowLoader(false);
                return;
            } else {
                if(dataMessage === 'Not admin'){
                    navigate('/login');
                }else{
                    setHidePage(false);
                    const prices = response.data.prices;
                    const new_preturi_servicii = {...preturi_servicii};
                    new_preturi_servicii.angle_cut = prices.angle_cut.toFixed(2);
                    new_preturi_servicii.angle_cut_edgebanding = prices.angle_cut_edgebanding.toFixed(2);
                    new_preturi_servicii.concealed_hinge_drilling = prices.concealed_hinge_drilling.toFixed(2);
                    new_preturi_servicii.adjustable_shelf_drilling = prices.adjustable_shelf_drilling.toFixed(2);
                    new_preturi_servicii.bonding_under_05_sqm = prices.bonding_under_05_sqm.toFixed(2);
                    new_preturi_servicii.bonding_under_1_sqm = prices.bonding_under_1_sqm.toFixed(2);
                    new_preturi_servicii.bonding_under_2_sqm = prices.bonding_under_2_sqm.toFixed(2);
                    new_preturi_servicii.bonding_under_3_sqm = prices.bonding_under_3_sqm.toFixed(2);
                    new_preturi_servicii.bonding_over_3_sqm = prices.bonding_over_3_sqm.toFixed(2);
                    new_preturi_servicii.white_primer = prices.white_primer.toFixed(2);
                    new_preturi_servicii.solid_color_satin = prices.solid_color_satin.toFixed(2);
                    new_preturi_servicii.solid_color_matt = prices.solid_color_matt.toFixed(2);
                    new_preturi_servicii.clear_lac_satin = prices.clear_lac_satin.toFixed(2);
                    new_preturi_servicii.clear_lac_matt = prices.clear_lac_matt.toFixed(2);
                    new_preturi_servicii.clear_sealant = prices.clear_sealant.toFixed(2);
                    setPreturiServicii(new_preturi_servicii);
                    setShowLoader(false);
                    return;
                }
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle or log the error
            setShowLoader(false);
            //navigate('/neworder');
        }
    }

    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            // get account details
            fetchPrices(savedToken);
        }else{
            console.log('You must be logged in to access this page!');
            navigate('/login');
        }
    }, []);

    const handlePriceInputChange = (event, fieldName) =>{
        const inputValue = event.target.value;
        if(fieldName === 'angle_cut'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.angle_cut = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'angle_cut_edgebanding'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.angle_cut_edgebanding = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'concealed_hinge_drilling'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.concealed_hinge_drilling = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'adjustable_shelf_drilling'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.adjustable_shelf_drilling = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'bonding_under_05_sqm'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.bonding_under_05_sqm = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'bonding_under_1_sqm'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.bonding_under_1_sqm = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'bonding_under_2_sqm'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.bonding_under_2_sqm = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'bonding_under_3_sqm'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.bonding_under_3_sqm = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'bonding_over_3_sqm'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.bonding_over_3_sqm = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'white_primer'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.white_primer = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'solid_color_satin'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.solid_color_satin = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'solid_color_matt'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.solid_color_matt = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'clear_lac_satin'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.clear_lac_satin = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'clear_lac_matt'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.clear_lac_matt = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }else if(fieldName === 'clear_sealant'){
            const new_preturi_servicii = {...preturi_servicii};
            new_preturi_servicii.clear_sealant = inputValue;
            setPreturiServicii(new_preturi_servicii);
        }
    }

    const updatePricesInDB = async() =>{
        try {
            const savedToken = cookies.get("authToken");
            const tokenData = {
                token: savedToken,
                preturi_servicii: preturi_servicii
            };
            const response = await axios.post(`${APIurl}/api/adminupdateprices`, tokenData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    }
    
    return (
        hidePage == true ? null : (
            <div className='adminPage'>
                <Sidebar activeTab={'preturi_servicii'}/>
                <div className='cererisupSection'>
                    {showLoader === true ? (
                        <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                    ) : (
                        <>
                            <div className='cererisupHeader'>
                                <p className='cererisupHeaderTitle'>Preturi servicii</p>
                            </div>
                            <div className='preturiServListSection'>
                                <div className='preturiservInputItem'>
                                    <p>Angle cut: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.angle_cut} onChange={(event) => handlePriceInputChange(event, 'angle_cut')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Angle cut edgebanding: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.angle_cut_edgebanding} onChange={(event) => handlePriceInputChange(event, 'angle_cut_edgebanding')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Concealed hinge drilling: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.concealed_hinge_drilling} onChange={(event) => handlePriceInputChange(event, 'concealed_hinge_drilling')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Adjustable shelf drilling: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.adjustable_shelf_drilling} onChange={(event) => handlePriceInputChange(event, 'adjustable_shelf_drilling')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Bonding under 0.5 sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.bonding_under_05_sqm} onChange={(event) => handlePriceInputChange(event, 'bonding_under_05_sqm')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Bonding under 1 sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.bonding_under_1_sqm} onChange={(event) => handlePriceInputChange(event, 'bonding_under_1_sqm')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Bonding under 2 sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.bonding_under_2_sqm} onChange={(event) => handlePriceInputChange(event, 'bonding_under_2_sqm')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Bonding under 3 sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.bonding_under_3_sqm} onChange={(event) => handlePriceInputChange(event, 'bonding_under_3_sqm')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Bonding over 3 sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.bonding_over_3_sqm} onChange={(event) => handlePriceInputChange(event, 'bonding_over_3_sqm')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>White primer per sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.white_primer} onChange={(event) => handlePriceInputChange(event, 'white_primer')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Solid color satin per sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.solid_color_satin} onChange={(event) => handlePriceInputChange(event, 'solid_color_satin')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Solid color matt per sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.solid_color_matt} onChange={(event) => handlePriceInputChange(event, 'solid_color_matt')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Clear lacquer satin per sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.clear_lac_satin} onChange={(event) => handlePriceInputChange(event, 'clear_lac_satin')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Clear lacquer matt per sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.clear_lac_matt} onChange={(event) => handlePriceInputChange(event, 'clear_lac_matt')}/>
                                </div>
                                <div className='preturiservInputItem'>
                                    <p>Clear sealant per sqm: £</p>
                                    <input className='preturiservInputField' value={preturi_servicii.clear_sealant} onChange={(event) => handlePriceInputChange(event, 'clear_sealant')}/>
                                </div>
                                <div className='preturiservUpdateBtn' onClick={() => updatePricesInDB()}>
                                    <p>Actualizeaza schimbarile de pret</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    )
}

export default PreturiServ