import React, { useState, useEffect } from 'react'
import './edgebandingPopup.css'
import { FiX, FiSearch } from "react-icons/fi";
import { getRowWithOpenSelection, getRowConfig, getThickPropsEdgebanding, getEdgebandingOptions, updateEdgebandingConfig } from '../../orderConfig';


function EdgebandingPopup(props) {

    const [rowConfig, setRowConfig] = useState(null);
    const [thickProps, setThickProps] = useState([]);
    const [edgebandingConfig, setEdgebandingConfig] = useState({
        l1: false,
        l2: false,
        w1: false,
        w2: false,
        l1_edgebanding: null,
        l2_edgebanding: null,
        w1_edgebanding: null,
        w2_edgebanding: null,
        l1_finish: null,
        l2_finish: null,
        w1_finish: null,
        w2_finish: null,
        l1_obj: null,
        l2_obj: null,
        w1_obj: null,
        w2_obj: null
    });
    const [edgebandingOptionsAvailable, setEdgebandingOptionsAvailable] = useState([]);
    const [edgebandingFinishAvailable, setEdgebandingFinishAvailable] = useState({
        radius: false,
        bevel: false,
        square: false
    });
    const [selectedSide, setSelectedSide] = useState('l1');

    useEffect(() => {
        const currRow = getRowWithOpenSelection();
        const config = getRowConfig(currRow);
        const thickProperties = getThickPropsEdgebanding();
        setRowConfig(config);
        setThickProps(thickProperties);
        console.log('edgebanding popup opened, row config for it: ' + currRow);
    }, []);
    useEffect(() => {
        console.log(thickProps);
        if(rowConfig !== null){
            // check if the row has an already existing edgebanding config
            if(rowConfig.edgebandingConfig == null){
                setEdgebandingConfig({
                    l1: false,
                    l2: false,
                    w1: false,
                    w2: false,
                    l1_edgebanding: null,
                    l2_edgebanding: null,
                    w1_edgebanding: null,
                    w2_edgebanding: null,
                    l1_finish: null,
                    l2_finish: null,
                    w1_finish: null,
                    w2_finish: null,
                    l1_obj: null,
                    l2_obj: null,
                    w1_obj: null,
                    w2_obj: null
                });
                console.log('This row doesnt have a edgebanding config');
            }else{
                setEdgebandingConfig(rowConfig.edgebandingConfig);
                console.log('This row has a edgebanding config');
            }

            // get available edgebanding options
            const edgebandingOptions = getEdgebandingOptions();
            setEdgebandingOptionsAvailable(edgebandingOptions);
        }
    }, [rowConfig]); 

    const changeSide = (side) => {
        setSelectedSide(side);
    };

    useEffect(() => {
        checkAvailableFinish();
    }, [selectedSide]);

    useEffect(() => {
        checkAvailableFinish();
    }, [edgebandingConfig]);

    const changeOption = (option) => {
        switch (selectedSide) {
            case 'l1':
                if(edgebandingConfig.l1_edgebanding != option.edgebanding_type){
                    setEdgebandingConfig((prevValues) => ({
                        ...prevValues,
                        l1: false,
                        l1_edgebanding: option.edgebanding_type,
                        l1_finish: null,
                        l1_obj: option
                    }));
                }
                checkAvailableFinish();
                break;
            case 'l2':
                if(edgebandingConfig.l2_edgebanding != option.edgebanding_type){
                    setEdgebandingConfig((prevValues) => ({
                        ...prevValues,
                        l2: false,
                        l2_edgebanding: option.edgebanding_type,
                        l2_finish: null,
                        l2_obj: option
                    }));
                }
                checkAvailableFinish();
                break;
            case 'w1':
                if(edgebandingConfig.w1_edgebanding != option.edgebanding_type){
                    setEdgebandingConfig((prevValues) => ({
                        ...prevValues,
                        w1: false,
                        w1_edgebanding: option.edgebanding_type,
                        w1_finish: null,
                        w1_obj: option
                    }));
                }
                checkAvailableFinish();
                break;
            case 'w2':
                if(edgebandingConfig.w2_edgebanding != option.edgebanding_type){
                    setEdgebandingConfig((prevValues) => ({
                        ...prevValues,
                        w2: false,
                        w2_edgebanding: option.edgebanding_type,
                        w2_finish: null,
                        w2_obj: option
                    }));
                }
                checkAvailableFinish();
                break;
            default:
                break;
        };
    };

    const checkAvailableFinish = () => {
        const option = edgebandingConfig[`${selectedSide}_obj`];
        let tempFinish = {
            radius: false,
            bevel: false,
            square: false
        }
        if(option != null){
            if(option.edgebanding_radius.toLowerCase() == 'yes'){
                tempFinish.radius = true;
            }
            if(option.edgebanding_bevel.toLowerCase() == 'yes'){
                tempFinish.bevel = true;
            }
            if(option.edgebanding_square.toLowerCase() == 'yes'){
                tempFinish.square = true;
            }
        }
        setEdgebandingFinishAvailable(tempFinish);
    };

    const changeFinish = (finishType) => {
        const sideFinishKey = `${selectedSide}_finish`;
        if(edgebandingFinishAvailable[finishType] == true){
            setEdgebandingConfig((prevValues) => ({
                ...prevValues,
                [sideFinishKey]: finishType,
                [selectedSide]: true
            }));
        }
    };

    const copyAllSides = () => {
        if(selectedSide == 'l1' && edgebandingConfig.l1 == true){
            setEdgebandingConfig((prevValues) => ({
                ...prevValues,
                l2: true,
                w1: true,
                w2: true,
                l2_edgebanding: prevValues.l1_edgebanding,
                w1_edgebanding: prevValues.l1_edgebanding,
                w2_edgebanding: prevValues.l1_edgebanding,
                l2_finish: prevValues.l1_finish,
                w1_finish: prevValues.l1_finish,
                w2_finish: prevValues.l1_finish,
                l2_obj: prevValues.l1_obj,
                w1_obj: prevValues.l1_obj,
                w2_obj: prevValues.l1_obj
            }));
        }else if(selectedSide == 'l2' && edgebandingConfig.l2 == true){
            setEdgebandingConfig((prevValues) => ({
                ...prevValues,
                l1: true,
                w1: true,
                w2: true,
                l1_edgebanding: prevValues.l2_edgebanding,
                w1_edgebanding: prevValues.l2_edgebanding,
                w2_edgebanding: prevValues.l2_edgebanding,
                l1_finish: prevValues.l2_finish,
                w1_finish: prevValues.l2_finish,
                w2_finish: prevValues.l2_finish,
                l1_obj: prevValues.l2_obj,
                w1_obj: prevValues.l2_obj,
                w2_obj: prevValues.l2_obj
            }));
        }else if(selectedSide == 'w1' && edgebandingConfig.w1 == true){
            setEdgebandingConfig((prevValues) => ({
                ...prevValues,
                l1: true,
                l2: true,
                w2: true,
                l1_edgebanding: prevValues.w1_edgebanding,
                l2_edgebanding: prevValues.w1_edgebanding,
                w2_edgebanding: prevValues.w1_edgebanding,
                l1_finish: prevValues.w1_finish,
                l2_finish: prevValues.w1_finish,
                w2_finish: prevValues.w1_finish,
                l1_obj: prevValues.w1_obj,
                l2_obj: prevValues.w1_obj,
                w2_obj: prevValues.w1_obj
            }));
        }else if(selectedSide == 'w2' && edgebandingConfig.w2 == true){
            setEdgebandingConfig((prevValues) => ({
                ...prevValues,
                l1: true,
                l2: true,
                w1: true,
                l1_edgebanding: prevValues.w2_edgebanding,
                l2_edgebanding: prevValues.w2_edgebanding,
                w1_edgebanding: prevValues.w2_edgebanding,
                l1_finish: prevValues.w2_finish,
                l2_finish: prevValues.w2_finish,
                w1_finish: prevValues.w2_finish,
                l1_obj: prevValues.w2_obj,
                l2_obj: prevValues.w2_obj,
                w1_obj: prevValues.w2_obj,
            }));
        }
    }

    const removeEdgebanding = () => {
        const edgebandingKey = `${selectedSide}_edgebanding`;
        const finishKey = `${selectedSide}_finish`;
        const objKey = `${selectedSide}_obj`;

        setEdgebandingConfig((prevValues) => ({
            ...prevValues,
            [selectedSide]: false,
            [edgebandingKey]: null,
            [finishKey]: null,
            [objKey]: null
        }));
    }

    const handleContainerClick = (e) => {
        // Check if the click occurred outside the container
        if (e.target.classList.contains('edgebandingPopup')) {
            closePopup(); // Close the popup
        }
    };
    const closePopup = () =>{
        updateEdgebandingConfig(edgebandingConfig);
        props.closeMaterialSelect();
    }


    return (
        <div className='edgebandingPopup' onClick={handleContainerClick}>
            <div className='edgebandingPopupContainer'>
                <div className='edgebandingPopupCloseBtn' onClick={() => closePopup()}>
                    <FiX />
                    <p>Save & close</p>
                </div>
                <div className='edgebandingDrawingContainer'>
                    <p style={{fontWeight: selectedSide === 'l1' ? '600' : '400', fontSize: selectedSide === 'l1' ? '16px' : '12px', cursor: 'pointer'}} onClick={() => changeSide('l1')}>L1</p>
                    <div className='edgebandingDrawingContainerMiddle'>
                        <p style={{fontWeight: selectedSide === 'w1' ? '600' : '400', fontSize: selectedSide === 'w1' ? '16px' : '12px', cursor: 'pointer'}} onClick={() => changeSide('w1')}>W1</p>
                        <div className='edgebandingDrawing'
                            style={{borderTop: edgebandingConfig.l1 == true ? '5px solid #6ab29b' : '0', 
                                borderRight: edgebandingConfig.w2 == true ? '5px solid #6ab29b' : '0',
                                borderBottom: edgebandingConfig.l2 == true ? '5px solid #6ab29b' : '0',
                                borderLeft: edgebandingConfig.w1 == true ? '5px solid #6ab29b' : '0' 
                            }}
                        >

                        </div>
                        <p style={{fontWeight: selectedSide === 'w2' ? '600' : '400', fontSize: selectedSide === 'w2' ? '16px' : '12px', cursor: 'pointer'}} onClick={() => changeSide('w2')}>W2</p>
                    </div>
                    <p style={{fontWeight: selectedSide === 'l2' ? '600' : '400', fontSize: selectedSide === 'l2' ? '16px' : '12px', cursor: 'pointer'}} onClick={() => changeSide('l2')}>L2</p>
                </div>
                <div className='edgebandingPopupContentContainer'>
                    <div className='edgebandingPopupSidesList'>
                        <div className='edgebandingPopupSidesListItem' style={{background: selectedSide === 'l1' ? '#e4d5c7' : '#f8f6f5', boxShadow: selectedSide ===  'l1' ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none', scale: selectedSide ===  'l1' ? '1.1' : '1'}} onClick={() => changeSide('l1')}>
                            <p>L1: {edgebandingConfig.l1 == true ? edgebandingConfig.l1_edgebanding : '-'}</p>
                        </div>
                        <div className='edgebandingPopupSidesListItem' style={{background: selectedSide === 'l2' ? '#e4d5c7' : '#f8f6f5', boxShadow: selectedSide ===  'l2' ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none', scale: selectedSide ===  'l2' ? '1.1' : '1'}} onClick={() => changeSide('l2')}>
                            <p>L2: {edgebandingConfig.l2 == true ? edgebandingConfig.l2_edgebanding : '-'}</p>
                        </div>
                        <div className='edgebandingPopupSidesListItem' style={{background: selectedSide === 'w1' ? '#e4d5c7' : '#f8f6f5', boxShadow: selectedSide ===  'w1' ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none', scale: selectedSide ===  'w1' ? '1.1' : '1'}} onClick={() => changeSide('w1')}>
                            <p>W1: {edgebandingConfig.w1 == true ? edgebandingConfig.w1_edgebanding : '-'}</p>
                        </div>
                        <div className='edgebandingPopupSidesListItem' style={{background: selectedSide === 'w2' ? '#e4d5c7' : '#f8f6f5', boxShadow: selectedSide ===  'w2' ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none', scale: selectedSide ===  'w2' ? '1.1' : '1'}} onClick={() => changeSide('w2')}>
                            <p>W2: {edgebandingConfig.w2 == true ? edgebandingConfig.w2_edgebanding : '-'}</p>
                        </div>
                    </div>
                    <div className='edgebandingPopupOptionsList'>
                        {edgebandingOptionsAvailable.length > 0 ? (
                            edgebandingOptionsAvailable.map((option, index) => (
                                <div key={index} className='edgebandingPopupOption' style={{background: edgebandingConfig[`${selectedSide}_edgebanding`] === option.edgebanding_type ? '#e4d5c7' : '#f8f6f5', boxShadow: edgebandingConfig[`${selectedSide}_edgebanding`] === option.edgebanding_type ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none'}} onClick={() => changeOption(option)}>
                                    <p>{option.edgebanding_type} / {rowConfig.productCode}</p>
                                    <p>{option.edgebanding_description}</p>
                                </div>
                            ))
                        ) : (
                            <div style={{flexDirection: 'row', gap: 5}}>
                                <FiSearch />
                                <p>No edgebanding options found.</p>
                            </div>
                        )}
                    </div>
                    <div className='edgebandingPopupFinishOptionsList'>
                        <div onClick={() => changeFinish('radius')} className={edgebandingFinishAvailable.radius ? edgebandingConfig[`${selectedSide}_finish`] == 'radius' ? 'edgebandingPopupFinishOptionActive' : 'edgebandingPopupFinishOption' : 'edgebandingPopupFinishOptionDisabled'}>
                            <img src='/res/images/corner1_selected.png' alt='Radius' className='edgebandingPopupFinishImage'/>
                            <p>Radius edge finish</p>
                        </div>
                        <div onClick={() => changeFinish('square')} className={edgebandingFinishAvailable.square ? edgebandingConfig[`${selectedSide}_finish`] == 'square' ? 'edgebandingPopupFinishOptionActive' : 'edgebandingPopupFinishOption' : 'edgebandingPopupFinishOptionDisabled'}>
                            <img src='/res/images/corner3_selected.png' alt='Square' className='edgebandingPopupFinishImage'/>
                            <p>Square edge finish</p>
                        </div>  
                    </div>
                    <div className='edgebandingPopupDoubleBtnDiv'>
                        <div className='edgebandingPopupCopyAllBtn' style={{opacity: edgebandingConfig[selectedSide] == true ? '1' : '0.5', cursor: edgebandingConfig[selectedSide] == true ? 'pointer' : 'initial'}} onClick={() => copyAllSides()}>
                            <p>Copy to all sides</p>
                        </div>
                        <div className='edgebandingPopupRemoveBtn' style={{opacity: edgebandingConfig[selectedSide] == true ? '1' : '0.5', cursor: edgebandingConfig[selectedSide] == true ? 'pointer' : 'initial'}} onClick={() => removeEdgebanding()}>
                            <p>Remove</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EdgebandingPopup