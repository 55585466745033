import React from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import PdfOrderSumary from './pdfOrderSumary'

function PdfPreview() {
    return (
        <PDFViewer style={{width: '100%', height: '100vh'}}>
            <PdfOrderSumary/>
        </PDFViewer>
    )
}

export default PdfPreview