import React, { useState, useEffect } from 'react'
import './resetPasswordPage.css'
import { useParams, useNavigate, Link } from 'react-router-dom';
import Loader from "react-js-loader";
import axios from 'axios';
import { FiAlertTriangle } from "react-icons/fi";

import { APIurl } from '../../ApiService';

function ResetPasswordPage() {
    const navigate = useNavigate();
    const { resetcode } = useParams();
    useEffect(() => {
        console.log('reset code: ' + resetcode);
        const validateResetCode = async() =>{
            const resetCode = {
                resetcode: resetcode
            };
            const response = await axios.post(`${APIurl}/api/validateresetcode`, resetCode, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });  
            console.log('Server response to reset code validation:');
            console.log(response);
            const responseStatus = response.data.status;
            const dataMessage = response.data.body;
            if(responseStatus == 'error'){  //  if server gave error
                navigate('/login');
            }else{  //  if server responded ok
                if(dataMessage == 'Good'){
                    return;
                }else{
                    navigate('/login');
                }
            } 
        }
        validateResetCode();
    }, [resetcode])
    const [passwordInput, setPasswordInput] = useState('');
    const [confirmedPasswordInput, setConfirmedPasswordInput] = useState('');
    const [inputError, setInputError] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const handlePasswordInputChange = (event) => {
        const newInput = event.target.value;
        setPasswordInput(newInput);
    };
    const handleConfirmedPasswordInputChange = (event) => {
        const newInput = event.target.value;
        setConfirmedPasswordInput(newInput);
    };

    const changePassword = async() => {
        let goodPassInput = false;
        let goodConfirmedPassInput = false;
        setShowLoader(true);
        const passwordRegex = /^[a-zA-Z0-9!@#\$%\^&\*\(\)-_+=<>?,.;:'"[\]{}|\\`~]*$/;
        // validate the password
        if (passwordInput == null || passwordInput == '' ||  passwordInput.length < 8 || passwordInput.length > 20) {
            setInputError('Password must be between 8 and 20 characters long!');
            goodPassInput = false;
            setShowLoader(false);
            return;
        }else{
            setInputError('');
            if(passwordRegex.test(passwordInput)){
                goodPassInput = true;
            }else{
                setInputError('Only letters, numbers, and special characters are allowed in your password. Your password contains other elements than those!');
                goodPassInput = false;
                setShowLoader(false);
                return;
            }
        }
        // validate the confirmed password
        if (confirmedPasswordInput == null || confirmedPasswordInput == '' ||  confirmedPasswordInput != passwordInput) {
            setInputError('Passwords do not match!');
            goodConfirmedPassInput = false;
            setShowLoader(false);
            return;
        }else{
            setInputError('');
            goodConfirmedPassInput = true;
        }
        if(goodPassInput && goodConfirmedPassInput){
            const passData = {
                resetcode: resetcode,
                password: passwordInput
            };
            const response = await axios.post(`${APIurl}/api/resetpassword`, passData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Server response:');
            console.log(response);
            const responseStatus = response.data.status;
            const dataMessage = response.data.body;
            if(responseStatus == 'error'){  //  if server gave error
                setShowLoader(false);
                setInputError('Something went wrong in the process..');
                return;
            }else{  //  if server responded ok
                if(dataMessage == 'Password changed succesfully'){
                    setInputError('');
                    setShowLoader(false);
                    setShowSuccessMsg(true);
                    return;
                }else{
                    setShowLoader(false);
                    setInputError('Something went wrong in the process..');
                    return;
                }
            }
        }
    }

    return (
        <div className='resetPassSection'>
            <div className='resetPassContainer'>
                <div className='resetPassContainerLogo'>
                    <img src='\res\images\myBoardsLogoShadow.png' alt='Logo' className='resetPassContainerLogoImg'/>
                </div>
                <div className='resetPassContainerContent'>
                    <div className='resetPassCard'>
                        <h3 style={{paddingBottom: '25px', fontWeight: '500'}}>Reset your password</h3>
                        <div className='resetPassForm'>
                            <div className='resetPassFormSection'>
                                <div className='resetPassFormLabelBox'>
                                    <p>New password</p>
                                </div>
                                <div className='resetPassFormInputFieldBox'>
                                    <input className='resetPassFormInputField' 
                                        value={passwordInput}
                                        onChange={handlePasswordInputChange}
                                        type='password'
                                    />
                                </div>
                                <div className='resetPassFormLabelBox' style={{marginTop: '10px'}}>
                                    <p>Confirm password</p>
                                </div>
                                <div className='resetPassFormInputFieldBox'>
                                    <input className='resetPassFormInputField' 
                                        value={confirmedPasswordInput}
                                        onChange={handleConfirmedPasswordInputChange}
                                        type='password'
                                    />
                                </div>
                            </div>
                        </div>
                        {inputError !== '' ? (
                            <div className='forgotPassErrorMsgDiv'>
                                <FiAlertTriangle style={{minWidth: '16px'}}/>
                                <p>{inputError}</p>
                            </div>
                        ) : null}
                        {showLoader === true ? (
                            <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                        ) : showSuccessMsg === true ? (
                            <div className='forgotPassSuccessMsgDiv'>
                                <p>Your account password has been succesfully changed. You can use your new password to log in to your account!</p>
                            </div>
                        ) : (
                            <div className='forgotPassBtnBox'>
                                <div className='forgotPassBtn' onClick={() => changePassword()}>
                                    <p>CHANGE PASSWORD</p>
                                </div>
                            </div>
                        )}
                        <Link to="/login" style={{textDecoration: 'none', color: 'black'}}><span className='resetPassToLoginBtn'>Back to Login</span></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage