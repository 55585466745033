import React, {useState, useEffect, useRef} from 'react'
import './../shopPage.css'
import './productPage.css'
import Header from '../../header/header';
import { FiSearch } from "react-icons/fi";


const ProductPage = () => {

    const [productImage, setProductImage] = useState(process.env.PUBLIC_URL + '/res/images/products/product1.png');

    return (
        <div className='shop-section'>
            <Header />
            <div className='shop-hero-container'>
                <p>Browse and shop for the best quality materials for your project</p>
            </div>
            <div className='shop-container'>
                <div className='shop-categories-list'>
                    <p style={{fontWeight: '500'}}>Shop for:</p>
                    <div className='shop-category-selected'>
                        <p>Wall Panelling</p>
                    </div>
                    <div className='shop-category'>
                        <p>Skirting Boards</p>
                    </div>
                    <div className='shop-category'>
                        <p>Architraves</p>
                    </div>
                    <div className='shop-category'>
                        <p>Dado Rails</p>
                    </div>
                    <div className='shop-category'>
                        <p>Picture Rails</p>
                    </div>
                    <div className='shop-category'>
                        <p>Window Boards</p>
                    </div>
                    <div className='shop-category'>
                        <p>Beading</p>
                    </div>
                    <div className='shop-category'>
                        <p>Stripwood</p>
                    </div>
                    <div className='shop-category'>
                        <p>Shelving</p>
                    </div>
                    <div className='shop-category'>
                        <p>Door Frames & Door Stops</p>
                    </div>
                    <div className='shop-category'>
                        <p>Plinth Blocks & Rosettes</p>
                    </div>
                    <div className='shop-category'>
                        <p>Monster Carpet Ramps</p>
                    </div>
                    <div className='shop-category'>
                        <p>Turret Blocks</p>
                    </div>
                    <div className='shop-category'>
                        <p>Miscellaneous</p>
                    </div>
                    <div className='shop-category'>
                        <p>Samples</p>
                    </div>
                </div>

                <div className='shop-products-container'>
                    <div className='shop-products-header'>
                        <p style={{fontWeight: '500'}}>Wall Panelling</p>
                        <div className='shop-products-header-btns'>
                            <div className='shop-products-header-search-field'>
                                <FiSearch className='shop-products-header-search-icon'/>
                                <input type="text" placeholder="Search products" />
                            </div>
                            <div className='shop-products-header-search-btn'>
                                <p>Search</p>
                            </div>
                        </div>
                    </div>
                    <div className='product-page-section'>
                        <div className='product-page-section-upper'>
                            <div className='product-page-section-upper-left'>
                                <img src={productImage} alt="product-image" className='product-page-section-upper-left-image'/>
                            </div>
                            <div className='product-page-section-upper-right'>
                                <div className='product-page-section-upper-right-top'>
                                    <div className='product-page-section-upper-right-top-left-container'>
                                        <p>Product Name</p>
                                    </div>
                                    <div className='product-page-section-upper-right-top-left-container' style={{justifyContent: 'flex-end'}}>
                                        <p>Product code</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductPage