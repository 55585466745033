import axios from 'axios';

let materialsData = [];

export const fetchMaterials =  async () => {
    try {
        const response = await axios.get(`${APIurl}/api/materials`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = response.data;
        materialsData = data;
        console.log('Materials API request finished');

        // You can return the data if needed
        return data;
    } catch (error) {
        console.error('Error fetching materials:', error);
        throw error; // Rethrow the error to handle it wherever this function is called
    }
}

export const getMaterials = () => materialsData;


export const APIurl = 'https://51.20.4.10';


