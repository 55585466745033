import React, { useState, useEffect } from 'react'
import './supportTicketPopup.css'
import { IoIosCloseCircleOutline } from "react-icons/io";

import { getTicketPopupData } from './support_ticket_data';

function SupportTicketPopup(props) {

    const [ticket_data, setTicketData] = useState(null);

    const getSelectedTicketData = async() =>{
        const curr_ticket_data = await getTicketPopupData();
        setTicketData(curr_ticket_data);
    }

    useEffect(() => {
        getSelectedTicketData();
    }, []);

    const handleContainerClick = (e) => {
        // Check if the click occurred outside the container
        if (e.target.classList.contains('support-ticket-popup')) {
            closePopup(); // Close the popup
        }
    };

    const closePopup = () =>{
        props.closeTicketPopup();
    } 

    return (
        <div className='support-ticket-popup' onClick={handleContainerClick}>
            <div className='support-ticket-popup-content'>
                <IoIosCloseCircleOutline id='support-ticket-popup-close-btn' onClick={closePopup}/>
                <p>ID cerere suport: <b>{ticket_data?.id}</b></p>
                <p>Data: <b>{ticket_data?.date}</b></p>
                <p>Email client: <b>{ticket_data?.email}</b></p>
                <p>Mesaj:</p>
                <p><b>"{ticket_data?.message}"</b></p>
            </div>
        </div>
    )
}

export default SupportTicketPopup