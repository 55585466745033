import React, { useState, useEffect } from 'react'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

function PdfOrderSumary(props) {

    const [order_pricing, setOrderPricing] = useState(props.order_pricing);
    const [offcuts_selections, setOffcutsSelections] = useState(props.offcuts_selections);
    const [selected_offcuts, setSelectedOffcuts] = useState([]);
    console.log('Log from pdf doc component:');

    useEffect(() =>{
        console.log(order_pricing);
    }, [order_pricing]);

    useEffect(() =>{
        console.log(offcuts_selections);
        lookForSelectedOffcuts();
    }, [offcuts_selections]);

    const lookForSelectedOffcuts = () =>{
        if(offcuts_selections !== null){
            let new_selected_offcuts = [];
            for(let i = 0; i < offcuts_selections.length; i++){
                const product_code = offcuts_selections[i].product_code;
                const thickness = offcuts_selections[i].thickness;
                const offcuts_array = offcuts_selections[i].offcuts;
                for(let j = 0; j < offcuts_array.length; j++){
                    const offcut_obj = offcuts_array[j];
                    if(offcut_obj.selected === true){
                        const obj = {
                            product_code: product_code,
                            thickness: thickness,
                            cut: offcut_obj.cut,
                            includeRemaining: offcut_obj.includeRemaining,
                            length: offcut_obj.cut === true ? offcut_obj.cutLength : offcut_obj.length,
                            width: offcut_obj.cut === true ? offcut_obj.cutWidth : offcut_obj.width,
                            length2: offcut_obj.length2,
                            width2: offcut_obj.width2
                        }
                        new_selected_offcuts.push(obj);
                    }
                }
            }
            setSelectedOffcuts(new_selected_offcuts);
        }
    }

    function formatPrice(price) {
        const priceNumber = parseFloat(price);
        return priceNumber.toLocaleString('en-GB', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    return (
        <Document title={`MyBoards order summary ${props.order_date}`}>
            <Page style={styles.page}>
                <View style={styles.header}>
                    <Image src='\res\images\myBoardsLogoShadow.png' style={{width: '150px', height: 'auto'}}/>
                    <Text style={{fontSize: '16px'}}>ORDER SUMMARY</Text>
                </View>
                <View style={styles.orderData}>
                    <Text style={styles.orderDataText}>Order ID: {props.order_id}</Text>
                    <Text style={styles.orderDataText}>Order date: {props.order_date}</Text>
                    <Text style={styles.orderDataText}>Price (with VAT): £{props.order_price}</Text>
                    <Text style={styles.orderDeliverySectionText}>Delivery or Pickup: {props.delivery_info.delivery === true ? 'Delivery' : 'Pickup'}</Text>

                    {props.delivery_info.delivery === true ? (
                        <>
                            <Text style={styles.orderDeliverySectionText}>First name: {props.delivery_info.first_name}</Text>
                            <Text style={styles.orderDeliverySectionText}>Last name: {props.delivery_info.last_name}</Text>
                            <Text style={styles.orderDeliverySectionText}>Email: {props.delivery_info.email}</Text>
                            <Text style={styles.orderDeliverySectionText}>Address: {props.delivery_info.address}</Text>
                            <Text style={styles.orderDeliverySectionText}>City: {props.delivery_info.city}</Text>
                            <Text style={styles.orderDeliverySectionText}>Postcode: {props.delivery_info.postcode}</Text>
                            <Text style={styles.orderDeliverySectionText}>Phone number: {props.delivery_info.phone_number}</Text>
                            <Text style={styles.orderDeliverySectionText}>Additional information: {props.delivery_info.additional_info}</Text>
                        </>
                    ) : (
                        <>
                            <Text style={styles.orderDeliverySectionText}>Email: {props.delivery_info.email}</Text>
                            <Text style={styles.orderDeliverySectionText}>Phone number: {props.delivery_info.phone_number}</Text>
                        </>
                    )}
                </View>
                <View style={styles.orderContent}>

                    {order_pricing?.panelsPricing?.length > 0 ? (
                        <View style={styles.orderCategory}>
                            <View style={styles.orderCategoryHeader}>
                                <Text style={styles.orderCategoryHeaderText}>Sheets in order</Text>
                                <Text style={styles.orderCategoryHeaderText}>£{formatPrice(order_pricing?.totalPanelsPricing)}</Text>
                            </View>
                            <View style={styles.orderCategoryContent}>
                                <View style={styles.orderCategoryGrid}>
                                    <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Product code</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Brand</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Decor name - core</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Sheet size</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Quantity</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Unit price</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Row total</Text>
                                    </View>
                                </View>
                                {order_pricing?.panelsPricing.map((panel, index) => (
                                    <View style={styles.orderCategoryGrid} key={'sheet' + index}>
                                        <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{panel?.product_code}</Text>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{panel?.full_sheet === true ? '[Full sheet]' : '[Sheet to be cut]'}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{panel?.brand}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{panel?.decor_name} - {panel?.material_type}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{panel?.sheet_size}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{panel?.quantity}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(panel?.unit_price)}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(panel?.row_total)}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>
                    ) : null}

                    {order_pricing?.edgebandingPricing?.length > 0 ? (
                        <View style={styles.orderCategory}>
                            <View style={styles.orderCategoryHeader}>
                                <Text style={styles.orderCategoryHeaderText}>Edgebanding</Text>
                                <Text style={styles.orderCategoryHeaderText}>£{formatPrice(order_pricing?.totalEdgebandingPricing)}</Text>
                            </View>
                            <View style={styles.orderCategoryContent}>
                                <View style={styles.orderCategoryGrid}>
                                    <View style={{...styles.orderCategoryGridItem, width: '30%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Edgebanding</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Product code</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Quantity (min. 10m)</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '15%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Unit price</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '15%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Row total</Text>
                                    </View>
                                </View>
                                {order_pricing?.edgebandingPricing?.map((edgebanding, index) => (
                                    <View style={styles.orderCategoryGrid} key={'edgebanding' + index}>
                                        <View style={{...styles.orderCategoryGridItem, width: '30%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{edgebanding?.description} - {edgebanding?.brand} - {edgebanding?.looks}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{edgebanding?.product_code}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{edgebanding?.quantity.toFixed(2)}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '15%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(edgebanding?.unit_price)}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '15%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(edgebanding?.total_price)}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>
                    ) : null}

                    {order_pricing?.angleCutsPricing?.unit_price !== null || order_pricing?.hingeDrillingPricing?.unit_price !== null || order_pricing?.shelfDrillingPricing?.unit_price !== null ||  
                        order_pricing?.bondingPricing?.under05sqm_unit_price !== null || order_pricing?.bondingPricing?.under1sqm_unit_price !== null || order_pricing?.bondingPricing?.under2sqm_unit_price !== null ||
                        order_pricing?.bondingPricing?.under3sqm_unit_price !== null || order_pricing?.bondingPricing?.over3sqm_unit_price !== null ? (
                            <View style={styles.orderCategory}>
                                <View style={styles.orderCategoryHeader}>
                                    <Text style={styles.orderCategoryHeaderText}>Additional services</Text>
                                    <Text style={styles.orderCategoryHeaderText}>£{formatPrice(order_pricing?.totalServicesPricing)}</Text>
                                </View>
                                <View style={styles.orderCategoryContent}>
                                    <View style={styles.orderCategoryGrid}>
                                        <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>Service</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>Short description</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>Qty</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>Unit price</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>Row total</Text>
                                        </View>
                                    </View>
                                    {order_pricing?.angleCutsPricing.unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Cutting of panel diagonally</Text>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>(Angle cut)</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Cutting of panel diagonally based on customer requirements - charged per single cut.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.angleCutsPricing.quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.angleCutsPricing.unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.angleCutsPricing.total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.hingeDrillingPricing.unit_price !== null && order_pricing?.hingeDrillingPricing.screwOn_quantity !== 0 || order_pricing?.hingeDrillingPricing.inserta_quantity !== 0 ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Drilling - 35mm hinge cup hole</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Concealed 35mm hinge cup hole drilling.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{parseInt(order_pricing?.hingeDrillingPricing.screwOn_quantity) + parseInt(order_pricing?.hingeDrillingPricing.inserta_quantity)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.hingeDrillingPricing.unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(parseFloat(order_pricing?.hingeDrillingPricing.screwOn_total_price) + parseFloat(order_pricing?.hingeDrillingPricing.inserta_total_price))}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.shelfDrillingPricing.unit_price !== null && order_pricing?.shelfDrillingPricing.mm5_quantity !== 0 || order_pricing?.shelfDrillingPricing.mm75_quantity !== 0 ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Drilling of holes on one side</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Adjustable shelf hole drilling on one side - charged per hole pair.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{parseInt(order_pricing?.shelfDrillingPricing.mm5_quantity) + parseInt(order_pricing?.shelfDrillingPricing.mm75_quantity)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.shelfDrillingPricing.unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(parseFloat(order_pricing?.shelfDrillingPricing.mm5_total_price) + parseFloat(order_pricing?.shelfDrillingPricing.mm75_total_price))}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.bondingPricing.under05sqm_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of panel up to 0.5 sq. m.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 0.5 m2 in size.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.bondingPricing.under05sqm_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.under05sqm_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.under05sqm_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.bondingPricing.under1sqm_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of panel up to 1 sq. m.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 1 m2 in size.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.bondingPricing.under1sqm_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.under1sqm_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.under1sqm_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.bondingPricing.under2sqm_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of panel up to 2 sq. m.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 2 m2 in size.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.bondingPricing.under2sqm_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.under2sqm_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.under2sqm_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.bondingPricing.under3sqm_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of panel up to 3 sq. m.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 3 m2 in size.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.bondingPricing.under3sqm_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.under3sqm_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.under3sqm_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.bondingPricing.over3sqm_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of panel over 3 sq. m.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding over 3 m2 in size.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.bondingPricing.over3sqm_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.over3sqm_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '10%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.bondingPricing.over3sqm_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                </View>
                            </View>
                    ) : null}

                    {order_pricing?.sprayCoatingPricing.whitePrimer_unit_price !== null || order_pricing?.sprayCoatingPricing.solidColorSatin_unit_price !== null || 
                        order_pricing?.sprayCoatingPricing.solidColorMatt_unit_price !== null || order_pricing?.sprayCoatingPricing.clearSealant_unit_price !== null ||
                        order_pricing?.sprayCoatingPricing.clearLacSatin_unit_price !== null || order_pricing?.sprayCoatingPricing.clearLacMatt_unit_price !== null ? (
                            <View style={styles.orderCategory}>
                                <View style={styles.orderCategoryHeader}>
                                    <Text style={styles.orderCategoryHeaderText}>Spray coating</Text>
                                    <Text style={styles.orderCategoryHeaderText}>£{formatPrice(order_pricing?.totalSprayCoatingPricing)}</Text>
                                </View>
                                <View style={styles.orderCategoryContent}>
                                    <View style={styles.orderCategoryGrid}>
                                        <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>Spray coating type</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>Qty [sq.m.]</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>Unit price</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>Row total</Text>
                                        </View>
                                    </View>
                                    {order_pricing?.sprayCoatingPricing.whitePrimer_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>White primer spray coating - charged per panel face.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.sprayCoatingPricing.whitePrimer_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.whitePrimer_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.whitePrimer_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing.solidColorSatin_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Solid colour satin spray coating - charged per panel face.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.sprayCoatingPricing.solidColorSatin_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.solidColorSatin_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.solidColorSatin_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing.solidColorMatt_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Solid colour matt spray coating - charged per panel face.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.sprayCoatingPricing.solidColorMatt_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.solidColorMatt_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.solidColorMatt_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing.clearLacSatin_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Clear lacquer satin spray coating - charged per panel face.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.sprayCoatingPricing.clearLacSatin_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.clearLacSatin_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.clearLacSatin_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing.clearLacMatt_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Clear lacquer matt spray coating - charged per panel face.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.sprayCoatingPricing.clearLacMatt_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.clearLacMatt_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.clearLacMatt_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing.clearSealant_unit_price !== null ? (
                                        <View style={styles.orderCategoryGrid}>
                                            <View style={{...styles.orderCategoryGridItem, width: '40%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>Clear sealant spray coating - charged per panel face.</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{order_pricing?.sprayCoatingPricing.clearSealant_quantity}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.clearSealant_unit_price)}</Text>
                                            </View>
                                            <View style={{...styles.orderCategoryGridItem, width: '20%'}}>
                                                <Text style={styles.orderCategoryGridItemTextSmall}>£{formatPrice(order_pricing?.sprayCoatingPricing.clearSealant_total_price)}</Text>
                                            </View>
                                        </View>
                                    ) : null}
                                </View>
                            </View>
                    ) : null}

                    {selected_offcuts !== null && selected_offcuts?.length > 0 ? (
                        <View style={styles.orderCategory}>
                            <View style={styles.orderCategoryHeader}>
                                <Text style={styles.orderCategoryHeaderText}>Selected offcuts to be included in delivery</Text>
                            </View>
                            <View style={styles.orderCategoryContent}>
                                <View style={styles.orderCategoryGrid}>
                                    <View style={{...styles.orderCategoryGridItem, width: '50%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Product code, thickness</Text>
                                    </View>
                                    <View style={{...styles.orderCategoryGridItem, width: '50%'}}>
                                        <Text style={styles.orderCategoryGridItemTextSmall}>Dimensions</Text>
                                    </View>
                                </View>
                                {selected_offcuts.map((offcut, index) => (
                                    <View style={styles.orderCategoryGrid} key={'offcut' + index}>
                                        <View style={{...styles.orderCategoryGridItem, width: '50%'}}>
                                            <Text style={styles.orderCategoryGridItemTextSmall}>{offcut.product_code}, {offcut.thickness}</Text>
                                        </View>
                                        <View style={{...styles.orderCategoryGridItem, width: '50%'}}>
                                            {offcut.cut === false ? (
                                                <Text style={styles.orderCategoryGridItemTextSmall}>{offcut.length} x {offcut.width} mm</Text>
                                            ) : (
                                                offcut.includeRemaining === true ? (
                                                    <Text style={styles.orderCategoryGridItemTextSmall}>{offcut.length} x {offcut.width} mm & {offcut.length2} x {offcut.width2} mm</Text>
                                                ) : (
                                                    <Text style={styles.orderCategoryGridItemTextSmall}>{offcut.length} x {offcut.width} mm</Text>
                                                )
                                            )}
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>
                    ) : null}

                </View>
            </Page>
        </Document>
    )
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: '25px',
        gap: '25px'
    },
    header: {
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '50px'
    },

    orderDataText: {
        fontSize: '10px',
    },
    orderContent: {
        width: '100%',
        gap: '25px'
    },
    orderCategory: {
        border: '1px solid #e4d5c7',
        borderRadius: '16px',
        overflow: 'hidden',
    },
    orderCategoryHeader: {
        backgroundColor: '#e4d5c7',
        padding: '10px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    orderCategoryHeaderText: {
        fontSize: '12px',
        fontWeight: '500'
    },
    orderCategoryGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 5,
        paddingHorizontal: 0,
        gap: '10px'
    },
    orderCategoryGridItem: {
        padding: '0 10px'
    },
    orderCategoryGridItemText: {
        fontSize: '10px'
    },
    orderDeliverySectionText: {
        fontSize: '10px'
    },
    orderCategoryGridItemTextSmall: {
        fontSize: '8px'
    },
});

export default PdfOrderSumary