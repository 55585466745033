import React, { useState, useEffect } from 'react'
import './pdfComenzi.css';
import { getPopupData } from '../comenzi/popup_data';
import { background } from '@chakra-ui/react';

import { getBinPlacementsAdmin, getPlacedCutsArray, getOffcutsArray } from '../new_order/orderConfig';

function PdfComenzi(props) {
    const [order_pricing, setOrderPricing] = useState(null);
    const [offcuts_selections, setOffcutsSelections] = useState(null);
    const [selected_offcuts, setSelectedOffcuts] = useState(null);
    const [rows_config, setRowsConfig] = useState(null);
    const [order_static_data, setOrderStaticData] = useState(null);
    const [delivery_info, setDeliveryInfo] = useState(null);

    const [binPlacements, setBinPlacements] = useState(null);
    const [placedCuts, setPlacedCuts] = useState(null);
    const [offcuts, setOffcuts] = useState(null);

    const fetchPopupData = async() =>{
        const popup_data = await getPopupData();
        console.log(popup_data);
        setOrderPricing(JSON.parse(popup_data.order_pricing));
        setOffcutsSelections(JSON.parse(popup_data.offcuts_selections));
        setRowsConfig(JSON.parse(popup_data.rows_config));
        let new_static_data = {
            order_price: popup_data.order_price,
            order_date: popup_data.order_date,
            order_id: popup_data.order_id
        };
        setOrderStaticData(new_static_data);
        setDeliveryInfo(JSON.parse(popup_data.delivery_info));

        setBinPlacements(JSON.parse(popup_data.bins));
        setPlacedCuts(JSON.parse(popup_data.placed_cuts));
        setOffcuts(JSON.parse(popup_data.offcuts_array));
    }

    useEffect(() =>{
        fetchPopupData();
    }, []);

    useEffect(() =>{
        console.log(offcuts_selections);
        lookForSelectedOffcuts();
    }, [offcuts_selections]);

    const lookForSelectedOffcuts = () =>{
        if(offcuts_selections !== null && offcuts_selections !== undefined){
            let new_selected_offcuts = [];
            for(let i = 0; i < offcuts_selections.length; i++){
                const product_code = offcuts_selections[i].product_code;
                const thickness = offcuts_selections[i].thickness;
                const offcuts_array = offcuts_selections[i].offcuts;
                for(let j = 0; j < offcuts_array.length; j++){
                    const offcut_obj = offcuts_array[j];
                    if(offcut_obj.selected === true){
                        const obj = {
                            product_code: product_code,
                            thickness: thickness,
                            cut: offcut_obj.cut,
                            includeRemaining: offcut_obj.includeRemaining,
                            length: offcut_obj.cut === true ? offcut_obj.cutLength : offcut_obj.length,
                            width: offcut_obj.cut === true ? offcut_obj.cutWidth : offcut_obj.width,
                            length2: offcut_obj.length2,
                            width2: offcut_obj.width2
                        }
                        new_selected_offcuts.push(obj);
                    }
                }
            }
            setSelectedOffcuts(new_selected_offcuts);
        }
    }

    function formatPrice(price) {
        if(price !== null && price !== undefined){
            const priceNumber = parseFloat(price);
            return priceNumber.toLocaleString('en-GB', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }else{
            return 0;
        }
    }


    const [svgDimensions, setSvgDimensions] = useState({svgWidth: 400, svgHeight: 400});

    const [drawing_displays, setDrawingDisplays] = useState([]);

    const displayInitialPanelDrawing = () =>{
        console.log('-> /// DISPLAY INITIAL DRAWING');
        const drawingPadding = 20;
        let width = 400;
        let height = 400;
        let viewWidth = 400;
        let viewHeight = 400;
        let new_drawing_displays = [];
        if(rows_config !== null){
            for(let i = 0; i < rows_config.length; i++){
                // check if there is an existing machiningConfig in the rowConfig
                const currRowConfig = rows_config[i];
                console.log(currRowConfig);
                let inputLength = parseInt(currRowConfig.materialLength, 10);
                let inputWidth = parseInt(currRowConfig.width, 10);
                if(currRowConfig.machiningConfig != null){
                    const existingMachiningConfig = currRowConfig.machiningConfig;
                    let drawingLength = null;
                    let drawingWidth = null;
                    let l1w1_arrows = null;
                    let l1w2_arrows = null;
                    let l2w1_arrows = null;
                    let l2w2_arrows = null;
                    let newSvgWidth = null;
                    let newSvgHeight = null;
                    let hingeScalePercentage = null;
                    // calculate and set the svg dimensions and angle cut arrows
                    if(inputLength >= inputWidth){
                        // calculate the percentage of width out of length
                        let percentage = (inputWidth / inputLength);
                        newSvgWidth = width;
                        if(percentage < 0.25){
                            percentage = 0.25;
                        }
                        newSvgHeight = width * percentage;
                        if(newSvgHeight > height){
                            newSvgHeight = height;
                        }
                        setSvgDimensions(() => ({
                            svgWidth: newSvgWidth,
                            svgHeight: newSvgHeight,
                        }));
                        drawingLength = newSvgWidth - drawingPadding * 2;
                        drawingWidth = newSvgHeight - drawingPadding * 2;
                        // write the points string for the "from X" arrows
                        l1w1_arrows = {
                            from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                            from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                        };
                        l1w2_arrows = {
                            from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                            from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                        };
                        l2w1_arrows = {
                            from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                            from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                        };
                        l2w2_arrows = {
                            from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                            from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                        };
                    }else{
                        // calculate the percentage of length out of width
                        let percentage = (inputLength / inputWidth);
                        newSvgHeight = height;
                        if(percentage < 0.25){
                            percentage = 0.25;
                        }
                        newSvgWidth = height * percentage;
                        if(newSvgWidth > width){
                            newSvgWidth = width;
                        }
                        setSvgDimensions(() => ({
                            svgWidth: newSvgWidth,
                            svgHeight: newSvgHeight,
                        }));
                        drawingLength = newSvgWidth - drawingPadding * 2;
                        drawingWidth = newSvgHeight - drawingPadding * 2;
                        // write the points string for the "from X" arrows
                        l1w1_arrows = {
                            from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                            from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                        };
                        l1w2_arrows = {
                            from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                            from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                        };
                        l2w1_arrows = {
                            from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                            from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                        };
                        l2w2_arrows = {
                            from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                            from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                        };
                    }
                    //  check for angle cuts and transform every point from machiningConfig to drawingProps points
                    //  points positions for the drawing display (scaled)
                    const lengthScale = drawingLength / inputLength;
                    const widthScale = drawingWidth / inputWidth;
                    let topLeft = { x: drawingPadding + parseInt(existingMachiningConfig.topLeft.x) * lengthScale, y: drawingPadding};
                    let topRight = { x: drawingPadding + drawingLength, y: drawingPadding + parseInt(existingMachiningConfig.topRight.y) * widthScale };
                    let bottomRight = { x: drawingPadding + parseInt(existingMachiningConfig.bottomRight.x) * lengthScale, y: drawingPadding + drawingWidth};
                    let bottomLeft = { x: drawingPadding, y: drawingPadding + parseInt(existingMachiningConfig.bottomLeft.y) * widthScale};
                    let pointsString = '';
                    let l1w1EdgebandingString = null;
                    let l1w2EdgebandingString = null;
                    let l2w1EdgebandingString = null;
                    let l2w2EdgebandingString = null;
                    let W1P = null;
                    let W2P = null;
                    let L1P = null;
                    let L2P = null;
                    // check for l1w1 cut
                    if(existingMachiningConfig.W1P != null){
                        const fW1P = existingMachiningConfig.W1P;
                        W1P = {x: drawingPadding, y: parseInt(fW1P.y) * widthScale + drawingPadding};
                        let W1P_points = `${W1P.x},${W1P.y}`;
                        let TL = topLeft;
                        let TL_points = `${TL.x},${TL.y}`;
                        pointsString = `${W1P_points} ${TL_points}`;
                        if(existingMachiningConfig.l1w1_edgebanding != null){
                            l1w1EdgebandingString = `M${W1P.x - 2.5} ${W1P.y - 2.5} L${TL.x - 2.5} ${TL.y - 2.5}`;
                        }
                    }else{
                        let TL = topLeft;
                        let TL_points = `${TL.x},${TL.y}`;
                        pointsString = `${TL_points}`;
                    }
                    // check for l1w2 cut
                    if(existingMachiningConfig.L1P != null){
                        const fL1P = existingMachiningConfig.L1P;
                        L1P = {x: parseInt(fL1P.x) * lengthScale + drawingPadding, y: drawingPadding};
                        let L1P_points = `${L1P.x},${L1P.y}`;
                        let TR = topRight;
                        let TR_points = `${TR.x},${TR.y}`;
                        pointsString = `${pointsString} ${L1P_points} ${TR_points}`;
                        if(existingMachiningConfig.l1w2_edgebanding != null){
                            l1w2EdgebandingString = `M${L1P.x + 2.5} ${L1P.y - 2.5} L${TR.x + 2.5} ${TR.y - 2.5}`;
                        }
                    }else{
                        let TR = topRight;
                        let TR_points = `${TR.x},${TR.y}`;
                        pointsString = `${pointsString} ${pointsString} ${TR_points}`;
                    }
                    // check for l2w2 cut
                    if(existingMachiningConfig.W2P != null){
                        const fW2P = existingMachiningConfig.W2P;
                        W2P = {x: topRight.x, y: parseInt(fW2P.y) * widthScale + drawingPadding};
                        let W2P_points = `${W2P.x},${W2P.y}`;
                        let BR = bottomRight;
                        let BR_points = `${BR.x},${BR.y}`;
                        pointsString = `${pointsString} ${W2P_points} ${BR_points}`;
                        if(existingMachiningConfig.l2w2_edgebanding != null){
                            l2w2EdgebandingString = `M${W2P.x + 2.5} ${W2P.y + 2.5} L${BR.x + 2.5} ${BR.y + 2.5}`;
                        }
                    }else{
                        let BR = bottomRight;
                        let BR_points = `${BR.x},${BR.y}`;
                        pointsString = `${pointsString} ${BR_points}`;
                    }
                    // check for l2w1 cut
                    if(existingMachiningConfig.L2P != null){
                        const fL2P = existingMachiningConfig.L2P;
                        L2P = {x: parseInt(fL2P.x) * lengthScale + drawingPadding, y: bottomRight.y};
                        let L2P_points = `${L2P.x},${L2P.y}`;
                        let BL= bottomLeft;
                        let BL_points = `${BL.x},${BL.y}`;
                        pointsString = `${pointsString} ${L2P_points} ${BL_points}`;
                        if(existingMachiningConfig.l2w1_edgebanding != null){
                            l2w1EdgebandingString = `M${L2P.x - 2.5} ${L2P.y + 2.5} L${BL.x - 2.5} ${BL.y + 2.5}`;
                        }
                    }else{
                        let BL= bottomLeft;
                        let BL_points = `${BL.x},${BL.y}`;
                        pointsString = `${pointsString} ${BL_points}`;
                    }
    
                    //  check for hinge drilling
                    let scaledHingeHolesPos = [];
                    if(existingMachiningConfig.hingeHoles != null){
                        console.log('-> found hinge drilling');
                        if(existingMachiningConfig.hingeHolesSide == 'l1'){
                            // add each hole to the drawing props (scaled)
                            const scalePercentage = lengthScale;
                            let hingeHolesPosInputs = [];
                            let hingeHolesPosInputsLimits = [];
                            let hingeHolesPosInputsErrors = [];
                            for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                                let scaledHingeHole = {
                                    x: parseInt(existingMachiningConfig.hingeHolesPos[i].x) * scalePercentage + drawingPadding,
                                    y: parseInt(existingMachiningConfig.hingeHolesPos[i].y) * scalePercentage + drawingPadding,
                                } 
                                scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                                //  set the hinge holes pos inputs values based on their current position
                                hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].x];
                                hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                                //  set the hinge holes pos limits from rowConfig
                                hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                            }
                        }else if(existingMachiningConfig.hingeHolesSide == 'l2'){
                            // add each hole to the drawing props (scaled)
                            const scalePercentage = lengthScale;
                            let hingeHolesPosInputs = [];
                            let hingeHolesPosInputsLimits = [];
                            let hingeHolesPosInputsErrors = [];
                            for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                                let scaledHingeHole = {
                                    x: parseInt(existingMachiningConfig.hingeHolesPos[i].x) * scalePercentage + drawingPadding,
                                    y: drawingWidth + drawingPadding - (50 * scalePercentage),
                                } 
                                scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                                //  set the hinge holes pos inputs values based on their current position
                                hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].x];
                                hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                                //  set the hinge holes pos limits from rowConfig
                                hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                            }
                        }else if(existingMachiningConfig.hingeHolesSide == 'w1'){
                            // add each hole to the drawing props (scaled)
                            const scalePercentage = widthScale;
                            let hingeHolesPosInputs = [];
                            let hingeHolesPosInputsLimits = [];
                            let hingeHolesPosInputsErrors = [];
                            for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                                let scaledHingeHole = {
                                    x: parseInt(existingMachiningConfig.hingeHolesPos[i].x) * scalePercentage + drawingPadding,
                                    y: parseInt(existingMachiningConfig.hingeHolesPos[i].y) * scalePercentage + drawingPadding,
                                } 
                                scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                                //  set the hinge holes pos inputs values based on their current position
                                hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].y];
                                hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                                //  set the hinge holes pos limits from rowConfig
                                hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                            }
                        }else if(existingMachiningConfig.hingeHolesSide == 'w2'){
                            // add each hole to the drawing props (scaled)
                            const scalePercentage = widthScale;
                            let hingeHolesPosInputs = [];
                            let hingeHolesPosInputsLimits = [];
                            let hingeHolesPosInputsErrors = [];
                            for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                                let scaledHingeHole = {
                                    x: drawingLength - 50 * scalePercentage + drawingPadding,
                                    y: parseInt(existingMachiningConfig.hingeHolesPos[i].y) * scalePercentage + drawingPadding,
                                } 
                                scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                                //  set the hinge holes pos inputs values based on their current position
                                hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].y];
                                hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                                //  set the hinge holes pos limits from rowConfig
                                hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                            }
                        }
                    }
    
                    //  check for adjustable shelf drilling (clusters)
                    let scaledClustersPosArray = [];
                    let scaledStep = null;
                    let clustersEdge = null;
                    let clustersPositions = 0;
                    if(existingMachiningConfig.clusters != null){
                        // clustersHoles: [],
                        // clusterStep: null,
                        if(existingMachiningConfig.clustersEdge == 'l1-l2'){
                            clustersEdge = 'l1-l2';
                            clustersPositions = existingMachiningConfig.clustersPositions;
                            //  calculate scaled positions for drawingProps
                            const scalePercentageX = lengthScale;
                            const scalePercentageY = widthScale;
                            const clusters_step = parseInt(existingMachiningConfig.clustersStep);
                            const clusters_distance1 = parseInt(existingMachiningConfig.clustersDistance1);
                            const clusters_distance2 = parseInt(existingMachiningConfig.clustersDistance2);
                            scaledStep = clusters_step * scalePercentageX;
                            scaledClustersPosArray = [...scaledClustersPosArray, {x: existingMachiningConfig.clustersPos[0] * scalePercentageX + drawingPadding, y1: drawingPadding + clusters_distance1 * scalePercentageY, y2: (parseInt(currRowConfig.width) - clusters_distance2) * scalePercentageY + drawingPadding}];
                            for(let i = 1; i < parseInt(existingMachiningConfig.clusters); i++){
                                const this_cluster_scaled_pos = {x: existingMachiningConfig.clustersPos[i] * scalePercentageX + drawingPadding, y1: drawingPadding + clusters_distance1 * scalePercentageY, y2: (parseInt(currRowConfig.width) - clusters_distance2) * scalePercentageY + drawingPadding};
                                scaledClustersPosArray = [...scaledClustersPosArray, this_cluster_scaled_pos];
                            }
                            //  set the shelf drilling input values
                            const savedInputValues = {
                                distance1: existingMachiningConfig.clustersDistance1,
                                distance2: existingMachiningConfig.clustersDistance2,
                                step: existingMachiningConfig.clustersStep,
                                positions: existingMachiningConfig.clustersPositions,
                                number_of_clusters: existingMachiningConfig.clusters,
                            }
                            //  set the clusters pos inputs limits error as false
                            let savedClustersPosInputsErrors = []
                            for(let i = 0; i < existingMachiningConfig.clusters; i++){
                                savedClustersPosInputsErrors = [...savedClustersPosInputsErrors, false];
                            }
                        }else if(existingMachiningConfig.clustersEdge == 'w1-w2'){
                            clustersEdge = 'w1-w2'
                            clustersPositions = existingMachiningConfig.clustersPositions;
                            const scalePercentageX = lengthScale;
                            const scalePercentageY = widthScale;
                            const clusters_step = parseInt(existingMachiningConfig.clustersStep);
                            const clusters_distance1 = parseInt(existingMachiningConfig.clustersDistance1);
                            const clusters_distance2 = parseInt(existingMachiningConfig.clustersDistance2);
                            scaledStep = clusters_step * scalePercentageY;
                            scaledClustersPosArray = [...scaledClustersPosArray, {x1: drawingPadding + clusters_distance1 * scalePercentageX, y: existingMachiningConfig.clustersPos[0] * scalePercentageY + drawingPadding, x2: (parseInt(currRowConfig.materialLength) - clusters_distance2) * scalePercentageX + drawingPadding}];
                            for(let i = 1; i < parseInt(existingMachiningConfig.clusters); i++){
                                const this_cluster_scaled_pos = {x1: drawingPadding + clusters_distance1 * scalePercentageX, y: existingMachiningConfig.clustersPos[i] * scalePercentageY + drawingPadding, x2: (parseInt(currRowConfig.materialLength) - clusters_distance2) * scalePercentageX + drawingPadding};
                                scaledClustersPosArray = [...scaledClustersPosArray, this_cluster_scaled_pos];
                            }
                            //  set the shelf drilling input values
                            const savedInputValues = {
                                distance1: existingMachiningConfig.clustersDistance1,
                                distance2: existingMachiningConfig.clustersDistance2,
                                step: existingMachiningConfig.clustersStep,
                                positions: existingMachiningConfig.clustersPositions,
                                number_of_clusters: existingMachiningConfig.clusters,
                            }
                            //  set the clusters pos inputs limits error as false
                            let savedClustersPosInputsErrors = []
                            for(let i = 0; i < existingMachiningConfig.clusters; i++){
                                savedClustersPosInputsErrors = [...savedClustersPosInputsErrors, false];
                            }
                        }
                    }
    
                    const row_drawing_display = {
                        topLeft: topLeft,
                        topRight: topRight,
                        bottomRight: bottomRight,
                        bottomLeft: bottomLeft,
                        L1P: L1P,
                        L2P: L2P,
                        W1P: W1P,
                        W2P: W2P,
                        points: pointsString,
                        drawingLength: drawingLength,
                        drawingWidth: drawingWidth,
                        l1w1_arrows: l1w1_arrows,
                        l1w2_arrows: l1w2_arrows,
                        l2w1_arrows: l2w1_arrows,
                        l2w2_arrows: l2w2_arrows,
                        l1w1_edgebanding: l1w1EdgebandingString,
                        l1w2_edgebanding: l1w2EdgebandingString,
                        l2w1_edgebanding: l2w1EdgebandingString,
                        l2w2_edgebanding: l2w2EdgebandingString,
                        hingeHoles: scaledHingeHolesPos,
                        clustersHoles: scaledClustersPosArray,
                        clusterStep: scaledStep,
                        clustersEdge: clustersEdge,
                        clustersPositions: clustersPositions
                    };
                    new_drawing_displays[i] = row_drawing_display;
    
                }else{  // if there is no existing machiningConfig in the rowConfig
                    let topLeft = null;
                    let topRight = null;
                    let bottomRight = null;
                    let bottomLeft = null;
                    console.log(currRowConfig);
                    console.log('rowConfig length: ' + inputLength);
                    console.log('rowConfig width: ' + inputWidth);
                    if(inputLength >= inputWidth){
                        // calculate the percentage of width out of length
                        let percentage = (inputWidth / inputLength);
                        const newSvgWidth = viewWidth;
                        if(percentage < 0.25){
                            percentage = 0.25;
                        }
                        let newSvgHeight = viewWidth * percentage;
                        if(newSvgHeight > viewHeight){
                            newSvgHeight = viewHeight;
                        }
    
                        console.log('viewWidth: ' + viewWidth);
                        console.log('viewHeight: ' + viewHeight);
                        setSvgDimensions(() => ({
                            svgWidth: newSvgWidth,
                            svgHeight: newSvgHeight,
                        }));
    
                        // points positions for the full size panel
                        let fTopLeft = { x: 0, y: 0 };
                        let fTopRight = { x: inputLength , y: 0 };
                        let fBottomRight = { x: inputLength, y: inputWidth};
                        let fBottomLeft = { x: 0, y: inputWidth };
    
                        // points positions for the drawing display (scaled)
                        topLeft = { x: drawingPadding, y: drawingPadding };
                        topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                        bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                        bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                        
                        const drawingLength = newSvgWidth - drawingPadding * 2;
                        const drawingWidth = newSvgHeight - drawingPadding * 2;
    
                        console.log('drawingLength: ' + drawingLength);
                        console.log('drawingWidth: ' + drawingWidth);
    
                        // write the points string for the "from X" arrows
                        const l1w1_arrows = {
                            from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                            from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                        };
                        const l1w2_arrows = {
                            from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                            from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                        };
                        const l2w1_arrows = {
                            from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                            from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                        };
                        const l2w2_arrows = {
                            from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                            from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                        };
                        console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);
    
                        const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                        console.log(pointsArray);
                        // Convert the points array to a string
                        const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
                        const row_drawing_display = {
                            topLeft: topLeft,
                            topRight: topRight,
                            bottomRight: bottomRight,
                            bottomLeft: bottomLeft,
                            points: pointsString,
                            drawingLength: drawingLength,
                            drawingWidth: drawingWidth,
                            l1w1_arrows: l1w1_arrows,
                            l1w2_arrows: l1w2_arrows,
                            l2w1_arrows: l2w1_arrows,
                            l2w2_arrows: l2w2_arrows,
                        };
                        new_drawing_displays[i] = row_drawing_display;
    
    
                        console.log('Svg width:' + newSvgWidth);
                        console.log('Svg height' + newSvgHeight);
                    }else{
                        // calculate the percentage of length out of width
                        let percentage = (inputLength / inputWidth);
                        const newSvgHeight = viewHeight;
                        if(percentage < 0.25){
                            percentage = 0.25;
                        }
                        let newSvgWidth = viewHeight * percentage;
                        if(newSvgWidth > viewWidth){
                            newSvgWidth = viewWidth;
                        }
    
                        console.log('viewWidth: ' + viewWidth);
                        console.log('viewHeight: ' + viewHeight);
                        setSvgDimensions(() => ({
                            svgWidth: newSvgWidth,
                            svgHeight: newSvgHeight,
                        }));
    
                        // points positions for the full size panel
                        let fTopLeft = { x: 0, y: 0 };
                        let fTopRight = { x: inputLength , y: 0 };
                        let fBottomRight = { x: inputLength, y: inputWidth};
                        let fBottomLeft = { x: 0, y: inputWidth };
    
                        // points positions for the drawing display (scaled)
                        topLeft = { x: drawingPadding, y: drawingPadding };
                        topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                        bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                        bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                        
                        const drawingLength = newSvgWidth - drawingPadding * 2;
                        const drawingWidth = newSvgHeight - drawingPadding * 2;
    
                        console.log('drawingLength: ' + drawingLength);
                        console.log('drawingWidth: ' + drawingWidth);
    
                        // write the points string for the "from X" arrows
                        const l1w1_arrows = {
                            from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                            from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                        };
                        const l1w2_arrows = {
                            from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                            from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                        };
                        const l2w1_arrows = {
                            from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                            from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                        };
                        const l2w2_arrows = {
                            from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                            from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                        };
                        console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);
    
                        const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                        console.log(pointsArray);
                        // Convert the points array to a string
                        const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
    
                        const row_drawing_display ={
                            topLeft: topLeft,
                            topRight: topRight,
                            bottomRight: bottomRight,
                            bottomLeft: bottomLeft,
                            points: pointsString,
                            drawingLength: drawingLength,
                            drawingWidth: drawingWidth,
                            l1w1_arrows: l1w1_arrows,
                            l1w2_arrows: l1w2_arrows,
                            l2w1_arrows: l2w1_arrows,
                            l2w2_arrows: l2w2_arrows,
                        };
                        new_drawing_displays[i] = row_drawing_display;
    
                        console.log('Svg width:' + newSvgWidth);
                        console.log('Svg height' + newSvgHeight);
                    }
                }
            }
            setDrawingDisplays(new_drawing_displays);
        }
    };

    useEffect(() =>{
        displayInitialPanelDrawing();
    }, [rows_config]);

    const [smallDrawingContainerSize, setSmallDrawingContainerSize] = useState({length: 400, width: 400});
    const smallDrawingPadding = 10;

    const [scaledBins, setScaledBins] = useState(null);
    const [scaledCuts, setScaledCuts] = useState(null);
    const [scaledOffcuts, setScaledOffcuts] = useState(null);

    //  calculate scaled bin rectangle, scaled cuts, scaled offcuts  
    useEffect(() => {
        let scaledBinsArray = [];
        let newScaledCuts = [];
        let newScaledOffcuts = [];
        if(binPlacements !== null && binPlacements !== undefined && placedCuts !== null && placedCuts !== undefined && offcuts !== null && offcuts !== undefined && smallDrawingContainerSize !== null && smallDrawingContainerSize !== undefined){
            for(let i = 0; i < binPlacements.length; i++){
                //  calculate the scaled bin rectangle dimensions to get the scale percentages
                const fullPanelLength = parseInt(binPlacements[i].root.length) + 10;
                const fullPanelWidth = parseInt(binPlacements[i].root.width) + 10;
                let scaledBinLength = null;
                let scaledBinWidth = null;
                if(fullPanelLength > fullPanelWidth){
                    scaledBinLength = smallDrawingContainerSize.length;
                    const differencePercentage = fullPanelWidth / fullPanelLength;
                    scaledBinWidth = scaledBinLength * differencePercentage;
                }else{
                    scaledBinWidth = smallDrawingContainerSize.width;
                    const differencePercentage = fullPanelLength / fullPanelWidth;
                    scaledBinLength = scaledBinWidth * differencePercentage;
                }
                const scaledBinObj = {length: scaledBinLength, width: scaledBinWidth};
                scaledBinsArray = [...scaledBinsArray, scaledBinObj];

                //  calculate the position and dimensions for each scaled cut
                const cutsObj = placedCuts[i].cuts;
                const scalePercentageLength = scaledBinLength / fullPanelLength;
                const scalePercentageWidth = scaledBinWidth / fullPanelWidth;
                let newScaledCutsObj = [];
                for(let j = 0; j < cutsObj.length; j++){    //  for each cut individually
                    const newScaledCut = {
                        length: parseInt(cutsObj[j].length) * scalePercentageLength,
                        width: parseInt(cutsObj[j].width) * scalePercentageWidth,
                        x: parseInt(cutsObj[j].x) * scalePercentageLength,
                        y: parseInt(cutsObj[j].y) * scalePercentageWidth,
                    };
                    newScaledCutsObj = [...newScaledCutsObj, newScaledCut];
                }
                newScaledCuts = [...newScaledCuts, newScaledCutsObj];

                //  calculate the position and dimensions for each scaled offcut
                const offcutObj = offcuts[i].nodes;
                let newScaledOffutsObj = [];
                for(let j = 0; j < offcutObj.length; j++){
                    const newScaledOffcut = {
                        length: parseInt(offcutObj[j].length) * scalePercentageLength,
                        width: parseInt(offcutObj[j].width) * scalePercentageWidth,
                        x: parseInt(offcutObj[j].x) * scalePercentageLength,
                        y: parseInt(offcutObj[j].y) * scalePercentageWidth,
                    };
                    newScaledOffutsObj = [...newScaledOffutsObj, newScaledOffcut];
                }
                newScaledOffcuts = [...newScaledOffcuts, newScaledOffutsObj];
            }
        }
        setScaledBins(scaledBinsArray);
        setScaledCuts(newScaledCuts);
        setScaledOffcuts(newScaledOffcuts);
    }, [binPlacements, placedCuts, offcuts, smallDrawingContainerSize]);


    const closePopup = () =>{
        props.closeSummaryPopup();
    }

    return (
            <div className='page'>
                <div style={{background: 'red', color: 'white', width: 'fit-content', padding: '5px', borderRadius: '5px', cursor: 'pointer'}} onClick={closePopup}>
                    <p>Close</p>
                </div>
                <div className={'header'}>
                    <img src='\res\images\myBoardsLogoShadow.png' style={{width: '150px', height: 'auto'}} alt='logo'/>
                    <p style={{fontSize: '16px'}}>ORDER SUMMARY</p>
                </div>
                <div className={'orderDatap'}>
                    <p className={'orderDatap'}>Order ID: {order_static_data?.order_id}</p>
                    <p className={'orderDatap'}>Order date: {order_static_data?.order_date}</p>
                    <p className={'orderDatap'}>Price (with VAT): £{order_static_data?.order_price.toString()}</p>

                    <p style={{fontSize: '12px', fontWeight: '500', marginTop: '25px'}}>Delivery info:</p>
                    <p className={'orderDeliverySectionp'}>Delivery or Pickup: {delivery_info?.delivery === true ? 'Delivery' : 'Pickup'}</p>
                    {delivery_info?.delivery === true ? (
                        <>
                            <p className={'orderDeliverySectionp'}>First name: {delivery_info?.first_name}</p>
                            <p className={'orderDeliverySectionp'}>Last name: {delivery_info?.last_name}</p>
                            <p className={'orderDeliverySectionp'}>Email: {delivery_info?.email}</p>
                            <p className={'orderDeliverySectionp'}>Address: {delivery_info?.address}</p>
                            <p className={'orderDeliverySectionp'}>City: {delivery_info?.city}</p>
                            <p className={'orderDeliverySectionp'}>Postcode: {delivery_info?.postcode}</p>
                            <p className={'orderDeliverySectionp'}>Phone number: {delivery_info?.phone_number}</p>
                            <p className={'orderDeliverySectionp'}>Additional information: {delivery_info?.additional_info}</p>
                        </>
                    ) : (
                        <>
                            <p className={'orderDeliverySectionp'}>First name: {delivery_info?.first_name}</p>
                            <p className={'orderDeliverySectionp'}>Last name: {delivery_info?.last_name}</p>
                            <p className={'orderDeliverySectionp'}>Email: {delivery_info?.email}</p>
                            <p className={'orderDeliverySectionp'}>Phone number: {delivery_info?.phone_number}</p>
                        </>
                    )}
                </div>
                <div className={'orderContent'}>

                    {order_pricing !== null ? (
                        <div className={'orderCategory'}>
                            <div className={'orderCategoryHeader'}>
                                <p className={'orderCategoryHeaderp'}>Sheets in order</p>
                                <p className={'orderCategoryHeaderp'}>£{formatPrice(order_pricing?.totalPanelsPricing)}</p>
                            </div>
                            <div className={'orderCategoryContent'}>
                                <div className={'orderCategoryGrid'}>
                                    <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Product code</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Brand</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Decor name - core</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Sheet size</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Quantity</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Unit price</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Row total</p>
                                    </div>
                                </div>
                                {order_pricing?.panelsPricing?.map((panel, index) => (
                                    <div className={'orderCategoryGrid'} key={'sheet' + index}>
                                        <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>{panel?.product_code}</p>
                                            <p className={'orderCategoryGridItempSmall'}>{panel?.full_sheet === true ? '[Full sheet]' : '[Sheet to be cut]'}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>{panel?.brand}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>{panel?.decor_name} - {panel?.material_type}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>{panel?.sheet_size}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>{panel?.quantity}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>£{formatPrice(panel?.unit_price)}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>£{formatPrice(panel?.row_total)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {order_pricing?.edgebandingPricing?.length > 0 ? (
                        <div className={'orderCategory'}>
                            <div className={'orderCategoryHeader'}>
                                <p className={'orderCategoryHeaderp'}>Edgebanding</p>
                                <p className={'orderCategoryHeaderp'}>£{formatPrice(order_pricing?.totalEdgebandingPricing)}</p>
                            </div>
                            <div className={'orderCategoryContent'}>
                                <div className={'orderCategoryGrid'}>
                                    <div className={'orderCategoryGridItem'} style={{width: '30%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Edgebanding</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Product code</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Quantity (min. 10m)</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '15%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Unit price</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '15%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Row total</p>
                                    </div>
                                </div>
                                {order_pricing?.edgebandingPricing.map((edgebanding, index) => (
                                    <div className={'orderCategoryGrid'} key={'edgebanding' + index}>
                                        <div className={'orderCategoryGridItem'} style={{width: '30%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>{edgebanding?.description} - {edgebanding?.brand} - {edgebanding?.looks}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>{edgebanding?.product_code}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>{edgebanding?.quantity.toFixed(2)}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '15%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>£{edgebanding?.unit_price?.toFixed(2)}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '15%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>£{formatPrice(edgebanding?.total_price)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {order_pricing?.angleCutsPricing?.unit_price !== null || 
                        order_pricing?.hingeDrillingPricing?.unit_price !== null || 
                        order_pricing?.shelfDrillingPricing?.unit_price !== null ||  
                        order_pricing?.bondingPricing?.under05sqm_unit_price !== null || 
                        order_pricing?.bondingPricing?.under1sqm_unit_price !== null || 
                        order_pricing?.bondingPricing?.under2sqm_unit_price !== null ||
                        order_pricing?.bondingPricing?.under3sqm_unit_price !== null || 
                        order_pricing?.bondingPricing?.over3sqm_unit_price !== null ? (
                            <div className={'orderCategory'}>
                                <div className={'orderCategoryHeader'}>
                                    <p className={'orderCategoryHeaderp'}>Additional services</p>
                                    <p className={'orderCategoryHeaderp'}>£{formatPrice(order_pricing?.totalServicesPricing)}</p>
                                </div>
                                <div className={'orderCategoryContent'}>
                                    <div className={'orderCategoryGrid'}>
                                        <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>Service</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>Short description</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>Qty</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>Unit price</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>Row total</p>
                                        </div>
                                    </div>
                                    {order_pricing?.angleCutsPricing?.unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Cutting of panel diagonally</p>
                                                <p className={'orderCategoryGridItempSmall'}>(Angle cut)</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Cutting of panel diagonally based on customer requirements - charged per single cut.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.angleCutsPricing?.quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{order_pricing?.angleCutsPricing?.unit_price !== null ? formatPrice(order_pricing?.angleCutsPricing?.unit_price) : '-'}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.angleCutsPricing?.total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.hingeDrillingPricing?.unit_price !== null && order_pricing?.hingeDrillingPricing?.screwOn_quantity !== 0 || order_pricing?.hingeDrillingPricing?.inserta_quantity !== 0 ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Drilling - 35mm hinge cup hole</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Concealed 35mm hinge cup hole drilling.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{parseInt(order_pricing?.hingeDrillingPricing?.screwOn_quantity) + parseInt(order_pricing?.hingeDrillingPricing?.inserta_quantity)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.hingeDrillingPricing?.unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(parseFloat(order_pricing?.hingeDrillingPricing?.screwOn_total_price) + parseFloat(order_pricing?.hingeDrillingPricing?.inserta_total_price))}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.shelfDrillingPricing?.unit_price !== null && order_pricing?.shelfDrillingPricing?.mm5_quantity !== 0 || order_pricing?.shelfDrillingPricing?.mm75_quantity !== 0 ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Drilling of holes on one side</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Adjustable shelf hole drilling on one side - charged per hole pair.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{parseInt(order_pricing?.shelfDrillingPricing?.mm5_quantity) + parseInt(order_pricing?.shelfDrillingPricing?.mm75_quantity)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.shelfDrillingPricing?.unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(parseFloat(order_pricing?.shelfDrillingPricing?.mm5_total_price) + parseFloat(order_pricing?.shelfDrillingPricing?.mm75_total_price))}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.bondingPricing?.under05sqm_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of panel up to 0.5 sq. m.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 0.5 m2 in size.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.bondingPricing?.under05sqm_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.under05sqm_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.under05sqm_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.bondingPricing?.under1sqm_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of panel up to 1 sq. m.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 1 m2 in size.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.bondingPricing?.under1sqm_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.under1sqm_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.under1sqm_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.bondingPricing?.under2sqm_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of panel up to 2 sq. m.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 2 m2 in size.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.bondingPricing?.under2sqm_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.under2sqm_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.under2sqm_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.bondingPricing?.under3sqm_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of panel up to 3 sq. m.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 3 m2 in size.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.bondingPricing?.under3sqm_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.under3sqm_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.under3sqm_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.bondingPricing?.over3sqm_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of panel over 3 sq. m.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Bonding of two panels in order to create a thicker panel. Price level of panel bonding over 3 m2 in size.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.bondingPricing?.over3sqm_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.over3sqm_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '10%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.bondingPricing?.over3sqm_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                    ) : null}

                    {order_pricing?.sprayCoatingPricing?.whitePrimer_unit_price !== null || order_pricing?.sprayCoatingPricing?.solidColorSatin_unit_price !== null || 
                        order_pricing?.sprayCoatingPricing?.solidColorMatt_unit_price !== null || order_pricing?.sprayCoatingPricing?.clearSealant_unit_price !== null ||
                        order_pricing?.sprayCoatingPricing?.clearLacSatin_unit_price !== null || order_pricing?.sprayCoatingPricing?.clearLacMatt_unit_price !== null ? (
                            <div className={'orderCategory'}>
                                <div className={'orderCategoryHeader'}>
                                    <p className={'orderCategoryHeaderp'}>Spray coating</p>
                                    <p className={'orderCategoryHeaderp'}>£{formatPrice(order_pricing?.totalSprayCoatingPricing)}</p>
                                </div>
                                <div className={'orderCategoryContent'}>
                                    <div className={'orderCategoryGrid'}>
                                        <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>Spray coating type</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>Qty [sq.m.]</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>Unit price</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>Row total</p>
                                        </div>
                                    </div>
                                    {order_pricing?.sprayCoatingPricing?.whitePrimer_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>White primer spray coating - charged per panel face.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.sprayCoatingPricing?.whitePrimer_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.whitePrimer_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.whitePrimer_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing?.solidColorSatin_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Solid colour satin spray coating - charged per panel face.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.sprayCoatingPricing?.solidColorSatin_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.solidColorSatin_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.solidColorSatin_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing?.solidColorMatt_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Solid colour matt spray coating - charged per panel face.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.sprayCoatingPricing?.solidColorMatt_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.solidColorMatt_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.solidColorMatt_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing?.clearLacSatin_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Clear lacquer satin spray coating - charged per panel face.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.sprayCoatingPricing?.clearLacSatin_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.clearLacSatin_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.clearLacSatin_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing?.clearLacMatt_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Clear lacquer matt spray coating - charged per panel face.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.sprayCoatingPricing?.clearLacMatt_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.clearLacMatt_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.clearLacMatt_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {order_pricing?.sprayCoatingPricing?.clearSealant_unit_price !== null ? (
                                        <div className={'orderCategoryGrid'}>
                                            <div className={'orderCategoryGridItem'} style={{width: '40%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>Clear sealant spray coating - charged per panel face.</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>{order_pricing?.sprayCoatingPricing?.clearSealant_quantity}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.clearSealant_unit_price)}</p>
                                            </div>
                                            <div className={'orderCategoryGridItem'} style={{width: '20%'}}>
                                                <p className={'orderCategoryGridItempSmall'}>£{formatPrice(order_pricing?.sprayCoatingPricing?.clearSealant_total_price)}</p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                    ) : null}

                    {selected_offcuts !== null && selected_offcuts?.length > 0 ? (
                        <div className={'orderCategory'}>
                            <div className={'orderCategoryHeader'}>
                                <p className={'orderCategoryHeaderp'}>Selected offcuts to be included in delivery</p>
                            </div>
                            <div className={'orderCategoryContent'}>
                                <div className={'orderCategoryGrid'}>
                                    <div className={'orderCategoryGridItem'} style={{width: '50%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Product code, thickness</p>
                                    </div>
                                    <div className={'orderCategoryGridItem'} style={{width: '50%'}}>
                                        <p className={'orderCategoryGridItempSmall'}>Dimensions</p>
                                    </div>
                                </div>
                                {selected_offcuts.map((offcut, index) => (
                                    <div className={'orderCategoryGrid'} key={'offcut' + index}>
                                        <div className={'orderCategoryGridItem'} style={{width: '50%'}}>
                                            <p className={'orderCategoryGridItempSmall'}>{offcut.product_code}, {offcut.thickness}</p>
                                        </div>
                                        <div className={'orderCategoryGridItem'} style={{width: '50%'}}>
                                            {offcut.cut === false ? (
                                                <p className={'orderCategoryGridItempSmall'}>{offcut.length} x {offcut.width} mm</p>
                                            ) : (
                                                offcut.includeRemaining === false ? (
                                                    <p className={'orderCategoryGridItempSmall'}>{offcut.length} x {offcut.width} mm</p>
                                                ) : (
                                                    <p className={'orderCategoryGridItempSmall'}>{offcut.length} x {offcut.width} mm & {offcut.length2} x {offcut.width2} mm</p>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    <div className={'panelsCutInfo'}>
                        <p style={{fontWeight: '500', textAlign: 'center'}}>Sheets cut info:</p>
                        {drawing_displays.map((drawing, index) => (
                            <div className='sheetsCutInfoElement' key={'drawing' + index}>
                                <p>Panel {index + 1}:</p>
                                <div className='sheetsCutElementInfoSection'>
                                    <p style={{fontWeight: '500'}}>General:</p>
                                    <p style={{marginLeft: '15px'}}>Product code: {rows_config[index]?.productCode}</p>
                                    <p style={{marginLeft: '15px'}}>Thickness: {rows_config[index]?.thickness}</p>
                                    <p style={{marginLeft: '15px'}}>Length: {rows_config[index]?.materialLength} mm</p>
                                    <p style={{marginLeft: '15px'}}>Width: {rows_config[index].width} mm</p>
                                    <p style={{marginLeft: '15px'}}>Quantity: {rows_config[index].quantity}</p>
                                    {rows_config[index]?.edgebandingConfig !== null ? (
                                        <div>
                                            <p style={{fontWeight: '500'}}>Edgebanding on full sides:</p>
                                            {rows_config[index]?.edgebandingConfig?.l1 === true ? (
                                                <p style={{marginLeft: '15px'}}>- L1: {rows_config[index].edgebandingConfig.l1_edgebanding}, {rows_config[index].edgebandingConfig.l1_finish}</p>
                                            ) : null}
                                            {rows_config[index]?.edgebandingConfig?.l2 === true ? (
                                                <p style={{marginLeft: '15px'}}>- L2: {rows_config[index].edgebandingConfig.l2_edgebanding}, {rows_config[index].edgebandingConfig.l2_finish}</p>
                                            ) : null}
                                            {rows_config[index]?.edgebandingConfig?.w1 === true ? (
                                                <p style={{marginLeft: '15px'}}>- W1: {rows_config[index].edgebandingConfig.w1_edgebanding}, {rows_config[index].edgebandingConfig.w1_finish}</p>
                                            ) : null}
                                            {rows_config[index]?.edgebandingConfig?.w2 === true ? (
                                                <p style={{marginLeft: '15px'}}>- W2: {rows_config[index].edgebandingConfig.w2_edgebanding}, {rows_config[index].edgebandingConfig.w2_finish}</p>
                                            ) : null}
                                        </div>
                                    ) : (
                                        null
                                    )}
                                    {rows_config[index]?.machiningConfig !== null ? (
                                        <div>
                                            <div>
                                                {rows_config[index]?.machiningConfig?.L1P !== null ? (
                                                    <div>
                                                        <p style={{fontWeight: '500'}}>Angle cut between L1-W2:</p>
                                                        <p style={{marginLeft: '15px'}}>- From W1: {rows_config[index]?.machiningConfig?.L1P.x} mm | From L2: {rows_config[index]?.width - rows_config[index]?.machiningConfig?.topRight.y} mm</p>
                                                        {rows_config[index]?.machiningConfig?.l1w2_edgebanding !== null ? (
                                                            <p style={{marginLeft: '15px'}}>- Edgebanding on angle cut: {rows_config[index]?.machiningConfig?.l1w2_edgebanding.option.edgebanding_type}, {rows_config[index]?.machiningConfig?.l1w2_edgebanding.finish}</p>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                                {rows_config[index]?.machiningConfig?.L2P !== null ? (
                                                    <div>
                                                        <p style={{fontWeight: '500'}}>Angle cut between L2-W1:</p>
                                                        <p style={{marginLeft: '15px'}}>- From W2: {rows_config[index]?.materialLength - rows_config[index]?.machiningConfig?.L2P.x} mm | From L1: {rows_config[index]?.machiningConfig?.bottomLeft.y} mm</p>
                                                        {rows_config[index]?.machiningConfig?.l2w1_edgebanding !== null ? (
                                                            <p style={{marginLeft: '15px'}}>- Edgebanding on angle cut: {rows_config[index]?.machiningConfig?.l2w1_edgebanding.option.edgebanding_type}, {rows_config[index]?.machiningConfig?.l2w1_edgebanding.finish}</p>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                                {rows_config[index]?.machiningConfig?.W1P !== null ? (
                                                    <div>
                                                        <p style={{fontWeight: '500'}}>Angle cut between L1-W1:</p>
                                                        <p style={{marginLeft: '15px'}}>- From W2: {rows_config[index]?.materialLength - rows_config[index]?.machiningConfig?.topLeft.x} mm | From L2: {rows_config[index]?.width - rows_config[index]?.machiningConfig?.W1P.y} mm</p>
                                                        {rows_config[index]?.machiningConfig?.l1w1_edgebanding !== null ? (
                                                            <p style={{marginLeft: '15px'}}>- Edgebanding on angle cut: {rows_config[index]?.machiningConfig?.l1w1_edgebanding.option.edgebanding_type}, {rows_config[index]?.machiningConfig?.l1w1_edgebanding.finish}</p>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                                {rows_config[index]?.machiningConfig?.W2P !== null ? (
                                                    <div>
                                                        <p style={{fontWeight: '500'}}>Angle cut between L2-W2:</p>
                                                        <p style={{marginLeft: '15px'}}>From W1: {rows_config[index]?.machiningConfig?.bottomRight.x} mm | From L1: {rows_config[index]?.machiningConfig?.W2P.y} mm</p>
                                                        {rows_config[index]?.machiningConfig?.l2w2_edgebanding !== null ? (
                                                            <p style={{marginLeft: '15px'}}>- Edgebanding on angle cut: {rows_config[index]?.machiningConfig?.l2w2_edgebanding.option.edgebanding_type}, {rows_config[index]?.machiningConfig?.l2w2_edgebanding.finish}</p>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div>
                                                {rows_config[index]?.machiningConfig?.hingeHoles !== null ? (
                                                    <div>
                                                        <p style={{fontWeight: '500'}}>Hinge drilling:</p>
                                                        <p style={{marginLeft: '15px'}}>- Hinges type: {rows_config[index]?.machiningConfig?.hingesType === 'screwOn' ? 'Screw-On' : 'INSERTA' }</p>
                                                        <p style={{marginLeft: '15px'}}>- Number of hinge holes: {rows_config[index]?.machiningConfig?.hingeHoles}</p>
                                                        <p style={{marginLeft: '15px'}}>- On side: {rows_config[index]?.machiningConfig?.hingeHolesSide.toUpperCase()}</p>
                                                        {rows_config[index]?.machiningConfig?.hingeHolesSide === 'l1' ? (
                                                            <div>
                                                                {rows_config[index]?.machiningConfig?.hingeHolesPos.map((hole, holeIndex) => (
                                                                    <div key={'hingeHole' + holeIndex}>
                                                                        <p style={{marginLeft: '15px'}}>- Hole {holeIndex + 1}: {hole.x} mm from W1</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : null}
                                                        {rows_config[index]?.machiningConfig?.hingeHolesSide === 'l2' ? (
                                                            <div>
                                                                {rows_config[index]?.machiningConfig?.hingeHolesPos.map((hole, holeIndex) => (
                                                                    <div key={'hingeHole' + holeIndex}>
                                                                        <p style={{marginLeft: '15px'}}>- Hole {holeIndex + 1}: {hole.x} mm from W1</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : null}
                                                        {rows_config[index]?.machiningConfig?.hingeHolesSide === 'w1' ? (
                                                            <div>
                                                                {rows_config[index]?.machiningConfig?.hingeHolesPos.map((hole, holeIndex) => (
                                                                    <div key={'hingeHole' + holeIndex}>
                                                                        <p style={{marginLeft: '15px'}}>- Hole {holeIndex + 1}: {hole.x} mm from L1</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : null}
                                                        {rows_config[index]?.machiningConfig?.hingeHolesSide === 'w2' ? (
                                                            <div>
                                                                {rows_config[index]?.machiningConfig?.hingeHolesPos.map((hole, holeIndex) => (
                                                                    <div key={'hingeHole' + holeIndex}>
                                                                        <p style={{marginLeft: '15px'}}>- Hole {holeIndex + 1}: {hole.x} mm from L1</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div>
                                                {rows_config[index]?.machiningConfig?.clusters !== null ? (
                                                    <div>
                                                        <p style={{fontWeight: '500'}}>Adjustable shelf drilling:</p>
                                                        <p style={{marginLeft: '15px'}}>- Type: {rows_config[index]?.machiningConfig?.clustersType === '5mm' ? '5 mm' : '7.5 mm' }</p>
                                                        <p style={{marginLeft: '15px'}}>- Number of clusters: {rows_config[index]?.machiningConfig?.clusters}</p>
                                                        <p style={{marginLeft: '15px'}}>- Clusters step: {rows_config[index]?.machiningConfig?.clustersStep} mm</p>
                                                        <p style={{marginLeft: '15px'}}>- Clusters positions: {rows_config[index]?.machiningConfig?.clustersPositions}</p>
                                                        <p style={{marginLeft: '15px'}}>- Panel face: {rows_config[index]?.machiningConfig?.clustersFace}</p>
                                                        <p style={{marginLeft: '15px'}}>- Along edges: {rows_config[index]?.machiningConfig?.clustersEdge.toUpperCase()}</p>
                                                        {rows_config[index]?.machiningConfig?.clustersEdge === 'l1-l2' ? (
                                                            <div>
                                                                <p style={{marginLeft: '15px'}}>- Distance from L1: {rows_config[index]?.machiningConfig?.clustersDistance1} mm</p>
                                                                <p style={{marginLeft: '15px'}}>- Distance from L2: {rows_config[index]?.machiningConfig?.clustersDistance2} mm</p>
                                                                <p>- Clusters positions (based on the first hole of the cluster):</p>
                                                                {rows_config[index]?.machiningConfig?.clustersPos.map((cluster, clusterIndex) => (
                                                                    <div key={'clusterIndex' + clusterIndex}>
                                                                        <p style={{marginLeft: '15px'}}>- Cluster {clusterIndex + 1}: {cluster} mm from W1</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <p>- Distance from W1: {rows_config[index]?.machiningConfig?.clustersDistance1} mm</p>
                                                                <p>- Distance from W2: {rows_config[index]?.machiningConfig?.clustersDistance2} mm</p>
                                                                <p>- Clusters positions (based on the first hole of the cluster):</p>
                                                                {rows_config[index]?.machiningConfig?.clustersPos.map((cluster, clusterIndex) => (
                                                                    <div key={'clusterIndex' + clusterIndex}>
                                                                        <p style={{marginLeft: '15px'}}>- Cluster {clusterIndex + 1}: {cluster} mm from L1</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )}

                                    {rows_config[index]?.sprayConfig !== null ? (
                                        <div>
                                            <p style={{fontWeight: '500'}}>Spray coating:</p>
                                            <p style={{marginLeft: '15px'}}>- Type: {rows_config[index]?.sprayConfig?.spray === 'whitePrimer' ? 'White primer' : 
                                                rows_config[index]?.sprayConfig?.spray === 'solidColor' ? 'Solid colour' : 'Clear lacquer' }
                                            </p>
                                            <p style={{ marginLeft: '15px' }}>- Finish: {rows_config[index]?.sprayConfig?.finish !== null ? 
                                                rows_config[index]?.sprayConfig?.finish === 'satin' ? 'Satin' : 
                                                rows_config[index]?.sprayConfig?.finish === 'matt' ? 'Matt' :
                                                '-' : '-'}
                                            </p>
                                            <p style={{ marginLeft: '15px' }}>- Backside: {rows_config[index]?.sprayConfig?.backside !== null ? 
                                                rows_config[index]?.sprayConfig?.backside === 'white primer' ? 'White primer' : 
                                                rows_config[index]?.sprayConfig?.backside === 'color' ? 'Same color as front side' :
                                                rows_config[index]?.sprayConfig?.backside === 'lac' ? 'Same clear lacquer finish as front side' :
                                                rows_config[index]?.sprayConfig?.backside === 'clear sealant' ? 'Clear sealant' :
                                                '-' : '-'}
                                            </p>
                                            {rows_config[index]?.sprayConfig?.spray === 'solidColor' ? (
                                                <div>
                                                    <p style={{marginLeft: '15px'}}>- Paint brand: {rows_config[index]?.sprayConfig?.paintBrand}</p>
                                                    <p style={{marginLeft: '15px'}}>- Color: {rows_config[index]?.sprayConfig?.colorName}</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}

                                </div>
                                <div className='pdf-comanda-drawing-side-label'>
                                    <p>L1</p>
                                </div>
                                <div className='pdf-comanda-drawing-section'>
                                    <div className='pdf-comanda-drawing-side-label'>
                                        <p>W1</p>
                                    </div>
                                    <svg width={svgDimensions.svgWidth} height={svgDimensions.svgHeight}>
                                        <polygon points={drawing.points} fill="#e4d5c7" stroke={'#6ab29b'} strokeWidth={1}/>

                                        {drawing.l1w1_edgebanding !== null && (
                                            <>
                                                <path
                                                    d={drawing.l1w1_edgebanding}
                                                    stroke="#6ab29b"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                />
                                            </>
                                        )}
                                        {drawing.l1w2_edgebanding !== null && (
                                            <>
                                                <path
                                                    d={drawing.l1w2_edgebanding}
                                                    stroke="#6ab29b"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                />
                                            </>
                                        )}
                                        {drawing.l2w2_edgebanding !== null && (
                                            <>
                                                <path
                                                    d={drawing.l2w2_edgebanding}
                                                    stroke="#6ab29b"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                />
                                            </>
                                        )}
                                        {drawing.l2w1_edgebanding !== null && (
                                            <>
                                                <path
                                                    d={drawing.l2w1_edgebanding}
                                                    stroke="#6ab29b"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                />
                                            </>
                                        )}

                                        {drawing.hingeHoles?.length > 0 ?
                                            drawing.hingeHoles?.map((hole, index) => (
                                                <React.Fragment key={`holeDraw${index}`}>
                                                    <circle cx={drawing.hingeHoles[index].x} cy={drawing.hingeHoles[index].y} r="3" fill="#253237" />
                                                </React.Fragment>
                                            ))
                                        : null}

                                        {drawing.clustersHoles?.length > 0 ?
                                            drawing.clustersHoles?.map((hole, index) => (
                                                <React.Fragment key={`holeDraw${index}`}>
                                                    {(() => {
                                                        const circles = [];
                                                        if(drawing.clustersEdge == 'l1-l2'){
                                                            for (let i = 0; i < drawing.clustersPositions; i++) {
                                                                console.log(`Drawing hole ${i + 1} for cluster ${index + 1}: x = ${drawing.clustersHoles[index].x + i * drawing.clusterStep}; y = ${drawing.clustersHoles[index].y1}`);
                                                                circles.push(
                                                                    <circle
                                                                        key={`circle${i}`}
                                                                        cx={drawing.clustersHoles[index].x + i * drawing.clusterStep}
                                                                        cy={drawing.clustersHoles[index].y1}
                                                                        r="1"
                                                                        fill="#253237"
                                                                    />
                                                                );
                                                                circles.push(
                                                                    <circle
                                                                        key={`circleMirror${i}`}
                                                                        cx={drawing.clustersHoles[index].x + i * drawing.clusterStep}
                                                                        cy={drawing.clustersHoles[index].y2}
                                                                        r="1"
                                                                        fill="#253237"
                                                                    />
                                                                );
                                                            }
                                                        }else if(drawing.clustersEdge == 'w1-w2'){
                                                            for (let i = 0; i < drawing.clustersPositions; i++) {
                                                                console.log(`Drawing hole ${i + 1} for cluster ${index + 1}: x = ${drawing.clustersHoles[index].x1}; y = ${drawing.clustersHoles[index].y + i * drawing.clusterStep}`);
                                                                circles.push(
                                                                    <circle
                                                                        key={`circle${i}`}
                                                                        cx={drawing.clustersHoles[index].x1}
                                                                        cy={drawing.clustersHoles[index].y + i * drawing.clusterStep}
                                                                        r="1"
                                                                        fill="#253237"
                                                                    />
                                                                );
                                                                circles.push(
                                                                    <circle
                                                                        key={`circleMirror${i}`}
                                                                        cx={drawing.clustersHoles[index].x2}
                                                                        cy={drawing.clustersHoles[index].y + i * drawing.clusterStep}
                                                                        r="1"
                                                                        fill="#253237"
                                                                    />
                                                                );
                                                            }
                                                        }

                                                        return circles;
                                                    })()}
                                                </React.Fragment>
                                            ))
                                        : null}

                                    </svg>
                                    <div className='pdf-comanda-drawing-side-label'>
                                        <p>W2</p>
                                    </div>
                                </div>
                                <div className='pdf-comanda-drawing-side-label'>
                                    <p>L2</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {binPlacements != null && binPlacements?.length > 0 ? (
                        <div className={'panelsCutInfo'}>
                            <p style={{fontWeight: '500', textAlign: 'center'}}>Cuts placements and selected offcuts:</p>
                            <p style={{fontSize: '12px', textAlign: 'center'}}>Panels configured by the customer are whole colored blocks. Offcuts to be included in the delivery are semi-transparent colored blocks.</p>
                            {binPlacements?.map((bin, index) => (
                                <div key={index} style={{borderBottom: '2px solid rgba(0, 0, 0, 0.2)', padding: '25px 0'}}>
                                    <p style={{fontWeight: '500', fontSize: '12px'}}>Plan {index+1}:</p>
                                    <p style={{marginLeft: '15px', fontSize: '12px'}}>- Product code and decor: {bin.root.product_code} {bin.root.decor_name}</p>
                                    <p style={{marginLeft: '15px', fontSize: '12px'}}>- Full sheet dimensions: {bin.root.length} x {bin.root.width} x {bin.root.thickness} mm</p>
                                    <p style={{fontWeight: '500', fontSize: '12px'}}>- Offcuts to be included: </p>
                                    {offcuts_selections[index].offcuts?.map((offcut, offcutIndex) => (
                                        offcut.selected === true ? (
                                            <div key={offcutIndex}>
                                            {offcut.cut === true ? (
                                                <div>
                                                    <p style={{marginLeft: '15px', fontSize: '12px'}}>#{offcutIndex + 1} - Offcut cut: {offcut.cutSide === 'horizontal' ? 'horizontal' : 'vertical'}</p>
                                                    {offcut.includeRemaining === true ? (
                                                        <p style={{marginLeft: '15px', fontSize: '12px'}}>#{offcutIndex + 1} - Offcut dimensions: {offcut.cutLength} x {offcut.cutWidth} mm & {offcut.length2} x {offcut.width2} mm</p>
                                                    ) : (
                                                        <p style={{marginLeft: '15px', fontSize: '12px'}}>#{offcutIndex + 1} - Offcut dimensions: {offcut.cutLength} x {offcut.cutWidth} mm </p>
                                                    )}
                                                </div>
                                            ) : (
                                                <p style={{marginLeft: '15px', fontSize: '12px'}}>#{offcutIndex + 1} - Offcut dimensions: {offcut.length} x {offcut.width} mm</p>
                                            )}
                                            </div>
                                        ) : null
                                    ))}
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <svg width={smallDrawingContainerSize.length + smallDrawingPadding*2} height={smallDrawingContainerSize.width + smallDrawingPadding*2}>
                                            <defs>
                                                <pattern id="dashPattern" patternUnits="userSpaceOnUse" width={10} height={10}>
                                                    <rect x={0} y={0} width="100%" height="100%" fill="hsla(0, 0%, 96%, 1)" />
                                                    <path d="M0 10h20z" strokeWidth={10} stroke="hsla(0, 0%, 100%, 1)" fill="none" />
                                                </pattern>
                                            </defs>
                                            {scaledBins !== null && scaledBins?.length > 0 && binPlacements?.length === scaledCuts?.length && binPlacements?.length === scaledOffcuts?.length ? (
                                                <>
                                                    <rect x={((smallDrawingContainerSize?.length + smallDrawingPadding*2) - scaledBins[index]?.length)/2} y={((smallDrawingContainerSize?.width + smallDrawingPadding*2) - scaledBins[index]?.width)/2} width={scaledBins[index]?.length} height={scaledBins[index]?.width} fill="url(#dashPattern)" stroke={"#247156"} strokeWidth={1}/>
                                                    {scaledOffcuts[index]?.map((offcut, offcutIndex) => (
                                                        <React.Fragment key={`smallScaledOffcutFragment0${offcutIndex}`}>
                                                            {offcuts_selections[index]?.offcuts[offcutIndex]?.selected === true ? (
                                                                <rect key={`smallScaledOffcut0${offcutIndex}`} x={((smallDrawingContainerSize?.length + smallDrawingPadding*2) - scaledBins[0]?.length)/2 + offcut.x} y={((smallDrawingContainerSize?.width + smallDrawingPadding*2) - scaledBins[0]?.width)/2 + offcut.y} width={offcut.length} height={offcut.width} fill='rgba(106, 178, 155, 0.5)' stroke={"#247156"} strokeWidth={1} />
                                                            ) : (
                                                                <rect key={`smallScaledOffcut0${offcutIndex}`} x={((smallDrawingContainerSize?.length + smallDrawingPadding*2) - scaledBins[0]?.length)/2 + offcut.x} y={((smallDrawingContainerSize?.width + smallDrawingPadding*2) - scaledBins[0]?.width)/2 + offcut.y} width={offcut.length} height={offcut.width} fill='transparent' stroke={"#247156"} strokeWidth={1} />
                                                            )}
                                                            <text key={`smallScaledOffcutText0${offcutIndex}`} fill="black" fontWeight="400" fontSize="10" x={((smallDrawingContainerSize.length + smallDrawingPadding*2) - scaledBins[index].length)/2 + offcut.x + (offcut.length /2)} y={((smallDrawingContainerSize.width + smallDrawingPadding*2) - scaledBins[index].width)/2 + offcut.y + (offcut.width / 2) - 5} textAnchor="middle">Offcut</text>
                                                            <text key={`smallScaledOffcutText20${offcutIndex}`} fill="black" fontWeight="400" fontSize="10" x={((smallDrawingContainerSize.length + smallDrawingPadding*2) - scaledBins[index].length)/2 + offcut.x + (offcut.length /2)} y={((smallDrawingContainerSize.width + smallDrawingPadding*2) - scaledBins[index].width)/2 + offcut.y + (offcut.width / 2) + 5} textAnchor="middle">{offcuts_selections[index]?.offcuts[offcutIndex]?.length} x {offcuts_selections[index]?.offcuts[offcutIndex]?.width} mm</text>
                                                        </React.Fragment>
                                                    ))}
                                                    <rect x={((smallDrawingContainerSize.length + smallDrawingPadding*2) - scaledBins[index]?.length)/2} y={((smallDrawingContainerSize.width + smallDrawingPadding*2) - scaledBins[index]?.width)/2} width={scaledBins[index]?.length} height={scaledBins[index]?.width} fill="transparent" stroke={"#247156"} strokeWidth={1}/>
                                                    {scaledCuts[index]?.map((cut, cutIndex) => (
                                                        <>
                                                            <rect key={`smallScaledCut${index}${cutIndex}`} x={((smallDrawingContainerSize.length + smallDrawingPadding*2) - scaledBins[index]?.length)/2 + cut.x + 1} y={((smallDrawingContainerSize.width + smallDrawingPadding*2) - scaledBins[index]?.width)/2 + cut.y + 1} width={cut.length} height={cut.width} fill="#6ab29b" stroke={"#247156"} strokeWidth={1}/>
                                                            <text key={`smallScaledCutText${cutIndex}`} fill="black" fontWeight="400" fontSize="10" x={((smallDrawingContainerSize.length + smallDrawingPadding*2) - scaledBins[index].length)/2 + cut.x + (cut.length /2)} y={((smallDrawingContainerSize.width + smallDrawingPadding*2) - scaledBins[index].width)/2 + cut.y + (cut.width / 2) + 5} textAnchor="middle">{placedCuts[index]?.cuts[cutIndex]?.length} x {placedCuts[index]?.cuts[cutIndex]?.width} mm</text>
                                                        </>
                                                    ))}
                                                </>
                                            ) : null}
                                        </svg>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}

                </div>
            </div>

    )
}

export default PdfComenzi