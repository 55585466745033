let rows_config = [];
let order_pricing = [];
let order_price = 0;
let order_id = 0;
let order_date = '';
let delivery_info = [];
let offcuts_selections = [];
let panels_array = [];

let bins = [];
let placed_cuts = [];
let offcuts_array = [];


export const setNewPopupData = async(data) =>{
    console.log('order data in file:');
    console.log(data);
    rows_config = data.rows_config;
    order_pricing = data.order_pricing;
    order_id = await formatNumberToSixDigits(data.id);
    order_date = data.date;
    order_price = await formatPrice(data.price);
    delivery_info = data.delivery_info;
    offcuts_selections = data.offcuts_selections;
    panels_array = data.panels_array;
    bins =   data.bins;
    placed_cuts = data.placed_cuts;
    offcuts_array = data.offcuts_array;
};

export const getPopupData = async() =>{
    return ({
        rows_config: rows_config,
        order_pricing: order_pricing,
        order_price: order_price,
        order_id: order_id,
        order_date: order_date,
        delivery_info: delivery_info,
        offcuts_selections: offcuts_selections,
        panels_array: panels_array,
        bins: bins,
        placed_cuts: placed_cuts,
        offcuts_array: offcuts_array
    });
};


const formatNumberToSixDigits = async(number) => {
     // Convert the number to a string
    let numberString = String(number);
    
    // Pad the string with leading zeros to make it 6 characters long
    while (numberString.length < 6) {
        numberString = '0' + numberString;
    }
    
    return numberString;
};

const formatPrice = async(price) => {
    const priceNumber = parseFloat(price);
    return priceNumber.toLocaleString('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
