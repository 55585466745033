import React, { useState, useEffect } from 'react'
import './conturiClienti.css'
import { IoIosSearch } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import Sidebar from '../sidebar/sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import Loader from "react-js-loader";

import { APIurl } from '../../ApiService';

function ConturiClienti() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [hidePage, setHidePage] = useState(true);

    const [showLoader, setShowLoader] = useState(false);
    const [fetched_users_list, setFetchedUsersList] = useState([]);

    const fetchUsers = async(userToken) =>{
        setShowLoader(true);
        const tokenData = {
            token: userToken
        };
        try {
            const response = await axios.post(`${APIurl}/api/adminclienti`, tokenData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
    
            if (responseStatus === 'error') {
                setShowLoader(false);
                return;
            } else {
                if(dataMessage === 'Not admin'){
                    navigate('/login');
                }else{
                    setHidePage(false);
                    console.log(response.data);
                    const users_list = response.data.users_list;
                    setFetchedUsersList(users_list);
                    setShowLoader(false);
                    return;
                }
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle or log the error
            setShowLoader(false);
            //navigate('/neworder');
        }
    }
    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            // get account details
            fetchUsers(savedToken);
        }else{
            console.log('You must be logged in to access this page!');
            navigate('/login');
        }
    }, []);

    const [new_user_inputs, setNewUserInputs] = useState({
        email: '',
        phone_number: '',
        password: '',
    });
    const [showAddUserLoader, setShowAddUserLoader] = useState(false);

    const hadleNewUserInputsChange = (event, fieldName) => {
        const inputValue = event.target.value;
        if(fieldName === 'email'){
            const newInputs = {...new_user_inputs};
            newInputs.email = inputValue;
            setNewUserInputs(newInputs);
        }else if(fieldName === 'phone_number'){
            const newInputs = {...new_user_inputs};
            newInputs.phone_number = inputValue;
            setNewUserInputs(newInputs);
        }else if(fieldName === 'password'){
            const newInputs = {...new_user_inputs};
            newInputs.password = inputValue;
            setNewUserInputs(newInputs);
        }
    };

    const createNewUser = async() =>{
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if email or password is empty
        if (!new_user_inputs.email || !new_user_inputs.password) {
            alert("Email and password cannot be empty.");
            return;
        }

        // Validate email format using regex
        if (!emailRegex.test(new_user_inputs.email)) {
            alert("Please enter a valid email address.");
            return;
        }

        // Check if password is at least 8 characters long
        if (new_user_inputs.password.length < 8) {
            alert("Password must be at least 8 characters long.");
            return;
        }

        setShowAddUserLoader(true);
        const savedToken = cookies.get("authToken");
        const new_user_data = {
            token:savedToken,
            email: new_user_inputs.email,
            phone_number: new_user_inputs.phone_number,
            password: new_user_inputs.password
        }
        try{
            const response = await axios.post(`${APIurl}/api/admincreateuser`, new_user_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus = response.data.status;
            const responseMessage = response.data.data;
            if(responseMessage === 'User created'){
                console.log('User created');
                setShowAddUserLoader(false);
                alert('User created');
                await fetchUsers(savedToken);
            }else{
                alert(responseMessage);
                setShowAddUserLoader(false);
            }
        }catch(error){
            alert('Eroare la crearea contului de client');
            setShowAddUserLoader(false);
        }       
    }

    const deleteUser = async(user_id) =>{
        const savedToken = cookies.get("authToken");
        const new_user_data = {
            token: savedToken,
            user_id: user_id
        }
        try{
            const response = await axios.post(`${APIurl}/api/admindeleteuser`, new_user_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus = response.data.status;
            const responseMessage = response.data.data;
            if(responseMessage === 'User deleted'){
                console.log('User deleted');
                alert('User deleted');
                await fetchUsers(savedToken);
            }else{
                alert(responseMessage);
            }
        }catch(error){
            alert('Eroare la stergerea contului de client');
        }       
    }

    const banUser = async(user_id) =>{
        const savedToken = cookies.get("authToken");
        const new_user_data = {
            token: savedToken,
            user_id: user_id
        }
        try{
            const response = await axios.post(`${APIurl}/api/adminbanuser`, new_user_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus = response.data.status;
            const responseMessage = response.data.data;
            if(responseMessage === 'User banned'){
                console.log('User banned');
                alert('User banned');
                await fetchUsers(savedToken);
            }else{
                alert(responseMessage);
            }
        }catch(error){
            alert('Eroare la banarea contului de client');
        }       
    }

    const unbanUser = async(user_id) =>{
        const savedToken = cookies.get("authToken");
        const new_user_data = {
            token: savedToken,
            user_id: user_id
        }
        try{
            const response = await axios.post(`${APIurl}/api/adminunbanuser`, new_user_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus = response.data.status;
            const responseMessage = response.data.data;
            if(responseMessage === 'User unbanned'){
                console.log('User unbanned');
                alert('User unbanned');
                await fetchUsers(savedToken);
            }else{
                alert(responseMessage);
            }
        }catch(error){
            alert('Eroare la debanarea contului de client');
        }       
    }

    return (
        hidePage == true ? null : (
            <div className='adminPage'>
                <Sidebar activeTab={'conturi_clienti'}/>
                <div className='cererisupSection'>
                    {showLoader === true ? (
                        <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                    ) : (
                        <>
                            <div className='cererisupHeader'>
                                <p className='cererisupHeaderTitle'>Conturi clienti</p>
                            </div>
                            <div className='conturiClientiAddSection'>
                                <div className='conturiClientiField'>
                                    <p>Email:</p>
                                    <input className='conturiClientiFieldInput' value={new_user_inputs.email} onChange={(event) => hadleNewUserInputsChange(event, 'email')}/>
                                </div>
                                <div className='conturiClientiField'>
                                    <p>Nr. telefon:</p>
                                    <input className='conturiClientiFieldInput' value={new_user_inputs.phone_number} onChange={(event) => hadleNewUserInputsChange(event, 'phone_number')}/>
                                </div>
                                <div className='conturiClientiField'>
                                    <p>Parola:</p>
                                    <input className='conturiClientiFieldInput' value={new_user_inputs.password} onChange={(event) => hadleNewUserInputsChange(event, 'password')}/>
                                </div>
                                <div className='conturiClientAddUserBtn' onClick={createNewUser}>
                                    {showAddUserLoader === true ? (
                                        <Loader type="spinner-default" bgColor='black' color={'transparent'} size={25} />
                                    ) : (
                                        <p>Adauga client</p>
                                    )}
                                </div>
                            </div>
                            <div className='conturiClientiListSection'>
                                {fetched_users_list.length > 0 ? (
                                    fetched_users_list.map((user, index) => (
                                        <div className='conturiClientiListItem' key={'order' + index}>
                                            <div className='conturiClientiItemGridItem'>
                                                <p>ID: <b>{user.id}</b></p>
                                            </div>
                                            <div className='conturiClientiItemGridItem'>
                                                <p>Email: <b>{user.email}</b></p>
                                            </div>
                                            <div className='conturiClientiItemGridItem'>
                                                <p>Phone number: <b>{user.phone}</b></p>
                                            </div>
                                            <div className='conturiClientiItemGridItem'>
                                                <p>Ban: <b>{user.ban ? 'Yes' : 'No'}</b></p>
                                            </div>
                                            <div className='conturiClientiBanBtn' onClick={() => user.ban ? unbanUser(user.id) : banUser(user.id)}>
                                                <p>{user.ban ? 'Unban' : 'Ban'}</p>
                                            </div>
                                            <div className='conturiClientiDeleteBtn' onClick={() => deleteUser(user.id)}>
                                                <p>Sterge</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>Nu s-au gasit conturi de clienti</p>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    )
}

export default ConturiClienti