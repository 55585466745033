import React, { useState, useEffect } from 'react'
import './header.css'
import { Link } from 'react-router-dom'
import { FaCartShopping } from "react-icons/fa6";


function Header() {
    return (
        <div className='headerSection'>
            <div className='headerContainer'>
                <Link to={'/neworder'} className='headerLogo'>
                    <img src='\res\images\myBoardsLogoShadow.png' alt='Logo'/>
                </Link>
                <div className='headerMenu'>
                    <Link to="/shop" className='headerMenuItem' style={{display: 'none'}}>
                        <p>Shop</p>
                    </Link>
                    <Link to="/neworder" className='headerMenuItem'>
                        <p>Cut & Edge</p>
                    </Link>
                    <Link to="/ordershistory" className='headerMenuItem'>
                        <p>Orders</p>
                    </Link>
                    <Link to="/myaccount" className='headerMenuItem'>
                        <p>My Account</p>
                    </Link>
                    <FaCartShopping style={{display: 'none'}}/>
                </div>
            </div>
        </div>
    )
}

export default Header