import React, { useState, useEffect } from 'react'
import './fullSheetMaterialSelection.css'
import { FiSearch, FiChevronDown } from "react-icons/fi";
import FullSheetMaterialElement from './fullSheetMaterialElement';
import Loader from "react-js-loader";

import { fetchMaterials } from '../../../ApiService';

function FullSheetMaterialSelection(props) {
    //  fetch the materials list
    const [materialsList, setMaterialsList] = useState([]);
    const [sortedMaterialsList, setSortedMaterialsList] = useState([]);
    const [loadingMaterials, setLoadingMaterials] = useState(false);
    useEffect(() => {
        const getMaterialsList = async() =>{
            console.log('fetching materials list');
            setLoadingMaterials(true);
            const fetchedMaterialsList = await fetchMaterials();
            console.log(fetchedMaterialsList);
            setMaterialsList(fetchedMaterialsList);
            setSortedMaterialsList(fetchedMaterialsList);
            setLoadingMaterials(false);
        }
        getMaterialsList();
    }, []);

    const closePopup = () =>{
        props.closeMaterialSelect();
    }

    const handleContainerClick = (e) => {
        // Check if the click occurred outside the container
        if (e.target.classList.contains('materialSelectPopup')) {
            closePopup(); // Close the popup
        }
        if (!e.target.closest('.materialSelectBrandDropdown') && brandDropdownOpen === true) {
            closeBrandDropdown(); // Close the popup
        }
    };

    //  brand filter dropdown
    const [brandDropdownOpen, setBrandDropdownOpen] = useState(false);
    const toggleBrandDropdown = () =>{
        setBrandDropdownOpen(!brandDropdownOpen);
    }
    const closeBrandDropdown = () =>{
        setBrandDropdownOpen(false);
    }
    //  search and filter functions
    const [searchValue, setSearchValue] = useState('');
    const handleSearchValueChange = (newValue) =>{
        setSearchValue(newValue);
        handleSearchInputChange(newValue, brandFilter);
    }
    const brandFilterOptions = [
        {id: 0, value: 'All brands'},
        {id: 1, value: 'Egger'},
        {id: 2, value: 'Kronospan'},
        {id: 3, value: 'XyloCleaf'},
        {id: 4, value: 'Saviola'},
        {id: 5, value: 'Swiss Krono'},
        {id: 6, value: 'MDF Boards'},
        {id: 7, value: 'Veneered Boards'},
    ];
    const [brandFilter, setBrandFilter] = useState('All brands');
    const handleBrandFilterChange = (brandID) =>{
        setBrandFilter(brandFilterOptions[brandID].value);
        handleSearchInputChange(searchValue, brandFilterOptions[brandID].value);
        closeBrandDropdown();
    };
    const handleSearchInputChange = (search, brand) =>{
        setLoadingMaterials(true);
        if (!materialsList) {
            setSortedMaterialsList([]);
            return;
        }else{
            const newList = materialsList.filter(material => {
                const codeMatches = material.product_code.toLowerCase().includes(search.toLowerCase());
                const colorMatches = material.color_description.toLowerCase().includes(search.toLowerCase());
                let manufacturerMatches = false;
                if(brand != 'All brands'){
                    manufacturerMatches = material.manufacturer.toLowerCase().includes(brand.toLowerCase());
                }else{
                    manufacturerMatches = true;
                }    
                // Return true if either product_code or color_description matches the searchQuery
                return ((codeMatches || colorMatches) && manufacturerMatches);
            });
    
            //console.log('filtered list');
            console.log('new filtered list for search:' + search);
            console.log(newList);
            setSortedMaterialsList(newList);
            setLoadingMaterials(false);
        }
    }

    return (
        <div className='materialSelectPopup' onClick={handleContainerClick}>
            <div className='materialSelectContainer'>
                <div className='materialSelectHeader'>
                    <div className='materialSelectSearchDiv'>
                        <div className='materialSelectSearchBar'>
                            <FiSearch />
                            <input className='materialSelectSearchInput' value={searchValue} onChange={(event) => handleSearchValueChange(event.target.value)} placeholder='Search for a decor...'/>
                        </div>
                        <div className='materialSelectBrandDropdown'>
                            <div className='materialSelectBrandDropdownTop' style={{cursor: 'pointer'}} onClick={() => toggleBrandDropdown()}>
                                <div className='materialSelectBrandDropdownTopValue'>
                                    <p>{brandFilter}</p>
                                </div>
                                <FiChevronDown />
                            </div>
                            <div className='materialSelectBrandDropdownMenu' style={{display: brandDropdownOpen === true ? 'flex' : 'none'}}>
                                {brandFilterOptions.map((brand, index) => (
                                    <div key={index} className='materialSelectBrandDropdownMenuItem' onClick={() => handleBrandFilterChange(index)}><p>{brand.value}</p></div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='materialSelectCloseBtn' onClick={() => closePopup()}>
                        <p>Close</p>
                    </div>
                </div>
                <div className='materialSelectList'>
                    {loadingMaterials === true ? (
                        <div className='materialSelectLoaderDiv'>
                            <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                            <p>Loading materials...</p>
                        </div>
                    ) : (
                        <>
                            {sortedMaterialsList.map((material, index) => (
                                <FullSheetMaterialElement key={index} material={material} closePopup={closePopup} fetchRows={props.fetchRows}/>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FullSheetMaterialSelection