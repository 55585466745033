import axios from 'axios';

import { APIurl } from '../../ApiService';


// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| CUTTING LIST |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/

// Initial array
let selectedMaterials = [];
let firstRowConfig = {
    productCode: null,
    thickness: null,
    quantity: '',
    materialLength: '',
    width: '',
    partDescription: '',
    grainMatch: false,
    edgebandingConfig: null,
    machiningConfig: null,
    sprayConfig: null,
    qtyState: false,
    lengthState: false,
    widthState: false,
    thicknessID: null,
    looks: null,
    brand: null,
    bonded: false,
};
let rowsConfig = [];
let rowWithOpenSelection = null;
let thicknessProps = [];
let orderTotalPrice = 0;
let orderTotalPriceVAT = 0;

// Function to return the selected materials array
export const getSelectedMaterials = () => selectedMaterials;

// Function to push to the selected materials array
export const pushToSelectedMaterials = (newMaterial) => {
    // copy the material basic information
    var tempMaterial = {
        manufacturer: newMaterial.manufacturer,
        product_code: newMaterial.product_code,
        color_description: newMaterial.color_description,
        back_side_description: newMaterial.back_side_description,
        image: newMaterial.image_1,
        material_type: newMaterial.material_type,
        cutting: newMaterial.cutting,
        product_characteristics: newMaterial.product_characteristics,
        material_length: newMaterial.material_length,
        width: newMaterial.width,
        grain: newMaterial.grain,
    }

    // look for each thickness and only add the ones that exist
    // For loop to create JSX elements
    for (let i = 1; i <= 11; i++) {
        const thicknessKey = `thickness${i}`;
        const thickness_angle_cutting = `thickness${i}_angle_cutting`;
        const thickness_cnc = `thickness${i}_cnc`;
        const thickness_cut_price = `thickness${i}_cut_price`;
        const thickness_drilling = `thickness${i}_drilling`;
        const thickness_edgebanding = `thickness${i}_edgebanding`;
        const thickness_edgebanding1_bevel = `thickness${i}_edgebanding1_bevel`;
        const thickness_edgebanding1_description = `thickness${i}_edgebanding1_description`;
        const thickness_edgebanding1_price = `thickness${i}_edgebanding1_price`;
        const thickness_edgebanding1_radius = `thickness${i}_edgebanding1_radius`;
        const thickness_edgebanding1_size = `thickness${i}_edgebanding1_size`;
        const thickness_edgebanding1_square = `thickness${i}_edgebanding1_square`;
        const thickness_edgebanding1_type = `thickness${i}_edgebanding1_type`;
        const thickness_edgebanding2_bevel = `thickness${i}_edgebanding2_bevel`;
        const thickness_edgebanding2_description = `thickness${i}_edgebanding2_description`;
        const thickness_edgebanding2_price = `thickness${i}_edgebanding2_price`;
        const thickness_edgebanding2_radius = `thickness${i}_edgebanding2_radius`;
        const thickness_edgebanding2_size = `thickness${i}_edgebanding2_size`;
        const thickness_edgebanding2_square = `thickness${i}_edgebanding2_square`;
        const thickness_edgebanding2_type = `thickness${i}_edgebanding2_type`;
        const thickness_edgebanding3_bevel = `thickness${i}_edgebanding3_bevel`;
        const thickness_edgebanding3_description = `thickness${i}_edgebanding3_description`;
        const thickness_edgebanding3_price = `thickness${i}_edgebanding3_price`;
        const thickness_edgebanding3_radius = `thickness${i}_edgebanding3_radius`;
        const thickness_edgebanding3_size = `thickness${i}_edgebanding3_size`;
        const thickness_edgebanding3_square = `thickness${i}_edgebanding3_square`;
        const thickness_edgebanding3_type = `thickness${i}_edgebanding3_type`;
        const thickness_glued = `thickness${i}_glued`;
        const thickness_hinge_drilling = `thickness${i}_hinge_drilling`;
        const thickness_sheet_price = `thickness${i}_sheet_price`;
        const thickness_shelf_drilling = `thickness${i}_shelf_drilling`;
        const thickness_spray_coating = `thickness${i}_spray_coating`;
        const thickness_stock = `thickness${i}_stock`;

        if (newMaterial[thicknessKey] != null && newMaterial[thicknessKey] != '' && newMaterial[thicknessKey] != 0) {
            tempMaterial[thicknessKey] = newMaterial[thicknessKey];
            tempMaterial[thickness_angle_cutting] = newMaterial[thickness_angle_cutting];
            tempMaterial[thickness_cnc] = newMaterial[thickness_cnc];
            tempMaterial[thickness_cut_price] = newMaterial[thickness_cut_price];
            tempMaterial[thickness_drilling] = newMaterial[thickness_drilling];
            tempMaterial[thickness_edgebanding] = newMaterial[thickness_edgebanding];
            tempMaterial[thickness_edgebanding1_bevel] = newMaterial[thickness_edgebanding1_bevel];
            tempMaterial[thickness_edgebanding1_description] = newMaterial[thickness_edgebanding1_description];
            tempMaterial[thickness_edgebanding1_price] = newMaterial[thickness_edgebanding1_price];
            tempMaterial[thickness_edgebanding1_radius] = newMaterial[thickness_edgebanding1_radius];
            tempMaterial[thickness_edgebanding1_size] = newMaterial[thickness_edgebanding1_size];
            tempMaterial[thickness_edgebanding1_square] = newMaterial[thickness_edgebanding1_square];
            tempMaterial[thickness_edgebanding1_type] = newMaterial[thickness_edgebanding1_type];
            tempMaterial[thickness_edgebanding2_bevel] = newMaterial[thickness_edgebanding2_bevel];
            tempMaterial[thickness_edgebanding2_description] = newMaterial[thickness_edgebanding2_description];
            tempMaterial[thickness_edgebanding2_price] = newMaterial[thickness_edgebanding2_price];
            tempMaterial[thickness_edgebanding2_radius] = newMaterial[thickness_edgebanding2_radius];
            tempMaterial[thickness_edgebanding2_size] = newMaterial[thickness_edgebanding2_size];
            tempMaterial[thickness_edgebanding2_square] = newMaterial[thickness_edgebanding2_square];
            tempMaterial[thickness_edgebanding2_type] = newMaterial[thickness_edgebanding2_type];
            tempMaterial[thickness_edgebanding3_bevel] = newMaterial[thickness_edgebanding3_bevel];
            tempMaterial[thickness_edgebanding3_description] = newMaterial[thickness_edgebanding3_description];
            tempMaterial[thickness_edgebanding3_price] = newMaterial[thickness_edgebanding3_price];
            tempMaterial[thickness_edgebanding3_radius] = newMaterial[thickness_edgebanding3_radius];
            tempMaterial[thickness_edgebanding3_size] = newMaterial[thickness_edgebanding3_size];
            tempMaterial[thickness_edgebanding3_square] = newMaterial[thickness_edgebanding3_square];
            tempMaterial[thickness_edgebanding3_type] = newMaterial[thickness_edgebanding3_type];
            tempMaterial[thickness_glued] = newMaterial[thickness_glued];
            tempMaterial[thickness_hinge_drilling] = newMaterial[thickness_hinge_drilling];
            tempMaterial[thickness_sheet_price] = newMaterial[thickness_sheet_price];
            tempMaterial[thickness_shelf_drilling] = newMaterial[thickness_shelf_drilling];
            tempMaterial[thickness_spray_coating] = newMaterial[thickness_spray_coating];
            tempMaterial[thickness_stock] = newMaterial[thickness_stock];

        }
    }

    selectedMaterials[rowWithOpenSelection] = tempMaterial;
};

export const getSelectedMaterial = (index) => {
    const material = selectedMaterials[index];
    return material;
};


// Function to return the rows configuration array
export const getRowsConfig = () => {return rowsConfig};



// Function to push to the rows configuration array
export const addNewRowInConfig = () => {
    if(rowsConfig.length > 0){
        const lastRow = rowsConfig[rowsConfig.length - 1];
        if(lastRow.productCode !== null){
        // copy the material basic information
        var tempConfig = {
            productCode: lastRow.productCode,
            thickness: null,
            quantity: '',
            materialLength: '',
            width: '',
            partDescription: '',
            grainMatch: false,
            edgebandingConfig: null,
            machiningConfig: null,
            sprayConfig: null,
            qtyState: false,
            lengthState: false,
            widthState: false,
            thicknessID: null,
            looks: null,
            brand: null,
            bonded: false,
        }
        rowsConfig = [...rowsConfig, tempConfig];
        const lastMaterial = selectedMaterials[selectedMaterials.length - 1];
        selectedMaterials.push(lastMaterial);
        }else{
            var tempConfig = {
                productCode: null,
                thickness: null,
                quantity: '',
                materialLength: '',
                width: '',
                partDescription: '',
                grainMatch: false,
                edgebandingConfig: null,
                machiningConfig: null,
                sprayConfig: null,
                qtyState: false,
                lengthState: false,
                widthState: false,
                thicknessID: null,
                looks: null,
                brand: null,
                bonded: false,
            }
            rowsConfig = [...rowsConfig, tempConfig];
        }
    }else{
        var tempConfig = {
            productCode: null,
            thickness: null,
            quantity: '',
            materialLength: '',
            width: '',
            partDescription: '',
            grainMatch: false,
            edgebandingConfig: null,
            machiningConfig: null,
            sprayConfig: null,
            qtyState: false,
            lengthState: false,
            widthState: false,
            thicknessID: null,
            looks: null,
            brand: null,
            bonded: false,
        }
        rowsConfig = [...rowsConfig, tempConfig];
    }
}

// Function to change the selected material for a row from the material selection modal, it also sets all the other inputs to null
export const selectedMaterialForRow = (productCode) => {
    console.log('new material selected for row:' + rowWithOpenSelection + ', ' + productCode);
    var tempConfig = {
        productCode: productCode,
        thickness: null,
        quantity: '',
        materialLength: '',
        width: '',
        partDescription: '',
        grainMatch: false,
        edgebandingConfig: null,
        machiningConfig: null,
        sprayConfig: null,
        qtyState: false,
        lengthState: false,
        widthState: false,
        thicknessID: null,
        looks: null,
        brand: null,
        bonded: false,
    }

    rowsConfig[rowWithOpenSelection] = tempConfig;
};

export const getRowConfig = (row) => {
    const rowConfig = rowsConfig[row];

    return rowConfig;
};

// Function to store the current row with an open modal
export const changeRowWithOpenSelection = (rowID) => {
    rowWithOpenSelection = rowID;
};

// Function to store the current row with an open modal
export const getRowWithOpenSelection = () => {
    return rowWithOpenSelection;
};

// Function to delete a row from the order
export const removeRow = () => {
    if (rowsConfig !== -1) {
        // Use filter to create a new array without the specified element
        rowsConfig = rowsConfig.filter((_, index) => index !== rowWithOpenSelection);
    }
    if (thicknessProps !== -1) {
        // Use filter to create a new array without the specified element
        thicknessProps = thicknessProps.filter((_, index) => index !== rowWithOpenSelection);
    }
};


// Function to return the available thicknesses for a material
export const getAvailableThicknesses = () => {
    var availableThicknesses = [];
    const material = selectedMaterials[rowWithOpenSelection];

    for (let i = 1; i <= 11; i++) {
        const thicknessKey = `thickness${i}`;
        if (material[thicknessKey] != null && material[thicknessKey] != '' && material[thicknessKey] != 0) {
            availableThicknesses = [...availableThicknesses, material[thicknessKey]]
        }
    }

    return availableThicknesses;
};

// Function to set the selected thickness for a row
export const selectedThicknessForRow = (thickness) => {
    const currentConfig = rowsConfig[rowWithOpenSelection];
    var tempConfig = {
        productCode: currentConfig.productCode,
        thickness: thickness,
        quantity: '',
        materialLength: '',
        width: '',
        partDescription: '',
        grainMatch: false,
        edgebandingConfig: null,
        machiningConfig: null,
        sprayConfig: null,
        qtyState: false,
        lengthState: false,
        widthState: false,
        thicknessID: currentConfig.thicknessID,
        looks: currentConfig.looks,
        brand: currentConfig.brand,
        bonded: currentConfig.bonded,
    }
    rowsConfig[rowWithOpenSelection] = tempConfig;
    updateThicknessProps(thickness);
};

const updateThicknessProps = (thickness) => {
    var tempThickProps = {
        thickness : null,
        angle_cutting : null,
        cnc : null,
        cut_price : null,
        drilling : null,
        edgebanding : null,
        edgebanding1_bevel : null,
        edgebanding1_description : null,
        edgebanding1_price : null,
        edgebanding1_radius : null,
        edgebanding1_size : null,
        edgebanding1_square : null,
        edgebanding1_type : null,
        edgebanding2_bevel : null,
        edgebanding2_description : null,
        edgebanding2_price : null,
        edgebanding2_radius : null,
        edgebanding2_size : null,
        edgebanding2_square : null,
        edgebanding2_type : null,
        edgebanding3_bevel : null,
        edgebanding3_description : null,
        edgebanding3_price : null,
        edgebanding3_radius : null,
        edgebanding3_size : null,
        edgebanding3_square : null,
        edgebanding3_type : null,
        glued : null,
        hinge_drilling : null,
        sheet_price : null,
        shelf_drilling : null,
        spray_coating : null,
        stock : null,
        thicknessId: null,
    }

    const materialSelected = selectedMaterials[rowWithOpenSelection];

    for(let i=1; i<=11; i++){
        // kes to get the properties out of the materialSelected array
        const thicknessKey = `thickness${i}`;
        const thickness_angle_cutting = `thickness${i}_angle_cutting`;
        const thickness_cnc = `thickness${i}_cnc`;
        const thickness_cut_price = `thickness${i}_cut_price`;
        const thickness_drilling = `thickness${i}_drilling`;
        const thickness_edgebanding = `thickness${i}_edgebanding`;
        const thickness_edgebanding1_bevel = `thickness${i}_edgebanding1_bevel`;
        const thickness_edgebanding1_description = `thickness${i}_edgebanding1_description`;
        const thickness_edgebanding1_price = `thickness${i}_edgebanding1_price`;
        const thickness_edgebanding1_radius = `thickness${i}_edgebanding1_radius`;
        const thickness_edgebanding1_size = `thickness${i}_edgebanding1_size`;
        const thickness_edgebanding1_square = `thickness${i}_edgebanding1_square`;
        const thickness_edgebanding1_type = `thickness${i}_edgebanding1_type`;
        const thickness_edgebanding2_bevel = `thickness${i}_edgebanding2_bevel`;
        const thickness_edgebanding2_description = `thickness${i}_edgebanding2_description`;
        const thickness_edgebanding2_price = `thickness${i}_edgebanding2_price`;
        const thickness_edgebanding2_radius = `thickness${i}_edgebanding2_radius`;
        const thickness_edgebanding2_size = `thickness${i}_edgebanding2_size`;
        const thickness_edgebanding2_square = `thickness${i}_edgebanding2_square`;
        const thickness_edgebanding2_type = `thickness${i}_edgebanding2_type`;
        const thickness_edgebanding3_bevel = `thickness${i}_edgebanding3_bevel`;
        const thickness_edgebanding3_description = `thickness${i}_edgebanding3_description`;
        const thickness_edgebanding3_price = `thickness${i}_edgebanding3_price`;
        const thickness_edgebanding3_radius = `thickness${i}_edgebanding3_radius`;
        const thickness_edgebanding3_size = `thickness${i}_edgebanding3_size`;
        const thickness_edgebanding3_square = `thickness${i}_edgebanding3_square`;
        const thickness_edgebanding3_type = `thickness${i}_edgebanding3_type`;
        const thickness_glued = `thickness${i}_glued`;
        const thickness_hinge_drilling = `thickness${i}_hinge_drilling`;
        const thickness_sheet_price = `thickness${i}_sheet_price`;
        const thickness_shelf_drilling = `thickness${i}_shelf_drilling`;
        const thickness_spray_coating = `thickness${i}_spray_coating`;
        const thickness_stock = `thickness${i}_stock`;

        const iterationThick = materialSelected[thicknessKey];
        if((iterationThick == thickness) && (materialSelected[thicknessKey] != null && materialSelected[thicknessKey] != '' && materialSelected[thicknessKey] != 0)){
            tempThickProps.thickness = materialSelected[thicknessKey];
            tempThickProps.angle_cutting = materialSelected[thickness_angle_cutting];
            tempThickProps.cnc = materialSelected[thickness_cnc];
            tempThickProps.cut_price = materialSelected[thickness_cut_price];
            tempThickProps.drilling = materialSelected[thickness_drilling];
            tempThickProps.edgebanding = materialSelected[thickness_edgebanding];
            tempThickProps.edgebanding1_bevel = materialSelected[thickness_edgebanding1_bevel];
            tempThickProps.edgebanding1_description = materialSelected[thickness_edgebanding1_description];
            tempThickProps.edgebanding1_price = materialSelected[thickness_edgebanding1_price];
            tempThickProps.edgebanding1_radius = materialSelected[thickness_edgebanding1_radius];
            tempThickProps.edgebanding1_size = materialSelected[thickness_edgebanding1_size];
            tempThickProps.edgebanding1_square = materialSelected[thickness_edgebanding1_square];
            tempThickProps.edgebanding1_type = materialSelected[thickness_edgebanding1_type];
            tempThickProps.edgebanding2_bevel = materialSelected[thickness_edgebanding2_bevel];
            tempThickProps.edgebanding2_description = materialSelected[thickness_edgebanding2_description];
            tempThickProps.edgebanding2_price = materialSelected[thickness_edgebanding2_price];
            tempThickProps.edgebanding2_radius = materialSelected[thickness_edgebanding2_radius];
            tempThickProps.edgebanding2_size = materialSelected[thickness_edgebanding2_size];
            tempThickProps.edgebanding2_square = materialSelected[thickness_edgebanding2_square];
            tempThickProps.edgebanding2_type = materialSelected[thickness_edgebanding2_type];
            tempThickProps.edgebanding3_bevel = materialSelected[thickness_edgebanding3_bevel];
            tempThickProps.edgebanding3_description = materialSelected[thickness_edgebanding3_description];
            tempThickProps.edgebanding3_price = materialSelected[thickness_edgebanding3_price];
            tempThickProps.edgebanding3_radius = materialSelected[thickness_edgebanding3_radius];
            tempThickProps.edgebanding3_size = materialSelected[thickness_edgebanding3_size];
            tempThickProps.edgebanding3_square = materialSelected[thickness_edgebanding3_square];
            tempThickProps.edgebanding3_type = materialSelected[thickness_edgebanding3_type];
            tempThickProps.glued = materialSelected[thickness_glued];
            tempThickProps.hinge_drilling = materialSelected[thickness_hinge_drilling];
            tempThickProps.sheet_price = materialSelected[thickness_sheet_price];
            tempThickProps.shelf_drilling = materialSelected[thickness_shelf_drilling];
            tempThickProps.spray_coating = materialSelected[thickness_spray_coating];
            tempThickProps.stock = materialSelected[thickness_stock];
            tempThickProps.thicknessId = parseInt(i);
        }
    }
    thicknessProps[rowWithOpenSelection] = tempThickProps;
    rowsConfig[rowWithOpenSelection].thicknessID = tempThickProps.thicknessId;
    rowsConfig[rowWithOpenSelection].looks = selectedMaterials[rowWithOpenSelection].color_description;
    rowsConfig[rowWithOpenSelection].brand = selectedMaterials[rowWithOpenSelection].manufacturer;
    rowsConfig[rowWithOpenSelection].bonded = tempThickProps.glued.toLowerCase() === 'yes' ? true : false;
    console.log('//// -> thickProps for the new thickness selection:');
    console.log(tempThickProps);
}

export const getThickProps = (index) => {
    const rowThickProps = thicknessProps[index];
    return rowThickProps;
}

export const getThickPropsEdgebanding = () => {
    const rowThickProps = thicknessProps[rowWithOpenSelection];
    return rowThickProps;
}

// Function to update the row config
export const changeRowConfig = (row, prop, value) => {
    if (prop === 'qty') {
        rowsConfig[row].quantity = value;
        return;
    } else if (prop === 'length') {
        rowsConfig[row].materialLength = value;
        return;
    } else if (prop === 'width') {
        rowsConfig[row].width = value;
        return;
    } else if (prop === 'partDescription') {
        rowsConfig[row].partDescription = value;
        return;
    } else if (prop === 'grainMatch') {
        rowsConfig[row].grainMatch = value;
        return;
    }
};

export const updateEdgebandingConfig = (edgebandingConf) => {
    const currRowConfig = rowsConfig[rowWithOpenSelection];
    const newRowConfig = {
        productCode: currRowConfig.productCode,
        thickness: currRowConfig.thickness,
        quantity: currRowConfig.quantity,
        materialLength: currRowConfig.materialLength,
        width: currRowConfig.width,
        partDescription: currRowConfig.partDescription,
        grainMatch: currRowConfig.grainMatch,
        edgebandingConfig: edgebandingConf,
        machiningConfig: currRowConfig.machiningConfig,
        sprayConfig: currRowConfig.sprayConfig,
        qtyState: currRowConfig.qtyState,
        lengthState: currRowConfig.lengthState,
        widthState: currRowConfig.widthState,
        thicknessID: currRowConfig.thicknessID,
        looks: currRowConfig.looks,
        brand: currRowConfig.brand,
        bonded: currRowConfig.bonded,
    }
    rowsConfig[rowWithOpenSelection] = newRowConfig;

    //  look for edgebanding to add to the available edging tape array
    if(edgebandingConf.l1 == true || edgebandingConf.l2 == true || edgebandingConf.w1 == true || edgebandingConf.w2 == true){
        if(edgebandingConf.l1){
            const edgebandingObj = edgebandingConf.l1_obj;
            const edgingTapeOption = {
                name: edgebandingObj.edgebanding_type,
                description: edgebandingObj.edgebanding_description,
                size: edgebandingObj.edgebanding_size,
                decor_name: currRowConfig.productCode,
            }
            // Find the MDF panel with the same product code and thickness
            let existingEdgingTapeInArray = availableEdgingTape.find(edgingTape => edgingTape.decor_name == edgingTapeOption.decor_name && edgingTape.name == edgingTapeOption.name);
            if(existingEdgingTapeInArray){
                //  don't add
            }else{
                availableEdgingTape = [...availableEdgingTape, edgingTapeOption];
                console.log('ADDED EDGING TAPE TO THE AVAILABLE LIST');
            }
        }
        if(edgebandingConf.l2){
            const edgebandingObj = edgebandingConf.l2_obj;
            const edgingTapeOption = {
                name: edgebandingObj.edgebanding_type,
                description: edgebandingObj.edgebanding_description,
                size: edgebandingObj.edgebanding_size,
                decor_name: currRowConfig.productCode,
            }
            // Find the MDF panel with the same product code and thickness
            let existingEdgingTapeInArray = availableEdgingTape.find(edgingTape => edgingTape.decor_name == edgingTapeOption.decor_name && edgingTape.name == edgingTapeOption.name);
            if(existingEdgingTapeInArray){
                //  don't add
            }else{
                availableEdgingTape = [...availableEdgingTape, edgingTapeOption];
                console.log('ADDED EDGING TAPE TO THE AVAILABLE LIST');
            }
        }
        if(edgebandingConf.w1){
            const edgebandingObj = edgebandingConf.w1_obj;
            const edgingTapeOption = {
                name: edgebandingObj.edgebanding_type,
                description: edgebandingObj.edgebanding_description,
                size: edgebandingObj.edgebanding_size,
                decor_name: currRowConfig.productCode,
            }
            // Find the MDF panel with the same product code and thickness
            let existingEdgingTapeInArray = availableEdgingTape.find(edgingTape => edgingTape.decor_name == edgingTapeOption.decor_name && edgingTape.name == edgingTapeOption.name);
            if(existingEdgingTapeInArray){
                //  don't add
            }else{
                availableEdgingTape = [...availableEdgingTape, edgingTapeOption];
                console.log('ADDED EDGING TAPE TO THE AVAILABLE LIST');
            }
        }
        if(edgebandingConf.w2){
            const edgebandingObj = edgebandingConf.w2_obj;
            const edgingTapeOption = {
                name: edgebandingObj.edgebanding_type,
                description: edgebandingObj.edgebanding_description,
                size: edgebandingObj.edgebanding_size,
                decor_name: currRowConfig.productCode,
            }
            // Find the MDF panel with the same product code and thickness
            let existingEdgingTapeInArray = availableEdgingTape.find(edgingTape => edgingTape.decor_name == edgingTapeOption.decor_name && edgingTape.name == edgingTapeOption.name);
            if(existingEdgingTapeInArray){
                //  don't add
            }else{
                availableEdgingTape = [...availableEdgingTape, edgingTapeOption];
                console.log('ADDED EDGING TAPE TO THE AVAILABLE LIST');
            }
        }
    }
};

export const getEdgebandingOptions = () => {
    var edgebandingOptions = [];

    const currMaterial = thicknessProps[rowWithOpenSelection];
    console.log(currMaterial);
    if(currMaterial.edgebanding1_price != null && currMaterial.edgebanding1_price != ''){
        console.log('found option 1');
        let edgeOption = {
            edgebanding_bevel: currMaterial.edgebanding1_bevel, 
            edgebanding_description: currMaterial.edgebanding1_description,
            edgebanding_price: currMaterial.edgebanding1_price,
            edgebanding_radius: currMaterial.edgebanding1_radius,
            edgebanding_size: currMaterial.edgebanding1_size,
            edgebanding_square: currMaterial.edgebanding1_square,
            edgebanding_type: currMaterial.edgebanding1_type
        }
        edgebandingOptions = [...edgebandingOptions, edgeOption];
    }
    if(currMaterial.edgebanding2_price != null && currMaterial.edgebanding2_price != ''){
        console.log('found option 1');
        let edgeOption = {
            edgebanding_bevel: currMaterial.edgebanding2_bevel, 
            edgebanding_description: currMaterial.edgebanding2_description,
            edgebanding_price: currMaterial.edgebanding2_price,
            edgebanding_radius: currMaterial.edgebanding2_radius,
            edgebanding_size: currMaterial.edgebanding2_size,
            edgebanding_square: currMaterial.edgebanding2_square,
            edgebanding_type: currMaterial.edgebanding2_type
        }
        edgebandingOptions = [...edgebandingOptions, edgeOption];
    }
    if(currMaterial.edgebanding3_price != null && currMaterial.edgebanding3_price != ''){
        console.log('found option 1');
        let edgeOption = {
            edgebanding_bevel: currMaterial.edgebanding3_bevel, 
            edgebanding_description: currMaterial.edgebanding3_description,
            edgebanding_price: currMaterial.edgebanding3_price,
            edgebanding_radius: currMaterial.edgebanding3_radius,
            edgebanding_size: currMaterial.edgebanding3_size,
            edgebanding_square: currMaterial.edgebanding3_square,
            edgebanding_type: currMaterial.edgebanding3_type
        }
        edgebandingOptions = [...edgebandingOptions, edgeOption];
    }

    console.log(edgebandingOptions);
    return edgebandingOptions;
}

// save the changes made to machining config for row
export const updateMachiningConfig = (newMachiningConf, newDrawingProps) => {
    const currRowConfig = rowsConfig[rowWithOpenSelection];
    const newRowConfig = {
        productCode: currRowConfig.productCode,
        thickness: currRowConfig.thickness,
        quantity: currRowConfig.quantity,
        materialLength: currRowConfig.materialLength,
        width: currRowConfig.width,
        partDescription: currRowConfig.partDescription,
        grainMatch: currRowConfig.grainMatch,
        edgebandingConfig: currRowConfig.edgebandingConfig,
        machiningConfig: newMachiningConf,
        sprayConfig: currRowConfig.sprayConfig,
        qtyState: currRowConfig.qtyState,
        lengthState: currRowConfig.lengthState,
        widthState: currRowConfig.widthState,
        thicknessID: currRowConfig.thicknessID,
        looks: currRowConfig.looks,
        brand: currRowConfig.brand,
        bonded: currRowConfig.bonded,
    }
    rowsConfig[rowWithOpenSelection] = newRowConfig;
};

// save the changes made to machining config for row
export const updateSprayConfig = (newSprayConfig) => {
    const currRowConfig = rowsConfig[rowWithOpenSelection];
    const newRowConfig = {
        productCode: currRowConfig.productCode,
        thickness: currRowConfig.thickness,
        quantity: currRowConfig.quantity,
        materialLength: currRowConfig.materialLength,
        width: currRowConfig.width,
        partDescription: currRowConfig.partDescription,
        grainMatch: currRowConfig.grainMatch,
        edgebandingConfig: currRowConfig.edgebandingConfig,
        machiningConfig: currRowConfig.machiningConfig,
        sprayConfig: newSprayConfig,
        qtyState: currRowConfig.qtyState,
        lengthState: currRowConfig.lengthState,
        widthState: currRowConfig.widthState,
        thicknessID: currRowConfig.thicknessID,
        looks: currRowConfig.looks,
        brand: currRowConfig.brand,
        bonded: currRowConfig.bonded,
    }
    rowsConfig[rowWithOpenSelection] = newRowConfig;
};



//  bin placement algo
let remainingCuts = [];
let bins = [];
let mdfPanelsInOrder = [];

export class Packer {
    constructor(product_code, thickness, thicknessId, decor_name, material_type, brand, length, width) {
        this.init(product_code, thickness, thicknessId, decor_name, material_type, brand, length, width);
    }

    init(product_code, thickness, thicknessId, decor_name, material_type, brand, length, width) {
        this.root = { product_code, thickness, thicknessId, decor_name, material_type, brand, x: 0, y: 0, length: length - 10, width: width - 10 };    // 10mm spacing from board edge
    }

    fit(cuts) {
        var n, node, cut;
        remainingCuts = [];
        for (n = 0; n < cuts.length; n++) {
            cut = cuts[n];
            var current_root = this.root;
            var length_with_spacing = cut.length;
            var width_with_spacing = cut.width;
            if(length_with_spacing <= (current_root.length - 15)){ // AM ADUNAT CU 15 LA LENGTH SI WIDTH PER CUT PT SPACING-UL DE 15MM DINTRE TAIETURI
                length_with_spacing = cut.length + 15;
            }
            if(length_with_spacing <= (current_root.width - 15)){
                width_with_spacing = cut.width + 15;
            }
        
            if (node = this.findNode(this.root, length_with_spacing, width_with_spacing)){
                const newPlacedCut = {
                    x: node.x,
                    y: node.y,
                    length: cut.length,
                    width: cut.width
                }
                const newPlacedCutObj = placedCutsArray[currentBinSelected];
                newPlacedCutObj.cuts = [...newPlacedCutObj.cuts, newPlacedCut];
                placedCutsArray[currentBinSelected] = newPlacedCutObj;

                cut.fit = this.splitNode(node, length_with_spacing, width_with_spacing);
            }else if(node = this.findNode(this.root, width_with_spacing, length_with_spacing)){
                const newPlacedCut = {
                    x: node.x,
                    y: node.y,
                    length: cut.width,
                    width: cut.length
                }
                const newPlacedCutObj = placedCutsArray[currentBinSelected];
                newPlacedCutObj.cuts = [...newPlacedCutObj.cuts, newPlacedCut];
                placedCutsArray[currentBinSelected] = newPlacedCutObj;

                cut.fit = this.splitNode(node, width_with_spacing, length_with_spacing);
                const cutLength = cut.length;
                cut.length = cut.width;
                cut.width = cutLength;
            }else{
                remainingCuts.push(cut);
            }
        }
    }
    
    findNode(root, length, width) {
        if (root.used)
            return this.findNode(root.right, length, width) || this.findNode(root.down, length, width);
        else if ((length <= root.length) && (width <= root.width))
            return root;
        else
            return null;
    }
    
    
    splitNode(node, length, width) {
        node.used = true;
        node.down  = { x: node.x,     y: node.y + width, length: node.length,     width: node.width - width };
        node.right = { x: node.x + length, y: node.y,     length: node.length - length, width: width          };
        return node;
    }
}

//  group cuts based on product code and thickness
const groupCuts = () =>{
    for (let i = 0; i < rowsConfig.length; i++) {
        if(rowsConfig[i].productCode != null && rowsConfig[i].thickness != null && rowsConfig[i].quantity != null && rowsConfig[i].materialLength != null && rowsConfig[i].width != null){
            console.log('-> found a valid row in the order');
            const cut = {
                product_code: rowsConfig[i].productCode,
                thickness: rowsConfig[i].thickness,
                length: parseInt(rowsConfig[i].materialLength),
                width: parseInt(rowsConfig[i].width),
                quantity: parseInt(rowsConfig[i].quantity),
                placed: false
            };
            console.log(`cut ${i} length:` + cut.length);
    
            // Find the MDF panel with the same product code and thickness
            let mdfPanel = mdfPanelsInOrder.find(panel => panel.product_code === cut.product_code && panel.thickness === cut.thickness);
            console.log('mdfPanel: ' + mdfPanel);

            if (!mdfPanel) {
                // If MDF panel doesn't exist, create a new one
                console.log('thicknessId for mdPanel:' +i, thicknessProps[i].thicknessId);
                console.log(thicknessProps);
                mdfPanel = {
                    product_code: cut.product_code,
                    thickness: cut.thickness,
                    thicknessID: thicknessProps[i].thicknessId,
                    decor_name: selectedMaterials[i].color_description,
                    material_type: selectedMaterials[i].material_type,
                    brand: selectedMaterials[i].manufacturer,
                    cuts: []
                };
                // Add cuts based on the quantity
                for (let j = 0; j < parseInt(rowsConfig[i].quantity); j++) {
                    console.log('adding cut to mdfPanel');
                    mdfPanel.cuts.push({
                        length: cut.length,
                        width: cut.width,
                        placed: cut.placed
                    });
                }
                mdfPanelsInOrder.push(mdfPanel);
            }else{
                for (let j = 0; j < parseInt(rowsConfig[i].quantity); j++) {
                    mdfPanel.cuts.push({
                        length: cut.length,
                        width: cut.width,
                        placed: cut.placed
                    });
                }
            }
        }
    }
    console.log(mdfPanelsInOrder);

}

//  sort the cuts for each panel descending based on max side
const sortCutsByMaxSide = () => {
    for (let i = 0; i < mdfPanelsInOrder.length; i++) {
        const mdfPanel = mdfPanelsInOrder[i];
        mdfPanel.cuts.sort((a, b) => calculateMaxDimension(b) - calculateMaxDimension(a));
    }
}

const calculateMaxDimension = (cut) => {
    return Math.max(cut.length, cut.width);
}

//  loop trough each material cuts and place them, if there are remaining cuts, place them aswell in a new bin
const placeCuts = () =>{
    console.log("placeCuts called");

    for(var i = 0; i < mdfPanelsInOrder.length; i++){
        console.log(mdfPanelsInOrder[i]);
        var cutsNumber = mdfPanelsInOrder[i].cuts.length;
        console.log('cutsNumber: ' + cutsNumber);
        var cutsArray = mdfPanelsInOrder[i].cuts;
        var panel = mdfPanelsInOrder[i];
        createBin(panel, cutsArray);

        while(remainingCuts.length > 0){
            console.log('remaining cuts: ' + remainingCuts.length);
            createBin(panel, remainingCuts);
        }

    }
}

//  create the bins in which the cuts will be placed for each material in order
let currentBinSelected = null;
const createBin = (mdfPanel, cutsArray) =>{
    console.log(mdfPanel);
    var productCode = mdfPanel.product_code;
    var thickness = mdfPanel.thickness;
    var thicknessId = mdfPanel.thicknessID;
    var decor_name = mdfPanel.decor_name;
    var material_type = mdfPanel.material_type;
    var brand = mdfPanel.brand;
    var panelLength = 0;
    var panelWidth = 0;

    for(var i = 0; i < selectedMaterials.length; i++){
        if(selectedMaterials[i].product_code == mdfPanel.product_code){
            panelLength = parseInt(selectedMaterials[i].material_length);
            panelWidth = parseInt(selectedMaterials[i].width);
        }
    }

    if(currentBinSelected == null){
        currentBinSelected = 0
        const placedCutsObj = {
            cuts: []
        }
        placedCutsArray[currentBinSelected] = placedCutsObj;
    }else{
        currentBinSelected = currentBinSelected + 1;
        const placedCutsObj = {
            cuts: []
        }
        placedCutsArray[currentBinSelected] = placedCutsObj;
    }
    var bin = new Packer(productCode, thickness, thicknessId, decor_name, material_type, brand, panelLength, panelWidth);
    bins.push(bin);
    bin.fit(cutsArray);
}

//  get the cuts array (drawing nodes) for each bin
let placedCutsArray = [];
export const getPlacedCutsArray = () =>{
    return placedCutsArray;
}

export const getPlacedCutsArrayAsync = () =>{
    return placedCutsArray;
}

const sortPlacedCuts = (node, index) =>{
    if (node.used) {
        // add the placed cuts to the placedCutsArray using the object stored in the index position
        let placedCutsObj = placedCutsArray[index];
        const cutDetails = {
            x: node.x,
            y: node.y,
            length: node.length,
            width: node.width
        }
        placedCutsObj.nodes = [...placedCutsObj.nodes, cutDetails];
        placedCutsArray[index] = placedCutsObj;
    }
    // Recursively sort child nodes
    if (node.down) {
        sortPlacedCuts(node.down, index);
    }
    if (node.right) {
        sortPlacedCuts(node.right, index);
    }
};

//  get the offcuts array (drawing nodes) for each bin
let offcutsArray = [];
export const getOffcutsArray = () =>{
    offcutsArray = []
    for (var i = 0; i < bins.length; i++) {
        const bin = bins[i];
        const offcutsObj = {
            product_code: bin.root.product_code,
            thickness: bin.root.thickness,
            nodes: []
        };
        offcutsArray = [...offcutsArray, offcutsObj];
        const offcutSelectionObj = {
            product_code: bin.root.product_code,
            thickness: bin.root.thickness,
            offcuts: []
        };
        offcutsSelections = [...offcutsSelections, offcutSelectionObj];
        sortOffcuts(bin.root, i);
    }
    return offcutsArray;
}

export const getOffcutsArrayAsync = async() =>{
    return offcutsArray;
}

const sortOffcuts = (node, index) =>{
    if(node.used) {
        // do nothing, skip
    }else{
        // add the placed cuts to the placedCutsArray using the object stored in the index position
        if (parseInt(node.length) > 250 && parseInt(node.width) > 100) {
            let offcutsObj = offcutsArray[index];
            offcutsObj.nodes = [...offcutsObj.nodes, node];
            offcutsArray[index] = offcutsObj;
            let offcutSelectionObj = offcutsSelections[index];
            let newOffcutSelection = {
                length: node.length,
                width: node.width,
                length2: null,
                width2: null,
                selected : false,
                cutLength: null,
                cutWidth: null,
                cut: false,
                cutSide: null,
                remainingAvailable: false,
                includeRemaining: false,
            }
            offcutSelectionObj.offcuts = [...offcutSelectionObj.offcuts, newOffcutSelection];
            offcutsSelections[index] = offcutSelectionObj;
        }
    }
    // Recursively sort child nodes
    if (node.down) {
        sortOffcuts(node.down, index);
    }
    if (node.right) {
        sortOffcuts(node.right, index);
    }
};

//  do all the bin placements and return the bin placements array
export const getBinPlacements = () =>{
    bins = [];
    mdfPanelsInOrder = [];
    placedCutsArray = [];
    currentBinSelected = null;
    remainingCuts = [];
    groupCuts();
    sortCutsByMaxSide();
    placeCuts();
    return bins;
};

export const getBins = async() =>{
    return bins;
}

//  match the bins with the selectedMaterials and return their details
export const getBinDetails = () =>{
    let detailsArray = []
    for (let i = 0; i < bins.length; i++) {
        const bin = bins[i];
        for(let j = 0; j < selectedMaterials.length; j++){
            if(bin.root.product_code == selectedMaterials[j].product_code){
                console.log('Bin details thickness:' + bin.root.thickness);
                const detailsObj = {
                    product_code: selectedMaterials[j].product_code,
                    materialLength: selectedMaterials[j].material_length,
                    width: selectedMaterials[j].width,
                    thickness: bin.root.thickness,
                    grain: selectedMaterials[j].grain,
                    colorDescription: selectedMaterials[j].color_description
                }
                detailsArray = [...detailsArray, detailsObj];
                break;
            }
        }
    }
    return detailsArray;
}

//  set the bin index of the latest bin select offcuts
let binWithOpenSelectOffcuts = null;
export const setBinWithOpenSelectOffcuts = (binId) => {
    binWithOpenSelectOffcuts = binId;
};

//  get the bin placement for the bin with select offcuts modal open
export const getBinPlacementsForBinWithOpenSelectOffcuts = () =>{
    return bins[binWithOpenSelectOffcuts];
};

//  get the placed cuts for the bin with select offcuts modal open
export const getPlacedCutsForBinWithOpenSelectOffcuts = () =>{
    return placedCutsArray[binWithOpenSelectOffcuts];
};

//  get the offcuts for the bin with select offcuts modal open
export const getOffcutsForBinWithOpenSelectOffcuts = () =>{
    return offcutsArray[binWithOpenSelectOffcuts];
};

//  array that stores the offcuts selections offcutsSelections[binIndex].offcuts[offcutIndex]
let offcutsSelections = [];

//  get the offcuts selections for the bin with select offcuts modal open
export const getOffcutsSelectionsForBin = () =>{
    return offcutsSelections[binWithOpenSelectOffcuts];
};

export const updateOffcutsSelectionsForBin = (newOffcutsSelections) =>{
    offcutsSelections[binWithOpenSelectOffcuts] = newOffcutsSelections;
    console.log('OFFCUTS SELECTIONS UPDATED IN ORDER CONFIG:');
    console.log(offcutsSelections);
}

export const getOffcutsSelections = () =>{
    return offcutsSelections;
}


//  update the row inputs state to determine if the user can advance to the pricing page
export const changeGoodInputState = (index, inputField, newState) =>{
    const currRowConfig = rowsConfig[index];
    if(inputField == 'qty'){
        const newRowConfig = {
            productCode: currRowConfig.productCode,
            thickness: currRowConfig.thickness,
            quantity: currRowConfig.quantity,
            materialLength: currRowConfig.materialLength,
            width: currRowConfig.width,
            partDescription: currRowConfig.partDescription,
            grainMatch: currRowConfig.grainMatch,
            edgebandingConfig: currRowConfig.edgebandingConfig,
            machiningConfig: currRowConfig.machiningConfig,
            sprayConfig: currRowConfig.sprayConfig,
            qtyState: newState,
            lengthState: currRowConfig.lengthState,
            widthState: currRowConfig.widthState,
            thicknessID: currRowConfig.thicknessID,
            looks: currRowConfig.looks,
            brand: currRowConfig.brand,
            bonded: currRowConfig.bonded,
        }
        rowsConfig[index] = newRowConfig;
    }else if(inputField == 'length'){
        const newRowConfig = {
            productCode: currRowConfig.productCode,
            thickness: currRowConfig.thickness,
            quantity: currRowConfig.quantity,
            materialLength: currRowConfig.materialLength,
            width: currRowConfig.width,
            partDescription: currRowConfig.partDescription,
            grainMatch: currRowConfig.grainMatch,
            edgebandingConfig: currRowConfig.edgebandingConfig,
            machiningConfig: null,
            sprayConfig: currRowConfig.sprayConfig,
            qtyState: currRowConfig.qtyState,
            lengthState: newState,
            widthState: currRowConfig.widthState,
            thicknessID: currRowConfig.thicknessID,
            looks: currRowConfig.looks,
            brand: currRowConfig.brand,
            bonded: currRowConfig.bonded,
        }
        rowsConfig[index] = newRowConfig;
    }else if(inputField == 'width'){
        const newRowConfig = {
            productCode: currRowConfig.productCode,
            thickness: currRowConfig.thickness,
            quantity: currRowConfig.quantity,
            materialLength: currRowConfig.materialLength,
            width: currRowConfig.width,
            partDescription: currRowConfig.partDescription,
            grainMatch: currRowConfig.grainMatch,
            edgebandingConfig: currRowConfig.edgebandingConfig,
            machiningConfig: null,
            sprayConfig: currRowConfig.sprayConfig,
            qtyState: currRowConfig.qtyState,
            lengthState: currRowConfig.lengthState,
            widthState: newState,
            thicknessID: currRowConfig.thicknessID,
            looks: currRowConfig.looks,
            brand: currRowConfig.brand,
            bonded: currRowConfig.bonded,
        }
        rowsConfig[index] = newRowConfig;
    }
}



// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| FULL SHEETS |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/

//  full sheets config section
let fullSheetsSelectedMaterials = [];
let fullSheetsConfig = [];
let fullSheetsThicknessProps = [];

let fullSheetsRowWithOpenSelection = null;
export const changeFullSheetRowWithOpenSelection = async(rowID) => {
    console.log('full sheets open row:' + rowID);
    fullSheetsRowWithOpenSelection = rowID;
}

// Function to push to the selected materials array
export const pushToSelectedFullSheetsMaterials = (newMaterial) => {
    // copy the material basic information
    var tempMaterial = {
        manufacturer: newMaterial.manufacturer,
        product_code: newMaterial.product_code,
        color_description: newMaterial.color_description,
        back_side_description: newMaterial.back_side_description,
        image: newMaterial.image_1,
        material_type: newMaterial.material_type,
        cutting: newMaterial.cutting,
        product_characteristics: newMaterial.product_characteristics,
        material_length: newMaterial.material_length,
        width: newMaterial.width,
        grain: newMaterial.grain,
    }

    // look for each thickness and only add the ones that exist
    // For loop to create JSX elements
    for (let i = 1; i <= 11; i++) {
        const thicknessKey = `thickness${i}`;
        const thickness_angle_cutting = `thickness${i}_angle_cutting`;
        const thickness_cnc = `thickness${i}_cnc`;
        const thickness_cut_price = `thickness${i}_cut_price`;
        const thickness_drilling = `thickness${i}_drilling`;
        const thickness_edgebanding = `thickness${i}_edgebanding`;
        const thickness_edgebanding1_bevel = `thickness${i}_edgebanding1_bevel`;
        const thickness_edgebanding1_description = `thickness${i}_edgebanding1_description`;
        const thickness_edgebanding1_price = `thickness${i}_edgebanding1_price`;
        const thickness_edgebanding1_radius = `thickness${i}_edgebanding1_radius`;
        const thickness_edgebanding1_size = `thickness${i}_edgebanding1_size`;
        const thickness_edgebanding1_square = `thickness${i}_edgebanding1_square`;
        const thickness_edgebanding1_type = `thickness${i}_edgebanding1_type`;
        const thickness_edgebanding2_bevel = `thickness${i}_edgebanding2_bevel`;
        const thickness_edgebanding2_description = `thickness${i}_edgebanding2_description`;
        const thickness_edgebanding2_price = `thickness${i}_edgebanding2_price`;
        const thickness_edgebanding2_radius = `thickness${i}_edgebanding2_radius`;
        const thickness_edgebanding2_size = `thickness${i}_edgebanding2_size`;
        const thickness_edgebanding2_square = `thickness${i}_edgebanding2_square`;
        const thickness_edgebanding2_type = `thickness${i}_edgebanding2_type`;
        const thickness_edgebanding3_bevel = `thickness${i}_edgebanding3_bevel`;
        const thickness_edgebanding3_description = `thickness${i}_edgebanding3_description`;
        const thickness_edgebanding3_price = `thickness${i}_edgebanding3_price`;
        const thickness_edgebanding3_radius = `thickness${i}_edgebanding3_radius`;
        const thickness_edgebanding3_size = `thickness${i}_edgebanding3_size`;
        const thickness_edgebanding3_square = `thickness${i}_edgebanding3_square`;
        const thickness_edgebanding3_type = `thickness${i}_edgebanding3_type`;
        const thickness_glued = `thickness${i}_glued`;
        const thickness_hinge_drilling = `thickness${i}_hinge_drilling`;
        const thickness_sheet_price = `thickness${i}_sheet_price`;
        const thickness_shelf_drilling = `thickness${i}_shelf_drilling`;
        const thickness_spray_coating = `thickness${i}_spray_coating`;
        const thickness_stock = `thickness${i}_stock`;

        if (newMaterial[thicknessKey] != null && newMaterial[thicknessKey] != '' && newMaterial[thicknessKey] != 0) {
            tempMaterial[thicknessKey] = newMaterial[thicknessKey];
            tempMaterial[thickness_angle_cutting] = newMaterial[thickness_angle_cutting];
            tempMaterial[thickness_cnc] = newMaterial[thickness_cnc];
            tempMaterial[thickness_cut_price] = newMaterial[thickness_cut_price];
            tempMaterial[thickness_drilling] = newMaterial[thickness_drilling];
            tempMaterial[thickness_edgebanding] = newMaterial[thickness_edgebanding];
            tempMaterial[thickness_edgebanding1_bevel] = newMaterial[thickness_edgebanding1_bevel];
            tempMaterial[thickness_edgebanding1_description] = newMaterial[thickness_edgebanding1_description];
            tempMaterial[thickness_edgebanding1_price] = newMaterial[thickness_edgebanding1_price];
            tempMaterial[thickness_edgebanding1_radius] = newMaterial[thickness_edgebanding1_radius];
            tempMaterial[thickness_edgebanding1_size] = newMaterial[thickness_edgebanding1_size];
            tempMaterial[thickness_edgebanding1_square] = newMaterial[thickness_edgebanding1_square];
            tempMaterial[thickness_edgebanding1_type] = newMaterial[thickness_edgebanding1_type];
            tempMaterial[thickness_edgebanding2_bevel] = newMaterial[thickness_edgebanding2_bevel];
            tempMaterial[thickness_edgebanding2_description] = newMaterial[thickness_edgebanding2_description];
            tempMaterial[thickness_edgebanding2_price] = newMaterial[thickness_edgebanding2_price];
            tempMaterial[thickness_edgebanding2_radius] = newMaterial[thickness_edgebanding2_radius];
            tempMaterial[thickness_edgebanding2_size] = newMaterial[thickness_edgebanding2_size];
            tempMaterial[thickness_edgebanding2_square] = newMaterial[thickness_edgebanding2_square];
            tempMaterial[thickness_edgebanding2_type] = newMaterial[thickness_edgebanding2_type];
            tempMaterial[thickness_edgebanding3_bevel] = newMaterial[thickness_edgebanding3_bevel];
            tempMaterial[thickness_edgebanding3_description] = newMaterial[thickness_edgebanding3_description];
            tempMaterial[thickness_edgebanding3_price] = newMaterial[thickness_edgebanding3_price];
            tempMaterial[thickness_edgebanding3_radius] = newMaterial[thickness_edgebanding3_radius];
            tempMaterial[thickness_edgebanding3_size] = newMaterial[thickness_edgebanding3_size];
            tempMaterial[thickness_edgebanding3_square] = newMaterial[thickness_edgebanding3_square];
            tempMaterial[thickness_edgebanding3_type] = newMaterial[thickness_edgebanding3_type];
            tempMaterial[thickness_glued] = newMaterial[thickness_glued];
            tempMaterial[thickness_hinge_drilling] = newMaterial[thickness_hinge_drilling];
            tempMaterial[thickness_sheet_price] = newMaterial[thickness_sheet_price];
            tempMaterial[thickness_shelf_drilling] = newMaterial[thickness_shelf_drilling];
            tempMaterial[thickness_spray_coating] = newMaterial[thickness_spray_coating];
            tempMaterial[thickness_stock] = newMaterial[thickness_stock];

        }
    }

    fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection] = tempMaterial;
};

// Function to change the selected material for a row from the material selection modal, it also sets all the other inputs to null
export const selectedFullSheetsMaterialForRow = (productCode) => {
    console.log('new material selected for full sheets row:' + fullSheetsRowWithOpenSelection + ', ' + productCode);
    var tempConfig = {
        productCode: productCode,
        thickness: null,
        brand: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].manufacturer,
        material_type: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].material_type,
        decor_name: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].color_description,
        materialLength: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].material_length,
        width: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].width,
        quantity: null,
        qtyState: false,
        thicknessID: null,
    }

    fullSheetsConfig[fullSheetsRowWithOpenSelection] = tempConfig;
};

export const getFullSheetsSelectedMaterials = () => {
    return fullSheetsSelectedMaterials;
};

export const getFullSheetsConfig = () =>{
    return fullSheetsConfig;
}

// Function to push to the full sheets rows configuration array
export const addNewFullSheetsRowInConfig = () => {
    // copy the material basic information
    var tempConfig = {
        productCode: null,
        thickness: null,
        brand: null,
        material_type: null,
        decor_name: null,
        materialLength: null,
        width: null,
        quantity: null,
        qtyState: false,
        thicknessID: null,
    }

    fullSheetsConfig = [...fullSheetsConfig, tempConfig];
}

export const getFullSheetsRowWithOpenSelection = () =>{
    return fullSheetsRowWithOpenSelection;
}

// Function to return the available thicknesses for a full sheet material
export const getFullSheetAvailableThicknesses = () => {
    var availableThicknesses = [];
    const material = fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection];

    for (let i = 1; i <= 11; i++) {
        const thicknessKey = `thickness${i}`;
        if (material[thicknessKey] != null && material[thicknessKey] != '' && material[thicknessKey] != 0) {
            availableThicknesses = [...availableThicknesses, material[thicknessKey]]
        }
    }
    return availableThicknesses;
};

// Function to set the selected thickness for a row
export const selectedThicknessForFullSheetRow = (thickness) => {
    const currentConfig = fullSheetsConfig[fullSheetsRowWithOpenSelection];
    var tempConfig = {
        productCode: currentConfig.productCode,
        thickness: thickness,
        brand: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].manufacturer,
        material_type: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].material_type,
        decor_name: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].color_description,
        materialLength: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].material_length,
        width: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].width,
        quantity: null,
        qtyState: false,
        thicknessID: currentConfig.thicknessID,
    }
    fullSheetsConfig[fullSheetsRowWithOpenSelection] = tempConfig;
    console.log('New thickness for full sheet:');
    console.log(thickness);
    updateFullSheetsThicknessProps(thickness);
};


const updateFullSheetsThicknessProps = (thickness) => {
    var tempThickProps = {
        thickness : null,
        angle_cutting : null,
        cnc : null,
        cut_price : null,
        drilling : null,
        edgebanding : null,
        edgebanding1_bevel : null,
        edgebanding1_description : null,
        edgebanding1_price : null,
        edgebanding1_radius : null,
        edgebanding1_size : null,
        edgebanding1_square : null,
        edgebanding1_type : null,
        edgebanding2_bevel : null,
        edgebanding2_description : null,
        edgebanding2_price : null,
        edgebanding2_radius : null,
        edgebanding2_size : null,
        edgebanding2_square : null,
        edgebanding2_type : null,
        edgebanding3_bevel : null,
        edgebanding3_description : null,
        edgebanding3_price : null,
        edgebanding3_radius : null,
        edgebanding3_size : null,
        edgebanding3_square : null,
        edgebanding3_type : null,
        glued : null,
        hinge_drilling : null,
        sheet_price : null,
        shelf_drilling : null,
        spray_coating : null,
        stock : null,
        thicknessId: null,
    }

    const materialSelected = fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection];
    console.log(materialSelected);
    
    for(let i=1; i<=11; i++){
        // kes to get the properties out of the materialSelected array
        const thicknessKey = `thickness${i}`;
        const thickness_angle_cutting = `thickness${i}_angle_cutting`;
        const thickness_cnc = `thickness${i}_cnc`;
        const thickness_cut_price = `thickness${i}_cut_price`;
        const thickness_drilling = `thickness${i}_drilling`;
        const thickness_edgebanding = `thickness${i}_edgebanding`;
        const thickness_edgebanding1_bevel = `thickness${i}_edgebanding1_bevel`;
        const thickness_edgebanding1_description = `thickness${i}_edgebanding1_description`;
        const thickness_edgebanding1_price = `thickness${i}_edgebanding1_price`;
        const thickness_edgebanding1_radius = `thickness${i}_edgebanding1_radius`;
        const thickness_edgebanding1_size = `thickness${i}_edgebanding1_size`;
        const thickness_edgebanding1_square = `thickness${i}_edgebanding1_square`;
        const thickness_edgebanding1_type = `thickness${i}_edgebanding1_type`;
        const thickness_edgebanding2_bevel = `thickness${i}_edgebanding2_bevel`;
        const thickness_edgebanding2_description = `thickness${i}_edgebanding2_description`;
        const thickness_edgebanding2_price = `thickness${i}_edgebanding2_price`;
        const thickness_edgebanding2_radius = `thickness${i}_edgebanding2_radius`;
        const thickness_edgebanding2_size = `thickness${i}_edgebanding2_size`;
        const thickness_edgebanding2_square = `thickness${i}_edgebanding2_square`;
        const thickness_edgebanding2_type = `thickness${i}_edgebanding2_type`;
        const thickness_edgebanding3_bevel = `thickness${i}_edgebanding3_bevel`;
        const thickness_edgebanding3_description = `thickness${i}_edgebanding3_description`;
        const thickness_edgebanding3_price = `thickness${i}_edgebanding3_price`;
        const thickness_edgebanding3_radius = `thickness${i}_edgebanding3_radius`;
        const thickness_edgebanding3_size = `thickness${i}_edgebanding3_size`;
        const thickness_edgebanding3_square = `thickness${i}_edgebanding3_square`;
        const thickness_edgebanding3_type = `thickness${i}_edgebanding3_type`;
        const thickness_glued = `thickness${i}_glued`;
        const thickness_hinge_drilling = `thickness${i}_hinge_drilling`;
        const thickness_sheet_price = `thickness${i}_sheet_price`;
        const thickness_shelf_drilling = `thickness${i}_shelf_drilling`;
        const thickness_spray_coating = `thickness${i}_spray_coating`;
        const thickness_stock = `thickness${i}_stock`;

        const iterationThick = materialSelected[thicknessKey];
        if(iterationThick == thickness && materialSelected[thicknessKey] != '' && materialSelected[thicknessKey] != 0){
            tempThickProps.thickness = fullSheetsSelectedMaterials[thicknessKey];
            tempThickProps.angle_cutting = fullSheetsSelectedMaterials[thickness_angle_cutting];
            tempThickProps.cnc = fullSheetsSelectedMaterials[thickness_cnc];
            tempThickProps.cut_price = fullSheetsSelectedMaterials[thickness_cut_price];
            tempThickProps.drilling = fullSheetsSelectedMaterials[thickness_drilling];
            tempThickProps.edgebanding = fullSheetsSelectedMaterials[thickness_edgebanding];
            tempThickProps.edgebanding1_bevel = fullSheetsSelectedMaterials[thickness_edgebanding1_bevel];
            tempThickProps.edgebanding1_description = fullSheetsSelectedMaterials[thickness_edgebanding1_description];
            tempThickProps.edgebanding1_price = fullSheetsSelectedMaterials[thickness_edgebanding1_price];
            tempThickProps.edgebanding1_radius = fullSheetsSelectedMaterials[thickness_edgebanding1_radius];
            tempThickProps.edgebanding1_size = fullSheetsSelectedMaterials[thickness_edgebanding1_size];
            tempThickProps.edgebanding1_square = fullSheetsSelectedMaterials[thickness_edgebanding1_square];
            tempThickProps.edgebanding1_type = fullSheetsSelectedMaterials[thickness_edgebanding1_type];
            tempThickProps.edgebanding2_bevel = fullSheetsSelectedMaterials[thickness_edgebanding2_bevel];
            tempThickProps.edgebanding2_description = fullSheetsSelectedMaterials[thickness_edgebanding2_description];
            tempThickProps.edgebanding2_price = fullSheetsSelectedMaterials[thickness_edgebanding2_price];
            tempThickProps.edgebanding2_radius = fullSheetsSelectedMaterials[thickness_edgebanding2_radius];
            tempThickProps.edgebanding2_size = fullSheetsSelectedMaterials[thickness_edgebanding2_size];
            tempThickProps.edgebanding2_square = fullSheetsSelectedMaterials[thickness_edgebanding2_square];
            tempThickProps.edgebanding2_type = fullSheetsSelectedMaterials[thickness_edgebanding2_type];
            tempThickProps.edgebanding3_bevel = fullSheetsSelectedMaterials[thickness_edgebanding3_bevel];
            tempThickProps.edgebanding3_description = fullSheetsSelectedMaterials[thickness_edgebanding3_description];
            tempThickProps.edgebanding3_price = fullSheetsSelectedMaterials[thickness_edgebanding3_price];
            tempThickProps.edgebanding3_radius = fullSheetsSelectedMaterials[thickness_edgebanding3_radius];
            tempThickProps.edgebanding3_size = fullSheetsSelectedMaterials[thickness_edgebanding3_size];
            tempThickProps.edgebanding3_square = fullSheetsSelectedMaterials[thickness_edgebanding3_square];
            tempThickProps.edgebanding3_type = fullSheetsSelectedMaterials[thickness_edgebanding3_type];
            tempThickProps.glued = fullSheetsSelectedMaterials[thickness_glued];
            tempThickProps.hinge_drilling = fullSheetsSelectedMaterials[thickness_hinge_drilling];
            tempThickProps.sheet_price = fullSheetsSelectedMaterials[thickness_sheet_price];
            tempThickProps.shelf_drilling = fullSheetsSelectedMaterials[thickness_shelf_drilling];
            tempThickProps.spray_coating = fullSheetsSelectedMaterials[thickness_spray_coating];
            tempThickProps.stock = fullSheetsSelectedMaterials[thickness_stock];
            tempThickProps.thicknessId = parseInt(i);

            console.log('-> A gasit thicknessu bun');
        }
    }
    fullSheetsThicknessProps[fullSheetsRowWithOpenSelection] = tempThickProps;
    fullSheetsConfig[fullSheetsRowWithOpenSelection].thicknessID = tempThickProps.thicknessId;
    console.log('//// -> thickProps for the new full sheets thickness selection:');
    console.log(tempThickProps);
}

//  update the row inputs state to determine if the user can advance to the pricing page
export const changeFullSheetsInputCheck = (index, newState) =>{
    const currRowConfig = {...fullSheetsConfig[index]};
    currRowConfig.qtyState = newState;
    fullSheetsConfig[index] = currRowConfig;
};


// Function to update the full sheets row config
export const changeFullSheetsRowConfig = (index, value) => {
    fullSheetsConfig[index].quantity = value;
    return;
};

// Function to delete a row from the order
export const removeFullSheetsRow = () => {
    if (fullSheetsConfig !== -1) {
        // Use filter to create a new array without the specified element
        fullSheetsConfig = fullSheetsConfig.filter((_, index) => index !== fullSheetsRowWithOpenSelection);
    }
    if (fullSheetsThicknessProps !== -1) {
        // Use filter to create a new array without the specified element
        fullSheetsThicknessProps = fullSheetsThicknessProps.filter((_, index) => index !== fullSheetsRowWithOpenSelection);
    }
};


// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| EDGING TAPE |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
let edgingTapeConfig = [];
let availableEdgingTape = [];
let edgingTapeRowWithOpenSelection = null;

export const setEdgingTapeRowWithOpenSelection = (rowID) =>{
    edgingTapeRowWithOpenSelection = rowID;
}

export const getEdgingTapeRowWithOpenSelection = () =>{
    return edgingTapeRowWithOpenSelection;
}

export const getEdgingTapeConfig = () => {
    return edgingTapeConfig;
}

export const getAvailableEdgingTape = async() =>{
    return availableEdgingTape;
}

//  an edging tape has been selected for a edging tape list row
export const selectedEdgingTapeForRow = (edgingTape) =>{
    var tempConfig = {
        name: edgingTape.name,
        description: edgingTape.description,
        size: edgingTape.size,
        decor_name: edgingTape.decor_name,
        quantity: null,
        qtyState: false,
    }
    edgingTapeConfig[edgingTapeRowWithOpenSelection] = tempConfig;
}

// Function to update the edging tape row config
export const changeEdgingTapeRowConfig = (index, value) => {
    edgingTapeConfig[index].quantity = value;
    return;
};

//  update the edging tape row inputs state to determine if the user can advance to the pricing page
export const changeEdgingTapeInputCheck = async(index, newState) =>{
    console.log('changing input check for:' + index, newState);
    const currRowConfig = {...edgingTapeConfig[index]};
    currRowConfig.qtyState = newState;
    edgingTapeConfig[index] = currRowConfig;
};

// Function to push to the edging tape rows configuration array
export const addNewEdgingTapeRowInConfig = () => {
    // copy the material basic information
    var tempConfig = {
        name: null,
        description: null,
        size: null,
        decor_name: null,
        quantity: null,
        qtyState: false,
    }

    edgingTapeConfig = [...edgingTapeConfig, tempConfig];
}

// Function to delete a row from the order
export const removeEdgingTapesRow = () =>{
    if (edgingTapeConfig !== -1) {
        // Use filter to create a new array without the specified element
        edgingTapeConfig = edgingTapeConfig.filter((_, index) => index !== edgingTapeRowWithOpenSelection);
    }
}




// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| ORDER COSTS |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
//  request costs for the order
export const getOrderCosts = async() => {
    try {
        let panelsArray = [];
        //  create the panels array
        for(let i = 0; i < bins.length; i++){
            const bin = bins[i];
            let panelExistsInArray = panelsArray.find(panel => panel.product_code === bin.root.product_code && panel.thickness === bin.root.thickness);
            if(panelExistsInArray){
                panelExistsInArray.quantity = panelExistsInArray.quantity + 1;
            }else{
                const panelObj = {
                    product_code: bin.root.product_code,
                    thickness: bin.root.thickness,
                    thicknessId: bin.root.thicknessId,
                    decor_name: bin.root.decor_name,
                    material_type: bin.root.material_type,
                    brand: bin.root.brand,
                    sheet_size: `${bin.root.length + 10} x ${bin.root.width + 10} x ${bin.root.thickness} mm`,
                    quantity: 1
                }
                panelsArray = [...panelsArray, panelObj];
            }
            console.log(`found bin for: ${bin.root.product_code}`);
            console.log(bin.root);
        }
        console.log('Panels ready for order pricing:');
        console.log(panelsArray);                        
        
        const requestData = {
            panelsArray: panelsArray,
            rowsConfig: rowsConfig,
            fullSheetsConfig: fullSheetsConfig,
            edgetapeConfig: edgingTapeConfig
        };

        const response = await axios.post(`${APIurl}/api/pricing`, requestData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log('Pricing API request finished');
        console.log(response.data);

        const pricingData = response.data;
        if(pricingData.Status === 'Ok'){
            if(pricingData.Data === 'Pricing calculated succesfully'){
                return pricingData;
            }
        }else{
            return 'Server responded negative';
        }
    } catch (error) {
        console.error('Error fetching materials:', error);
        throw error; // Rethrow the error to handle it wherever this function is called
    }
}

export const getPanelsArray = async() =>{
    let panelsArray = [];
    //  create the panels array
    for(let i = 0; i < bins.length; i++){
        const bin = bins[i];
        let panelExistsInArray = panelsArray.find(panel => panel.product_code === bin.root.product_code && panel.thickness === bin.root.thickness);
        if(panelExistsInArray){
            panelExistsInArray.quantity = panelExistsInArray.quantity + 1;
        }else{
            const panelObj = {
                product_code: bin.root.product_code,
                thickness: bin.root.thickness,
                thicknessId: bin.root.thicknessId,
                decor_name: bin.root.decor_name,
                material_type: bin.root.material_type,
                brand: bin.root.brand,
                sheet_size: `${bin.root.length + 10} x ${bin.root.width + 10} x ${bin.root.thickness} mm`,
                quantity: 1
            }
            panelsArray = [...panelsArray, panelObj];
        }
        console.log(`found bin for: ${bin.root.product_code}`);
        console.log(bin.root);
    }
    return panelsArray;
}

export const setOrderTotalPrice = (orderTotal, orderTotalVAT) =>{
    orderTotalPrice = orderTotal;
    orderTotalPriceVAT = orderTotalVAT;
}

export const getOrderTotalPrice = () =>{
    return orderTotalPrice;
}

export const getOrderTotalPriceVAT = () =>{
    return orderTotalPriceVAT;
}
