
let ticket_data = {
    id: 0,
    email: '',
    date: '',
    message: ''
};

export const setTicketPopupData = async(data) => {
    ticket_data = {
        id: data.id,
        email: data.email,
        date: data.date,
        message: data.message
    };
}

export const getTicketPopupData = async() =>{
    return ticket_data;
}