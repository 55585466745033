import React, { useState, useEffect } from 'react'
import './myAccount.css'
import Header from '../header/header'
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from "react-js-loader";
import { FiAlertTriangle, FiCheck } from "react-icons/fi";
import SupportPopup from './supportPopup';

import { APIurl } from '../../ApiService';

function MyAccount() {
    //  initialize cookies
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState({
        email: '',
        phoneNumber: ''
    });

    const [phoneNumberInput, setPhoneNumberInput] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberUpdateMsg, setPhoneNumberUpdateMsg] = useState('');
    const [phoneNumberLoader, setPhoneNumberLoader] = useState(false);

    const [showLoaderMsg, setShowLoaderMsg] = useState(true);
    const [showServerProblem, setShowServerProblem] = useState(true);

    const [changePassMsg, setChangePassMsg] = useState('');
    const [showChangePassLoader, setShowChangePassLoader] = useState(false);


    const fetchUserData = async(token) => {
        setShowLoaderMsg(true);
        const tokenData = {
            token: token
        };
        const response = await axios.post(`${APIurl}/api/accountdetails`, tokenData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const responseStatus = response.data.status;
        const dataMessage = response.data.data;
        if(responseStatus == 'error'){  //  if server gave error
            setShowLoaderMsg(false);
            setShowServerProblem(true);
            return;
        }else{  //  if server responded ok
            if(dataMessage == 'Cant get details'){
                setShowLoaderMsg(false);
                setShowServerProblem(true);
                return;
            }else if(dataMessage == 'User details fetched'){
                console.log(response);
                const emailDetails = response.data.email;
                const phoneNumberDetails = response.data.phone;
                const newUserDetails = {...userDetails};
                newUserDetails.email = emailDetails;
                newUserDetails.phoneNumber = phoneNumberDetails;
                setUserDetails(newUserDetails);
                if (phoneNumberDetails !== undefined && phoneNumberDetails !== null) {
                    setPhoneNumberInput(phoneNumberDetails);
                } else {
                    setPhoneNumberInput(phoneNumberDetails || ''); // Ensure phoneNumberDetails is not undefined
                }
                setShowLoaderMsg(false);
                setShowServerProblem(false);
                console.log(`User details fetched: ${emailDetails}; ${phoneNumberDetails}`);
                return;
            }else if(dataMessage === 'Invalid token'){
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            // get account details
            fetchUserData(savedToken);
        }else{
            console.log('You must be logged in to access this page!');
            navigate('/login');
        }
    }, []);

    // handle the changes made inside the phone number input
    const handlePhoneNumberInputChange = (event) => {
        const inputValue = event.target.value;
        setPhoneNumberInput(inputValue);
    };

    //  handle the click on the update phone number btn
    const updatePhoneNumber = async() =>{
        setPhoneNumberUpdateMsg('');
        // validate the phone number
        const phoneRegex = /^\+?[0-9]{1,14}$/; // Allows for optional '+' sign and up to 14 digits
        if (phoneNumberInput === null || phoneNumberInput === '' || phoneNumberInput === undefined || !phoneRegex.test(phoneNumberInput)) {
            setPhoneNumberError(true);
        } else {
            setPhoneNumberLoader(true);
            setPhoneNumberError(false);
            const savedToken = cookies.get("authToken");
            if(savedToken){
                const phoneNumberData = {
                    phone: phoneNumberInput,
                    token: savedToken
                };
                const response = await axios.post(`${APIurl}/api/changePhoneNumber`, phoneNumberData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if(responseStatus == 'error'){  //  if server gave error
                    setPhoneNumberUpdateMsg('fail');
                    setPhoneNumberLoader(false);
                    return;
                }else{  //  if server responded ok
                    if(dataMessage == 'Cant get details'){
                        setPhoneNumberUpdateMsg('fail');
                        setPhoneNumberLoader(false);
                        return;
                    }else if(dataMessage == 'Phone updated'){
                        setPhoneNumberLoader(false);
                        setPhoneNumberUpdateMsg('success');
                        const newUserDetails = {...userDetails};
                        newUserDetails.phoneNumber = phoneNumberInput;
                        setUserDetails(newUserDetails);
                        return;
                    }
                }
            }
        }        
    }

    const changePasswordClicked = async() =>{
        setShowChangePassLoader(true);
        const emailData = {
            email: userDetails.email
        };
        const response = await axios.post(`${APIurl}/api/requestpasswordreset`, emailData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const responseStatus = response.data.status;
        const dataMessage = response.data.body;
        if(responseStatus == 'error'){  //  if server gave error
            setChangePassMsg('fail');
            setShowChangePassLoader(false);
            return;
        }else{  //  if server responded ok
            if(dataMessage == 'Email sent'){
                setChangePassMsg('success');
                setShowChangePassLoader(false);
                return;
            }else{
                setChangePassMsg('fail');
                setShowChangePassLoader(false);
                return;
            }
        }
    }

    //  support popup
    const [supportPopupVisible, setSupportPopupVisible] = useState(false);
    const openSupportPopup = (rowID) =>{
        setSupportPopupVisible(true);
    }
    const closeSupportPopup = () =>{
        setSupportPopupVisible(false);
    }

    const signOutClicked = async() => {
        await cookies.remove("authToken");
        await cookies.remove('email');
        await cookies.remove('phone');
        navigate('/login');
    }

    return (
        <div className='accountSection'>
            <Header />
            {supportPopupVisible === true ? (
                <SupportPopup closePopup={closeSupportPopup} />
            ) : null}
            {showLoaderMsg === true ? (
                <div className='accountContainer'>
                    <div className='accountBox'>
                        <p style={{textAlign: 'center'}}>Loading account details...</p>
                        <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                    </div>
                </div>
            ) : showServerProblem === true ? (
                <div className='accountContainer'>
                    <div className='accountBox' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '25px'}}>
                        <FiAlertTriangle style={{width: '35px', height: '35px', color: '#FF5A2E'}} />
                        <p>There was a problem trying to get your account details...</p>
                    </div>
                </div>
            ) : (
                <div className='accountContainer'>
                    <div className='accountBox'>
                        <div className='accountBoxHeader'>
                            <p>Account details</p>
                            <p>{userDetails.email}</p>
                        </div>
                        <div className='accountBoxContent'>
                            <div className='accountPhoneNumberDiv'>
                                <p>Phone number: </p>
                                <input className='accountPhoneNumberInput'
                                    value={phoneNumberInput}
                                    onChange={handlePhoneNumberInputChange}
                                />
                                {phoneNumberLoader === true ? (
                                    <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={25} />
                                ) : (
                                    <div className='accountPhoneNumberUpdateBtn' onClick={() => updatePhoneNumber()}>
                                        <p>Update</p>
                                    </div>
                                )}
                            </div>
                            {phoneNumberError === true ? (
                                <div className='accountPhoneNumberError'>
                                    <FiAlertTriangle style={{width: '25px', height: '25px', color: 'white'}} />
                                    <p>Please enter a valid phone number!</p>
                                </div>
                            ) : null}
                            {phoneNumberUpdateMsg === 'fail' ? (
                                <div className='accountPhoneNumberError'>
                                    <FiAlertTriangle style={{width: '25px', height: '25px', color: 'white'}} />
                                    <p>Failed to update your phone number...</p>
                                </div>
                            ) : phoneNumberUpdateMsg === 'success' ? (
                                <div className='accountPhoneNumberUpdateSuccess'>
                                    <FiCheck style={{width: '25px', height: '25px', color: 'white'}} />
                                    <p>Phone number has been changed!</p>
                                </div>
                            ) : null}
                            {showChangePassLoader === true ? (
                                <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={25} />
                            ) : (
                                <div className='accountContentBtn' onClick={() => changePasswordClicked()}>
                                    <p>Change password</p>
                                </div>
                            )}
                            {changePassMsg === 'fail' ? (
                                <div className='accountPhoneNumberError'>
                                    <FiAlertTriangle style={{width: '25px', height: '25px', color: 'white'}} />
                                    <p>Password reset email couldn't be sent!</p>
                                </div>
                            ) : null}
                            {changePassMsg === 'success' ? (
                                <div className='accountPhoneNumberUpdateSuccess'>
                                    <FiCheck style={{width: '25px', height: '25px', color: 'white'}} />
                                    <p>Check your email inbox for the password reset mail!</p>
                                </div>
                            ) : null}
                            <div className='accountContentBtn' onClick={() => openSupportPopup()}>
                                <p>Support</p>
                            </div>
                            <div className='accountContentBtn' onClick={() => signOutClicked()} style={{background: '#ff0030'}}>
                                <p>Sign out</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default MyAccount