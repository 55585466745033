import React, { useState, useEffect } from 'react'
import './supportPopup.css'
import { FiAlertTriangle, FiCheck } from "react-icons/fi";
import axios from 'axios';
import Loader from "react-js-loader";
import { Cookies } from 'react-cookie';

import { APIurl } from '../../ApiService';


function SupportPopup(props) {

    const cookies = new Cookies();

    const [ticketInput, setTicketInput] = useState('');
    const [successMsg, setSuccessMsg] = useState(false);
    const [failMsg, setFailMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [msgAlert, setMsgAlert] = useState('');

    const handleContainerClick = (e) => {
        // Check if the click occurred outside the container
        if (e.target.classList.contains('supportPopup')) {
            closePopup(); // Close the popup
        }
    };
    const closePopup = () =>{
        props.closePopup();
    }

    const handleTicketMsgChange = (event) => {
        const inputValue = event.target.value;
        setTicketInput(inputValue);
    };

    const submitTicket = async() =>{
        if(ticketInput === null || ticketInput === '' || ticketInput.length > 1000){
            setMsgAlert('Your message must not be empty or bigger than 1000 characters!');
        }else{
            setMsgAlert('');
            setShowLoader(true);
            setSuccessMsg(false);
            setFailMsg(false);
            const savedToken = cookies.get("authToken");
            const userEmail = cookies.get("email");
            if(savedToken){
                const ticketData = {
                    message: ticketInput,
                    token: savedToken,
                    email: userEmail,
                };
                const response = await axios.post(`${APIurl}/api/submitSupportTicket`, ticketData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if(responseStatus == 'error'){  //  if server gave error
                    setShowLoader(false);
                    setFailMsg(true);
                    return;
                }else{  //  if server responded ok
                    if(dataMessage == 'Support ticket submited'){
                        setShowLoader(false);
                        setFailMsg(false);
                        setSuccessMsg(true);
                        return;
                    }else {
                        setShowLoader(false);
                        setFailMsg(true);
                        return;
                    }
                }
            }
        }
    }

    return (
        <div className='supportPopup' onClick={handleContainerClick}>
            <div className='supportPopupContainer'>
                <p style={{fontSize: '16px', fontWeight: '500'}}>Support</p>
                <div className='supportPopupFormContainer'>
                    <p>Please describe the issue you need help with:</p>
                    <textarea className='supportPopupTextarea' onChange={handleTicketMsgChange}/>
                    {msgAlert !== '' ? (
                        <div className='supportPopupFailMsg'>
                            <FiAlertTriangle style={{minWidth: '25px', height: '25px', color: 'white'}} />
                            <p>{msgAlert}</p>
                        </div>
                    ) : null}
                    {successMsg === true ? (
                        <div className='supportPopupSuccesMsg'>
                            <FiCheck style={{minWidth: '25px', height: '25px', color: 'white'}} />
                            <p>Support ticket has been submited! We are doing our best to answer your ticket as fast as possible. We will contact you via email regarding this issue.</p>
                        </div>
                    ) : showLoader === true ? (
                        <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={25} />
                    ) : (
                        <div className='supportPopupSubmitBtn' onClick={() => submitTicket()}>
                            <p>Submit support ticket</p>
                        </div>
                    )}
                    {failMsg === true ? (
                        <div className='supportPopupFailMsg'>
                            <FiAlertTriangle style={{minWidth: '25px', height: '25px', color: 'white'}} />
                            <p>Something went wrong trying to submit your support ticket...</p>
                        </div>
                    ) : null}
                    <div className='supportPopupCloseBtn' onClick={closePopup}>
                        <p>Close</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportPopup