import React, { useState, useEffect } from 'react'
import './removeConfirmation.css'
import { getRowWithOpenSelection } from '../../orderConfig'

function RemoveConfirmation(props) {

    const [rowID, setRowID] = useState(null);

    useEffect(() => {
        const currRowID = getRowWithOpenSelection();
        setRowID(currRowID + 1);
    }, []);

    const handleContainerClick = (e) => {
        // Check if the click occurred outside the container
        if (e.target.classList.contains('removePopup')) {
            closePopup(); // Close the popup
        }
    };
    const closePopup = () =>{
        props.closeRemoveConfirmationPopup();
    }

    const removeConfirmed = () =>{
        props.removeRow();
        closePopup();
    }

    return (
        <div className='removePopup' onClick={handleContainerClick}>
            <div className='removePopupContainer'>
                <p>Remove cutting list row {rowID}?</p>
                <div className='removePopupBtns'>
                    <div className='removePopupCancel' onClick={() => closePopup()}>
                        <p>Cancel</p>
                    </div>
                    <div className='removePopupConfirm' onClick={() => removeConfirmed()}>
                        <p>Remove</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemoveConfirmation