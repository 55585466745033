import React, { useState, useEffect } from 'react'
import './cuttingListRow.css'
import { FiChevronDown, FiAlertTriangle } from "react-icons/fi";
import { MdListAlt, MdClose } from "react-icons/md";
import { changeRowWithOpenSelection, getSelectedMaterial, changeRowConfig, getThickProps, getRowConfig, changeGoodInputState, getAvailableThicknesses, selectedThicknessForRow } from './orderConfig';

const CuttingListRow = React.memo(({ rowID, row, openMaterialSelect, openEdgebandingPopup, openMachiningPopup, openSprayPopup, openSummaryPopup, openRemoveConfirmationPopup, fetchRowsConfig }) => {

    // get the material information for this row
    let material = getSelectedMaterial(rowID);

    const [rowConfig, setRowConfig] = useState(null);

    const [inputValues, setInputValues] = useState({
        qty: row.quantity,
        length: row.materialLength,
        width: row.width,
        grainMatch: row.grainMatch,
        description: row.partDescription,
    });
    const [inputAlerts, setInputAlerts] = useState({
        qty: false,
        length: null,
        width: null,
    });
    const [btnsStatus, setBtnsStatus] = useState({
        edgebanding: 'disabled',
        machining: 'disabled',
        spray: 'disabled',
        summary: 'disabled'
    });

    const [thickConfigsAvailable, setThickConfigsAvailable] = useState({
        edgebanding: false,
        machining_angble_cutting: false,
        machining_hinge_drilling: false,
        machining_shelf_drilling: false,
        spray: false,
    });

     // refresh the input values when the component gets row changes (rowsConfig changed for this row)
    useEffect(() => {
        setInputValues(() => ({
            qty: row.quantity,
            length: row.materialLength,
            width: row.width,
            grainMatch: row.grainMatch,
            description: row.partDescription
        }));

        let qtyAlert = false;
        let lengthAlert = null;
        let widthAlert = null;

        if(row.quantity != null && row.quantity != '' && row.quantity <= 0){
            qtyAlert = true;
        }
        if(row.materialLength != null && row.materialLength != ''){
            lengthAlert = inputAlerts.length;
        }
        if(row.width != null && row.width != ''){
            widthAlert = inputAlerts.width;
        }

        setInputAlerts(() => ({
            qty: qtyAlert,
            length: lengthAlert,
            width: widthAlert
        }));
        const thickProps = getThickProps(rowID);
        console.log('-> row thickness props:');
        console.log(thickProps);
        if(thickProps != null && thickProps != undefined){
            setThickConfigsAvailable(() => ({
                ...thickConfigsAvailable,
                edgebanding: thickProps.edgebanding.toLowerCase() == 'yes' ? true : false,
                machining_angble_cutting: thickProps.angle_cutting.toLowerCase() == 'yes' ? true : false,
                machining_hinge_drilling: thickProps.hinge_drilling.toLowerCase() == 'yes' ? true : false,
                machining_shelf_drilling: thickProps.shelf_drilling.toLowerCase() == 'yes' ? true : false,
                spray: thickProps.spray_coating.toLowerCase() == 'yes' ? true : false,
            }));
        }
        material = getSelectedMaterial(rowID);
        console.log('row element rendered');
        //console.log(thickProps);
        //console.log(material);

        if(row.thickness != null && thickProps.edgebanding.toLowerCase() == 'yes'){
            if(inputValues.qty != '' && inputValues.qty != null && inputAlerts.qty == false && inputValues.length != '' && inputValues.length != null && inputAlerts.length == null && inputValues.width != '' && inputValues.width != null && inputAlerts.width == null){
                setBtnsStatus((prevValues) => ({
                    ...prevValues,
                    edgebanding: 'enabled',
                }));
            }else{
                setBtnsStatus((prevValues) => ({
                    ...prevValues,
                    edgebanding: 'disabled',
                }));
            }
        }

        const newRowConfig = getRowConfig(rowID);
        setRowConfig(newRowConfig);

    }, [row]);

    // useEffect to run everytime the input values change
    useEffect(() => {
        const thickProps = getThickProps(rowID);
        material = getSelectedMaterial(rowID);

        if(row.thickness != null && inputValues.qty != '' && inputValues.qty != null && inputAlerts.qty == false && inputValues.length != '' && inputValues.length != null && inputAlerts.length == null && inputValues.width != '' && inputValues.width != null && inputAlerts.width == null){
            if(thickProps.edgebanding.toLowerCase() == 'yes'){
                setBtnsStatus((prevValues) => ({
                    ...prevValues,
                    edgebanding: 'enabled',
                }));
            }else{
                setBtnsStatus((prevValues) => ({
                    ...prevValues,
                    edgebanding: 'disabled',
                }));
            }
            
            if(thickProps.angle_cutting.toLowerCase() == 'yes' || thickProps.hinge_drilling.toLowerCase() == 'yes' || thickProps.shelf_drilling.toLowerCase() == 'yes'){
                setBtnsStatus((prevValues) => ({
                    ...prevValues,
                    machining: 'enabled',
                }));
            }else{
                setBtnsStatus((prevValues) => ({
                    ...prevValues,
                    machining: 'disabled',
                }));
            }

            if(thickProps.spray_coating.toLowerCase() == 'yes'){
                setBtnsStatus((prevValues) => ({
                    ...prevValues,
                    spray: 'enabled',
                }));
            }else{
                setBtnsStatus((prevValues) => ({
                    ...prevValues,
                    spray: 'disabled',
                }));
            }

            setBtnsStatus((prevValues) => ({
                ...prevValues,
                summary: 'enabled',
            }));

        }else{
            setBtnsStatus(() => ({
                edgebanding: 'disabled',
                machining: 'disabled',
                spray: 'disabled',
                summary: 'disabled'
            }));
        }

    }, [inputValues]);

    const minLength = 30;
    const minWidth = 30;
    let maxLength = null;
    let maxWidth = null;
    if(material != undefined){
        maxLength = parseInt(material.material_length) - 40; // the cut from margin
        maxWidth = parseInt(material.width) - 40; // the cut from margin
    }

    // handle the changes made inside the quantity input
    const handleQtyChange = (event) => {
        const inputValue = event.target.value;
        if (row.thickness != null) {
            const integerOnly = inputValue.replace(/[^0-9]/g, '');

            changeRowConfig(rowID, 'qty', integerOnly);
            setInputValues((prevValues) => ({
                ...prevValues,
                qty: integerOnly,
            }));
            setInputAlerts((prevValues) => ({
                ...prevValues,
                qty: integerOnly > 0 ? false : true,
            }));
            if(integerOnly <= 0){
                changeGoodInputState(rowID, 'qty', false);
            }else{
                changeGoodInputState(rowID, 'qty', true);
            }
            fetchRowsConfig();
        }
    };

    // hnadle the changes made inside the length input
    const handleLengthChange = (event) => {
        const inputValue = event.target.value;
        if(row.thickness != null && material != undefined){
            if(inputValue != null && inputValue != undefined){
                const integerOnly = inputValue.replace(/[^0-9]/g, '');
                // check if length is smaller than 30mm
                if(integerOnly < minLength){
                    changeRowConfig(rowID, 'length', integerOnly);
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        length: integerOnly,
                    }));
                    setInputAlerts((prevValues) => ({
                        ...prevValues,
                        length: 'Min. length size is 30mm',
                    }));
                    changeGoodInputState(rowID, 'length', false);
                }else if(integerOnly > maxLength){ // check if length is bigger than maxLength
                    changeRowConfig(rowID, 'length', integerOnly);
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        length: integerOnly,
                    }));
                    setInputAlerts((prevValues) => ({
                        ...prevValues,
                        length: 'Max. length size is ' + maxLength + 'mm',
                    }));
                    changeGoodInputState(rowID, 'length', false);
                }else{
                    changeRowConfig(rowID, 'length', integerOnly);
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        length: integerOnly,
                    }));
                    setInputAlerts((prevValues) => ({
                        ...prevValues,
                        length: null,
                    }));
                    changeGoodInputState(rowID, 'length', true);
                }
                fetchRowsConfig();
            }
        }
    };

    // handle the changes made inside the width input
    const handleWidthChange = (event) => {
        const inputValue = event.target.value;
        if(row.thickness != null && material != undefined){
            if(inputValue != null && inputValue != undefined){
                const integerOnly = inputValue.replace(/[^0-9]/g, '');
                console.log('WIDTH INPUT:' + integerOnly);
                // check if length is smaller than 30mm
                if(parseInt(integerOnly) < parseInt(minWidth)){
                    changeRowConfig(rowID, 'width', integerOnly);
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        width: integerOnly,
                    }));
                    setInputAlerts((prevValues) => ({
                        ...prevValues,
                        width: 'Min. width size is 30mm',
                    }));
                    changeGoodInputState(rowID, 'width', false);
                }else if(parseInt(integerOnly) > parseInt(maxWidth)){ // check if length is bigger than maxWidth
                    console.log('Input exceeds maxWidth:', parseInt(integerOnly), parseInt(maxWidth));
                    changeRowConfig(rowID, 'width', integerOnly);
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        width: integerOnly,
                    }));
                    setInputAlerts((prevValues) => ({
                        ...prevValues,
                        width: 'Max. width size is ' + maxWidth + 'mm',
                    }));
                    changeGoodInputState(rowID, 'width', false);
                }else{
                    console.log('WIDTH INPUT IS GOOD');
                    changeRowConfig(rowID, 'width', integerOnly);
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        width: integerOnly,
                    }));
                    setInputAlerts((prevValues) => ({
                        ...prevValues,
                        width: null,
                    }));
                    changeGoodInputState(rowID, 'width', true);
                }
                fetchRowsConfig();
            }
        }
    };

    // handle the changes made to the grain match checkbox (checked = true <-> false)
    const changeGrainMatch = () => {
        const newGrainMatch = !inputValues.grainMatch;
        changeRowConfig(rowID, 'grainMatch', newGrainMatch);
        setInputValues((prevValues) => ({
            ...prevValues,
            grainMatch: !prevValues.grainMatch,
        }));
    }

    // handle the changes made to the description input
    const handleDescriptionChange = (value) => {
        changeRowConfig(rowID, 'partDescription', value);
        setInputValues((prevValues) => ({
            ...prevValues,
            description: value,
        }));
    };

    //  Popups handlers
    const handleOpenMaterialSelect = () => {
        console.log('Opening material select for row:' + rowID);
        openMaterialSelect(rowID);
    };

    const handleOpenEdgebandingPopup = () =>{
        console.log('Opening edgebanding popup for row:' + rowID);
        openEdgebandingPopup(rowID);
    }

    const handleOpenMachiningPopup = () =>{
        console.log('Opening edgebanding popup for row:' + rowID);
        openMachiningPopup(rowID);
    }

    const handleOpenSprayPopup = () =>{
        console.log('Opening spray popup for row:' + rowID);
        openSprayPopup(rowID);
    }

    const handleOpenSummaryPopup = () =>{
        console.log('Opening spray popup for row:' + rowID);
        openSummaryPopup(rowID);
    }

    //  Thickness dropdown handlers
    const [thicknessDropdownOpen, setThicknessDropdownOpen] = useState(false);
    const [thicknessDropdownOptions, setThicknessDropdownOptions] = useState([]);
    const toggleThicknessDropdown = () =>{
        if(row?.productCode != null){
            setThicknessDropdownOpen(!thicknessDropdownOpen);
            if(!thicknessDropdownOpen == true){
                loadThicknessDropdownOptions();
            }
        }
    }
    const closeThicknessDropdown = () =>{
        setThicknessDropdownOpen(false);
    }
    const loadThicknessDropdownOptions = () =>{
        changeRowWithOpenSelection(rowID);
        const thicknesses = getAvailableThicknesses();
        setThicknessDropdownOptions(thicknesses);
    }
    const handleThicknessChange = (thickness) => {
        selectedThicknessForRow(thickness);
        closeThicknessDropdown();
        fetchRowsConfig();
    }


    return (
        <div className='cuttingListRow'>
            <div className='cuttingListRowInputs'>
                <div className='grid_item'><p>{rowID + 1}</p></div>
                <div className='grid_item'>
                    <div className='rowBtn' style={{cursor: 'pointer'}} onClick={() => handleOpenMaterialSelect()}>
                        <p>{row.productCode !== null ? `${row.productCode} - ${material.color_description}` : 'Select decor'}</p>
                    </div>
                </div>
                <div className='grid_item'>
                <div className='thicknessDropdown'>
                    <div className='thicknessDropdownTop' style={{cursor: 'pointer'}} onClick={() => toggleThicknessDropdown()}>
                            <div className='thicknessDropdownTopValue'>
                                <p>{row.thickness}</p>
                            </div>
                            <FiChevronDown />
                        </div>
                        <div className='thicknessDropdownMenu' style={{display: thicknessDropdownOpen === true ? 'flex' : 'none'}}>
                            {thicknessDropdownOptions.map((thickness, index) => (
                                <div key={index} className='thicknessDropdownMenuItem' onClick={() => handleThicknessChange(thickness)}><p>{thickness}</p></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='grid_item'>
                    <input className='inputField' value={inputValues.length} onChange={handleLengthChange} disabled={row.thickness != null ? false : true} style={{borderColor: inputAlerts.length != null ? '#fb0429' : '#ccc'}}></input>
                    <div className='rowInputAlert' style={{display: inputAlerts.length != null ? 'flex' : 'none'}}>
                        <FiAlertTriangle className='rowInputAlertIcon'/>
                        <p className='rowInputAlertText'>{inputAlerts.length}</p>
                    </div>
                </div>
                <div className='grid_item'>
                    <input className='inputField' value={inputValues.width} onChange={handleWidthChange} disabled={row.thickness != null ? false : true} style={{borderColor: inputAlerts.width != null ? '#fb0429' : '#ccc'}}></input>
                    <div className='rowInputAlert' style={{display: inputAlerts.width != null ? 'flex' : 'none'}}>
                        <FiAlertTriangle className='rowInputAlertIcon'/>
                        <p className='rowInputAlertText'>{inputAlerts.width}</p>
                    </div>
                </div>
                <div className='grid_item'>
                    <input className='inputField' value={inputValues.qty} onChange={handleQtyChange} disabled={row.thickness != null ? false : true} style={{borderColor: inputAlerts.qty === true ? '#fb0429' : '#ccc'}}></input>
                    <div className='rowInputAlert' style={{display: inputAlerts.qty === true ? 'flex' : 'none'}}>
                        <FiAlertTriangle className='rowInputAlertIcon'/>
                        <p className='rowInputAlertText'>Minimum quantity is 1</p>
                    </div>
                </div>
                <div className='grid_item'>
                    <input className='inputField' style={{borderColor: '#ccc'}}></input>
                </div>
                <div className='grid_item'>
                    <div className={
                        ((rowConfig !== null && rowConfig.edgebandingConfig !== null) && 
                            (rowConfig.edgebandingConfig.l1 === true || rowConfig.edgebandingConfig.l2 === true || rowConfig.edgebandingConfig.w1 === true || rowConfig.edgebandingConfig.w2 === true) 
                            ? 'rowBtnActive' : 'rowBtn')
                        } style={{cursor: thickConfigsAvailable.edgebanding === true ? 'pointer' : 'not-allowed'}} onClick={btnsStatus.edgebanding === 'enabled' ? handleOpenEdgebandingPopup : null}
                    >
                        <p>Edgebanding</p>
                    </div>
                </div>
                <div className='grid_item'>
                    <div className={
                        ((rowConfig !== null && rowConfig.machiningConfig !== null) && 
                            ((rowConfig.machiningConfig.L1P !== null || rowConfig.machiningConfig.L2P !== null || rowConfig.machiningConfig.W1P !== null || rowConfig.machiningConfig.W2P !== null) || 
                            rowConfig.machiningConfig.hingeHoles !== null || rowConfig.machiningConfig.clusters !== null) 
                            ? 'rowBtnActive' : 'rowBtn')
                        } style={{cursor: (thickConfigsAvailable.machining_angble_cutting === true || thickConfigsAvailable.machining_hinge_drilling === true || thickConfigsAvailable.machining_shelf_drilling === true) ? 'pointer' : 'not-allowed'}} onClick={btnsStatus.machining === 'enabled' ? handleOpenMachiningPopup : null}
                    >
                        <p>Machining</p>
                    </div>
                </div>
                <div className='grid_item'>
                    <div className={
                        (rowConfig !== null && rowConfig.sprayConfig !== null) ? 'rowBtnActive' : 'rowBtn'
                        } style={{cursor: thickConfigsAvailable.spray === true ? 'pointer' : 'not-allowed'}} onClick={btnsStatus.spray == 'enabled' ? handleOpenSprayPopup : null}
                    >
                        <p>Spray</p>
                    </div>
                </div>
                <div className='grid_item'>
                    <input type="checkbox" name="myCheckbox" className='checkbox' style={{cursor: 'pointer'}}/>
                </div>
                <div className='grid_item'>
                    <div className='actions'>
                        <MdListAlt style={{color: '#3a86ff', scale: '1.7', cursor: 'pointer'}} 
                            onClick={btnsStatus.summary == 'enabled' ? handleOpenSummaryPopup : null}
                        />
                        <MdClose style={{color: '#fb0429', scale: '1.7', cursor: 'pointer'}}
                            onClick={() => openRemoveConfirmationPopup(rowID)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
});

export default CuttingListRow