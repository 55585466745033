import React, { useState, useEffect } from 'react'
import './registerPage.css'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { FiAlertTriangle } from "react-icons/fi";
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { APIurl } from '../../ApiService';

function RegisterPage() {
    //  Initialize cookies
    const cookies = new Cookies();

    const navigate = useNavigate();

    //  inputs states
    const [registerForm, setRegisterForm] = useState({
        email: '',
        password: '',
        confirmedPassword: '',
        phoneNumber: ''
    });
    const [registerFormAlerts, setRegisterFormAlerts] = useState({
        email: null,
        password: null,
        confirmedPassword: null,
        phoneNumber: null
    });
    const [displayRegisterLoading, setDisplayRegisterLoading] = useState(false);
    const [registerServerError, setRegisterServerError] = useState(false);

    // change the inputs state
    const handleRegisterFormChanges = async(fieldID, event) =>{
        const newInput = event.target.value;
        if(fieldID == 'email'){
            // change the register form state
            let newState = {...registerForm};
            newState.email = newInput;
            setRegisterForm(newState);
        }else if(fieldID == 'phoneNumber'){
            // change the register form state
            let newState = {...registerForm};
            newState.phoneNumber = newInput;
            setRegisterForm(newState);
        }else if(fieldID == 'password'){
            // change the register form state
            let newState = {...registerForm};
            newState.password = newInput;
            setRegisterForm(newState);
        }else if(fieldID == 'confirmPassword'){
            // change the register form state
            let newState = {...registerForm};
            //console.log(newState);
            newState.confirmedPassword = newInput;
            setRegisterForm(newState);
        }
    }

    const validateRegisterForm = async() =>{
        const newState = {...registerFormAlerts};
        // validate the email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (registerForm.email == null || registerForm.email == '' || !emailRegex.test(registerForm.email)) {
            newState.email = 'Please enter a valid email address';
        }else{
            newState.email = null;
        }
        // validate the phone number
        const phoneRegex = /^\+?[0-9]{1,14}$/; // Allows for optional '+' sign and up to 14 digits
        if (registerForm.phoneNumber != null && registerForm.phoneNumber != '' && !phoneRegex.test(registerForm.phoneNumber)) {
            newState.phoneNumber = 'Please enter a valid phone number';
        }else{
            newState.phoneNumber = null;
        }
        // validate the password
        if (registerForm.password == null || registerForm.password == '' ||  registerForm.password.length < 8 || registerForm.password.length > 20) {
            newState.password = 'Password must be between 8 and 20 characters long';
        }else{
            newState.password = null;
        }
        // validate the confirmed password
        if (registerForm.confirmedPassword == null || registerForm.confirmedPassword == '' ||  registerForm.confirmedPassword != registerForm.password) {
            newState.confirmedPassword = 'Passwords do not match';
        }else{
            newState.confirmedPassword = null;
        }
        console.log(newState);
        setRegisterFormAlerts(newState);

        //  check if there are any errors
        if(newState.email == null && newState.phoneNumber == null && newState.password == null && newState.confirmedPassword == null){
            // check for the email, password and confirmed password not to be null
            if(registerForm.email != null && registerForm.email != '' && registerForm.password != null && registerForm.password != '' && registerForm.confirmedPassword != null && registerForm.confirmedPassword != ''){
                //  all good
                try{
                    setRegisterServerError(false);
                    setDisplayRegisterLoading(true);
                    const userData = {
                        email: registerForm.email.toLowerCase(),
                        password: registerForm.password,
                        phone: registerForm.phoneNumber !== '' ? registerForm.phoneNumber : null
                    };
                    const response = await axios.post(`${APIurl}/api/register`, userData, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    console.log('User registration response:', response.data);
                    console.log(response.data.status);
                    const dataMessage = response.data.data;
                    const responseStatus = response.data.status;
                    if(responseStatus == 'error'){
                        setDisplayRegisterLoading(false);
                        setRegisterServerError(true);
                        return;
                    }
                    if(dataMessage == 'User already exists!'){
                        const newState = {...registerFormAlerts};
                        newState.email = `This email is already used by an account`;
                        setRegisterFormAlerts(newState);
                        setDisplayRegisterLoading(false);
                        return;
                    }else{  //  register successfull
                        //  save the new token
                        const newToken = response.data.token;
                        cookies.set("authToken", newToken);
                        const savedToken = cookies.get("authToken");
                        if(savedToken){
                            console.log("🔐 Here's your saved token 🔐 \n" + savedToken);
                            navigate('/neworder');
                        }
                        return;
                    }
                } catch(error){
                    console.error('Error registering user:', error);
                }
            }
        }
    };

    const checkToken = async(savedToken) =>{
        const tokenData = {
            token: savedToken,
        };

        const response = await axios.post(`${APIurl}/api/checkToken`, tokenData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const responseStatus = response.data.status;
        const dataMessage = response.data.data;

        //  verify the response
        if(responseStatus == 'error'){  //  if server gave error
            return;
        }else{  //  if server responded ok
            if(dataMessage == 'Good token'){
                const userMail = response.data.email;
                const phone = response.data.phone;
                cookies.set("email", userMail);
                cookies.set("phone", phone);
                navigate('/neworder');
                return;
            }else{
                return;
            }
        }
    }

    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are trying to log in with this token 🔐 \n" + savedToken);
            checkToken(savedToken);
        }
    }, []);

    return (
        <div className='registerSection'>
            <div className='registerContainer'>
                <div className='registerContainerLogo'>
                    <img src='res\images\myBoardsLogoShadow.png' alt='Logo' className='registerLogoImg'/>
                </div>
                <div className='registerContainerContent'>
                    <div className='registerCard'>
                        <h3 style={{paddingBottom: '25px', fontWeight: '500'}}>Sign up</h3>
                        <div className='registerForm'>
                            <div className='registerFormSection'>
                                <div className='registerFormLabelBox'>
                                    <p>Email address*</p>
                                </div>
                                <div className='registerFormInputFieldBox'>
                                    {registerFormAlerts.email !== null ? (
                                        <div className='registerFormAlert'>
                                            <FiAlertTriangle />
                                            <p>{registerFormAlerts.email}</p>
                                        </div>
                                    ) : null}
                                    <input className='registerFormInputField' 
                                        value={registerForm.email}
                                        onChange={(event) => handleRegisterFormChanges('email', event)}
                                    />
                                </div>
                            </div>
                            <div className='registerFormSection'>
                                <div className='registerFormLabelBox'>
                                    <p>Password*</p>
                                </div>
                                <div className='registerFormInputFieldBox'>
                                    {registerFormAlerts.password !== null ? (
                                        <div className='registerFormAlert'>
                                            <FiAlertTriangle />
                                            <p>{registerFormAlerts.password}</p>
                                        </div>
                                    ) : null}
                                    <input className='registerFormInputField'
                                        value={registerForm.password}
                                        onChange={(event) => handleRegisterFormChanges('password', event)}
                                        type='password'
                                    />
                                </div>
                            </div>
                            <div className='registerFormSection'>
                                <div className='registerFormLabelBox'>
                                    <p>Confirm password*</p>
                                </div>
                                <div className='registerFormInputFieldBox'>
                                    {registerFormAlerts.confirmedPassword !== null ? (
                                        <div className='registerFormAlert'>
                                            <FiAlertTriangle />
                                            <p>{registerFormAlerts.confirmedPassword}</p>
                                        </div>
                                    ) : null}
                                    <input className='registerFormInputField'
                                        value={registerForm.confirmedPassword}
                                        onChange={(event) => handleRegisterFormChanges('confirmPassword', event)}
                                        type='password'
                                    />
                                </div>
                            </div>
                            <div className='registerFormSection'>
                                <div className='registerFormLabelBox'>
                                    <p>Phone number</p>
                                </div>
                                <div className='registerFormInputFieldBox'>
                                    {registerFormAlerts.phoneNumber !== null ? (
                                        <div className='registerFormAlert'>
                                            <FiAlertTriangle />
                                            <p>{registerFormAlerts.phoneNumber}</p>
                                        </div>
                                    ) : null}
                                    <input className='registerFormInputField'
                                        value={registerForm.phoneNumber}
                                        onChange={(event) => handleRegisterFormChanges('phoneNumber', event)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='registerBtnBox'>
                            <div className='registerBtn' onClick={() => validateRegisterForm()}>
                                <p>REGISTER</p>
                            </div>
                        </div>
                        <p>By clicking REGISTER you agree to our Terms and Conditions and Privacy Policy.</p>
                        <p style={{paddingTop: '5px', fontSize: '16px'}}>Already have an account? <Link to="/login" style={{textDecoration: 'none', color: 'black'}}><span className='registerToSignUpBtn'>Login</span></Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterPage