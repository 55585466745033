import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import './machiningPopup.css'
import { FiX, FiChevronDown, FiAlertTriangle, FiInfo } from "react-icons/fi";
import { getRowConfig, getRowWithOpenSelection, getEdgebandingOptions, updateMachiningConfig, getThickProps } from '../../orderConfig';
import isEqual from 'lodash/isEqual';

function MachiningPopup(props) {

    const handleContainerClick = (e) => {
        // Check if the click occurred outside the container
        if (e.target.classList.contains('machiningPopup')) {
            closeAndSaveMachiningConfig(); // Close the popup
        }
    };
    const closePopup = () =>{
        props.closeMaterialSelect();
    }

    //  selection dropdown
    const [selectionDropdownOpen, setSelectionDropdownOpen] = useState(false);
    const toggleSelectionDropdown = () =>{
        setSelectionDropdownOpen(!selectionDropdownOpen);
    }
    const closeSelectionDropdown = () =>{
        setSelectionDropdownOpen(false);
    }
    const [selectionsAvailable, setSelectionsAvailable] = useState([]);
    const [selectionValue, setSelectionValue] = useState('Angle cut');
    const [selectionConfigValue, setSelectionConfigValue] = useState('angleCut');
    const handleSelectionClicked = (brandID) =>{
        setSelectionValue(selectionsAvailable[brandID].value);
        setSelectionConfigValue(selectionsAvailable[brandID].configValue);
        if(selectionsAvailable[brandID].configValue == 'hingeDrilling'){
            calculateMaxHingeHolerPerSide();
            if(brandID == 1){
                changeHingeType('screwOn');
            }else if(brandID == 2){
                changeHingeType('inserta');
            }
        }
        if(selectionsAvailable[brandID].configValue == 'shelfDrilling'){
            if(brandID == 3){
                console.log('Changing shelf type to 5mm');
                changeShelfType('5mm');
            }else if(brandID == 4){
                console.log('Changing shelf type to 7.5mm');
                changeShelfType('7.5mm');
            }
        }
        closeSelectionDropdown();
    };

// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| ANGLE CUTTING |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const [rowConfig, setRowConfig] = useState(null);
    useEffect(() => {
        const currRow = getRowWithOpenSelection();
        const config = getRowConfig(currRow);
        setRowConfig(config);

        const tempEdgeOptions = getEdgebandingOptions();
        console.log(tempEdgeOptions);
        setEdgebandingOptions(tempEdgeOptions);
    }, []);
    const drawingPadding = 20;
    // the row machining config
    const [machiningConfig, setMachiningConfig] = useState(null);
    useEffect(() => {
        if (rowConfig) {
            if (rowConfig.machiningConfig == null) {
                const obj = {
                    topLeft: null,
                    topRight: null,
                    bottomRight: null,
                    bottomLeft: null,
                    L1P: null,
                    L2P: null,
                    W1P: null,
                    W2P: null,
                    l1w1_edgebanding: null,
                    l1w2_edgebanding: null,
                    l2w1_edgebanding: null,
                    l2w2_edgebadning: null,
                    hingeHoles: null,
                    hingeHolesPos: [],
                    hingeHolesSide: null,
                    hingeHolesPosLimits: null,
                    hingeHolesSpacing: null,
                    hingesType: null,
                    clusters: null,
                    clustersType: null,
                    clustersPos: [],
                    clustersStep: null,
                    clustersPositions: null,
                    clustersEdge: null,
                    clustersFace: null,
                    clustersDistance1: null,
                    clustersDistance2: null,
                    clustersPosLimits: null,
                    shelfDrillingInputsLimits: null
                }
                setMachiningConfig(obj);
            } else {
                setMachiningConfig(rowConfig.machiningConfig);
            }

            // get the avaiable machining options
            const currRow = getRowWithOpenSelection();
            const currRowThickProps = getThickProps(currRow);
            let  tempSelectionsAvailable = [];
            if(currRowThickProps.angle_cutting.toLowerCase() == 'yes'){
                tempSelectionsAvailable.push({id: 0, value: 'Angle cut', configValue: 'angleCut'});
                setSelectionConfigValue('angleCut');
            }
            if(currRowThickProps.hinge_drilling.toLowerCase() == 'yes'){
                tempSelectionsAvailable.push({id: 1, value: 'Concealed hinge drilling Blum 35mm Screw-On',  configValue: 'hingeDrilling'});
                tempSelectionsAvailable.push({id: 2, value: 'Concealed hinge drilling Blum 35mm INSERTA', configValue: 'hingeDrilling'});
                if(tempSelectionsAvailable.length == 0){
                    setSelectionConfigValue('hingeDrilling');
                }
            }
            if(currRowThickProps.shelf_drilling.toLowerCase() == 'yes'){
                tempSelectionsAvailable.push({id: 3, value: 'Adjustable shelf drilling 5mm Ø (8mm deep)', configValue: 'shelfDrilling'});
                tempSelectionsAvailable.push({id: 4, value: 'Adjustable shelf drilling 7.5mm Ø (8mm deep)', configValue: 'shelfDrilling'});
                if(tempSelectionsAvailable.length == 0){
                    setSelectionConfigValue('shelfDrilling');
                }
            }
            setSelectionsAvailable([...tempSelectionsAvailable]);
        }
    }, [rowConfig]);
    // drawing props are used only to store the display drawing data (scaled)
    const [drawingProps, setDrawingProps] = useState({
        drawingLength: null,
        drawingWidth: null,
        topLeft: null,
        topRight: null,
        bottomRight: null,
        bottomLeft: null,
        L1P: null,
        L2P: null,
        W1P: null,
        W2P: null,
        l1w1_edgebanding: null,
        l1w2_edgebanding: null,
        l2w1_edgebanding: null,
        l2w2_edgebadning: null,
        points: null,
        hingeHoles: [],
        clustersHoles: [],
        clusterStep: null,
        l1w1_arrows: {from_w2: null, from_l2: null},
        l1w2_arrows: {from_w1: null, from_l2: null},
        l2w1_arrows: {from_w2: null, from_l1: null},
        l2w2_arrows: {from_w1: null, from_l1: null},
    });

    const machiningPopupDrawingRef = useRef(null);
    const [viewDimensions, setViewDimensions] = useState({viewWidth: 0, viewHeight: 0});
    const [svgDimensions, setSvgDimensions] = useState({svgWidth: 0, svgHeight: 0});
    // get the middleSection width and height in px
    useLayoutEffect(() => {
        const element = machiningPopupDrawingRef.current;
        if (element) {
            // Get the size and position of the element
            const { width, height, top, left } = element.getBoundingClientRect();
            setViewDimensions(() => ({
                viewWidth: width,
                viewHeight: height,
            }));

            if(width != 0 && height != 0){
                const currRow = getRowWithOpenSelection();
                const currRowConfig = getRowConfig(currRow );
                displayInitialPanelDrawing(currRowConfig, width, height);
            }

            console.log('Middle section width:' + width);
            console.log('Middle section height:' + height);
        }
    }, []);
    // edgebanding options for angle cuts
    const [edgebandingOptions, setEdgebandingOptions] = useState(null);

    const [angleCutInputs, setAngleCutInputs] = useState({
        input1: '',
        input2: ''
    });
    const [angleCutInputErrors, setAngleCutInputErros] = useState({
        input1: null,
        input2: null
    });

    // draw the drawing again when the cut props change
    useEffect(() => {
        if(viewDimensions.viewHeight != 0 && viewDimensions.viewWidth != 0){
            displayPanelDrawing();
            updateHingeHolesPosLimits();
        }
    }, [machiningConfig]);

    // states for config section display
    const [angleCutSide, setAngleCutSide] = useState('l1w1');

    // changes the side to apply the angle cut to (l1-w1 / l1-w2 / l2-w1 / l2-w2)
    const changeAngleCutSide = (side) => {
        setAngleCutSide(side);
    };

    // on change functions for the cuts input
    const angleCutInput1Changed = (event) => {
        const inputValue = event.target.value;
        const value = inputValue.replace(/[^0-9]/g, '');
        setAngleCutInputs((prevValues) => ({
            ...prevValues,
            input1: value,
        }));
        console.log('input1 changes it`s value to:' + value);
    };
    const angleCutInput2Changed = (event) => {
        const inputValue = event.target.value;
        const value = inputValue.replace(/[^0-9]/g, '');
        setAngleCutInputs((prevValues) => ({
            ...prevValues,
            input2: value,
        }));
        console.log('input2 changes it`s value to:' + value);
    };

    // functions to display the "from x" labels on the angle cut inputs
    const displayInputFromLabel = (inputIndex) => {
        if(inputIndex == 'input1'){
            if(angleCutSide == 'l1w1' || angleCutSide == 'l2w1'){
                return 'W2';
            }else if(angleCutSide == 'l1w2' || angleCutSide == 'l2w2'){
                return 'W1';
            }
        }
        if(inputIndex == 'input2'){
            if(angleCutSide == 'l1w1' || angleCutSide == 'l1w2'){
                return 'L2';
            }else if(angleCutSide == 'l2w1' || angleCutSide == 'l2w2'){
                return 'L1';
            }
        }
    }

    // calculate the svg dimensions and display the initial render of the drawing and set up drawingProps (when the modal first opens up)
    const displayInitialPanelDrawing = (currRowConfig, width, height) =>{
        console.log('-> /// DISPLAY INITIAL DRAWING');
        console.log('-> Received row config for initial panel drawing:');
        console.log(currRowConfig);
        let inputLength = parseInt(currRowConfig.materialLength, 10);
        let inputWidth = parseInt(currRowConfig.width, 10);
        // check if there is an existing machiningConfig in the rowConfig
        if(currRowConfig.machiningConfig != null){
            const existingMachiningConfig = currRowConfig.machiningConfig;
            let drawingLength = null;
            let drawingWidth = null;
            let l1w1_arrows = null;
            let l1w2_arrows = null;
            let l2w1_arrows = null;
            let l2w2_arrows = null;
            let newSvgWidth = null;
            let newSvgHeight = null;
            let hingeScalePercentage = null;
            // calculate and set the svg dimensions and angle cut arrows
            if(inputLength >= inputWidth){
                // calculate the percentage of width out of length
                let percentage = (inputWidth / inputLength);
                newSvgWidth = width;
                if(percentage < 0.25){
                    percentage = 0.25;
                }
                newSvgHeight = width * percentage;
                if(newSvgHeight > height){
                    newSvgHeight = height;
                }
                setSvgDimensions(() => ({
                    svgWidth: newSvgWidth,
                    svgHeight: newSvgHeight,
                }));
                drawingLength = newSvgWidth - drawingPadding * 2;
                drawingWidth = newSvgHeight - drawingPadding * 2;
                // write the points string for the "from X" arrows
                l1w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                    from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                };
                l1w2_arrows = {
                    from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                    from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                };
                l2w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                };
                l2w2_arrows = {
                    from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                };
            }else{
                // calculate the percentage of length out of width
                let percentage = (inputLength / inputWidth);
                newSvgHeight = height;
                if(percentage < 0.25){
                    percentage = 0.25;
                }
                newSvgWidth = height * percentage;
                if(newSvgWidth > width){
                    newSvgWidth = width;
                }
                setSvgDimensions(() => ({
                    svgWidth: newSvgWidth,
                    svgHeight: newSvgHeight,
                }));
                drawingLength = newSvgWidth - drawingPadding * 2;
                drawingWidth = newSvgHeight - drawingPadding * 2;
                // write the points string for the "from X" arrows
                l1w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                    from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                };
                l1w2_arrows = {
                    from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                    from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                };
                l2w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                };
                l2w2_arrows = {
                    from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                };
            }
            //  check for angle cuts and transform every point from machiningConfig to drawingProps points
            //  points positions for the drawing display (scaled)
            const lengthScale = drawingLength / inputLength;
            const widthScale = drawingWidth / inputWidth;
            let topLeft = { x: drawingPadding + parseInt(existingMachiningConfig.topLeft.x) * lengthScale, y: drawingPadding};
            let topRight = { x: drawingPadding + drawingLength, y: drawingPadding + parseInt(existingMachiningConfig.topRight.y) * widthScale };
            let bottomRight = { x: drawingPadding + parseInt(existingMachiningConfig.bottomRight.x) * lengthScale, y: drawingPadding + drawingWidth};
            let bottomLeft = { x: drawingPadding, y: drawingPadding + parseInt(existingMachiningConfig.bottomLeft.y) * widthScale};
            let pointsString = '';
            let l1w1EdgebandingString = null;
            let l1w2EdgebandingString = null;
            let l2w1EdgebandingString = null;
            let l2w2EdgebandingString = null;
            let W1P = null;
            let W2P = null;
            let L1P = null;
            let L2P = null;
            // check for l1w1 cut
            if(existingMachiningConfig.W1P != null){
                const fW1P = existingMachiningConfig.W1P;
                W1P = {x: drawingPadding, y: parseInt(fW1P.y) * widthScale + drawingPadding};
                let W1P_points = `${W1P.x},${W1P.y}`;
                let TL = topLeft;
                let TL_points = `${TL.x},${TL.y}`;
                pointsString = `${W1P_points} ${TL_points}`;
                if(existingMachiningConfig.l1w1_edgebanding != null){
                    l1w1EdgebandingString = `M${W1P.x - 2.5} ${W1P.y - 2.5} L${TL.x - 2.5} ${TL.y - 2.5}`;
                    const edgebandingOption = existingMachiningConfig.l1w1_edgebanding.option;
                    console.log('Existing edgebanding option:');
                    console.log(edgebandingOption);
                    setTempEdgebandingOptions((prevValues) => ({
                        ...prevValues,
                        l1w1: edgebandingOption,
                    }));
                }
            }else{
                let TL = topLeft;
                let TL_points = `${TL.x},${TL.y}`;
                pointsString = `${TL_points}`;
            }
            // check for l1w2 cut
            if(existingMachiningConfig.L1P != null){
                const fL1P = existingMachiningConfig.L1P;
                L1P = {x: parseInt(fL1P.x) * lengthScale + drawingPadding, y: drawingPadding};
                let L1P_points = `${L1P.x},${L1P.y}`;
                let TR = topRight;
                let TR_points = `${TR.x},${TR.y}`;
                pointsString = `${pointsString} ${L1P_points} ${TR_points}`;
                if(existingMachiningConfig.l1w2_edgebanding != null){
                    l1w2EdgebandingString = `M${L1P.x + 2.5} ${L1P.y - 2.5} L${TR.x + 2.5} ${TR.y - 2.5}`;
                    const edgebandingOption = existingMachiningConfig.l1w2_edgebanding.option;
                    setTempEdgebandingOptions((prevValues) => ({
                        ...prevValues,
                        l1w2: edgebandingOption,
                    }));
                }
            }else{
                let TR = topRight;
                let TR_points = `${TR.x},${TR.y}`;
                pointsString = `${pointsString} ${pointsString} ${TR_points}`;
            }
            // check for l2w2 cut
            if(existingMachiningConfig.W2P != null){
                const fW2P = existingMachiningConfig.W2P;
                W2P = {x: topRight.x, y: parseInt(fW2P.y) * widthScale + drawingPadding};
                let W2P_points = `${W2P.x},${W2P.y}`;
                let BR = bottomRight;
                let BR_points = `${BR.x},${BR.y}`;
                pointsString = `${pointsString} ${W2P_points} ${BR_points}`;
                if(existingMachiningConfig.l2w2_edgebanding != null){
                    l2w2EdgebandingString = `M${W2P.x + 2.5} ${W2P.y + 2.5} L${BR.x + 2.5} ${BR.y + 2.5}`;
                    const edgebandingOption = existingMachiningConfig.l2w2_edgebanding.option;
                    setTempEdgebandingOptions((prevValues) => ({
                        ...prevValues,
                        l2w2: edgebandingOption,
                    }));
                }
            }else{
                let BR = bottomRight;
                let BR_points = `${BR.x},${BR.y}`;
                pointsString = `${pointsString} ${BR_points}`;
            }
            // check for l2w1 cut
            if(existingMachiningConfig.L2P != null){
                const fL2P = existingMachiningConfig.L2P;
                L2P = {x: parseInt(fL2P.x) * lengthScale + drawingPadding, y: bottomRight.y};
                let L2P_points = `${L2P.x},${L2P.y}`;
                let BL= bottomLeft;
                let BL_points = `${BL.x},${BL.y}`;
                pointsString = `${pointsString} ${L2P_points} ${BL_points}`;
                if(existingMachiningConfig.l2w1_edgebanding != null){
                    l2w1EdgebandingString = `M${L2P.x - 2.5} ${L2P.y + 2.5} L${BL.x - 2.5} ${BL.y + 2.5}`;
                    const edgebandingOption = existingMachiningConfig.l2w1_edgebanding.option;
                    setTempEdgebandingOptions((prevValues) => ({
                        ...prevValues,
                        l2w1: edgebandingOption,
                    }));
                }
            }else{
                let BL= bottomLeft;
                let BL_points = `${BL.x},${BL.y}`;
                pointsString = `${pointsString} ${BL_points}`;
            }

            //  check for hinge drilling
            let scaledHingeHolesPos = [];
            if(existingMachiningConfig.hingeHoles != null){
                console.log('-> found hinge drilling');
                setHingeType(existingMachiningConfig.hingesType);
                if(existingMachiningConfig.hingeHolesSide == 'l1'){
                    // add each hole to the drawing props (scaled)
                    const scalePercentage = lengthScale;
                    let hingeHolesPosInputs = [];
                    let hingeHolesPosInputsLimits = [];
                    let hingeHolesPosInputsErrors = [];
                    setHingeDrillingInputValue(existingMachiningConfig.hingeHoles);
                    setHingeDrillSide(existingMachiningConfig.hingeHolesSide);
                    for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                        let scaledHingeHole = {
                            x: parseInt(existingMachiningConfig.hingeHolesPos[i].x) * scalePercentage + drawingPadding,
                            y: parseInt(existingMachiningConfig.hingeHolesPos[i].y) * scalePercentage + drawingPadding,
                        } 
                        scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                        //  set the hinge holes pos inputs values based on their current position
                        hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].x];
                        hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                        //  set the hinge holes pos limits from rowConfig
                        hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                    }
                    setHingeDrillingPosInputs(hingeHolesPosInputs);
                    setHingeDrillingPosInputsErrors(hingeHolesPosInputsErrors);
                    setHingeDrillingPosLimits(hingeHolesPosInputsLimits);
                }else if(existingMachiningConfig.hingeHolesSide == 'l2'){
                    // add each hole to the drawing props (scaled)
                    const scalePercentage = lengthScale;
                    let hingeHolesPosInputs = [];
                    let hingeHolesPosInputsLimits = [];
                    let hingeHolesPosInputsErrors = [];
                    setHingeDrillingInputValue(existingMachiningConfig.hingeHoles);
                    setHingeDrillSide(existingMachiningConfig.hingeHolesSide);
                    for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                        let scaledHingeHole = {
                            x: parseInt(existingMachiningConfig.hingeHolesPos[i].x) * scalePercentage + drawingPadding,
                            y: drawingWidth + drawingPadding - (50 * scalePercentage),
                        } 
                        scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                        //  set the hinge holes pos inputs values based on their current position
                        hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].x];
                        hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                        //  set the hinge holes pos limits from rowConfig
                        hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                    }
                    setHingeDrillingPosInputs(hingeHolesPosInputs);
                    setHingeDrillingPosInputsErrors(hingeHolesPosInputsErrors);
                    setHingeDrillingPosLimits(hingeHolesPosInputsLimits);
                }else if(existingMachiningConfig.hingeHolesSide == 'w1'){
                    // add each hole to the drawing props (scaled)
                    const scalePercentage = widthScale;
                    let hingeHolesPosInputs = [];
                    let hingeHolesPosInputsLimits = [];
                    let hingeHolesPosInputsErrors = [];
                    setHingeDrillingInputValue(existingMachiningConfig.hingeHoles);
                    setHingeDrillSide(existingMachiningConfig.hingeHolesSide);
                    for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                        let scaledHingeHole = {
                            x: parseInt(existingMachiningConfig.hingeHolesPos[i].x) * scalePercentage + drawingPadding,
                            y: parseInt(existingMachiningConfig.hingeHolesPos[i].y) * scalePercentage + drawingPadding,
                        } 
                        scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                        //  set the hinge holes pos inputs values based on their current position
                        hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].y];
                        hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                        //  set the hinge holes pos limits from rowConfig
                        hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                    }
                    setHingeDrillingPosInputs(hingeHolesPosInputs);
                    setHingeDrillingPosInputsErrors(hingeHolesPosInputsErrors);
                    setHingeDrillingPosLimits(hingeHolesPosInputsLimits);
                }else if(existingMachiningConfig.hingeHolesSide == 'w2'){
                    // add each hole to the drawing props (scaled)
                    const scalePercentage = widthScale;
                    let hingeHolesPosInputs = [];
                    let hingeHolesPosInputsLimits = [];
                    let hingeHolesPosInputsErrors = [];
                    setHingeDrillingInputValue(existingMachiningConfig.hingeHoles);
                    setHingeDrillSide(existingMachiningConfig.hingeHolesSide);
                    for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                        let scaledHingeHole = {
                            x: drawingLength - 50 * scalePercentage + drawingPadding,
                            y: parseInt(existingMachiningConfig.hingeHolesPos[i].y) * scalePercentage + drawingPadding,
                        } 
                        scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                        //  set the hinge holes pos inputs values based on their current position
                        hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].y];
                        hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                        //  set the hinge holes pos limits from rowConfig
                        hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                    }
                    setHingeDrillingPosInputs(hingeHolesPosInputs);
                    setHingeDrillingPosInputsErrors(hingeHolesPosInputsErrors);
                    setHingeDrillingPosLimits(hingeHolesPosInputsLimits);
                }
            }

            //  check for adjustable shelf drilling (clusters)
            let scaledClustersPosArray = [];
            let scaledStep = null;
            if(existingMachiningConfig.clusters != null){
                // clustersHoles: [],
                // clusterStep: null,
                if(existingMachiningConfig.clustersEdge == 'l1-l2'){
                    //  calculate scaled positions for drawingProps
                    const scalePercentageX = lengthScale;
                    const scalePercentageY = widthScale;
                    const clusters_step = parseInt(existingMachiningConfig.clustersStep);
                    const clusters_distance1 = parseInt(existingMachiningConfig.clustersDistance1);
                    const clusters_distance2 = parseInt(existingMachiningConfig.clustersDistance2);
                    scaledStep = clusters_step * scalePercentageX;
                    scaledClustersPosArray = [...scaledClustersPosArray, {x: existingMachiningConfig.clustersPos[0] * scalePercentageX + drawingPadding, y1: drawingPadding + clusters_distance1 * scalePercentageY, y2: (parseInt(currRowConfig.width) - clusters_distance2) * scalePercentageY + drawingPadding}];
                    for(let i = 1; i < parseInt(existingMachiningConfig.clusters); i++){
                        const this_cluster_scaled_pos = {x: existingMachiningConfig.clustersPos[i] * scalePercentageX + drawingPadding, y1: drawingPadding + clusters_distance1 * scalePercentageY, y2: (parseInt(currRowConfig.width) - clusters_distance2) * scalePercentageY + drawingPadding};
                        scaledClustersPosArray = [...scaledClustersPosArray, this_cluster_scaled_pos];
                    }
                    //  set the shelf drilling input values
                    const savedInputValues = {
                        distance1: existingMachiningConfig.clustersDistance1,
                        distance2: existingMachiningConfig.clustersDistance2,
                        step: existingMachiningConfig.clustersStep,
                        positions: existingMachiningConfig.clustersPositions,
                        number_of_clusters: existingMachiningConfig.clusters,
                    }
                    setShelfDrillingInputs(savedInputValues);
                    // set the shelf drilling face
                    setShelfDrillingFace(existingMachiningConfig.clustersFace);
                    //  set the shelf drilling edge
                    setShelfDrillingEdge(existingMachiningConfig.clustersEdge);
                    //  set the shelf drilling inputs limits
                    setShelfDrillingInputsLimits(existingMachiningConfig.shelfDrillingInputsLimits);
                    //  set the clusters pos inputs values
                    setClustersPosInputs(existingMachiningConfig.clustersPos);
                    //  set the clusters pos limits
                    setClusterPosLimits(existingMachiningConfig.clustersPosLimits);
                    //  set the clusters pos inputs limits error as false
                    let savedClustersPosInputsErrors = []
                    for(let i = 0; i < existingMachiningConfig.clusters; i++){
                        savedClustersPosInputsErrors = [...savedClustersPosInputsErrors, false];
                    }
                    setclustersPosInputsErrors(savedClustersPosInputsErrors);
                }else if(existingMachiningConfig.clustersEdge == 'w1-w2'){
                    const scalePercentageX = lengthScale;
                    const scalePercentageY = widthScale;
                    const clusters_step = parseInt(existingMachiningConfig.clustersStep);
                    const clusters_distance1 = parseInt(existingMachiningConfig.clustersDistance1);
                    const clusters_distance2 = parseInt(existingMachiningConfig.clustersDistance2);
                    scaledStep = clusters_step * scalePercentageY;
                    scaledClustersPosArray = [...scaledClustersPosArray, {x1: drawingPadding + clusters_distance1 * scalePercentageX, y: existingMachiningConfig.clustersPos[0] * scalePercentageY + drawingPadding, x2: (parseInt(currRowConfig.materialLength) - clusters_distance2) * scalePercentageX + drawingPadding}];
                    for(let i = 1; i < parseInt(existingMachiningConfig.clusters); i++){
                        const this_cluster_scaled_pos = {x1: drawingPadding + clusters_distance1 * scalePercentageX, y: existingMachiningConfig.clustersPos[i] * scalePercentageY + drawingPadding, x2: (parseInt(currRowConfig.materialLength) - clusters_distance2) * scalePercentageX + drawingPadding};
                        scaledClustersPosArray = [...scaledClustersPosArray, this_cluster_scaled_pos];
                    }
                    //  set the shelf drilling input values
                    const savedInputValues = {
                        distance1: existingMachiningConfig.clustersDistance1,
                        distance2: existingMachiningConfig.clustersDistance2,
                        step: existingMachiningConfig.clustersStep,
                        positions: existingMachiningConfig.clustersPositions,
                        number_of_clusters: existingMachiningConfig.clusters,
                    }
                    setShelfDrillingInputs(savedInputValues);
                    // set the shelf drilling face
                    setShelfDrillingFace(existingMachiningConfig.clustersFace);
                    //  set the shelf drilling edge
                    setShelfDrillingEdge(existingMachiningConfig.clustersEdge);
                    //  set the shelf drilling inputs limits
                    setShelfDrillingInputsLimits(existingMachiningConfig.shelfDrillingInputsLimits);
                    //  set the clusters pos inputs values
                    setClustersPosInputs(existingMachiningConfig.clustersPos);
                    //  set the clusters pos limits
                    setClusterPosLimits(existingMachiningConfig.clustersPosLimits);
                    //  set the clusters pos inputs limits error as false
                    let savedClustersPosInputsErrors = []
                    for(let i = 0; i < existingMachiningConfig.clusters; i++){
                        savedClustersPosInputsErrors = [...savedClustersPosInputsErrors, false];
                    }
                    setclustersPosInputsErrors(savedClustersPosInputsErrors);
                }
            }

            setDrawingProps((prevValues) => ({
                ...prevValues,
                topLeft: topLeft,
                topRight: topRight,
                bottomRight: bottomRight,
                bottomLeft: bottomLeft,
                L1P: L1P,
                L2P: L2P,
                W1P: W1P,
                W2P: W2P,
                points: pointsString,
                drawingLength: drawingLength,
                drawingWidth: drawingWidth,
                l1w1_arrows: l1w1_arrows,
                l1w2_arrows: l1w2_arrows,
                l2w1_arrows: l2w1_arrows,
                l2w2_arrows: l2w2_arrows,
                l1w1_edgebanding: l1w1EdgebandingString,
                l1w2_edgebanding: l1w2EdgebandingString,
                l2w1_edgebanding: l2w1EdgebandingString,
                l2w2_edgebanding: l2w2EdgebandingString,
                hingeHoles: scaledHingeHolesPos,
                clustersHoles: scaledClustersPosArray,
                clusterStep: scaledStep,
            }));

            const new_drawing_props = {topLeft: topLeft,
                topRight: topRight,
                bottomRight: bottomRight,
                bottomLeft: bottomLeft,
                points: pointsString,
                drawingLength: drawingLength,
                drawingWidth: drawingWidth,
                l1w1_arrows: l1w1_arrows,
                l1w2_arrows: l1w2_arrows,
                l2w1_arrows: l2w1_arrows,
                l2w2_arrows: l2w2_arrows,
                l1w1_edgebanding: l1w1EdgebandingString,
                l1w2_edgebanding: l1w2EdgebandingString,
                l2w1_edgebanding: l2w1EdgebandingString,
                l2w2_edgebanding: l2w2EdgebandingString,
                hingeHoles: scaledHingeHolesPos,
                clustersHoles: scaledClustersPosArray,
                clusterStep: scaledStep,}
                console.log('-> new drawing props:');
                console.log(new_drawing_props);

        }else{  // if there is no existing machiningConfig in the rowConfig
            let topLeft = null;
            let topRight = null;
            let bottomRight = null;
            let bottomLeft = null;
            console.log(currRowConfig);
            console.log('currRowConfig length: ' + inputLength);
            console.log('currRowConfig width: ' + inputWidth);
            if(inputLength >= inputWidth){
                // calculate the percentage of width out of length
                let percentage = (inputWidth / inputLength);
                const newSvgWidth = viewDimensions.viewWidth;
                if(percentage < 0.25){
                    percentage = 0.25;
                }
                let newSvgHeight = viewDimensions.viewWidth * percentage;
                if(newSvgHeight > viewDimensions.viewHeight){
                    newSvgHeight = viewDimensions.viewHeight;
                }

                console.log('viewWidth: ' + viewDimensions.viewWidth);
                console.log('viewHeight: ' + viewDimensions.viewHeight);
                setSvgDimensions(() => ({
                    svgWidth: newSvgWidth,
                    svgHeight: newSvgHeight,
                }));
    
                // points positions for the full size panel
                let fTopLeft = { x: 0, y: 0 };
                let fTopRight = { x: inputLength , y: 0 };
                let fBottomRight = { x: inputLength, y: inputWidth};
                let fBottomLeft = { x: 0, y: inputWidth };

                // points positions for the drawing display (scaled)
                topLeft = { x: drawingPadding, y: drawingPadding };
                topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                
                const drawingLength = newSvgWidth - drawingPadding * 2;
                const drawingWidth = newSvgHeight - drawingPadding * 2;

                console.log('drawingLength: ' + drawingLength);
                console.log('drawingWidth: ' + drawingWidth);

                // write the points string for the "from X" arrows
                const l1w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                    from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                };
                const l1w2_arrows = {
                    from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                    from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                };
                const l2w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                };
                const l2w2_arrows = {
                    from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                };
                console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);

                const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                console.log(pointsArray);
                // Convert the points array to a string
                const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
    
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    topLeft: fTopLeft,
                    topRight: fTopRight,
                    bottomRight: fBottomRight,
                    bottomLeft: fBottomLeft,
                }));
    
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    topLeft: topLeft,
                    topRight: topRight,
                    bottomRight: bottomRight,
                    bottomLeft: bottomLeft,
                    points: pointsString,
                    drawingLength: drawingLength,
                    drawingWidth: drawingWidth,
                    l1w1_arrows: l1w1_arrows,
                    l1w2_arrows: l1w2_arrows,
                    l2w1_arrows: l2w1_arrows,
                    l2w2_arrows: l2w2_arrows,
                }));
    
                console.log(drawingProps);
    
                console.log('Svg width:' + newSvgWidth);
                console.log('Svg height' + newSvgHeight);
            }else{
                // calculate the percentage of length out of width
                let percentage = (inputLength / inputWidth);
                const newSvgHeight = viewDimensions.viewHeight;
                if(percentage < 0.25){
                    percentage = 0.25;
                }
                let newSvgWidth = viewDimensions.viewHeight * percentage;
                if(newSvgWidth > viewDimensions.viewWidth){
                    newSvgWidth = viewDimensions.viewWidth;
                }

                console.log('viewWidth: ' + viewDimensions.viewWidth);
                console.log('viewHeight: ' + viewDimensions.viewHeight);
                setSvgDimensions(() => ({
                    svgWidth: newSvgWidth,
                    svgHeight: newSvgHeight,
                }));

                // points positions for the full size panel
                let fTopLeft = { x: 0, y: 0 };
                let fTopRight = { x: inputLength , y: 0 };
                let fBottomRight = { x: inputLength, y: inputWidth};
                let fBottomLeft = { x: 0, y: inputWidth };

                // points positions for the drawing display (scaled)
                topLeft = { x: drawingPadding, y: drawingPadding };
                topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                
                const drawingLength = newSvgWidth - drawingPadding * 2;
                const drawingWidth = newSvgHeight - drawingPadding * 2;

                console.log('drawingLength: ' + drawingLength);
                console.log('drawingWidth: ' + drawingWidth);

                // write the points string for the "from X" arrows
                const l1w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                    from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                };
                const l1w2_arrows = {
                    from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                    from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                };
                const l2w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                };
                const l2w2_arrows = {
                    from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                };
                console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);

                const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                console.log(pointsArray);
                // Convert the points array to a string
                const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
    
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    topLeft: fTopLeft,
                    topRight: fTopRight,
                    bottomRight: fBottomRight,
                    bottomLeft: fBottomLeft,
                }));
    
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    topLeft: topLeft,
                    topRight: topRight,
                    bottomRight: bottomRight,
                    bottomLeft: bottomLeft,
                    points: pointsString,
                    drawingLength: drawingLength,
                    drawingWidth: drawingWidth,
                    l1w1_arrows: l1w1_arrows,
                    l1w2_arrows: l1w2_arrows,
                    l2w1_arrows: l2w1_arrows,
                    l2w2_arrows: l2w2_arrows,
                }));
    
                console.log(drawingProps);
    
                console.log('Svg width:' + newSvgWidth);
                console.log('Svg height' + newSvgHeight);
            }
        }
    };

    // function to display the drawing
    const displayPanelDrawing = () => {
        console.log('-> /// DISPLAY DRAWING');
        if(rowConfig != null && machiningConfig != null){
            console.log('displayPanelDrawing() rowConfig check passed');
            if((rowConfig.machiningConfig != null || drawingProps.points != null) && machiningConfig.topLeft != null && machiningConfig.topRight != null 
                && machiningConfig.bottomLeft != null && machiningConfig.bottomRight != null && drawingProps.topLeft != null && drawingProps.topRight != null && drawingProps.bottomLeft != null && drawingProps.bottomRight != null
            ){
                console.log('displayPanelDrawing() first if passed /////////////////////////////////////////////');
                console.log(machiningConfig);
                console.log(drawingProps);

                // get data from rowConfig.machiningConfig
                let pointsString = '';
                let l1w1EdgebandingString = null;
                let l1w2EdgebandingString = null;
                let l2w1EdgebandingString = null;
                let l2w2EdgebandingString = null;

                // check for l1w1 cut
                if(machiningConfig.W1P != null && drawingProps.W1P != null){
                    let W1P = drawingProps.W1P;
                    let W1P_points = `${W1P.x},${W1P.y}`;
                    let TL = drawingProps.topLeft;
                    let TL_points = `${TL.x},${TL.y}`;
                    pointsString = `${W1P_points} ${TL_points}`;
                    console.log(`-> W1P: ${W1P.x}; ${W1P.y}`);
                    console.log(`-> TL: ${TL.x}; ${TL.y}`);

                    if(machiningConfig.l1w1_edgebanding != null){
                        console.log(`-> W1P for edgebanding: ${W1P.x}; ${W1P.y}`);
                        console.log(`-> TL for edgebanding: ${TL.x}; ${TL.y}`);
                        l1w1EdgebandingString = `M${W1P.x - 2.5} ${W1P.y - 2.5} L${TL.x - 2.5} ${TL.y - 2.5}`;
                    }
                }else{
                    let TL = drawingProps.topLeft;
                    let TL_points = `${TL.x},${TL.y}`;
                    pointsString = `${TL_points}`;
                }
                // check for l1w2 cut
                if(machiningConfig.L1P != null && drawingProps.L1P != null){
                    let L1P = drawingProps.L1P;
                    let L1P_points = `${L1P.x},${L1P.y}`;
                    let TR = drawingProps.topRight;
                    let TR_points = `${TR.x},${TR.y}`;
                    pointsString = `${pointsString} ${L1P_points} ${TR_points}`;

                    if(machiningConfig.l1w2_edgebanding != null){
                        l1w2EdgebandingString = `M${L1P.x + 2.5} ${L1P.y - 2.5} L${TR.x + 2.5} ${TR.y - 2.5}`;
                    }
                }else{
                    let TR = drawingProps.topRight;
                    let TR_points = `${TR.x},${TR.y}`;
                    pointsString = `${pointsString} ${pointsString} ${TR_points}`;
                }
                // check for l2w2 cut
                if(machiningConfig.W2P != null && drawingProps.W2P != null){
                    let W2P = drawingProps.W2P;
                    let W2P_points = `${W2P.x},${W2P.y}`;
                    let BR = drawingProps.bottomRight;
                    let BR_points = `${BR.x},${BR.y}`;
                    pointsString = `${pointsString} ${W2P_points} ${BR_points}`;

                    if(machiningConfig.l2w2_edgebanding != null){
                        l2w2EdgebandingString = `M${W2P.x + 2.5} ${W2P.y + 2.5} L${BR.x + 2.5} ${BR.y + 2.5}`;
                    }
                }else{
                    let BR = drawingProps.bottomRight;
                    let BR_points = `${BR.x},${BR.y}`;
                    pointsString = `${pointsString} ${BR_points}`;
                }
                // check for l2w1 cut
                if(machiningConfig.L2P != null && drawingProps.L2P != null){
                    let L2P = drawingProps.L2P;
                    let L2P_points = `${L2P.x},${L2P.y}`;
                    let BL= drawingProps.bottomLeft;
                    let BL_points = `${BL.x},${BL.y}`;
                    pointsString = `${pointsString} ${L2P_points} ${BL_points}`;

                    if(machiningConfig.l2w1_edgebanding != null){
                        l2w1EdgebandingString = `M${L2P.x - 2.5} ${L2P.y + 2.5} L${BL.x - 2.5} ${BL.y + 2.5}`;
                    }
                }else{
                    let BL= drawingProps.bottomLeft;
                    let BL_points = `${BL.x},${BL.y}`;
                    pointsString = `${pointsString} ${BL_points}`;
                }
    
                console.log('New pointsString with cuts: ' + pointsString);
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    points: pointsString,
                    l1w1_edgebanding: l1w1EdgebandingString,
                    l1w2_edgebanding: l1w2EdgebandingString,
                    l2w1_edgebanding: l2w1EdgebandingString,
                    l2w2_edgebanding: l2w2EdgebandingString,
                }));
            }else if(machiningConfig.topLeft == null || machiningConfig.topRight == null || machiningConfig.bottomLeft == null || machiningConfig.bottomRight == null){
                console.log('displayPanelDrawing() second if passed //////////////////////////////////////////////');
                // create a drawing display from the inputs
                let topLeft = null;
                let topRight = null;
                let bottomRight = null;
                let bottomLeft = null;
                let inputLength = parseInt(rowConfig.materialLength, 10);
                let inputWidth = parseInt(rowConfig.width, 10);
                console.log(rowConfig);
                console.log('rowConfig length: ' + inputLength);
                console.log('rowConfig width: ' + inputWidth);
                if(inputLength >= inputWidth){
                    // calculate the percentage of width out of length
                    let percentage = (inputWidth / inputLength);
                    const newSvgWidth = viewDimensions.viewWidth;
                    if(percentage < 0.25){
                        percentage = 0.25;
                    }
                    let newSvgHeight = viewDimensions.viewWidth * percentage;
                    if(newSvgHeight > viewDimensions.viewHeight){
                        newSvgHeight = viewDimensions.viewHeight;
                    }
    
                    console.log('viewWidth: ' + viewDimensions.viewWidth);
                    console.log('viewHeight: ' + viewDimensions.viewHeight);
                    setSvgDimensions(() => ({
                        svgWidth: newSvgWidth,
                        svgHeight: newSvgHeight,
                    }));
        
                    // points positions for the full size panel
                    let fTopLeft = { x: 0, y: 0 };
                    let fTopRight = { x: inputLength , y: 0 };
                    let fBottomRight = { x: inputLength, y: inputWidth};
                    let fBottomLeft = { x: 0, y: inputWidth };
    
                    // points positions for the drawing display (scaled)
                    topLeft = { x: drawingPadding, y: drawingPadding };
                    topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                    bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                    bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                    
                    const drawingLength = newSvgWidth - drawingPadding * 2;
                    const drawingWidth = newSvgHeight - drawingPadding * 2;

                    console.log('drawingLength: ' + drawingLength);
                    console.log('drawingWidth: ' + drawingWidth);

                    // write the points string for the "from X" arrows
                    const l1w1_arrows = {
                        from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                        from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                    };
                    const l1w2_arrows = {
                        from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                        from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                    };
                    const l2w1_arrows = {
                        from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                        from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                    };
                    const l2w2_arrows = {
                        from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                        from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                    };
                    console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);

                    const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                    console.log(pointsArray);
                    // Convert the points array to a string
                    const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
        
                    setMachiningConfig((prevValues) => ({
                        ...prevValues,
                        topLeft: fTopLeft,
                        topRight: fTopRight,
                        bottomRight: fBottomRight,
                        bottomLeft: fBottomLeft,
                    }));
        
                    setDrawingProps((prevValues) => ({
                        ...prevValues,
                        topLeft: topLeft,
                        topRight: topRight,
                        bottomRight: bottomRight,
                        bottomLeft: bottomLeft,
                        points: pointsString,
                        drawingLength: drawingLength,
                        drawingWidth: drawingWidth,
                        l1w1_arrows: l1w1_arrows,
                        l1w2_arrows: l1w2_arrows,
                        l2w1_arrows: l2w1_arrows,
                        l2w2_arrows: l2w2_arrows,
                    }));
        
                    console.log(drawingProps);
        
                    console.log('Svg width:' + newSvgWidth);
                    console.log('Svg height' + newSvgHeight);
                }else{
                    // calculate the percentage of length out of width
                    let percentage = (inputLength / inputWidth);
                    const newSvgHeight = viewDimensions.viewHeight;
                    if(percentage < 0.25){
                        percentage = 0.25;
                    }
                    let newSvgWidth = viewDimensions.viewHeight * percentage;
                    if(newSvgWidth > viewDimensions.viewWidth){
                        newSvgWidth = viewDimensions.viewWidth;
                    }
    
                    console.log('viewWidth: ' + viewDimensions.viewWidth);
                    console.log('viewHeight: ' + viewDimensions.viewHeight);
                    setSvgDimensions(() => ({
                        svgWidth: newSvgWidth,
                        svgHeight: newSvgHeight,
                    }));

                    // points positions for the full size panel
                    let fTopLeft = { x: 0, y: 0 };
                    let fTopRight = { x: inputLength , y: 0 };
                    let fBottomRight = { x: inputLength, y: inputWidth};
                    let fBottomLeft = { x: 0, y: inputWidth };
    
                    // points positions for the drawing display (scaled)
                    topLeft = { x: drawingPadding, y: drawingPadding };
                    topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                    bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                    bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                    
                    const drawingLength = newSvgWidth - drawingPadding * 2;
                    const drawingWidth = newSvgHeight - drawingPadding * 2;

                    console.log('drawingLength: ' + drawingLength);
                    console.log('drawingWidth: ' + drawingWidth);

                    // write the points string for the "from X" arrows
                    const l1w1_arrows = {
                        from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                        from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                    };
                    const l1w2_arrows = {
                        from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                        from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                    };
                    const l2w1_arrows = {
                        from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                        from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                    };
                    const l2w2_arrows = {
                        from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                        from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                    };
                    console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);

                    const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                    console.log(pointsArray);
                    // Convert the points array to a string
                    const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
        
                    setMachiningConfig((prevValues) => ({
                        ...prevValues,
                        topLeft: fTopLeft,
                        topRight: fTopRight,
                        bottomRight: fBottomRight,
                        bottomLeft: fBottomLeft,
                    }));
        
                    setDrawingProps((prevValues) => ({
                        ...prevValues,
                        topLeft: topLeft,
                        topRight: topRight,
                        bottomRight: bottomRight,
                        bottomLeft: bottomLeft,
                        points: pointsString,
                        drawingLength: drawingLength,
                        drawingWidth: drawingWidth,
                        l1w1_arrows: l1w1_arrows,
                        l1w2_arrows: l1w2_arrows,
                        l2w1_arrows: l2w1_arrows,
                        l2w2_arrows: l2w2_arrows,
                    }));
        
                    console.log(drawingProps);
        
                    console.log('Svg width:' + newSvgWidth);
                    console.log('Svg height' + newSvgHeight);
                }
            
            }   
        }
    };

    // function to calculate the angle cut points and apply them
    const makeNewCut = () => {
        const input1 = angleCutInputs.input1;
        const input2 = angleCutInputs.input2;
        let fPanelLength = parseInt(rowConfig.materialLength, 10);
        let fPanelWidth = parseInt(rowConfig.width, 10);

        //check for l1-w1 side
        if(angleCutSide == 'l1w1'){

            let input1_check = 'bad';
            let input2_check = 'bad';
            let input1_error = null;
            let input2_error = null;
            // check for input1 ( TL | X - axis )
            // check if there is a l1w2 cut
            if(machiningConfig.L1P != null ){
                const L1P = machiningConfig.L1P;
                if(input1 > 0 && (fPanelLength- input1) < L1P.x && (fPanelLength- input1) > 0){
                    // good
                    input1_check = 'good';
                }else{
                    // bad
                    input1_check = 'bad';
                    input1_error = `Min: ${fPanelLength - L1P.x + 1}, Max: ${fPanelLength - 1}`;
                    console.log('input1 is bad, lower than 0 or higher than L1P');
                }
            }else{
                const topRight = machiningConfig.topRight;
                if(input1 > 0 && (fPanelLength - input1) < topRight.x && (fPanelLength- input1) > 0){
                    //good
                    input1_check = 'good';
                }else{
                    // bad
                    input1_check = 'bad';
                    input1_error = `Min: ${fPanelLength - topRight.x + 1}, Max: ${fPanelLength - 1}`;
                    console.log('input1 is bad, lower than 0 or higher than Top Right');
                }
            }
            // check for input2 ( W1P | Y - axis )
            const bottomLeft = machiningConfig.bottomLeft;
            if(input2 > 0 && (fPanelWidth - input2) < bottomLeft.y && (fPanelWidth - input2) > 0){
                //good 
                input2_check = 'good';
            }else{
                // bad
                input2_check = 'bad';
                input2_error = `Min: ${fPanelWidth - bottomLeft.y + 1}, Max: ${fPanelWidth - 1}`;
                console.log('input2 is bad, lower than 0 or higher than Bottom Left:' + bottomLeft.y);
            }
            if(input1_check == 'good' && input2_check == 'good'){
                const drawingLength = svgDimensions.svgWidth - drawingPadding * 2;
                const drawingHeight = svgDimensions.svgHeight - drawingPadding * 2;
                // calculate how much percentage is the input1 / input2 value out of full panel length / width
                const input1Percentage = (fPanelLength - input1) / fPanelLength;
                const input2Percentage = (fPanelWidth - input2) / fPanelWidth;
                // calcutate the size of the drawing cut based on the percentage and the drawing size
                const input1Scaled = drawingLength * input1Percentage;
                const input2Scaled = drawingHeight * input2Percentage;
                // change TL
                let new_TL = {x: (fPanelLength - input1), y: 0};
                // change W1P
                let new_W1P = {x: 0, y: (fPanelWidth - input2)}
                // add the cut with the real inputs to the machiningConfig
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    topLeft: new_TL,
                    W1P: new_W1P,
                    hingeHoles: null,
                    hingeHolesPos: [],
                    hingeHolesSide: null,
                    hingeHolesPosLimits: null,
                    clusters: null,
                    clustersPos: [],
                    clustersStep: null,
                    clustersPositions: null,
                    clustersEdge: null,
                    clustersFace: null,
                    clustersDistance1: null,
                    clustersDistance2: null,
                    clustersPosLimits: null,
                }));
                // add the cut to the drawingProps with the scaled inputs
                let new_TL_scaled = {x: input1Scaled + drawingPadding, y: drawingPadding};
                let new_W1P_scaled = {x: drawingPadding, y: input2Scaled + drawingPadding}
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    topLeft: new_TL_scaled,
                    W1P: new_W1P_scaled,
                    hingeHoles: [],
                    clustersHoles: [],
                    clusterStep: null,
                }));

                // reset inputs erros
                setAngleCutInputErros(() => ({
                    input1: null,
                    input2: null
                }));

                // reset clusters input
                setShelfDrillingInputs((prevValues) => ({
                    ...prevValues,
                    distance1: 50,
                    distance2: 50,
                    step: 32,
                    positions: 2,
                    number_of_clusters: 1,
                }));

                displayPanelDrawing();
            }else{
                setAngleCutInputErros(() => ({
                    input1: input1_error,
                    input2: input2_error
                }));
            }
        }else if(angleCutSide == 'l1w2'){
            let input1_check = 'bad';
            let input2_check = 'bad';
            let input1_error = null;
            let input2_error = null;

            // check for input1 ( L1P | X - axis )
            const topLeft = machiningConfig.topLeft;
            if(input1 > topLeft.x && input1 < fPanelLength){
                //good
                input1_check = 'good';
            }else{
                // bad
                input1_check = 'bad';
                input1_error = `Min: ${parseInt(topLeft.x) + 1}, Max: ${fPanelLength - 1}`;
                console.log('input1 is bad, lower than Top Left or higher than panel length');
            }
            // check for input2 ( TR | Y - axis )
            // check if there is a l2w2 cut
            if(machiningConfig.W2P != null ){
                const W2P = machiningConfig.W2P;
                if(input2 > 0 && (fPanelWidth - input2) < W2P.y && (fPanelWidth - input2) > 0){
                    // good
                    input2_check = 'good';
                }else{
                    // bad
                    input2_check = 'bad';
                    input2_error = `Min: ${fPanelWidth - W2P.y + 1}, Max: ${fPanelWidth - 1}`;
                    console.log('input2 is bad, lower than 0 or higher than W2P');
                }
            }else{
                const bottomRight = machiningConfig.bottomRight;
                if(input2 > 0 && (fPanelWidth - input2) < bottomRight.y && (fPanelWidth - input2) > 0){
                    //good
                    input2_check = 'good';
                }else{
                    // bad
                    input2_check = 'bad';
                    input2_error = `Min: ${fPanelWidth - bottomRight.y + 1}, Max: ${fPanelWidth - 1}`;
                    console.log('input2 is bad, lower than 0 or higher than Bottom Right');
                }
            }
            if(input1_check == 'good' && input2_check == 'good'){
                const drawingLength = svgDimensions.svgWidth - drawingPadding * 2;
                const drawingHeight = svgDimensions.svgHeight - drawingPadding * 2;
                // calculate how much percentage is the input1 / input2 value out of full panel length / width
                const input1Percentage = input1 / fPanelLength;
                const input2Percentage = (fPanelWidth - input2) / fPanelWidth;
                // calcutate the size of the drawing cut based on the percentage and the drawing size
                const input1Scaled = drawingLength * input1Percentage;
                const input2Scaled = drawingHeight * input2Percentage;
                // change L1P
                let new_L1P = {x: input1, y: 0};
                // change TR
                let new_TR = {x: rowConfig.materialLength, y: input2}
                // add the cut with the real inputs to the machiningConfig
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    L1P: new_L1P,
                    topRight: new_TR,
                    hingeHoles: null,
                    hingeHolesPos: [],
                    hingeHolesSide: null,
                    hingeHolesPosLimits: null,
                    clusters: null,
                    clustersPos: [],
                    clustersStep: null,
                    clustersPositions: null,
                    clustersEdge: null,
                    clustersFace: null,
                    clustersDistance1: null,
                    clustersDistance2: null,
                    clustersPosLimits: null,
                }));
                // add the cut to the drawingProps with the scaled inputs
                let new_L1p_scaled = {x: input1Scaled + drawingPadding, y: drawingPadding};
                let new_TR_scaled = {x: drawingProps.drawingLength + drawingPadding, y: input2Scaled + drawingPadding}
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    L1P: new_L1p_scaled,
                    topRight: new_TR_scaled,
                    hingeHoles: [],
                    clustersHoles: [],
                    clusterStep: null,
                }));

                // reset inputs erros
                setAngleCutInputErros(() => ({
                    input1: null,
                    input2: null
                }));

                // reset clusters input
                setShelfDrillingInputs((prevValues) => ({
                    ...prevValues,
                    distance1: 50,
                    distance2: 50,
                    step: 32,
                    positions: 2,
                    number_of_clusters: 1,
                }));

                displayPanelDrawing();
            }else{
                setAngleCutInputErros(() => ({
                    input1: input1_error,
                    input2: input2_error
                }));
            }
        }else if(angleCutSide == 'l2w1'){
            let input1_check = 'bad';
            let input2_check = 'bad';
            let input1_error = null;
            let input2_error = null;

            // check for input1 ( L2P | X - axis )
            const bottomRight = machiningConfig.bottomRight;
            if(input1 > 0 && (fPanelLength - input1) < bottomRight.x && (fPanelLength - input1) > 0){
                //good
                input1_check = 'good';
            }else{
                // bad
                input1_check = 'bad';
                input1_error = `Min: ${fPanelLength - bottomRight.x + 1}, Max: ${fPanelLength - 1}`;
                console.log('input1 is bad, lower than 0 or higher than Bottom Right');
            }
            // check for input2 ( BL | Y - axis )
            // check if there is a l1w1 cut
            if(machiningConfig.W1P != null ){
                const W1P = machiningConfig.W1P;
                if(input2 > W1P.y && input2 < fPanelWidth){
                    // good
                    input2_check = 'good';
                }else{
                    // bad
                    input2_check = 'bad';
                    input2_error = `Min: ${parseInt(W1P.y) + 1}, Max: ${fPanelWidth - 1}`;
                    console.log('input2 is bad, lower than W1P or higher than panel width');
                }
            }else{
                if(input2 > 0 && input2 < fPanelWidth){
                    //good
                    input2_check = 'good';
                }else{
                    // bad
                    input2_check = 'bad';
                    input2_error = `Min: 1, Max: ${fPanelWidth - 1}`;
                    console.log('input2 is bad, lower than 0 or higher than Bottom Left');
                }
            }
            if(input1_check == 'good' && input2_check == 'good'){
                const drawingLength = svgDimensions.svgWidth - drawingPadding * 2;
                const drawingHeight = svgDimensions.svgHeight - drawingPadding * 2;
                // calculate how much percentage is the input1 / input2 value out of full panel length / width
                const input1Percentage = (fPanelLength - input1) / fPanelLength;
                const input2Percentage = input2 / fPanelWidth;
                // calcutate the size of the drawing cut based on the percentage and the drawing size
                const input1Scaled = drawingLength * input1Percentage;
                const input2Scaled = drawingHeight * input2Percentage;
                // change L2P
                let new_L2P = {x: (fPanelLength - input1), y: rowConfig.width};
                // change BL
                let new_BL = {x: 0, y: input2}
                // add the cut with the real inputs to the machiningConfig
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    L2P: new_L2P,
                    bottomLeft: new_BL,
                    hingeHoles: null,
                    hingeHolesPos: [],
                    hingeHolesSide: null,
                    hingeHolesPosLimits: null,
                    clusters: null,
                    clustersPos: [],
                    clustersStep: null,
                    clustersPositions: null,
                    clustersEdge: null,
                    clustersFace: null,
                    clustersDistance1: null,
                    clustersDistance2: null,
                    clustersPosLimits: null,
                }));
                // add the cut to the drawingProps with the scaled inputs
                let new_L2P_scaled = {x: input1Scaled + drawingPadding, y: drawingProps.drawingWidth + drawingPadding};
                let new_BL_scaled = {x: drawingPadding, y: input2Scaled + drawingPadding}
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    L2P: new_L2P_scaled,
                    bottomLeft: new_BL_scaled,
                    hingeHoles: [],
                    clustersHoles: [],
                    clusterStep: null,
                }));

                // reset inputs erros
                setAngleCutInputErros(() => ({
                    input1: null,
                    input2: null
                }));

                // reset clusters input
                setShelfDrillingInputs((prevValues) => ({
                    ...prevValues,
                    distance1: 50,
                    distance2: 50,
                    step: 32,
                    positions: 2,
                    number_of_clusters: 1,
                }));

                displayPanelDrawing();
            }else{
                setAngleCutInputErros(() => ({
                    input1: input1_error,
                    input2: input2_error
                }));
            }
        }else if(angleCutSide == 'l2w2'){
            let input1_check = 'bad';
            let input2_check = 'bad';
            let input1_error = null;
            let input2_error = null;

            // check for input1 ( BR | X - axis )
            // check if there is a l2w1 cut
            if(machiningConfig.L2P != null ){
                const L2P = machiningConfig.L2P;
                if(input1 > L2P.x && input1 < fPanelLength){
                    // good
                    input1_check = 'good';
                }else{
                    // bad
                    input1_check = 'bad';
                    input1_error = `Min: ${parseInt(L2P.x) + 1}, Max: ${fPanelLength - 1}`;
                    console.log('input1 is bad, lower than L2P or higher than panel length');
                }
            }else{
                if(input1 > 0 && input1 < fPanelLength){
                    //good
                    input1_check = 'good';
                }else{
                    // bad
                    input1_check = 'bad';
                    input1_error = `Min: 1, Max: ${fPanelLength - 1}`;
                    console.log('input1 is bad, lower than 0 or higher than panel length');
                }
            }
            // check for input2 ( W2P | Y - axis )
            const topRight = machiningConfig.topRight;
            if(input2 > topRight.y && input2 < fPanelWidth){
                //good'
                input2_check = 'good';
            }else{
                // bad
                input2_check = 'bad';
                input2_error = `Min: ${parseInt(topRight.y) + 1}, Max: ${fPanelWidth - 1}`;
                console.log('input2 is bad, lower than Top Right or higher than panel width');
            }
            if(input1_check == 'good' && input2_check == 'good'){
                const drawingLength = svgDimensions.svgWidth - drawingPadding * 2;
                const drawingHeight = svgDimensions.svgHeight - drawingPadding * 2;
                // calculate how much percentage is the input1 / input2 value out of full panel length / width
                const input1Percentage = input1 / fPanelLength;
                const input2Percentage = input2 / fPanelWidth;
                // calcutate the size of the drawing cut based on the percentage and the drawing size
                const input1Scaled = drawingLength * input1Percentage;
                const input2Scaled = drawingHeight * input2Percentage;
                // change BR
                let new_BR = {x: input1, y: rowConfig.width};
                // change W2P
                let new_W2P = {x: rowConfig.materialLength, y: input2}
                // add the cut with the real inputs to the machiningConfig
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    W2P: new_W2P,
                    bottomRight: new_BR,
                    hingeHoles: null,
                    hingeHolesPos: [],
                    hingeHolesSide: null,
                    hingeHolesPosLimits: null,
                    clusters: null,
                    clustersPos: [],
                    clustersStep: null,
                    clustersPositions: null,
                    clustersEdge: null,
                    clustersFace: null,
                    clustersDistance1: null,
                    clustersDistance2: null,
                    clustersPosLimits: null,
                }));
                // add the cut to the drawingProps with the scaled inputs
                let new_BR_scaled = {x: input1Scaled + drawingPadding, y: drawingProps.drawingWidth + drawingPadding};
                let new_W2P_scaled = {x: drawingProps.drawingLength + drawingPadding, y: input2Scaled + drawingPadding}
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    W2P: new_W2P_scaled,
                    bottomRight: new_BR_scaled,
                    hingeHoles: [],
                    clustersHoles: [],
                    clusterStep: null,
                }));

                // reset inputs erros
                setAngleCutInputErros(() => ({
                    input1: null,
                    input2: null
                }));

                // reset clusters input
                setShelfDrillingInputs((prevValues) => ({
                    ...prevValues,
                    distance1: 50,
                    distance2: 50,
                    step: 32,
                    positions: 2,
                    number_of_clusters: 1,
                }));

                displayPanelDrawing();
            }else{
                setAngleCutInputErros(() => ({
                    input1: input1_error,
                    input2: input2_error
                }));
            }
        }
    };

    const [tempEdgebandingOptions, setTempEdgebandingOptions] = useState({
        l1w1: null,
        l1w2: null,
        l2w1: null,
        l2w2: null
    });
    // function that manages when a edgebanding option is clicked
    const edgebandingOptionClicked = (edgendingOption) => {
        if(angleCutSide == 'l1w1' && machiningConfig.W1P != null){
            if(machiningConfig.l1w1_edgebanding != null && machiningConfig.l1w1_edgebanding.option != edgendingOption){
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l1w1_edgebanding: null,
                }));
            }
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l1w1: edgendingOption,
            }));
        }else if(angleCutSide == 'l1w2' && machiningConfig.L1P != null){
            if(machiningConfig.l1w2_edgebanding != null && machiningConfig.l1w2_edgebanding.option != edgendingOption){
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l1w2_edgebanding: null,
                }));
            }
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l1w2: edgendingOption,
            }));
        }else if(angleCutSide == 'l2w1' && machiningConfig.L2P != null){
            if(machiningConfig.l2w1_edgebanding != null && machiningConfig.l2w1_edgebanding.option != edgendingOption){
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l2w1_edgebanding: null,
                }));
            }
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l2w1: edgendingOption,
            }));
        }else if(angleCutSide == 'l2w2' && machiningConfig.W2P != null){
            if(machiningConfig.l2w2_edgebanding != null && machiningConfig.l2w2_edgebanding.option != edgendingOption){
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l2w2_edgebanding: null,
                }));
            }
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l2w2: edgendingOption,
            }));
        }
    };

    // function that manages when a edgebanding option finish is clicked
    const edgebandingFinishClicked = (finish) => {
        if(angleCutSide == 'l1w1' && machiningConfig.W1P != null && tempEdgebandingOptions.l1w1 != null){
            if(finish == 'radius' && tempEdgebandingOptions.l1w1.edgebanding_radius.toLowerCase() == 'yes'){
                let l1w1_edgebanding = {
                    option: tempEdgebandingOptions.l1w1,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l1w1_edgebanding: l1w1_edgebanding,
                }));
            }else if(finish == 'bevel' && tempEdgebandingOptions.l1w1.edgebanding_bevel.toLowerCase() == 'yes'){
                let l1w1_edgebanding = {
                    option: tempEdgebandingOptions.l1w1,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l1w1_edgebanding: l1w1_edgebanding,
                }));
            }else if(finish == 'square' && tempEdgebandingOptions.l1w1.edgebanding_square.toLowerCase() == 'yes'){
                let l1w1_edgebanding = {
                    option: tempEdgebandingOptions.l1w1,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l1w1_edgebanding: l1w1_edgebanding,
                }));
            }
            console.log('Edgebanding finish for l1w1 set!');
        }else if(angleCutSide == 'l1w2' && machiningConfig.L1P != null && tempEdgebandingOptions.l1w2 != null){
            if(finish == 'radius' && tempEdgebandingOptions.l1w2.edgebanding_radius.toLowerCase() == 'yes'){
                let l1w2_edgebanding = {
                    option: tempEdgebandingOptions.l1w2,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l1w2_edgebanding: l1w2_edgebanding,
                }));
            }else if(finish == 'bevel' && tempEdgebandingOptions.l1w2.edgebanding_bevel.toLowerCase() == 'yes'){
                let l1w2_edgebanding = {
                    option: tempEdgebandingOptions.l1w2,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l1w2_edgebanding: l1w2_edgebanding,
                }));
            }else if(finish == 'square' && tempEdgebandingOptions.l1w2.edgebanding_square.toLowerCase() == 'yes'){
                let l1w2_edgebanding = {
                    option: tempEdgebandingOptions.l1w2,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l1w2_edgebanding: l1w2_edgebanding,
                }));
            }
            console.log('Edgebanding finish for l1w2 set!');
        }else if(angleCutSide == 'l2w1' && machiningConfig.L2P != null && tempEdgebandingOptions.l2w1 != null){
            if(finish == 'radius' && tempEdgebandingOptions.l2w1.edgebanding_radius.toLowerCase() == 'yes'){
                let l2w1_edgebanding = {
                    option: tempEdgebandingOptions.l2w1,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l2w1_edgebanding: l2w1_edgebanding,
                }));
            }else if(finish == 'bevel' && tempEdgebandingOptions.l2w1.edgebanding_bevel.toLowerCase() == 'yes'){
                let l2w1_edgebanding = {
                    option: tempEdgebandingOptions.l2w1,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l2w1_edgebanding: l2w1_edgebanding,
                }));
            }else if(finish == 'square' && tempEdgebandingOptions.l2w1.edgebanding_square.toLowerCase() == 'yes'){
                let l2w1_edgebanding = {
                    option: tempEdgebandingOptions.l2w1,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l2w1_edgebanding: l2w1_edgebanding,
                }));
            }
        }else if(angleCutSide == 'l2w2' && machiningConfig.W2P != null && tempEdgebandingOptions.l2w2 != null){
            if(finish == 'radius' && tempEdgebandingOptions.l2w2.edgebanding_radius.toLowerCase() == 'yes'){
                let l2w2_edgebanding = {
                    option: tempEdgebandingOptions.l2w2,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l2w2_edgebanding: l2w2_edgebanding,
                }));
            }else if(finish == 'bevel' && tempEdgebandingOptions.l2w2.edgebanding_bevel.toLowerCase() == 'yes'){
                let l2w2_edgebanding = {
                    option: tempEdgebandingOptions.l2w2,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l2w2_edgebanding: l2w2_edgebanding,
                }));
            }else if(finish == 'square' && tempEdgebandingOptions.l2w2.edgebanding_square.toLowerCase() == 'yes'){
                let l2w2_edgebanding = {
                    option: tempEdgebandingOptions.l2w2,
                    finish: finish
                };
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    l2w2_edgebanding: l2w2_edgebanding,
                }));
            }
        }
    };

    // function to remove angle cut (also removes angle cut edgebanding)
    const removeAngleCut = () => {
        if(angleCutSide == 'l1w1'){
            let topLeft = { x: 0, y: 0 };
            let topLeftScaled = { x: drawingPadding, y: drawingPadding };
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                topLeft: topLeft,
                W1P: null,
                l1w1_edgebanding: null,
            }));
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l1w1: null,
            }));
            setDrawingProps((prevValues) => ({
                ...prevValues,
                topLeft: topLeftScaled,
                W1P: null,
            }));
        }else if(angleCutSide == 'l1w2'){
            let topRight = { x: parseInt(rowConfig.materialLength) , y: 0 };
            let topRightScaled = { x: parseFloat(svgDimensions.svgWidth) - drawingPadding, y: drawingPadding };
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                topRight: topRight,
                L1P: null,
                l1w2_edgebanding: null,
            }));
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l1w2: null,
            }));
            setDrawingProps((prevValues) => ({
                ...prevValues,
                topRight: topRightScaled,
                L1P: null,
            }));
        }else if(angleCutSide == 'l2w1'){
            let bottomLeft = { x: 0, y: parseInt(rowConfig.width) };
            let bottomLeftScaled = { x: drawingPadding, y: parseFloat(svgDimensions.svgHeight) - drawingPadding };
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                bottomLeft: bottomLeft,
                L2P: null,
                l2w1_edgebanding: null,
            }));
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l2w1: null,
            }));
            setDrawingProps((prevValues) => ({
                ...prevValues,
                bottomLeft: bottomLeftScaled,
                L2P: null,
            }));
        }else if(angleCutSide == 'l2w2'){
            let bottomRight = { x: rowConfig.materialLength, y: rowConfig.width};
            let bottomRightScaled = { x: parseFloat(svgDimensions.svgWidth) - drawingPadding, y: parseFloat(svgDimensions.svgHeight)  - drawingPadding};
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                bottomRight: bottomRight,
                W2P: null,
                l2w2_edgebanding: null,
            }));
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l2w2: null,
            }));
            setDrawingProps((prevValues) => ({
                ...prevValues,
                bottomRight: bottomRightScaled,
                W2P: null,
            }));
        }
    };

    // function to remove angle cut edgebanding
    const removeAngleCutEdgebanding = () => {
        if(angleCutSide == 'l1w1'){
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                l1w1_edgebanding: null,
            }));
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l1w1: null,
            }));
        }else if(angleCutSide == 'l1w2'){
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                l1w2_edgebanding: null,
            }));
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l1w2: null,
            }));
        }else if(angleCutSide == 'l2w1'){
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                l2w1_edgebanding: null,
            }));
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l2w1: null,
            }));
        }else if(angleCutSide == 'l2w2'){
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                l2w2_edgebanding: null,
            }));
            setTempEdgebandingOptions((prevValues) => ({
                ...prevValues,
                l2w2: null,
            }));
        }
    }


// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| HINGE DRILLING |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const [hingeType, setHingeType] = useState('screwOn');
    const [hingeDrillSide, setHingeDrillSide] = useState('l1');
    const [maxHingeHolesPerSide, setMaxHingeHolesPerSide] = useState({
        l1: 0,
        l2: 0,
        w1: 0,
        w2: 0
    });
    const [hingeDrillingInputValue, setHingeDrillingInputValue] = useState('');
    const [hingeDrillingError, setHingeDrillingError] = useState(null);
    const [hingeDrillingPosLimits, setHingeDrillingPosLimits] = useState(null);
    const [hingeDrillingPosInputs, setHingeDrillingPosInputs] = useState([]);
    const [hingeDrillingPosInputsErrors, setHingeDrillingPosInputsErrors] = useState([]);

    //function to change the selected hinge drilling type
    const changeHingeType = (type) => {
        setHingeType(type);
    };
    //function to change the selected hinge drilling type
    const changeHingeDrillSide = (side) => {
        setHingeDrillSide(side);
    };
    // on hinge drilling nr of holes input change
    const hingeDrillingInputChange = (event) => {
        const inputValue = event.target.value;
        const value = inputValue.replace(/[^0-9]/g, '');
        setHingeDrillingInputValue(value);
        console.log('hinge drill input changed it`s value to:' + value);
    };

    // function to calculate the max hinge holes per side
    const calculateMaxHingeHolerPerSide = () => {
        const distance_from_edge = 50;
        const distance_between_holes = 100;
        let maxHolesL1 = null;
        let maxHolesL2 = null;
        let maxHolesW1 = null;
        let maxHolesW2 = null;

        // calculate for l1 side (from TL to L1P/TR)
        if(machiningConfig.L1P != null){
            const length_available = parseInt(machiningConfig.L1P.x) - parseInt(machiningConfig.topLeft.x);
            const maxHolesNr = Math.floor((length_available - distance_from_edge*2) / distance_between_holes);
            maxHolesL1 = maxHolesNr;
            console.log('max holes on l1: ' + maxHolesNr);
        }else{
            const length_available = parseInt(machiningConfig.topRight.x) - parseInt(machiningConfig.topLeft.x);
            const maxHolesNr = Math.floor((length_available - distance_from_edge*2) / distance_between_holes);
            maxHolesL1 = maxHolesNr;
            console.log('max holes on l1: ' + maxHolesNr);
        }
        // calculate for l2 side (from BL/L2P to BR)
        if(machiningConfig.L2P != null){
            const length_available = parseInt(machiningConfig.bottomRight.x) - parseInt(machiningConfig.L2P.x);
            const maxHolesNr = Math.floor((length_available - distance_from_edge*2) / distance_between_holes);
            maxHolesL2 = maxHolesNr;
            console.log('max holes on l2: ' + maxHolesNr);
        }else{
            const length_available = parseInt(machiningConfig.bottomRight.x) - parseInt(machiningConfig.bottomLeft.x);
            const maxHolesNr = Math.floor((length_available - distance_from_edge*2) / distance_between_holes);
            maxHolesL2 = maxHolesNr;
            console.log('max holes on l2: ' + maxHolesNr);
        }
        // calculate for W1 side (from TL/W1P to BL)
        if(machiningConfig.W1P != null){
            const length_available = parseInt(machiningConfig.bottomLeft.y) - parseInt(machiningConfig.W1P.y);
            const maxHolesNr = Math.floor((length_available - distance_from_edge*2) / distance_between_holes);
            maxHolesW1 = maxHolesNr;
            console.log('max holes on w1: ' + maxHolesNr);
        }else{
            const length_available = parseInt(machiningConfig.bottomLeft.y) - parseInt(machiningConfig.topLeft.y);
            const maxHolesNr = Math.floor((length_available - distance_from_edge*2) / distance_between_holes);
            maxHolesW1 = maxHolesNr;
            console.log('max holes on w1: ' + maxHolesNr);
        }
        // calculate for W2 side (from TR to W2P/BR)
        if(machiningConfig.W2P != null){
            const length_available = parseInt(machiningConfig.W2P.y) - parseInt(machiningConfig.topRight.y);
            const maxHolesNr = Math.floor((length_available - distance_from_edge*2) / distance_between_holes);
            maxHolesW2 = maxHolesNr;
            console.log('max holes on w2: ' + maxHolesNr);
        }else{
            const length_available = parseInt(machiningConfig.bottomRight.y) - parseInt(machiningConfig.topRight.y);
            const maxHolesNr = Math.floor((length_available - distance_from_edge*2) / distance_between_holes);
            maxHolesW2 = maxHolesNr;
            console.log('max holes on w2: ' + maxHolesNr);
        }

        setMaxHingeHolesPerSide((prevValues) => ({
            ...prevValues,
            l1: maxHolesL1,
            l2: maxHolesL2,
            w1: maxHolesW1,
            w2: maxHolesW2,
        }));
    };

    // function to apply the holes in the machiningConfig
    const applyHingeHoles = () => {
        const distanceFromEdge = 50;
        const minDistanceBetweenHoles = 100;
        const maxDistanceFromEdge = 300;
        let hingeInputCheck = checkHingeInput();
        if(hingeInputCheck == 'good'){
            setHingeDrillingError(null);
            if(hingeDrillSide == 'l1'){
                let holeSpacing = null;
                const startingPosX = parseInt(machiningConfig.topLeft.x) + distanceFromEdge;
                // check if L1P exists
                if(machiningConfig.L1P != null){
                    holeSpacing = Math.floor((parseInt(machiningConfig.L1P.x) - parseInt(machiningConfig.topLeft.x) - distanceFromEdge*2) / (hingeDrillingInputValue - 1));
                }else{
                    holeSpacing = Math.floor((parseInt(machiningConfig.topRight.x) - parseInt(machiningConfig.topLeft.x) - distanceFromEdge*2) / (hingeDrillingInputValue - 1));
                }
                console.log('hole spacing: ' + holeSpacing);

                // add each hole to machining config
                let hingeHolesPos = [];
                let hingeHolesPosInputsInitalValues = [];
                let hingeHolesPosInputsInitalErrors = [];
                for(let i = 0; i < hingeDrillingInputValue; i++){
                    const holePos = {x: startingPosX + i*holeSpacing, y: distanceFromEdge};
                    hingeHolesPos = [...hingeHolesPos, holePos];

                    const holePosInputInitialValues = startingPosX + i*holeSpacing;
                    hingeHolesPosInputsInitalValues = [...hingeHolesPosInputsInitalValues, holePosInputInitialValues];

                    const holePosInputInitialErros = false;
                    hingeHolesPosInputsInitalErrors = [...hingeHolesPosInputsInitalErrors, holePosInputInitialErros];
                }
                console.log(hingeHolesPos);
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    hingeHoles: hingeDrillingInputValue,
                    hingeHolesPos: hingeHolesPos,
                    hingeHolesSide: 'l1',
                    hingeHolesSpacing: holeSpacing,
                    hingesType: hingeType,
                }));

                // add each hole to the drawing props (scaled)
                const scalePercentage = drawingProps.drawingLength / rowConfig.materialLength;
                console.log(`drawing length: ${drawingProps.drawingLength}; materrial length: ${rowConfig.materialLength}; scale percentage: ${scalePercentage}`);
                let scaledHingeHolesPos = [];
                for(let i = 0; i < hingeDrillingInputValue; i++){
                    const holePos = {x: ((startingPosX + i*holeSpacing) * scalePercentage + drawingPadding), y: (drawingPadding + (distanceFromEdge * scalePercentage))};
                    scaledHingeHolesPos = [...scaledHingeHolesPos, holePos];
                }
                console.log(scaledHingeHolesPos);
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    hingeHoles: scaledHingeHolesPos,
                }));

                setHingeDrillingPosInputs(hingeHolesPosInputsInitalValues);
                setHingeDrillingPosInputsErrors(hingeHolesPosInputsInitalErrors);
            }else if(hingeDrillSide == 'l2'){
                let holeSpacing = null;
                let startingPosX = parseInt(machiningConfig.bottomLeft.x) + distanceFromEdge;
                // check if L2P exists
                if(machiningConfig.L2P != null){
                    holeSpacing = Math.floor((parseInt(machiningConfig.bottomRight.x) - parseInt(machiningConfig.L2P.x) - distanceFromEdge*2) / (hingeDrillingInputValue - 1));
                    startingPosX = parseInt(machiningConfig.L2P.x) + distanceFromEdge;
                }else{
                    holeSpacing = Math.floor((parseInt(machiningConfig.bottomRight.x) - parseInt(machiningConfig.bottomLeft.x) - distanceFromEdge*2) / (hingeDrillingInputValue - 1));
                    startingPosX = parseInt(machiningConfig.bottomLeft.x) + distanceFromEdge;
                }
                console.log('hole spacing: ' + holeSpacing);

                // add each hole to machining config
                let hingeHolesPos = [];
                let hingeHolesPosInputsInitalValues = [];
                let hingeHolesPosInputsInitalErrors = [];
                for(let i = 0; i < hingeDrillingInputValue; i++){
                    const holePos = {x: startingPosX + i*holeSpacing, y: parseInt(rowConfig.width) - distanceFromEdge};
                    hingeHolesPos = [...hingeHolesPos, holePos];

                    const holePosInputInitialValues = startingPosX + i*holeSpacing;
                    hingeHolesPosInputsInitalValues = [...hingeHolesPosInputsInitalValues, holePosInputInitialValues];

                    const holePosInputInitialErros = false;
                    hingeHolesPosInputsInitalErrors = [...hingeHolesPosInputsInitalErrors, holePosInputInitialErros];
                }

                console.log(hingeHolesPos);
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    hingeHoles: hingeDrillingInputValue,
                    hingeHolesPos: hingeHolesPos,
                    hingeHolesSide: 'l2',
                    hingeHolesSpacing: holeSpacing,
                    hingesType: hingeType,
                }));

                // add each hole to the drawing props (scaled)
                const scalePercentage = drawingProps.drawingLength / rowConfig.materialLength;
                console.log(`drawing length: ${drawingProps.drawingLength}; materrial length: ${rowConfig.materialLength}; scale percentage: ${scalePercentage}`);
                let scaledHingeHolesPos = [];
                for(let i = 0; i < hingeDrillingInputValue; i++){
                    const holePos = {x: ((startingPosX + i*holeSpacing) * scalePercentage + drawingPadding), y: (drawingProps.drawingWidth + drawingPadding - (distanceFromEdge * scalePercentage))};
                    scaledHingeHolesPos = [...scaledHingeHolesPos, holePos];
                }
                console.log(scaledHingeHolesPos);
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    hingeHoles: scaledHingeHolesPos,
                }));

                setHingeDrillingPosInputs(hingeHolesPosInputsInitalValues);
                setHingeDrillingPosInputsErrors(hingeHolesPosInputsInitalErrors);
            }else if(hingeDrillSide == 'w1'){
                let holeSpacing = null;
                let startingPosY = parseInt(machiningConfig.topLeft.y) + distanceFromEdge;
                // check if W1P exists
                if(machiningConfig.W1P != null){
                    holeSpacing = Math.floor((parseInt(machiningConfig.bottomLeft.y) - parseInt(machiningConfig.W1P.y) - distanceFromEdge*2) / (hingeDrillingInputValue - 1));
                    startingPosY = parseInt(machiningConfig.W1P.y) + distanceFromEdge;
                }else{
                    holeSpacing = Math.floor((parseInt(machiningConfig.bottomLeft.y) - parseInt(machiningConfig.topLeft.y) - distanceFromEdge*2) / (hingeDrillingInputValue - 1));
                    startingPosY = parseInt(machiningConfig.topLeft.y) + distanceFromEdge;
                }
                console.log('hole spacing: ' + holeSpacing);

                // add each hole to machining config
                let hingeHolesPos = [];
                let hingeHolesPosInputsInitalValues = [];
                let hingeHolesPosInputsInitalErrors = [];
                for(let i = 0; i < hingeDrillingInputValue; i++){
                    const holePos = {x: distanceFromEdge, y: startingPosY + i*holeSpacing};
                    hingeHolesPos = [...hingeHolesPos, holePos];

                    const holePosInputInitialValues = startingPosY + i*holeSpacing;
                    hingeHolesPosInputsInitalValues = [...hingeHolesPosInputsInitalValues, holePosInputInitialValues];

                    const holePosInputInitialErros = false;
                    hingeHolesPosInputsInitalErrors = [...hingeHolesPosInputsInitalErrors, holePosInputInitialErros];
                }
                console.log(hingeHolesPos);
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    hingeHoles: hingeDrillingInputValue,
                    hingeHolesPos: hingeHolesPos,
                    hingeHolesSide: 'w1',
                    hingeHolesSpacing: holeSpacing,
                    hingesType: hingeType,
                }));

                // add each hole to the drawing props (scaled)
                const scalePercentage = drawingProps.drawingWidth / rowConfig.width;
                console.log(`drawing width: ${drawingProps.drawingWidth}; materrial width: ${rowConfig.width}; scale percentage: ${scalePercentage}`);
                let scaledHingeHolesPos = [];
                for(let i = 0; i < hingeDrillingInputValue; i++){
                    const holePos = {x: (distanceFromEdge * scalePercentage + drawingPadding), y: ((startingPosY + i*holeSpacing) * scalePercentage + drawingPadding)};
                    scaledHingeHolesPos = [...scaledHingeHolesPos, holePos];
                }
                console.log(scaledHingeHolesPos);
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    hingeHoles: scaledHingeHolesPos,
                }));

                setHingeDrillingPosInputs(hingeHolesPosInputsInitalValues);
                setHingeDrillingPosInputsErrors(hingeHolesPosInputsInitalErrors);
            }else if(hingeDrillSide == 'w2'){
                let holeSpacing = null;
                let startingPosY = parseInt(machiningConfig.topRight.y) + distanceFromEdge;
                // check if W1P exists
                if(machiningConfig.W2P != null){
                    holeSpacing = Math.floor((parseInt(machiningConfig.W2P.y) - parseInt(machiningConfig.topRight.y) - distanceFromEdge*2) / (hingeDrillingInputValue - 1));
                    startingPosY = parseInt(machiningConfig.topRight.y) + distanceFromEdge;
                }else{
                    holeSpacing = Math.floor((parseInt(machiningConfig.bottomRight.y) - parseInt(machiningConfig.topRight.y) - distanceFromEdge*2) / (hingeDrillingInputValue - 1));
                    startingPosY = parseInt(machiningConfig.topRight.y) + distanceFromEdge;
                }
                console.log('hole spacing: ' + holeSpacing);

                // add each hole to machining config
                let hingeHolesPos = [];
                let hingeHolesPosInputsInitalValues = [];
                let hingeHolesPosInputsInitalErrors = [];
                for(let i = 0; i < hingeDrillingInputValue; i++){
                    const holePos = {x: parseInt(rowConfig.materialLength) - distanceFromEdge, y: startingPosY + i*holeSpacing};
                    hingeHolesPos = [...hingeHolesPos, holePos];

                    const holePosInputInitialValues = startingPosY + i*holeSpacing;
                    hingeHolesPosInputsInitalValues = [...hingeHolesPosInputsInitalValues, holePosInputInitialValues];

                    const holePosInputInitialErros = false;
                    hingeHolesPosInputsInitalErrors = [...hingeHolesPosInputsInitalErrors, holePosInputInitialErros];
                }
                console.log(hingeHolesPos);
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    hingeHoles: hingeDrillingInputValue,
                    hingeHolesPos: hingeHolesPos,
                    hingeHolesSide: 'w2',
                    hingeHolesSpacing: holeSpacing,
                    hingesType: hingeType,
                }));

                // add each hole to the drawing props (scaled)
                const scalePercentage = drawingProps.drawingWidth / rowConfig.width;
                console.log(`drawing width: ${drawingProps.drawingWidth}; materrial width: ${rowConfig.width}; scale percentage: ${scalePercentage}`);
                let scaledHingeHolesPos = [];
                for(let i = 0; i < hingeDrillingInputValue; i++){
                    const holePos = {x: (drawingProps.drawingLength - (distanceFromEdge * scalePercentage) + drawingPadding), y: ((startingPosY + i*holeSpacing) * scalePercentage + drawingPadding)};
                    scaledHingeHolesPos = [...scaledHingeHolesPos, holePos];
                }
                console.log(scaledHingeHolesPos);
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    hingeHoles: scaledHingeHolesPos,
                }));

                setHingeDrillingPosInputs(hingeHolesPosInputsInitalValues);
                setHingeDrillingPosInputsErrors(hingeHolesPosInputsInitalErrors);
            }
        }else{
            setHingeDrillingError(hingeInputCheck);
        }

    };

    // function to check if the hinge holes number in the input is good
    const checkHingeInput = () => {
        if(hingeDrillSide == 'l1'){
            console.log('input: ' + hingeDrillingInputValue);
            console.log('max holes:' + maxHingeHolesPerSide.l1);
            if(hingeDrillingInputValue >= 2 && hingeDrillingInputValue <= parseInt(maxHingeHolesPerSide.l1)){
                return 'good';
            }else{
                return `Min: 2, Max: ${maxHingeHolesPerSide.l1}`;
            }
        }else if(hingeDrillSide == 'l2'){
            if(hingeDrillingInputValue >= 2 && hingeDrillingInputValue <= parseInt(maxHingeHolesPerSide.l2)){
                return 'good';
            }else{
                return `Min: 2, Max: ${maxHingeHolesPerSide.l2}`;
            }
        }else if(hingeDrillSide == 'w1'){
            if(hingeDrillingInputValue >= 2 && hingeDrillingInputValue <= parseInt(maxHingeHolesPerSide.w1)){
                return 'good';
            }else{
                return `Min: 2, Max: ${maxHingeHolesPerSide.w1}`;
            }
        }else if(hingeDrillSide == 'w2'){
            if(hingeDrillingInputValue >= 2 && hingeDrillingInputValue <= parseInt(maxHingeHolesPerSide.w2)){
                return 'good';
            }else{
                return `Min: 2, Max: ${maxHingeHolesPerSide.w2}`;
            }
        }
    }

    // function to remove the hinge holes
    const removeHingeDrilling = () =>{
        setMachiningConfig((prevValues) => ({
            ...prevValues,
            hingeHoles: null,
            hingeHolesPos: [],
            hingeHolesPosLimits: null,
            hingesType: null,
            hingeHolesSide: null,
        }));
        setHingeDrillingPosLimits(null);
    };

    // function for the hinge hole input value change
    const updateHingeHolePosition = (holeIndex, event) => {
        const newValue = event.target.value;
        if(hingeDrillSide == 'l1'){
            const sanitizedValue = newValue.replace(/[^\d]/g, '');
            const updatedInputs = [...hingeDrillingPosInputs];
            updatedInputs[holeIndex] = sanitizedValue;
            setHingeDrillingPosInputs(updatedInputs);
            if(sanitizedValue >= hingeDrillingPosLimits[holeIndex].min && sanitizedValue <= hingeDrillingPosLimits[holeIndex].max){
                const updatedInputsErros = [...hingeDrillingPosInputsErrors];
                updatedInputsErros[holeIndex] = false; // dont show error
                setHingeDrillingPosInputsErrors(updatedInputsErros);

                const modifiedHingeHolesPos = [...machiningConfig.hingeHolesPos];
                modifiedHingeHolesPos[holeIndex].x = sanitizedValue;
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    hingeHolesPos: modifiedHingeHolesPos,
                }));

                const scalePercentage = drawingProps.drawingLength / rowConfig.materialLength;
                const drawingHingeHolePositions = [...drawingProps.hingeHoles];
                drawingHingeHolePositions[holeIndex].x = sanitizedValue * scalePercentage + drawingPadding;
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    hingeHoles: drawingHingeHolePositions,
                }));

            }else{
                const updatedInputsErros = [...hingeDrillingPosInputsErrors];
                updatedInputsErros[holeIndex] = true; // show error
                setHingeDrillingPosInputsErrors(updatedInputsErros);
            }
        }else if(hingeDrillSide == 'l2'){
            const sanitizedValue = newValue.replace(/[^\d]/g, '');
            const updatedInputs = [...hingeDrillingPosInputs];
            updatedInputs[holeIndex] = sanitizedValue;
            setHingeDrillingPosInputs(updatedInputs);
            if(sanitizedValue >= hingeDrillingPosLimits[holeIndex].min && sanitizedValue <= hingeDrillingPosLimits[holeIndex].max){
                const updatedInputsErros = [...hingeDrillingPosInputsErrors];
                updatedInputsErros[holeIndex] = false; // dont show error
                setHingeDrillingPosInputsErrors(updatedInputsErros);

                const modifiedHingeHolesPos = [...machiningConfig.hingeHolesPos];
                modifiedHingeHolesPos[holeIndex].x = sanitizedValue;
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    hingeHolesPos: modifiedHingeHolesPos,
                }));

                const scalePercentage = drawingProps.drawingLength / rowConfig.materialLength;
                const drawingHingeHolePositions = [...drawingProps.hingeHoles];
                drawingHingeHolePositions[holeIndex].x = sanitizedValue * scalePercentage + drawingPadding;
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    hingeHoles: drawingHingeHolePositions,
                }));

            }else{
                const updatedInputsErros = [...hingeDrillingPosInputsErrors];
                updatedInputsErros[holeIndex] = true; // show error
                setHingeDrillingPosInputsErrors(updatedInputsErros);
            }
        }else if(hingeDrillSide == 'w1'){
            const sanitizedValue = newValue.replace(/[^\d]/g, '');
            const updatedInputs = [...hingeDrillingPosInputs];
            updatedInputs[holeIndex] = sanitizedValue;
            setHingeDrillingPosInputs(updatedInputs);
            if(sanitizedValue >= hingeDrillingPosLimits[holeIndex].min && sanitizedValue <= hingeDrillingPosLimits[holeIndex].max){
                const updatedInputsErros = [...hingeDrillingPosInputsErrors];
                updatedInputsErros[holeIndex] = false; // dont show error
                setHingeDrillingPosInputsErrors(updatedInputsErros);

                const modifiedHingeHolesPos = [...machiningConfig.hingeHolesPos];
                modifiedHingeHolesPos[holeIndex].y = sanitizedValue;
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    hingeHolesPos: modifiedHingeHolesPos,
                }));

                const scalePercentage = drawingProps.drawingWidth / rowConfig.width;
                const drawingHingeHolePositions = [...drawingProps.hingeHoles];
                drawingHingeHolePositions[holeIndex].y = sanitizedValue * scalePercentage + drawingPadding;
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    hingeHoles: drawingHingeHolePositions,
                }));

            }else{
                const updatedInputsErros = [...hingeDrillingPosInputsErrors];
                updatedInputsErros[holeIndex] = true; // show error
                setHingeDrillingPosInputsErrors(updatedInputsErros);
            }
        }else if(hingeDrillSide == 'w2'){
            const sanitizedValue = newValue.replace(/[^\d]/g, '');
            const updatedInputs = [...hingeDrillingPosInputs];
            updatedInputs[holeIndex] = sanitizedValue;
            setHingeDrillingPosInputs(updatedInputs);
            if(sanitizedValue >= hingeDrillingPosLimits[holeIndex].min && sanitizedValue <= hingeDrillingPosLimits[holeIndex].max){
                const updatedInputsErros = [...hingeDrillingPosInputsErrors];
                updatedInputsErros[holeIndex] = false; // dont show error
                setHingeDrillingPosInputsErrors(updatedInputsErros);

                const modifiedHingeHolesPos = [...machiningConfig.hingeHolesPos];
                modifiedHingeHolesPos[holeIndex].y = sanitizedValue;
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    hingeHolesPos: modifiedHingeHolesPos,
                }));

                const scalePercentage = drawingProps.drawingWidth / rowConfig.width;
                const drawingHingeHolePositions = [...drawingProps.hingeHoles];
                drawingHingeHolePositions[holeIndex].y = sanitizedValue * scalePercentage + drawingPadding;
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    hingeHoles: drawingHingeHolePositions,
                }));

            }else{
                const updatedInputsErros = [...hingeDrillingPosInputsErrors];
                updatedInputsErros[holeIndex] = true; // show error
                setHingeDrillingPosInputsErrors(updatedInputsErros);
            }
        }
    };

    // function to update and calculate the hinge holes position limits (min and max)
    const updateHingeHolesPosLimits = () =>{
        if(machiningConfig.hingeHoles != null && machiningConfig.hingeHolesPos.length > 0){
            const minDistanceBetweenHoles = 100;
            if(hingeDrillSide == 'l1'){
                let posLimits = [];
                machiningConfig.hingeHolesPos.forEach((hole, index) => {
                    console.log('index for holePos: ' + index);
                    if(index == 0){ // check for the first hole
                        let holeLimits = {min: parseInt(machiningConfig.topLeft.x) + 50, max: parseInt(machiningConfig.topLeft.x) + 300};
                        if(machiningConfig.hingeHolesPos[index + 1] != null && machiningConfig.hingeHolesPos[index + 1].x < (300 + minDistanceBetweenHoles)){
                            holeLimits.max = machiningConfig.hingeHolesPos[index + 1].x - 100;
                        }
                        posLimits[index] = holeLimits;
                    }else if(machiningConfig.hingeHoles == index + 1){ // check for last holes
                        let holeLimits = {
                            min: parseInt(rowConfig.materialLength) - 300,
                            max: parseInt(rowConfig.materialLength) - 50
                        };
                        if(machiningConfig.L1P != null){
                            holeLimits = {min: parseInt(machiningConfig.L1P.x) - 300, max: parseInt(machiningConfig.L1P.x) - 50};
                        }
                        if (machiningConfig.hingeHolesPos[index - 1] != null) {
                            const prevHoleX = parseInt(machiningConfig.hingeHolesPos[index - 1].x);
                            if (prevHoleX > rowConfig.materialLength - (300 + minDistanceBetweenHoles)) {
                                holeLimits.min = prevHoleX + 100;
                            }
                        }
                        posLimits[index] = holeLimits;
                    }else{ // check for normal hole
                        if(machiningConfig.hingeHolesPos[index - 1] != null && machiningConfig.hingeHolesPos[index + 1] != null){
                            let holeLimits = {
                                min: parseInt(machiningConfig.hingeHolesPos[index - 1].x) + minDistanceBetweenHoles,
                                max: parseInt(machiningConfig.hingeHolesPos[index + 1].x) - minDistanceBetweenHoles
                            };
                            posLimits[index] = holeLimits;
                        }
                    }
                });
                setHingeDrillingPosLimits(posLimits);
            }else if(hingeDrillSide == 'l2'){
                let posLimits = [];
                machiningConfig.hingeHolesPos.forEach((hole, index) => {
                    console.log('index for holePos: ' + index);
                    if(index == 0){ // check for the first hole
                        let holeLimits = {min: parseInt(machiningConfig.topLeft.x) + 50, max: parseInt(machiningConfig.topLeft.x) + 300};
                        if(machiningConfig.hingeHolesPos[index + 1] != null && machiningConfig.hingeHolesPos[index + 1].x < (300 + minDistanceBetweenHoles)){
                            holeLimits.max = machiningConfig.hingeHolesPos[index + 1].x - 100;
                        }
                        if(machiningConfig.L2P != null){
                            holeLimits = {min: parseInt(machiningConfig.L2P.x) + 50, max: parseInt(machiningConfig.L2P.x) + 300};
                        }
                        posLimits[index] = holeLimits;
                    }else if(machiningConfig.hingeHoles == index + 1){ // check for last holes
                        let holeLimits = {
                            min: parseInt(rowConfig.materialLength) - 300,
                            max: parseInt(rowConfig.materialLength) - 50
                        };
                        if (machiningConfig.hingeHolesPos[index - 1] != null) {
                            const prevHoleX = parseInt(machiningConfig.hingeHolesPos[index - 1].x);
                            if (prevHoleX > rowConfig.materialLength - (300 + minDistanceBetweenHoles)) {
                                holeLimits.min = prevHoleX + 100;
                            }
                        }
                        posLimits[index] = holeLimits;
                    }else{ // check for normal hole
                        if(machiningConfig.hingeHolesPos[index - 1] != null && machiningConfig.hingeHolesPos[index + 1] != null){
                            let holeLimits = {
                                min: parseInt(machiningConfig.hingeHolesPos[index - 1].x) + minDistanceBetweenHoles,
                                max: parseInt(machiningConfig.hingeHolesPos[index + 1].x) - minDistanceBetweenHoles
                            };
                            posLimits[index] = holeLimits;
                        }
                    }
                });
                setHingeDrillingPosLimits(posLimits);
            }else if(hingeDrillSide == 'w1'){
                let posLimits = [];
                machiningConfig.hingeHolesPos.forEach((hole, index) => {
                    console.log('index for holePos: ' + index);
                    if(index == 0){ // check for the first hole
                        let holeLimits = {min: 50, max: 300};
                        if(machiningConfig.hingeHolesPos[index + 1] != null && machiningConfig.hingeHolesPos[index + 1].y < (300 + minDistanceBetweenHoles)){
                            holeLimits.max = machiningConfig.hingeHolesPos[index + 1].y - 100;
                        }
                        if(machiningConfig.W1P != null){
                            holeLimits = {min: parseInt(machiningConfig.W1P.y) + 50, max: parseInt(machiningConfig.W1P.y) + 300};
                        }
                        posLimits[index] = holeLimits;
                    }else if(machiningConfig.hingeHoles == index + 1){ // check for last holes
                        let holeLimits = {
                            min: parseInt(rowConfig.width) - 300,
                            max: parseInt(rowConfig.width) - 50
                        };
                        if (machiningConfig.hingeHolesPos[index - 1] != null) {
                            const prevHoleY = parseInt(machiningConfig.hingeHolesPos[index - 1].y);
                            if (prevHoleY > rowConfig.width - (300 + minDistanceBetweenHoles)) {
                                holeLimits.min = prevHoleY + 100;
                            }
                        }
                        posLimits[index] = holeLimits;
                    }else{ // check for normal hole
                        if(machiningConfig.hingeHolesPos[index - 1] != null && machiningConfig.hingeHolesPos[index + 1] != null){
                            let holeLimits = {
                                min: parseInt(machiningConfig.hingeHolesPos[index - 1].y) + minDistanceBetweenHoles,
                                max: parseInt(machiningConfig.hingeHolesPos[index + 1].y) - minDistanceBetweenHoles
                            };
                            posLimits[index] = holeLimits;
                        }
                    }
                });
                setHingeDrillingPosLimits(posLimits);
            }else if(hingeDrillSide == 'w2'){
                let posLimits = [];
                machiningConfig.hingeHolesPos.forEach((hole, index) => {
                    console.log('index for holePos: ' + index);
                    if(index == 0){ // check for the first hole
                        let holeLimits = {min: 50, max: 300};
                        if(machiningConfig.hingeHolesPos[index + 1] != null && machiningConfig.hingeHolesPos[index + 1].y < (300 + minDistanceBetweenHoles)){
                            holeLimits.max = machiningConfig.hingeHolesPos[index + 1].y - 100;
                        }
                        posLimits[index] = holeLimits;
                    }else if(machiningConfig.hingeHoles == index + 1){ // check for last holes
                        let holeLimits = {
                            min: parseInt(rowConfig.width) - 300,
                            max: parseInt(rowConfig.width) - 50
                        };
                        if(machiningConfig.W2P != null){
                            holeLimits = {min: parseInt(machiningConfig.W2P.y) - 300, max: parseInt(machiningConfig.W2P.y) - 50};
                        }
                        if (machiningConfig.hingeHolesPos[index - 1] != null) {
                            const prevHoleY = parseInt(machiningConfig.hingeHolesPos[index - 1].y);
                            if (prevHoleY > rowConfig.width - (300 + minDistanceBetweenHoles)) {
                                holeLimits.min = prevHoleY + 100;
                            }
                        }
                        posLimits[index] = holeLimits;
                    }else{ // check for normal hole
                        if(machiningConfig.hingeHolesPos[index - 1] != null && machiningConfig.hingeHolesPos[index + 1] != null){
                            let holeLimits = {
                                min: parseInt(machiningConfig.hingeHolesPos[index - 1].y) + minDistanceBetweenHoles,
                                max: parseInt(machiningConfig.hingeHolesPos[index + 1].y) - minDistanceBetweenHoles
                            };
                            posLimits[index] = holeLimits;
                        }
                    }
                });
                setHingeDrillingPosLimits(posLimits);
            }
        }
    };


// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| SHELF DRILLING |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const [shelfDrillingInputsErrors, setShelfDrillingInputsErrors] = useState({
        distance1: null,
        distance2: null,
        step: null,
        positions: null,
        number_of_clusters: false,
    });
    const [shelfDrillingInputsLimits, setShelfDrillingInputsLimits] = useState({
        distance1_min: 20,
        distance1_max: null,
        distance2_min: 20,
        distance2_max: null,
        step_min: 25,
        step_max: null,
        positions_min: 1,
        positions_max: null,
        number_of_clusters_min: 1,
        number_of_clusters_max: null
    });
    const [shelfDrillingInputs, setShelfDrillingInputs] = useState({
        distance1: 50,
        distance2: 50,
        step: 32,
        positions: 2,
        number_of_clusters: 1,
    });
    const [canClustersBePlaced, setCanClustersBePlaced] = useState(true);
    const [shelfType, setShelfType] = useState('5mm');
    const [shelfDrillingEdge, setShelfDrillingEdge] = useState('l1-l2');
    const [shelfDrillingFace, setShelfDrillingFace] = useState('B');
    const [clustersPosInputs, setClustersPosInputs] = useState([]);
    const [clusterPosLimits, setClusterPosLimits] = useState(null);
    const [clustersPosInputsErrors, setclustersPosInputsErrors] = useState([]);

    useEffect(() => {
        if(selectionConfigValue == 'shelfDrilling' && machiningConfig.clusters == null){
            checkIfShelfDrillingCanBeApplied();
            console.log('//////////////////////////////////////////////////////////////////////////////////');
        }
    }, [shelfDrillingEdge, selectionConfigValue]);

    // change shelf drilling type (5mm / 7.5mm)
    const changeShelfType = (type) =>{
        setShelfType(type);
    };

    // change shelf drilling edge (l1-l2 / w1-w2)
    const changeShelfDrillingEdge = (side) =>{
        setShelfDrillingEdge(side);
    };

    // check if shelf drilling can be applid to the edge
    const checkIfShelfDrillingCanBeApplied = () =>{
        const minDistanceFromEnd = 50;
        const minDistanceFromEdge = 20;
        const minStep = 25;
        const minDistBetweenClusters = 75;
        if(shelfDrillingEdge == 'l1-l2'){
            // check if the edge length is big enough for clusters to be even placed
            let startingPosX = parseInt(machiningConfig.topLeft.x) + minDistanceFromEnd;
            // check if there is a l2-w1 cut
            if(machiningConfig.L2P != null && (parseInt(machiningConfig.L2P.x) > parseInt(machiningConfig.topLeft.x))){ // if L2P exists and it is bigger than TL
                startingPosX = parseInt(machiningConfig.L2P.x) + minDistanceFromEnd;
            }
            let endingPosX = parseInt(machiningConfig.bottomRight.x) - minDistanceFromEnd;
            // check if there is a l1-w2 cut
            if(machiningConfig.L1P != null && (parseInt(machiningConfig.L1P.x) < parseInt(machiningConfig.bottomRight.x))){ // if L1P exists and it is smaller than BR
                endingPosX = parseInt(machiningConfig.L1P.x) - minDistanceFromEnd;
            }
            // calculate the available space
            const availableSpaceLength = endingPosX - startingPosX;

            // check if the width is big enough for clusters to be even placed
            let startingPosY = parseInt(machiningConfig.topRight.y) + minDistanceFromEnd;
            // check if there is a l1-w1 cut
            if(machiningConfig.W1P != null && (parseInt(machiningConfig.W1P.y) > parseInt(machiningConfig.topRight.y))){ // if L2P exists and it is bigger than TL
                startingPosY = parseInt(machiningConfig.W1P).y + minDistanceFromEnd;
            }
            let endingPosY = parseInt(machiningConfig.bottomLeft.y) - minDistanceFromEnd;
            // check if there is a l2-w2 cut
            if(machiningConfig.W2P != null && (parseInt(machiningConfig.W2P.y) < parseInt(machiningConfig.bottomLeft.y))){ // if L1P exists and it is smaller than BR
                endingPosY = parseInt(machiningConfig.W2P.y) - minDistanceFromEnd;
            }
            // calculate the available space
            const availableSpaceWidth = endingPosY - startingPosY;

            // check if the edge space is bigger than 300mm
            if(availableSpaceLength < 300 || availableSpaceWidth < 150){
                // clusters can't be placed
                setCanClustersBePlaced(false);
                console.log('CLUSTERS CANT BE PLACED');
                return;
            }else{
                setCanClustersBePlaced(true);
                console.log('CLUSTERS GOOD TO BE PLACED');
            }
        }
    };

    // change all the shelf drilling inputs and validate them
    const changeShelfDrillingInputs = (inputField, newValue) => {
        const sanitizedValue = newValue.replace(/[^\d]/g, '');
        const minDistanceFromEnd = 50;
        const minDistanceFromEdge = 20;
        const minStep = 25;
        const minDistBetweenClusters = 75;

        let new_dist1_max = null;
        let dist1_error = shelfDrillingInputsErrors.distance1;
        let new_dist2_max = null;
        let dist2_error = shelfDrillingInputsErrors.distance2;
        let new_step_max = null;
        let step_error = shelfDrillingInputsErrors.step;
        let new_pos_max = null;
        let pos_error = shelfDrillingInputsErrors.positions;
        let new_numb_max = null;
        let numb_error = shelfDrillingInputsErrors.number_of_clusters;

        if(inputField == 'distance1'){
            if(rowConfig != null && machiningConfig.topLeft != null && machiningConfig.topRight != null && machiningConfig.bottomLeft != null && machiningConfig.bottomRight != null){
                if(shelfDrillingFace == 'A' || shelfDrillingFace == 'Both' || shelfDrillingFace == 'B'){
                    if(shelfDrillingEdge == 'l1-l2'){
                        let dist1Value = sanitizedValue;
                        let dist2Value = parseInt(shelfDrillingInputs.distance2);
                        let stepValue = parseInt(shelfDrillingInputs.step);
                        let positionsValue = parseInt(shelfDrillingInputs.positions);
                        let numbValue = parseInt(shelfDrillingInputs.number_of_clusters);
                        // check for distance 1
                        setShelfDrillingInputs((prevValues) => ({
                            ...prevValues,
                            distance1: dist1Value,
                        }));

                        const distanceBetweenD1AndD2 = parseInt(rowConfig.width) - dist2Value - dist1Value;
                        if(distanceBetweenD1AndD2 < 50){
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance1: 'Distance between holes in a pair cannot be less than 50mm',
                                distance2: 'Distance between holes in a pair cannot be less than 50mm',
                            }));
                            dist1_error = 'Distance between holes in a pair cannot be less than 50mm';
                        }else if(dist1Value < shelfDrillingInputsLimits.distance1_min){
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance1: 'Min. 20mm',
                            }));
                            dist1_error = 'Min. 20mm';
                        }else{
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance1: null,
                            }));
                            dist1_error = null;
                            if(dist2Value > shelfDrillingInputsLimits.distance2_min){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    distance2: null,
                                }));
                                dist2_error = null;
                            }
                            if(dist2_error == null && step_error == null && pos_error == null && numb_error == false){ //  apply changes to drawing if it's all good
                                applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                            }
                        }
                    }else if(shelfDrillingEdge == 'w1-w2'){
                        let dist1Value = sanitizedValue;
                        let dist2Value = parseInt(shelfDrillingInputs.distance2);
                        let stepValue = parseInt(shelfDrillingInputs.step);
                        let positionsValue = parseInt(shelfDrillingInputs.positions);
                        let numbValue = parseInt(shelfDrillingInputs.number_of_clusters);
                        // check for distance 1
                        setShelfDrillingInputs((prevValues) => ({
                            ...prevValues,
                            distance1: dist1Value,
                        }));

                        const distanceBetweenD1AndD2 = parseInt(rowConfig.materialLength) - dist2Value - dist1Value;
                        if(distanceBetweenD1AndD2 < 50){
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance1: 'Distance between holes in a pair cannot be less than 50mm',
                                distance2: 'Distance between holes in a pair cannot be less than 50mm',
                            }));
                            dist1_error = 'Distance between holes in a pair cannot be less than 50mm';
                        }else if(dist1Value < shelfDrillingInputsLimits.distance1_min){
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance1: 'Min. 20mm',
                            }));
                            dist1_error = 'Min. 20mm';
                        }else{
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance1: null,
                            }));
                            dist1_error = null;
                            if(dist2Value > shelfDrillingInputsLimits.distance2_min){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    distance2: null,
                                }));
                                dist2_error = null;
                            }
                            if(dist2_error == null && step_error == null && pos_error == null && numb_error == false){ //  apply changes to drawing if it's all good
                                applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                            }
                        }
                    }
                }
            }
        }else if(inputField == 'distance2'){
            if(rowConfig != null && machiningConfig.topLeft != null && machiningConfig.topRight != null && machiningConfig.bottomLeft != null && machiningConfig.bottomRight != null){
                if(shelfDrillingFace == 'A' || shelfDrillingFace == 'Both' || shelfDrillingFace == 'B'){
                    if(shelfDrillingEdge == 'l1-l2'){
                        let dist1Value = parseInt(shelfDrillingInputs.distance1);
                        let dist2Value = sanitizedValue;
                        let stepValue = parseInt(shelfDrillingInputs.step);
                        let positionsValue = parseInt(shelfDrillingInputs.positions);
                        let numbValue = parseInt(shelfDrillingInputs.number_of_clusters);
                        // check for distance 1
                        setShelfDrillingInputs((prevValues) => ({
                            ...prevValues,
                            distance2: sanitizedValue,
                        }));
                        const distanceBetweenD1AndD2 = parseInt(rowConfig.width) - dist2Value - dist1Value;
                        // check for distance 2
                        if(distanceBetweenD1AndD2 < 50){
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance1: 'Distance between holes in a pair cannot be less than 50mm',
                                distance2: 'Distance between holes in a pair cannot be less than 50mm',
                            }));
                            dist2_error = 'Distance between holes in a pair cannot be less than 50mm';
                        }else if(dist2Value < shelfDrillingInputsLimits.distance2_min){
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance2: 'Min. 20mm',
                            }));
                            dist2_error = 'Min. 20mm';
                        }else{
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance2: null,
                            }));
                            dist2_error = null;
                            if(dist1Value > shelfDrillingInputsLimits.distance1_min){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    distance1: null,
                                }));
                                dist1_error = null;
                            }
                            if(dist1_error == null && step_error == null && pos_error == null && numb_error == false){ //  apply changes to drawing if it's all good
                                applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                            }
                        }
                    }else if(shelfDrillingEdge == 'w1-w2'){
                        let dist1Value = parseInt(shelfDrillingInputs.distance1);
                        let dist2Value = sanitizedValue;
                        let stepValue = parseInt(shelfDrillingInputs.step);
                        let positionsValue = parseInt(shelfDrillingInputs.positions);
                        let numbValue = parseInt(shelfDrillingInputs.number_of_clusters);
                        // check for distance 1
                        setShelfDrillingInputs((prevValues) => ({
                            ...prevValues,
                            distance2: sanitizedValue,
                        }));
                        const distanceBetweenD1AndD2 = parseInt(rowConfig.materialLength) - dist2Value - dist1Value;
                        // check for distance 2
                        if(distanceBetweenD1AndD2 < 50){
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance1: 'Distance between holes in a pair cannot be less than 50mm',
                                distance2: 'Distance between holes in a pair cannot be less than 50mm',
                            }));
                            dist2_error = 'Distance between holes in a pair cannot be less than 50mm';
                        }else if(dist2Value < shelfDrillingInputsLimits.distance2_min){
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance2: 'Min. 20mm',
                            }));
                            dist2_error = 'Min. 20mm';
                        }else{
                            setShelfDrillingInputsErrors((prevValues) => ({
                                ...prevValues,
                                distance2: null,
                            }));
                            dist2_error = null;
                            if(dist1Value > shelfDrillingInputsLimits.distance1_min){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    distance1: null,
                                }));
                                dist1_error = null;
                            }
                            if(dist1_error == null && step_error == null && pos_error == null && numb_error == false){ //  apply changes to drawing if it's all good
                                applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                            }
                        }
                    }
                }
            }
        }else if(inputField == 'step' || inputField == 'positions' || inputField == 'number_of_clusters'){
            if(rowConfig != null && machiningConfig.topLeft != null && machiningConfig.topRight != null && machiningConfig.bottomLeft != null && machiningConfig.bottomRight != null){
                if(shelfDrillingFace == 'A' || shelfDrillingFace == 'Both' || shelfDrillingFace == 'B'){
                    if(shelfDrillingEdge == 'l1-l2'){
                        let dist1Value = parseInt(shelfDrillingInputs.distance1);
                        let dist2Value = parseInt(shelfDrillingInputs.distance2);
                        let stepValue = parseInt(shelfDrillingInputs.step);
                        let positionsValue = parseInt(shelfDrillingInputs.positions);
                        let numbValue = parseInt(shelfDrillingInputs.number_of_clusters);
                        let startingPosX = parseInt(machiningConfig.topLeft.x) + minDistanceFromEnd;
                        // check if there is a l2-w1 cut
                        if(machiningConfig.L2P != null && (parseInt(machiningConfig.L2P.x) > parseInt(machiningConfig.topLeft.x))){ // if L2P exists and it is bigger than TL
                            startingPosX = parseInt(machiningConfig.L2P.x) + minDistanceFromEnd;
                        }
                        let endingPosX = parseInt(machiningConfig.bottomRight.x) - minDistanceFromEnd;
                        // check if there is a l1-w2 cut
                        if(machiningConfig.L1P != null && (parseInt(machiningConfig.L1P.x) < parseInt(machiningConfig.bottomRight.x))){ // if L1P exists and it is smaller than BR
                            endingPosX = parseInt(machiningConfig.L1P.x) - minDistanceFromEnd;
                        }
                        // calculate the available space
                        const availableSpace = endingPosX - startingPosX;

                        if(inputField == 'step'){
                            stepValue = sanitizedValue;
                            setShelfDrillingInputs((prevValues) => ({
                                ...prevValues,
                                step: stepValue,
                            }));

                            //  calculate step max
                            const calcFromStep = Math.ceil(availableSpace/positionsValue);
                            for (let step = calcFromStep; step > 0; step--) {
                                const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                const minSpaceForClusters = numbValue * ((positionsValue-1) * step);
                                const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                if(minSpaceRequired <= availableSpace){
                                    new_step_max = step;
                                    break;
                                }
                            }
                            if(new_step_max > 100){
                                new_step_max = 100;
                            }
                            setShelfDrillingInputsLimits((prevValues) => ({
                                ...prevValues,
                                step_max: new_step_max,
                            }));
                            if(new_step_max < shelfDrillingInputsLimits.step_min){  //   if the maximum is smaller than the minimum of 25mm
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    step: `Clusters can't be applied with the current positions and no. of clusters`,
                                }));
                                return;
                            }else if(stepValue < shelfDrillingInputsLimits.step_min){   //  if the step input is smaller than the minimum
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    step: `Min. size 25mm`,
                                }));
                                return;
                            }else if(stepValue > new_step_max){ //  if the step input is bigger than the maximum
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    step: `Max. size 100mm`,
                                }));
                                return;
                            }else{  // if it's all good
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    step: null,
                                }));
                                step_error = null;

                                // calculate for positions max
                                const calcFromPos = Math.ceil(availableSpace/minDistBetweenClusters);
                                for (let positions = calcFromPos; positions > 0; positions--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                    const minSpaceForClusters = numbValue * ((positions-1) * stepValue);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                    if(minSpaceRequired <= availableSpace){
                                        new_pos_max = positions;
                                        break;
                                    }
                                }
                                setShelfDrillingInputsLimits((prevValues) => ({
                                    ...prevValues,
                                    positions_max: new_pos_max,
                                }));
                                if(new_pos_max < 1){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Clusters can't be applied with the current step and no. of clusters`,
                                    }));
                                    return;
                                }else if(positionsValue < shelfDrillingInputsLimits.positions_min){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Min. qty 1`,
                                    }));
                                    return;
                                }else if(positionsValue > new_pos_max){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Max. qty ${new_pos_max}`,
                                    }));
                                    return;
                                }else{
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: null,
                                    }));
                                    pos_error = null;
                                }

                                //  recalculate the number of clusters max
                                const calcFromNumb = Math.ceil(availableSpace/positionsValue);
                                for (let no = calcFromNumb; no > 0; no--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (no - 1);
                                    const minSpaceForClusters = no * ((positionsValue-1) * stepValue);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                
                                    if(minSpaceRequired <= availableSpace){
                                        new_numb_max = no;
                                        break;
                                    }
                                }
                                if(new_numb_max > 0){   //  if there can be placed any clusters
                                    setShelfDrillingInputsLimits((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters_min: 1,
                                        number_of_clusters_max: new_numb_max,
                                    }));
                                    if(numbValue > new_numb_max){   //  if the existing input is bigger than the new max, change the input to be equal to new max
                                        numbValue = new_numb_max;
                                        setShelfDrillingInputs((prevValues) => ({
                                            ...prevValues,
                                            number_of_clusters: numbValue,
                                        }));
                                        setShelfDrillingInputsErrors((prevValues) => ({
                                            ...prevValues,
                                            number_of_clusters: false,
                                        }));
                                        numb_error = false;
                                    }
                                }else{  //  if there cannot be placed any clusters
                                    setShelfDrillingInputsLimits((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters_min: 0,
                                        number_of_clusters_max: new_numb_max,
                                    }));
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters: true,
                                    }));
                                    numb_error = true;
                                    return;
                                }

                                if(dist1_error == null && dist2_error == null && pos_error == null && numb_error == false){ //  apply changes to drawing if it's all good
                                    applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                                }
                            }
                        }else if(inputField == 'positions'){
                            positionsValue = sanitizedValue;
                            setShelfDrillingInputs((prevValues) => ({
                                ...prevValues,
                                positions: positionsValue,
                            }));
                            //  calculate for positions max
                            const calcFromPos = Math.ceil(availableSpace/minDistBetweenClusters);
                            for (let positions = calcFromPos; positions > 0; positions--) {
                                const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                const minSpaceForClusters = numbValue * ((positions-1) * stepValue);
                                const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                if(minSpaceRequired <= availableSpace){
                                    new_pos_max = positions;
                                    break;
                                }
                            }
                            setShelfDrillingInputsLimits((prevValues) => ({
                                ...prevValues,
                                positions_max: new_pos_max,
                            }));
                            if(new_pos_max < 1){    //  if there cannot be placed any clusters with this inputs
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    positions: `Clusters can't be applied with the current step and no. of clusters`,
                                }));
                                return;
                            }else if(positionsValue < shelfDrillingInputsLimits.positions_min){ //  if the input is smaller than the minimum
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    positions: `Min. qty 1`,
                                }));
                                return;
                            }else if(positionsValue > new_pos_max){ // if the input is bigger than the maximum
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    positions: `Max. qty ${new_pos_max}`,
                                }));
                                return;
                            }else{  //  if it's all good
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    positions: null,
                                }));
                                pos_error = null;

                                //  calculate step max
                                const calcFromStep = Math.ceil(availableSpace/positionsValue);
                                for (let step = calcFromStep; step > 0; step--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                    const minSpaceForClusters = numbValue * ((positionsValue-1) * step);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                    if(minSpaceRequired <= availableSpace){
                                        new_step_max = step;
                                        break;
                                    }
                                }
                                if(new_step_max > 100){
                                    new_step_max = 100;
                                }
                                setShelfDrillingInputsLimits((prevValues) => ({
                                    ...prevValues,
                                    step_max: new_step_max,
                                }));
                                if(new_step_max < shelfDrillingInputsLimits.step_min){  //   if the maximum is smaller than the minimum of 25mm
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Clusters can't be applied with the current positions and no. of clusters`,
                                    }));
                                    return;
                                }else if(stepValue < shelfDrillingInputsLimits.step_min){   //  if the step input is smaller than the minimum
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Min. size 25mm`,
                                    }));
                                    return;
                                }else if(stepValue > new_step_max){ //  if the step input is bigger than the maximum
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Max. size 100mm`,
                                    }));
                                    return;
                                }else{  // if it's all good
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: null,
                                    }));
                                    step_error = null;
                                }

                                //  recalculate the number of clusters max
                                const calcFromNumb = Math.ceil(availableSpace/positionsValue);
                                for (let no = calcFromNumb; no > 0; no--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (no - 1);
                                    const minSpaceForClusters = no * ((positionsValue-1) * stepValue);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                
                                    if(minSpaceRequired <= availableSpace){
                                        new_numb_max = no;
                                        break;
                                    }
                                }
                                if(new_numb_max > 0){   //  if there can be placed any clusters
                                    setShelfDrillingInputsLimits((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters_min: 1,
                                        number_of_clusters_max: new_numb_max,
                                    }));
                                    if(numbValue > new_numb_max){   //  if the existing input is bigger than the new max, change the input to be equal to new max
                                        numbValue = new_numb_max;
                                        setShelfDrillingInputs((prevValues) => ({
                                            ...prevValues,
                                            number_of_clusters: numbValue,
                                        }));
                                        setShelfDrillingInputsErrors((prevValues) => ({
                                            ...prevValues,
                                            number_of_clusters: false,
                                        }));
                                        numb_error = false;
                                    }
                                }else{  //  if there cannot be placed any clusters
                                    setShelfDrillingInputsLimits((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters_min: 0,
                                        number_of_clusters_max: new_numb_max,
                                    }));
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters: true,
                                    }));
                                    numb_error = true;
                                    return;
                                }

                                if(dist1_error == null && dist2_error == null && step_error == null && numb_error == false){ //  apply changes to drawing if it's all good
                                    applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                                }
                            }
                        }else if(inputField == 'number_of_clusters'){
                            console.log('Checking for input field NUMBER OF CLUSTERS');
                            numbValue = sanitizedValue;
                            setShelfDrillingInputs((prevValues) => ({
                                ...prevValues,
                                number_of_clusters: numbValue,
                            }));
                            if(shelfDrillingInputsLimits.number_of_clusters_max < 1){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    number_of_clusters: true,
                                }));
                                numb_error = true;
                            }else if(numbValue < 1){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    number_of_clusters: true,
                                }));
                                numb_error = true;
                            }else if(numbValue > shelfDrillingInputsLimits.number_of_clusters_max){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    number_of_clusters: true,
                                }));
                                numb_error = true;
                            }else{
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    number_of_clusters: false,
                                }));
                                numb_error = false;
                                console.log('all GOOD for NUMBER OF CLUSTERS at NUMBER OF CLUSTERS input check');

                                //  calculate step max
                                const calcFromStep = Math.ceil(availableSpace/positionsValue);
                                for (let step = calcFromStep; step > 0; step--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                    const minSpaceForClusters = numbValue * ((positionsValue-1) * step);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                    if(minSpaceRequired <= availableSpace){
                                        new_step_max = step;
                                        break;
                                    }
                                }
                                if(new_step_max > 100){
                                    new_step_max = 100;
                                }
                                setShelfDrillingInputsLimits((prevValues) => ({
                                    ...prevValues,
                                    step_max: new_step_max,
                                }));
                                if(new_step_max < shelfDrillingInputsLimits.step_min){  //   if the maximum is smaller than the minimum of 25mm
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Clusters can't be applied with the current positions and no. of clusters`,
                                    }));
                                    return;
                                }else if(stepValue < shelfDrillingInputsLimits.step_min){   //  if the step input is smaller than the minimum
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Min. size 25mm`,
                                    }));
                                    return;
                                }else if(stepValue > new_step_max){ //  if the step input is bigger than the maximum
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Max. size 100mm`,
                                    }));
                                    return;
                                }else{  // if it's all good
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: null,
                                    }));
                                    step_error = null;
                                    console.log('all GOOD for STEP at NUMBER OF CLUSTERS input check');
                                }

                                // calculate for positions max
                                const calcFromPos = Math.ceil(availableSpace/minDistBetweenClusters);
                                for (let positions = calcFromPos; positions > 0; positions--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                    const minSpaceForClusters = numbValue * ((positions-1) * stepValue);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                    if(minSpaceRequired <= availableSpace){
                                        new_pos_max = positions;
                                        break;
                                    }
                                }
                                setShelfDrillingInputsLimits((prevValues) => ({
                                    ...prevValues,
                                    positions_max: new_pos_max,
                                }));
                                if(new_pos_max < 1){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Clusters can't be applied with the current step and no. of clusters`,
                                    }));
                                    return;
                                }else if(positionsValue < shelfDrillingInputsLimits.positions_min){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Min. qty 1`,
                                    }));
                                    return;
                                }else if(positionsValue > new_pos_max){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Max. qty ${new_pos_max}`,
                                    }));
                                    return;
                                }else{
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: null,
                                    }));
                                    pos_error = null;
                                    console.log('all GOOD for POSITIONS at NUMBER OF CLUSTERS input check');
                                }

                                if(dist1_error == null && dist2_error == null && step_error == null && pos_error == null){ //  apply changes to drawing if it's all good
                                    applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                                }
                            }
                        }

                    }else if(shelfDrillingEdge == 'w1-w2'){
                        let dist1Value = parseInt(shelfDrillingInputs.distance1);
                        let dist2Value = parseInt(shelfDrillingInputs.distance2);
                        let stepValue = parseInt(shelfDrillingInputs.step);
                        let positionsValue = parseInt(shelfDrillingInputs.positions);
                        let numbValue = parseInt(shelfDrillingInputs.number_of_clusters);
                        let startingPosY = parseInt(machiningConfig.topRight.y) + minDistanceFromEnd;
                        // check if there is a l1-w1 cut
                        if(machiningConfig.W1P != null && (parseInt(machiningConfig.W1P.y) > parseInt(machiningConfig.topRight.y))){ // if L2P exists and it is bigger than TL
                            startingPosY = parseInt(machiningConfig.W1P).y + minDistanceFromEnd;
                        }
                        let endingPosY = parseInt(machiningConfig.bottomLeft.y) - minDistanceFromEnd;
                        // check if there is a l2-w2 cut
                        if(machiningConfig.W2P != null && (parseInt(machiningConfig.W2P.y) < parseInt(machiningConfig.bottomLeft.y))){ // if L1P exists and it is smaller than BR
                            endingPosY = parseInt(machiningConfig.W2P.y) - minDistanceFromEnd;
                        }
                        // calculate the available space
                        const availableSpace = endingPosY - startingPosY;

                        if(inputField == 'step'){
                            stepValue = sanitizedValue;
                            setShelfDrillingInputs((prevValues) => ({
                                ...prevValues,
                                step: stepValue,
                            }));

                            //  calculate step max
                            const calcFromStep = Math.ceil(availableSpace/positionsValue);
                            for (let step = calcFromStep; step > 0; step--) {
                                const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                const minSpaceForClusters = numbValue * ((positionsValue-1) * step);
                                const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                if(minSpaceRequired <= availableSpace){
                                    new_step_max = step;
                                    break;
                                }
                            }
                            if(new_step_max > 100){
                                new_step_max = 100;
                            }
                            setShelfDrillingInputsLimits((prevValues) => ({
                                ...prevValues,
                                step_max: new_step_max,
                            }));
                            if(new_step_max < shelfDrillingInputsLimits.step_min){  //   if the maximum is smaller than the minimum of 25mm
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    step: `Clusters can't be applied with the current positions and no. of clusters`,
                                }));
                                return;
                            }else if(stepValue < shelfDrillingInputsLimits.step_min){   //  if the step input is smaller than the minimum
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    step: `Min. size 25mm`,
                                }));
                                return;
                            }else if(stepValue > new_step_max){ //  if the step input is bigger than the maximum
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    step: `Max. size 100mm`,
                                }));
                                return;
                            }else{  // if it's all good
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    step: null,
                                }));
                                step_error = null;

                                // calculate for positions max
                                const calcFromPos = Math.ceil(availableSpace/minDistBetweenClusters);
                                for (let positions = calcFromPos; positions > 0; positions--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                    const minSpaceForClusters = numbValue * ((positions-1) * stepValue);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                    if(minSpaceRequired <= availableSpace){
                                        new_pos_max = positions;
                                        break;
                                    }
                                }
                                setShelfDrillingInputsLimits((prevValues) => ({
                                    ...prevValues,
                                    positions_max: new_pos_max,
                                }));
                                if(new_pos_max < 1){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Clusters can't be applied with the current step and no. of clusters`,
                                    }));
                                    return;
                                }else if(positionsValue < shelfDrillingInputsLimits.positions_min){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Min. qty 1`,
                                    }));
                                    return;
                                }else if(positionsValue > new_pos_max){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Max. qty ${new_pos_max}`,
                                    }));
                                    return;
                                }else{
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: null,
                                    }));
                                    pos_error = null;
                                }

                                //  recalculate the number of clusters max
                                const calcFromNumb = Math.ceil(availableSpace/positionsValue);
                                for (let no = calcFromNumb; no > 0; no--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (no - 1);
                                    const minSpaceForClusters = no * ((positionsValue-1) * stepValue);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                
                                    if(minSpaceRequired <= availableSpace){
                                        new_numb_max = no;
                                        break;
                                    }
                                }
                                if(new_numb_max > 0){   //  if there can be placed any clusters
                                    setShelfDrillingInputsLimits((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters_min: 1,
                                        number_of_clusters_max: new_numb_max,
                                    }));
                                    if(numbValue > new_numb_max){   //  if the existing input is bigger than the new max, change the input to be equal to new max
                                        numbValue = new_numb_max;
                                        setShelfDrillingInputs((prevValues) => ({
                                            ...prevValues,
                                            number_of_clusters: numbValue,
                                        }));
                                        setShelfDrillingInputsErrors((prevValues) => ({
                                            ...prevValues,
                                            number_of_clusters: false,
                                        }));
                                        numb_error = false;
                                    }
                                }else{  //  if there cannot be placed any clusters
                                    setShelfDrillingInputsLimits((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters_min: 0,
                                        number_of_clusters_max: new_numb_max,
                                    }));
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters: true,
                                    }));
                                    numb_error = true;
                                    return;
                                }

                                if(dist1_error == null && dist2_error == null && pos_error == null && numb_error == false){ //  apply changes to drawing if it's all good
                                    applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                                }
                            }
                        }else if(inputField == 'positions'){
                            positionsValue = sanitizedValue;
                            setShelfDrillingInputs((prevValues) => ({
                                ...prevValues,
                                positions: positionsValue,
                            }));
                            //  calculate for positions max
                            const calcFromPos = Math.ceil(availableSpace/minDistBetweenClusters);
                            for (let positions = calcFromPos; positions > 0; positions--) {
                                const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                const minSpaceForClusters = numbValue * ((positions-1) * stepValue);
                                const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                if(minSpaceRequired <= availableSpace){
                                    new_pos_max = positions;
                                    break;
                                }
                            }
                            setShelfDrillingInputsLimits((prevValues) => ({
                                ...prevValues,
                                positions_max: new_pos_max,
                            }));
                            if(new_pos_max < 1){    //  if there cannot be placed any clusters with this inputs
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    positions: `Clusters can't be applied with the current step and no. of clusters`,
                                }));
                                return;
                            }else if(positionsValue < shelfDrillingInputsLimits.positions_min){ //  if the input is smaller than the minimum
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    positions: `Min. qty 1`,
                                }));
                                return;
                            }else if(positionsValue > new_pos_max){ // if the input is bigger than the maximum
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    positions: `Max. qty ${new_pos_max}`,
                                }));
                                return;
                            }else{  //  if it's all good
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    positions: null,
                                }));
                                pos_error = null;

                                //  calculate step max
                                const calcFromStep = Math.ceil(availableSpace/positionsValue);
                                for (let step = calcFromStep; step > 0; step--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                    const minSpaceForClusters = numbValue * ((positionsValue-1) * step);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                    if(minSpaceRequired <= availableSpace){
                                        new_step_max = step;
                                        break;
                                    }
                                }
                                if(new_step_max > 100){
                                    new_step_max = 100;
                                }
                                setShelfDrillingInputsLimits((prevValues) => ({
                                    ...prevValues,
                                    step_max: new_step_max,
                                }));
                                if(new_step_max < shelfDrillingInputsLimits.step_min){  //   if the maximum is smaller than the minimum of 25mm
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Clusters can't be applied with the current positions and no. of clusters`,
                                    }));
                                    return;
                                }else if(stepValue < shelfDrillingInputsLimits.step_min){   //  if the step input is smaller than the minimum
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Min. size 25mm`,
                                    }));
                                    return;
                                }else if(stepValue > new_step_max){ //  if the step input is bigger than the maximum
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Max. size 100mm`,
                                    }));
                                    return;
                                }else{  // if it's all good
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: null,
                                    }));
                                    step_error = null;
                                }

                                //  recalculate the number of clusters max
                                const calcFromNumb = Math.ceil(availableSpace/positionsValue);
                                for (let no = calcFromNumb; no > 0; no--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (no - 1);
                                    const minSpaceForClusters = no * ((positionsValue-1) * stepValue);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                
                                    if(minSpaceRequired <= availableSpace){
                                        new_numb_max = no;
                                        break;
                                    }
                                }
                                if(new_numb_max > 0){   //  if there can be placed any clusters
                                    setShelfDrillingInputsLimits((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters_min: 1,
                                        number_of_clusters_max: new_numb_max,
                                    }));
                                    if(numbValue > new_numb_max){   //  if the existing input is bigger than the new max, change the input to be equal to new max
                                        numbValue = new_numb_max;
                                        setShelfDrillingInputs((prevValues) => ({
                                            ...prevValues,
                                            number_of_clusters: numbValue,
                                        }));
                                        setShelfDrillingInputsErrors((prevValues) => ({
                                            ...prevValues,
                                            number_of_clusters: false,
                                        }));
                                        numb_error = false;
                                    }
                                }else{  //  if there cannot be placed any clusters
                                    setShelfDrillingInputsLimits((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters_min: 0,
                                        number_of_clusters_max: new_numb_max,
                                    }));
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        number_of_clusters: true,
                                    }));
                                    numb_error = true;
                                    return;
                                }

                                if(dist1_error == null && dist2_error == null && step_error == null && numb_error == false){ //  apply changes to drawing if it's all good
                                    applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                                }
                            }
                        }else if(inputField == 'number_of_clusters'){
                            console.log('Checking for input field NUMBER OF CLUSTERS');
                            numbValue = sanitizedValue;
                            setShelfDrillingInputs((prevValues) => ({
                                ...prevValues,
                                number_of_clusters: numbValue,
                            }));
                            if(shelfDrillingInputsLimits.number_of_clusters_max < 1){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    number_of_clusters: true,
                                }));
                                numb_error = true;
                            }else if(numbValue < 1){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    number_of_clusters: true,
                                }));
                                numb_error = true;
                            }else if(numbValue > shelfDrillingInputsLimits.number_of_clusters_max){
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    number_of_clusters: true,
                                }));
                                numb_error = true;
                            }else{
                                setShelfDrillingInputsErrors((prevValues) => ({
                                    ...prevValues,
                                    number_of_clusters: false,
                                }));
                                numb_error = false;
                                console.log('all GOOD for NUMBER OF CLUSTERS at NUMBER OF CLUSTERS input check');

                                //  calculate step max
                                const calcFromStep = Math.ceil(availableSpace/positionsValue);
                                for (let step = calcFromStep; step > 0; step--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                    const minSpaceForClusters = numbValue * ((positionsValue-1) * step);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                    if(minSpaceRequired <= availableSpace){
                                        new_step_max = step;
                                        break;
                                    }
                                }
                                if(new_step_max > 100){
                                    new_step_max = 100;
                                }
                                setShelfDrillingInputsLimits((prevValues) => ({
                                    ...prevValues,
                                    step_max: new_step_max,
                                }));
                                if(new_step_max < shelfDrillingInputsLimits.step_min){  //   if the maximum is smaller than the minimum of 25mm
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Clusters can't be applied with the current positions and no. of clusters`,
                                    }));
                                    return;
                                }else if(stepValue < shelfDrillingInputsLimits.step_min){   //  if the step input is smaller than the minimum
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Min. size 25mm`,
                                    }));
                                    return;
                                }else if(stepValue > new_step_max){ //  if the step input is bigger than the maximum
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Max. size 100mm`,
                                    }));
                                    return;
                                }else{  // if it's all good
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: null,
                                    }));
                                    step_error = null;
                                    console.log('all GOOD for STEP at NUMBER OF CLUSTERS input check');
                                }

                                // calculate for positions max
                                const calcFromPos = Math.ceil(availableSpace/minDistBetweenClusters);
                                for (let positions = calcFromPos; positions > 0; positions--) {
                                    const minSpaceBetweenClusters = minDistBetweenClusters * (numbValue - 1);
                                    const minSpaceForClusters = numbValue * ((positions-1) * stepValue);
                                    const minSpaceRequired = minSpaceForClusters + minSpaceBetweenClusters;
                                    if(minSpaceRequired <= availableSpace){
                                        new_pos_max = positions;
                                        break;
                                    }
                                }
                                setShelfDrillingInputsLimits((prevValues) => ({
                                    ...prevValues,
                                    positions_max: new_pos_max,
                                }));
                                if(new_pos_max < 1){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Clusters can't be applied with the current step and no. of clusters`,
                                    }));
                                    return;
                                }else if(positionsValue < shelfDrillingInputsLimits.positions_min){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Min. qty 1`,
                                    }));
                                    return;
                                }else if(positionsValue > new_pos_max){
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: `Max. qty ${new_pos_max}`,
                                    }));
                                    return;
                                }else{
                                    setShelfDrillingInputsErrors((prevValues) => ({
                                        ...prevValues,
                                        step: null,
                                    }));
                                    pos_error = null;
                                    console.log('all GOOD for POSITIONS at NUMBER OF CLUSTERS input check');
                                }

                                if(dist1_error == null && dist2_error == null && step_error == null && pos_error == null){ //  apply changes to drawing if it's all good
                                    applyShelfDrilling(dist1_error, dist2_error, step_error, pos_error, numb_error, dist1Value, dist2Value, stepValue, positionsValue, numbValue);
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    // change shelf drilling face (A / B / Both)
    const changeShelfDrillingFace = (face) =>{
        setShelfDrillingFace(face);
    };

    // function to add shelf drilling to board
    const addShelfDrilling = () =>{
        const minDistanceFromEnd = 50;
        const minDistanceFromEdge = 20;
        const minStep = 25;
        const minDistBetweenClusters = 75;
        if(rowConfig != null && machiningConfig.topLeft != null && machiningConfig.topRight != null && machiningConfig.bottomLeft != null && machiningConfig.bottomRight != null){
            if(shelfDrillingFace == 'A' || shelfDrillingFace == 'Both' || shelfDrillingFace == 'B'){
                if(shelfDrillingEdge == 'l1-l2'){
                    // check if the edge length is big enough for clusters to be even placed
                    let startingPosX = parseInt(machiningConfig.topLeft.x) + minDistanceFromEnd;
                    // check if there is a l2-w1 cut
                    if(machiningConfig.L2P != null && (parseInt(machiningConfig.L2P.x) > parseInt(machiningConfig.topLeft.x))){ // if L2P exists and it is bigger than TL
                        startingPosX = parseInt(machiningConfig.L2P.x) + minDistanceFromEnd;
                    }
                    let endingPosX = parseInt(machiningConfig.bottomRight.x) - minDistanceFromEnd;
                    // check if there is a l1-w2 cut
                    if(machiningConfig.L1P != null && (parseInt(machiningConfig.L1P.x) < parseInt(machiningConfig.bottomRight.x))){ // if L1P exists and it is smaller than BR
                        endingPosX = parseInt(machiningConfig.L1P.x) - minDistanceFromEnd;
                    }
                    // calculate the available space
                    const availableSpace = endingPosX - startingPosX;

                    // calculate for step (get the positions and number of clusters and calculate the maximum | if maximum < 25 => error: "Clusters can't be placed with the current positions and number of clusters inputs");
                    let step_max = parseInt(shelfDrillingInputsLimits.step_max);
                    if(shelfDrillingInputsErrors.positions == null && shelfDrillingInputsErrors.number_of_clusters == false){
                        console.log('CALCULATING INITIAL STEP LIMITS');
                        // check for the max cluster step with the current positions and number of clusters
                        const length = availableSpace;
                        const number_of_clusters = parseInt(shelfDrillingInputs.number_of_clusters);
                        const positions = parseInt(shelfDrillingInputs.positions);
                        let new_step_max = Math.floor((length - (number_of_clusters-1)*minDistBetweenClusters) / (number_of_clusters*positions));
                        if(new_step_max > 100){
                            new_step_max = 100;
                        }
                        step_max = new_step_max;
                        const new_limits = shelfDrillingInputsLimits;
                        new_limits.step_max = new_step_max;
                        setShelfDrillingInputsLimits(new_limits);
                    }else{
                        console.log(`shelfDrillingInputsErrors.positions = ${shelfDrillingInputsErrors.positions} and shelfDrillingInputsErrors.number_of_clusters = ${shelfDrillingInputsErrors.number_of_clusters}`);
                    }
                    if(shelfDrillingInputs.step < shelfDrillingInputsLimits.step_min){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            step: 'Min. size 25mm',
                        }));
                    }else if(step_max < 25){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            step: `Clusters can't be placed with the current positions and number of clusters inputs`,
                        }));
                    }else if(shelfDrillingInputs.step > step_max && step_max > 25){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            step: `Max. size ${shelfDrillingInputsLimits.step_max}mm due to ${shelfDrillingInputs.positions} positions`,
                        }));
                    }else{
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            step: null,
                        }));
                    }
                    // calculate for positions (get the step and number of clusters and calculate the maximum | if maximum < 1 => error: 'Clusters can't be placed with the current step and number of clusters inputs");
                    let max_positions = shelfDrillingInputsLimits.positions_max;
                    if(shelfDrillingInputs.step >= minStep && shelfDrillingInputs.step <= shelfDrillingInputsLimits.step_max && shelfDrillingInputs.number_of_clusters > 0){
                        console.log('CALCULATING INITIAL POSITIONS LIMITS');
                        // check for the max cluster step with the current positions and number of clusters
                        const length = availableSpace;
                        const number_of_clusters = parseInt(shelfDrillingInputs.number_of_clusters);
                        const step = parseInt(shelfDrillingInputs.step);
                        const new_max_positions = Math.floor((length - (number_of_clusters-1)*minDistBetweenClusters) / (step * (number_of_clusters)));
                        max_positions = new_max_positions;
                        const new_limits = shelfDrillingInputsLimits;
                        new_limits.positions_max = new_max_positions;
                        setShelfDrillingInputsLimits(new_limits);
                    }
                    if(shelfDrillingInputs.positions < shelfDrillingInputsLimits.positions_min){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            positions: 'Min. qty 1',
                        }));
                    }else if(max_positions < 1){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            positions: `Clusters can't be placed with the current step and number of clusters inputs`,
                        }));
                    }else if(shelfDrillingInputs.positions > max_positions){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            positions: `Max. qty ${max_positions}`,
                        }));
                    }else{
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            positions: null,
                        }));
                    }
                    // calculate for number of clusters (get the step and positions and calculate the maximum | if maximum < 1 => error: Max clusters = 0)
                    let max_clusters = shelfDrillingInputsLimits.number_of_clusters_max;
                    if(shelfDrillingInputs.step >= minStep && shelfDrillingInputs.step <= shelfDrillingInputsLimits.step_max && shelfDrillingInputs.positions >= shelfDrillingInputsLimits.positions_min && shelfDrillingInputs.positions <= shelfDrillingInputsLimits.positions_max){
                        console.log('CALCULATING INITIAL NUMBER OF CLUSTERS LIMITS');
                        // check for the max cluster step with the current positions and number of clusters
                        const length = availableSpace;
                        const step = parseInt(shelfDrillingInputs.step);
                        const positions = parseInt(shelfDrillingInputs.positions);
                        const new_max_clusters = Math.floor((length + minDistBetweenClusters) / (step * (positions-1) + minDistBetweenClusters));
                        max_clusters = new_max_clusters;
                        const new_limits = shelfDrillingInputsLimits;
                        new_limits.number_of_clusters_max = new_max_clusters;
                        if(new_max_clusters < 1){
                            new_limits.number_of_clusters_min = 0;
                        }else{
                            new_limits.number_of_clusters_min = 1;
                        }
                        setShelfDrillingInputsLimits(new_limits);
                    }
                    if(max_clusters < 0){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            number_of_clusters: true,
                        }));
                    }else if(shelfDrillingInputs.number_of_clusters < 1 || shelfDrillingInputs.number_of_clusters > max_clusters){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            number_of_clusters: true,
                        }));
                    }else{
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            number_of_clusters: false,
                        }));
                    }

                }else if(shelfDrillingEdge == 'w1-w2'){
                    // check if the edge length is big enough for clusters to be even placed
                    let startingPosY = parseInt(machiningConfig.topRight.y) + minDistanceFromEnd;
                    // check if there is a l1-w1 cut
                    if(machiningConfig.W1P != null && (parseInt(machiningConfig.W1P.y) > parseInt(machiningConfig.topRight.y))){ // if L2P exists and it is bigger than TL
                        startingPosY = parseInt(machiningConfig.W1P).y + minDistanceFromEnd;
                    }
                    let endingPosY = parseInt(machiningConfig.bottomLeft.y) - minDistanceFromEnd;
                    // check if there is a l2-w2 cut
                    if(machiningConfig.W2P != null && (parseInt(machiningConfig.W2P.y) < parseInt(machiningConfig.bottomLeft.y))){ // if L1P exists and it is smaller than BR
                        endingPosY = parseInt(machiningConfig.W2P.y) - minDistanceFromEnd;
                    }
                    // calculate the available space
                    const availableSpace = endingPosY - startingPosY;

                    // calculate for step (get the positions and number of clusters and calculate the maximum | if maximum < 25 => error: "Clusters can't be placed with the current positions and number of clusters inputs");
                    let step_max = parseInt(shelfDrillingInputsLimits.step_max);
                    if(shelfDrillingInputsErrors.positions == null && shelfDrillingInputsErrors.number_of_clusters == false){
                        console.log('CALCULATING INITIAL STEP LIMITS');
                        // check for the max cluster step with the current positions and number of clusters
                        const length = availableSpace;
                        const number_of_clusters = parseInt(shelfDrillingInputs.number_of_clusters);
                        const positions = parseInt(shelfDrillingInputs.positions);
                        let new_step_max = Math.floor((length - (number_of_clusters-1)*minDistBetweenClusters) / (number_of_clusters*positions));
                        if(new_step_max > 100){
                            new_step_max = 100;
                        }
                        step_max = new_step_max;
                        const new_limits = shelfDrillingInputsLimits;
                        new_limits.step_max = new_step_max;
                        setShelfDrillingInputsLimits(new_limits);
                    }else{
                        console.log(`shelfDrillingInputsErrors.positions = ${shelfDrillingInputsErrors.positions} and shelfDrillingInputsErrors.number_of_clusters = ${shelfDrillingInputsErrors.number_of_clusters}`);
                    }
                    if(shelfDrillingInputs.step < shelfDrillingInputsLimits.step_min){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            step: 'Min. size 25mm',
                        }));
                    }else if(step_max < 25){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            step: `Clusters can't be placed with the current positions and number of clusters inputs`,
                        }));
                    }else if(shelfDrillingInputs.step > step_max && step_max > 25){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            step: `Max. size ${shelfDrillingInputsLimits.step_max}mm due to ${shelfDrillingInputs.positions} positions`,
                        }));
                    }else{
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            step: null,
                        }));
                    }
                    // calculate for positions (get the step and number of clusters and calculate the maximum | if maximum < 1 => error: 'Clusters can't be placed with the current step and number of clusters inputs");
                    let max_positions = shelfDrillingInputsLimits.positions_max;
                    if(shelfDrillingInputs.step >= minStep && shelfDrillingInputs.step <= shelfDrillingInputsLimits.step_max && shelfDrillingInputs.number_of_clusters > 0){
                        console.log('CALCULATING INITIAL POSITIONS LIMITS');
                        // check for the max cluster step with the current positions and number of clusters
                        const length = availableSpace;
                        const number_of_clusters = parseInt(shelfDrillingInputs.number_of_clusters);
                        const step = parseInt(shelfDrillingInputs.step);
                        const new_max_positions = Math.floor((length - (number_of_clusters-1)*minDistBetweenClusters) / (step * (number_of_clusters)));
                        max_positions = new_max_positions;
                        const new_limits = shelfDrillingInputsLimits;
                        new_limits.positions_max = new_max_positions;
                        setShelfDrillingInputsLimits(new_limits);
                    }
                    if(shelfDrillingInputs.positions < shelfDrillingInputsLimits.positions_min){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            positions: 'Min. qty 1',
                        }));
                    }else if(max_positions < 1){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            positions: `Clusters can't be placed with the current step and number of clusters inputs`,
                        }));
                    }else if(shelfDrillingInputs.positions > max_positions){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            positions: `Max. qty ${max_positions}`,
                        }));
                    }else{
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            positions: null,
                        }));
                    }
                    // calculate for number of clusters (get the step and positions and calculate the maximum | if maximum < 1 => error: Max clusters = 0)
                    let max_clusters = shelfDrillingInputsLimits.number_of_clusters_max;
                    if(shelfDrillingInputs.step >= minStep && shelfDrillingInputs.step <= shelfDrillingInputsLimits.step_max && shelfDrillingInputs.positions >= shelfDrillingInputsLimits.positions_min && shelfDrillingInputs.positions <= shelfDrillingInputsLimits.positions_max){
                        console.log('CALCULATING INITIAL NUMBER OF CLUSTERS LIMITS');
                        // check for the max cluster step with the current positions and number of clusters
                        const length = availableSpace;
                        const step = parseInt(shelfDrillingInputs.step);
                        const positions = parseInt(shelfDrillingInputs.positions);
                        const new_max_clusters = Math.floor((length + minDistBetweenClusters) / (step * (positions-1) + minDistBetweenClusters));
                        max_clusters = new_max_clusters;
                        const new_limits = shelfDrillingInputsLimits;
                        new_limits.number_of_clusters_max = new_max_clusters;
                        if(new_max_clusters < 1){
                            new_limits.number_of_clusters_min = 0;
                        }else{
                            new_limits.number_of_clusters_min = 1;
                        }
                        setShelfDrillingInputsLimits(new_limits);
                    }
                    if(max_clusters < 0){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            number_of_clusters: true,
                        }));
                    }else if(shelfDrillingInputs.number_of_clusters < 1 || shelfDrillingInputs.number_of_clusters > max_clusters){
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            number_of_clusters: true,
                        }));
                    }else{
                        setShelfDrillingInputsErrors((prevValues) => ({
                            ...prevValues,
                            number_of_clusters: false,
                        }));
                    }
                }
            }
            applyShelfDrilling(shelfDrillingInputsErrors.distance1, shelfDrillingInputsErrors.distance2, shelfDrillingInputsErrors.step, shelfDrillingInputsErrors.positions, shelfDrillingInputsErrors.number_of_clusters, shelfDrillingInputs.distance1, shelfDrillingInputs.distance2, shelfDrillingInputs.step, shelfDrillingInputs.positions, shelfDrillingInputs.number_of_clusters);
        }
    }

    // called when the shelf apply button is pressed
    const applyShelfDrilling = (dist1Error, dist2Error, stepError, positionsError, numberOfClustersError, dist1Value, dist2Value, stepValue, posValue, numbValue) =>{
        const minDistanceFromEnd = 50;
        const minDistanceFromEdge = 20;
        const minStep = 25;
        const minDistBetweenClusters = 75;
        if(dist1Error == null && dist2Error == null && stepError == null && positionsError == null && numberOfClustersError == false){
            if(shelfDrillingFace == 'A' || shelfDrillingFace == 'Both' || shelfDrillingFace == 'B'){
                if(shelfDrillingEdge == 'l1-l2'){
                    const number_of_clusters = parseInt(numbValue);
                    // check for positions
                    let startingPosX = parseInt(machiningConfig.topLeft.x) + minDistanceFromEnd;
                    // check if there is a l2-w1 cut
                    if(machiningConfig.L2P != null && (parseInt(machiningConfig.L2P.x) > parseInt(machiningConfig.topLeft.x))){ // if L2P exists and it is bigger than TL
                        startingPosX = parseInt(machiningConfig.L2P.x) + minDistanceFromEnd;
                    }
                    let endingPosX = parseInt(machiningConfig.bottomRight.x) - minDistanceFromEnd;
                    // check if there is a l1-w2 cut
                    if(machiningConfig.L1P != null && (parseInt(machiningConfig.L1P.x) < parseInt(machiningConfig.bottomRight.x))){ // if L1P exists and it is smaller than BR
                        endingPosX = parseInt(machiningConfig.L1P.x) - minDistanceFromEnd;
                    }
                    // calculate the available space
                    const availableSpace = endingPosX - startingPosX;
                    const cluster_size = parseInt(stepValue) * (parseInt(posValue - 1));
                    const last_cluster_pos = endingPosX - cluster_size;
                    const first_cluster_pos = startingPosX;
                    const space_left = last_cluster_pos - (first_cluster_pos + cluster_size);
                    const spacing = Math.floor((space_left - ((number_of_clusters - 2) * cluster_size)) / ((number_of_clusters - 1))); 
                    let clustersPosArray = []; // the clusters positions array
                    clustersPosArray = [...clustersPosArray, first_cluster_pos]; // add the first cluster position
                    //  add all clusters positions from the second till the last
                    for(let i = 1; i<number_of_clusters; i++){
                        if(i == (number_of_clusters - 1)){ // if it's the last hole
                            clustersPosArray = [...clustersPosArray, last_cluster_pos];
                        }else{ //   if it's not the last hole
                            const prev_cluster_pos = clustersPosArray[i-1];
                            const this_cluster_pos = prev_cluster_pos + cluster_size + spacing;
                            clustersPosArray = [...clustersPosArray, this_cluster_pos];
                        }
                    }

                    let clustersPosLimitsArray = [];
                    let clustersPosInputsErrorsArray = [];
                    let firstClusterLimits = {
                        min: 50,
                        max: parseInt(rowConfig.materialLength) - cluster_size - minDistanceFromEnd
                    }
                    if(number_of_clusters > 1){
                        firstClusterLimits = {
                            min: 50,
                            max: parseInt(clustersPosArray[1]) - minDistBetweenClusters - cluster_size
                        }
                    }
                    clustersPosLimitsArray = [...clustersPosLimitsArray, firstClusterLimits];
                    clustersPosInputsErrorsArray = [...clustersPosInputsErrorsArray, false];
                    //  add all clusters limits
                    for(let i = 1; i < number_of_clusters; i++){
                        if(i == number_of_clusters - 1){  // if it's the last cluster
                            const clusterLimits = {
                                min: parseInt(clustersPosArray[i-1]) + minDistBetweenClusters + cluster_size,
                                max: parseInt(rowConfig.materialLength) - 50 - cluster_size
                            };
                            clustersPosLimitsArray = [...clustersPosLimitsArray, clusterLimits];
                            clustersPosInputsErrorsArray = [...clustersPosInputsErrorsArray, false];
                        }else{  // if it's any other cluster
                            const clusterLimits = {
                                min: parseInt(clustersPosArray[i-1]) + minDistBetweenClusters + cluster_size,
                                max: parseInt(clustersPosArray[i+1]) - minDistBetweenClusters - cluster_size
                            };
                            clustersPosLimitsArray = [...clustersPosLimitsArray, clusterLimits];
                            clustersPosInputsErrorsArray = [...clustersPosInputsErrorsArray, false];
                        }
                    }
                    console.log('clustersPosLimitsArray:');
                    console.log(clustersPosLimitsArray);

                    console.log('clustersPosArray:');
                    console.log(clustersPosArray);
                    const clusters_edge = shelfDrillingEdge;
                    const clusters_face = shelfDrillingFace;
                    const clusters_distance1 = parseInt(dist1Value);
                    const clusters_distance2 = parseInt(dist2Value);
                    const clusters_step = parseInt(stepValue);
                    const clusters_positions = parseInt(posValue);


                    setClustersPosInputs(clustersPosArray);
                    setClusterPosLimits(clustersPosLimitsArray);
                    setclustersPosInputsErrors(clustersPosInputsErrorsArray);
                    //  apply shelf drilling to the machining config
                    setMachiningConfig((prevValues) => ({
                        ...prevValues,
                        clusters: number_of_clusters,
                        clustersPos: clustersPosArray,
                        clustersStep: clusters_step,
                        clustersPositions: clusters_positions,
                        clustersEdge: clusters_edge,
                        clustersFace: clusters_face,
                        clustersDistance1: clusters_distance1,
                        clustersDistance2: clusters_distance2,
                        clustersPosLimits: clustersPosLimitsArray,
                        clustersType: shelfType
                    }));

                    //  calculate scaled positions for drawingProps
                    const scalePercentageX = drawingProps.drawingLength / rowConfig.materialLength;
                    const scalePercentageY = drawingProps.drawingWidth / rowConfig.width;

                    let scaledStep = clusters_step * scalePercentageX;
                    let scaledClustersPosArray = [];
                    scaledClustersPosArray = [...scaledClustersPosArray, {x: clustersPosArray[0] * scalePercentageX + drawingPadding, y1: drawingPadding + clusters_distance1 * scalePercentageY, y2: (parseInt(rowConfig.width) - clusters_distance2) * scalePercentageY + drawingPadding}];
                    for(let i = 1; i < number_of_clusters; i++){
                        const this_cluster_scaled_pos = {x: clustersPosArray[i] * scalePercentageX + drawingPadding, y1: drawingPadding + clusters_distance1 * scalePercentageY, y2: (parseInt(rowConfig.width) - clusters_distance2) * scalePercentageY + drawingPadding};
                        scaledClustersPosArray = [...scaledClustersPosArray, this_cluster_scaled_pos];
                    }
                    console.log('scaledClustersPosArray:');
                    console.log(scaledClustersPosArray);
                    setDrawingProps((prevValues) => ({
                        ...prevValues,
                        clustersHoles: scaledClustersPosArray,
                        clusterStep: scaledStep,
                    }));

                }else if(shelfDrillingEdge == 'w1-w2'){
                    const number_of_clusters = parseInt(numbValue);
                    // check for positions
                    let startingPosY = parseInt(machiningConfig.topRight.y) + minDistanceFromEnd;
                    // check if there is a l1-w1 cut
                    if(machiningConfig.W1P != null && (parseInt(machiningConfig.W1P.y) > parseInt(machiningConfig.topRight.y))){ // if L2P exists and it is bigger than TL
                        startingPosY = parseInt(machiningConfig.W1P).y + minDistanceFromEnd;
                    }
                    let endingPosY = parseInt(machiningConfig.bottomLeft.y) - minDistanceFromEnd;
                    // check if there is a l2-w2 cut
                    if(machiningConfig.W2P != null && (parseInt(machiningConfig.W2P.y) < parseInt(machiningConfig.bottomLeft.y))){ // if L1P exists and it is smaller than BR
                        endingPosY = parseInt(machiningConfig.W2P.y) - minDistanceFromEnd;
                    }
                    // calculate the available space
                    const availableSpace = endingPosY - startingPosY;
                    const cluster_size = parseInt(stepValue) * (parseInt(posValue - 1));
                    const last_cluster_pos = endingPosY - cluster_size;
                    const first_cluster_pos = startingPosY;
                    const space_left = last_cluster_pos - (first_cluster_pos + cluster_size);
                    const spacing = Math.floor((space_left - ((number_of_clusters - 2) * cluster_size)) / ((number_of_clusters - 1))); 
                    let clustersPosArray = []; // the clusters positions array
                    clustersPosArray = [...clustersPosArray, first_cluster_pos]; // add the first cluster position
                    //  add all clusters positions from the second till the last
                    for(let i = 1; i<number_of_clusters; i++){
                        if(i == (number_of_clusters - 1)){ // if it's the last hole
                            clustersPosArray = [...clustersPosArray, last_cluster_pos];
                        }else{ //   if it's not the last hole
                            const prev_cluster_pos = clustersPosArray[i-1];
                            const this_cluster_pos = prev_cluster_pos + cluster_size + spacing;
                            clustersPosArray = [...clustersPosArray, this_cluster_pos];
                        }
                    }
                    let clustersPosLimitsArray = [];
                    let clustersPosInputsErrorsArray = [];
                    let firstClusterLimits = {
                        min: 50,
                        max: parseInt(rowConfig.width) - minDistanceFromEnd - cluster_size
                    }
                    if(number_of_clusters > 1){
                        firstClusterLimits = {
                            min: 50,
                            max: parseInt(clustersPosArray[1]) - minDistBetweenClusters - cluster_size
                        }
                    }
                    clustersPosLimitsArray = [...clustersPosLimitsArray, firstClusterLimits];
                    clustersPosInputsErrorsArray = [...clustersPosInputsErrorsArray, false];
                    //  add all clusters limits
                    for(let i = 1; i < number_of_clusters; i++){
                        if(i == number_of_clusters - 1){  // if it's the last cluster
                            const clusterLimits = {
                                min: parseInt(clustersPosArray[i-1]) + minDistBetweenClusters + cluster_size,
                                max: parseInt(rowConfig.width) - 50 - cluster_size
                            };
                            clustersPosLimitsArray = [...clustersPosLimitsArray, clusterLimits];
                            clustersPosInputsErrorsArray = [...clustersPosInputsErrorsArray, false];
                        }else{  // if it's any other cluster
                            const clusterLimits = {
                                min: parseInt(clustersPosArray[i-1]) + minDistBetweenClusters + cluster_size,
                                max: parseInt(clustersPosArray[i+1]) - minDistBetweenClusters - cluster_size
                            };
                            clustersPosLimitsArray = [...clustersPosLimitsArray, clusterLimits];
                            clustersPosInputsErrorsArray = [...clustersPosInputsErrorsArray, false];
                        }
                    }
                    console.log('clustersPosArray:');
                    console.log(clustersPosArray);
                    const clusters_edge = shelfDrillingEdge;
                    const clusters_face = shelfDrillingFace;
                    const clusters_distance1 = parseInt(dist1Value);
                    const clusters_distance2 = parseInt(dist2Value);
                    const clusters_step = parseInt(stepValue);
                    const clusters_positions = parseInt(posValue);
                    
                    setClustersPosInputs(clustersPosArray);
                    setClusterPosLimits(clustersPosLimitsArray);
                    setclustersPosInputsErrors(clustersPosInputsErrorsArray);
                    //  apply shelf drilling to the machining config
                    setMachiningConfig((prevValues) => ({
                        ...prevValues,
                        clusters: number_of_clusters,
                        clustersPos: clustersPosArray,
                        clustersStep: clusters_step,
                        clustersPositions: clusters_positions,
                        clustersEdge: clusters_edge,
                        clustersFace: clusters_face,
                        clustersDistance1: clusters_distance1,
                        clustersDistance2: clusters_distance2,
                        clustersPosLimits: clustersPosLimitsArray,
                        clustersType: shelfType,
                    }));

                    //  calculate scaled positions for drawingProps
                    const scalePercentageX = drawingProps.drawingLength / rowConfig.materialLength;
                    const scalePercentageY = drawingProps.drawingWidth / rowConfig.width;

                    let scaledStep = clusters_step * scalePercentageY;
                    let scaledClustersPosArray = [];
                    scaledClustersPosArray = [...scaledClustersPosArray, {x1: drawingPadding + clusters_distance1 * scalePercentageX, y: clustersPosArray[0] * scalePercentageY + drawingPadding, x2: (parseInt(rowConfig.materialLength) - clusters_distance2) * scalePercentageX + drawingPadding}];
                    for(let i = 1; i < number_of_clusters; i++){
                        const this_cluster_scaled_pos = {x1: drawingPadding + clusters_distance1 * scalePercentageX, y: clustersPosArray[i] * scalePercentageY + drawingPadding, x2: (parseInt(rowConfig.materialLength) - clusters_distance2) * scalePercentageX + drawingPadding};
                        scaledClustersPosArray = [...scaledClustersPosArray, this_cluster_scaled_pos];
                    }
                    console.log('scaledClustersPosArray:');
                    console.log(scaledClustersPosArray);
                    setDrawingProps((prevValues) => ({
                        ...prevValues,
                        clustersHoles: scaledClustersPosArray,
                        clusterStep: scaledStep,
                    }));
                }
            }
        }
    };

    //  update cluster position based on input
    const updateClusterPositon = (index, event) =>{
        const newValue = event.target.value;
        const minDistanceFromEnd = 50;
        const minDistanceFromEdge = 20;
        const minStep = 25;
        const minDistBetweenClusters = 75;
        if(shelfDrillingEdge == 'l1-l2'){
            const sanitizedValue = newValue.replace(/[^\d]/g, '');
            //  change the input state
            const tempClustersPosInputs = [...clustersPosInputs];
            tempClustersPosInputs[index] = sanitizedValue;
            setClustersPosInputs(tempClustersPosInputs);
            //  check the new position input if it's within the limits
            const min_limit = parseInt(machiningConfig.clustersPosLimits[index].min);
            const max_limit = parseInt(machiningConfig.clustersPosLimits[index].max);
            if(sanitizedValue >= min_limit && sanitizedValue <= max_limit){
                //  dont show error anymore
                const tempClustersPosInputsErrors = [...clustersPosInputsErrors];
                tempClustersPosInputsErrors[index] = false;
                setclustersPosInputsErrors(tempClustersPosInputsErrors);

                //  add the changes to the machiningConfig
                const tempClustersPos = [...machiningConfig.clustersPos];
                tempClustersPos[index] = sanitizedValue;
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    clustersPos: tempClustersPos,
                }));

                //  change the limits of the prev and next cluster
                const scalePercentageX = drawingProps.drawingLength / rowConfig.materialLength;
                const scalePercentageY = drawingProps.drawingWidth / rowConfig.width;
                const tempScaledClustersPos = [...drawingProps.clustersHoles];
                tempScaledClustersPos[index].x = sanitizedValue * scalePercentageX + drawingPadding;
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    clustersHoles: tempScaledClustersPos,
                }));
            }else{
                //  show error
                console.log(`Input for cluster ${index} not good, min: ${min_limit}; max: ${max_limit}`);
                const tempClustersPosInputsErrors = [...clustersPosInputsErrors];
                tempClustersPosInputsErrors[index] = true;
                setclustersPosInputsErrors(tempClustersPosInputsErrors);
            }
        }else if(shelfDrillingEdge == 'w1-w2'){
            const sanitizedValue = newValue.replace(/[^\d]/g, '');
            //  change the input state
            const tempClustersPosInputs = [...clustersPosInputs];
            tempClustersPosInputs[index] = sanitizedValue;
            setClustersPosInputs(tempClustersPosInputs);
            //  check the new position input if it's within the limits
            const min_limit = parseInt(machiningConfig.clustersPosLimits[index].min);
            const max_limit = parseInt(machiningConfig.clustersPosLimits[index].max);
            if(sanitizedValue >= min_limit && sanitizedValue <= max_limit){
                //  dont show error anymore
                const tempClustersPosInputsErrors = [...clustersPosInputsErrors];
                tempClustersPosInputsErrors[index] = false;
                setclustersPosInputsErrors(tempClustersPosInputsErrors);

                //  add the changes to the machiningConfig
                const tempClustersPos = [...machiningConfig.clustersPos];
                tempClustersPos[index] = sanitizedValue;
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    clustersPos: tempClustersPos,
                }));

                //  change the limits of the prev and next cluster
                const scalePercentageX = drawingProps.drawingLength / rowConfig.materialLength;
                const scalePercentageY = drawingProps.drawingWidth / rowConfig.width;
                const tempScaledClustersPos = [...drawingProps.clustersHoles];
                tempScaledClustersPos[index].y = sanitizedValue * scalePercentageY + drawingPadding;
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    clustersHoles: tempScaledClustersPos,
                }));
            }else{
                //  show error
                const tempClustersPosInputsErrors = [...clustersPosInputsErrors];
                tempClustersPosInputsErrors[index] = true;
                setclustersPosInputsErrors(tempClustersPosInputsErrors);
            }
        }
    }

    useEffect(() => {
        if(machiningConfig != null && machiningConfig.clusters != null && machiningConfig.clustersStep != null && machiningConfig.clustersPositions != null){
            updateClustersPosLimits();
        }
    }, [machiningConfig?.clustersPos]);

    //  update each cluster position limits
    const updateClustersPosLimits = () =>{
        const minDistanceFromEnd = 50;
        const minDistanceFromEdge = 20;
        const minStep = 25;
        const minDistBetweenClusters = 75;
        if(shelfDrillingEdge == 'l1-l2'){
            const number_of_clusters = parseInt(machiningConfig.clusters);
            const cluster_size = parseInt(machiningConfig.clustersStep) * (parseInt(machiningConfig.clustersPositions - 1));
            let clustersPosLimitsArray = [];
            let firstClusterLimits = {
                min: 50,
                max: parseInt(rowConfig.materialLength) - cluster_size - minDistanceFromEnd
            }
            if(number_of_clusters > 1){
                firstClusterLimits = {
                    min: 50,
                    max: parseInt(machiningConfig.clustersPos[1]) - minDistBetweenClusters - cluster_size
                }
            }
            clustersPosLimitsArray = [...clustersPosLimitsArray, firstClusterLimits];
            //  add all clusters limits
            for(let i = 1; i < number_of_clusters; i++){
                if(i == number_of_clusters - 1){  // if it's the last cluster
                    const clusterLimits = {
                        min: parseInt(machiningConfig.clustersPos[i-1]) + minDistBetweenClusters + cluster_size,
                        max: parseInt(rowConfig.materialLength) - 50 - cluster_size
                    };
                    clustersPosLimitsArray = [...clustersPosLimitsArray, clusterLimits];
                }else{  // if it's any other cluster
                    const clusterLimits = {
                        min: parseInt(machiningConfig.clustersPos[i-1]) + minDistBetweenClusters + cluster_size,
                        max: parseInt(machiningConfig.clustersPos[i+1]) - minDistBetweenClusters - cluster_size
                    };
                    clustersPosLimitsArray = [...clustersPosLimitsArray, clusterLimits];
                }
            }
            setClusterPosLimits(clustersPosLimitsArray);
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                clustersPosLimits: clustersPosLimitsArray,
            }));
        }else if(shelfDrillingEdge == 'w1-w2'){
            const number_of_clusters = parseInt(machiningConfig.clusters);
            const cluster_size = parseInt(machiningConfig.clustersStep) * (parseInt(machiningConfig.clustersPositions - 1));
            let clustersPosLimitsArray = [];
            let firstClusterLimits = {
                min: 50,
                max: parseInt(rowConfig.width) - cluster_size - minDistanceFromEnd
            }
            if(number_of_clusters > 1){
                firstClusterLimits = {
                    min: 50,
                    max: parseInt(machiningConfig.clustersPos[1]) - minDistBetweenClusters - cluster_size
                }
            }
            clustersPosLimitsArray = [...clustersPosLimitsArray, firstClusterLimits];
            //  add all clusters limits
            for(let i = 1; i < number_of_clusters; i++){
                if(i == number_of_clusters - 1){  // if it's the last cluster
                    const clusterLimits = {
                        min: parseInt(machiningConfig.clustersPos[i-1]) + minDistBetweenClusters + cluster_size,
                        max: parseInt(rowConfig.width) - 50 - cluster_size
                    };
                    clustersPosLimitsArray = [...clustersPosLimitsArray, clusterLimits];
                }else{  // if it's any other cluster
                    const clusterLimits = {
                        min: parseInt(machiningConfig.clustersPos[i-1]) + minDistBetweenClusters + cluster_size,
                        max: parseInt(machiningConfig.clustersPos[i+1]) - minDistBetweenClusters - cluster_size
                    };
                    clustersPosLimitsArray = [...clustersPosLimitsArray, clusterLimits];
                }
            }
            setClusterPosLimits(clustersPosLimitsArray);
            setMachiningConfig((prevValues) => ({
                ...prevValues,
                clustersPosLimits: clustersPosLimitsArray,
            }));
        }
    }

    // remove shelf drilling
    const removeShelfDrilling = () =>{
        setShelfDrillingFace('B');
        setMachiningConfig((prevValues) => ({
            ...prevValues,
            clusters: null,
            clustersPos: [],
            clustersStep: null,
            clustersPositions: null,
            clustersEdge: null,
            clustersFace: null,
            clustersDistance1: null,
            clustersDistance2: null,
            clustersType: null, 
        }));
        setDrawingProps((prevValues) => ({
            ...prevValues,
            clustersHoles: [],
            clusterStep: null,
        }));
        setShelfDrillingInputs((prevValues) => ({
            ...prevValues,
            distance1: 50,
            distance2: 50,
            step: 32,
            positions: 2,
            number_of_clusters: 1,
        }));
        setShelfDrillingInputsLimits((prevValues) => ({
            ...prevValues,
            distance1_min: 20,
            distance1_max: null,
            distance2_min: 20,
            distance2_max: null,
            step_min: 25,
            step_max: null,
            positions_min: 1,
            positions_max: null,
            number_of_clusters_min: 1,
            number_of_clusters_max: null
        }));
        setShelfDrillingInputsErrors((prevValues) => ({
            ...prevValues,
            distance1: null,
            distance2: null,
            step: null,
            positions: null,
            number_of_clusters: false,
        }));
    };

    //  clusters input active functions
    const [activeClustersInput, setActiveClustersInput] = useState(null);
    const handleActiveClusterInput = (inputIndex) => {
        console.log('Active cluster input: ' + inputIndex);
        setActiveClustersInput(inputIndex);
    };
    const handleBlurClusterInput = () => {
        console.log('No cluster active');
        setActiveClustersInput(null);
    };


// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| CLOSE & SAVE MACHINING CONFIG CHANGES|-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const closeAndSaveMachiningConfig = () => {
        const savedMachiningConfig = machiningConfig;
        // save all about hinge drilling
        savedMachiningConfig.hingeHolesPosLimits = hingeDrillingPosLimits;
        savedMachiningConfig.clustersPosLimits = clusterPosLimits;
        savedMachiningConfig.shelfDrillingInputsLimits = shelfDrillingInputsLimits; 
        savedMachiningConfig.clustersType = shelfType;
        updateMachiningConfig(savedMachiningConfig, drawingProps);
        closePopup();
    }




    return (
        <div className='machiningPopup' onClick={handleContainerClick}>
            <div className='machiningPopupContainer'>
                <div className='machiningPopupContent'>
                    <div className='machiningPopupCloseBtn' onClick={() => closeAndSaveMachiningConfig()}>
                        <FiX />
                        <p>Save & close</p>
                    </div>
                    <div className='machiningPopupControlContainer'>
                        <div className='machiningPopupSelectionDropdown'>
                            <div className='machiningPopupSelectionDropdownTop' style={{cursor: 'pointer'}} onClick={() => toggleSelectionDropdown()}>
                                <div className='machiningPopupSelectionDropdownTopValue'>
                                    <p>{selectionValue}</p>
                                </div>
                                <FiChevronDown />
                            </div>
                            <div className='machiningPopupSelectionDropdownMenu' style={{display: selectionDropdownOpen === true ? 'flex' : 'none'}}>
                                {selectionsAvailable.map((brand, index) => (
                                    <div key={index} className='machiningPopupSelectionDropdownMenuItem' onClick={() => handleSelectionClicked(index)}><p>{brand.value}</p></div>
                                ))}
                            </div>
                        </div>
                        
                        {/* ANGLE CUT */}
                        {selectionConfigValue === 'angleCut' ? (
                            <div className='machiningPopupAngleCutSection'>
                                <div className='machiningPopupAngleCutSidesContainer'>
                                    <p>Angle cut between:</p>
                                    <div className='machiningPopupAngleCutSidesList'>
                                        <div className='machiningPopupAngleCutSide' 
                                            style={{background: angleCutSide === 'l1w1' ? '#e4d5c7' : 'none'}} 
                                            onClick={() => changeAngleCutSide('l1w1')}
                                        >
                                            <p>L1-W1</p>
                                        </div>
                                        <div className='machiningPopupAngleCutSide'
                                            style={{background: angleCutSide === 'l1w2' ? '#e4d5c7' : 'none'}}
                                            onClick={() => changeAngleCutSide('l1w2')}
                                        >
                                            <p>L1-W2</p>
                                        </div>
                                        <div className='machiningPopupAngleCutSide' 
                                            style={{background: angleCutSide === 'l2w1' ? '#e4d5c7' : 'none'}}
                                            onClick={() => changeAngleCutSide('l2w1')}
                                        >
                                            <p>L2-W1</p>
                                        </div>
                                        <div className='machiningPopupAngleCutSide' 
                                            style={{background: angleCutSide === 'l2w2' ? '#e4d5c7' : 'none'}}
                                            onClick={() => changeAngleCutSide('l2w2')}
                                        >
                                            <p>L2-W2</p>
                                        </div>
                                    </div>
                                    <div className='machiningPopupAngleCutDoubleInputDiv'>
                                        <div className='machiningPopupAngleCutInputDiv'>
                                            <p>From {displayInputFromLabel('input1')}:</p>
                                            <div className='machiningPopupAngleCutInputInner'>
                                                <input className='machiningPopupAngleCutInput'
                                                    value={angleCutInputs.input1} 
                                                    onChange={angleCutInput1Changed}
                                                    style={{border: angleCutInputErrors.input1 != null ? '1px solid #fb0429' : '1px solid #ccc'}}
                                                />
                                                {angleCutInputErrors.input1 != null ? (
                                                    <div className='machiningPopupAngleCutInputError'>
                                                        <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                        <p className='machiningPopupAlertText'>{angleCutInputErrors.input1}</p>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='machiningPopupAngleCutInputDiv'>
                                            <p>From {displayInputFromLabel('input2')}:</p>
                                            <div className='machiningPopupAngleCutInputInner'>
                                                <input className='machiningPopupAngleCutInput'
                                                    value={angleCutInputs.input2} 
                                                    onChange={angleCutInput2Changed}
                                                    style={{border: angleCutInputErrors.input2 != null ? '1px solid #fb0429' : '1px solid #ccc'}}
                                                />
                                                {angleCutInputErrors.input2 != null ? (
                                                    <div className='machiningPopupAngleCutInputError'>
                                                        <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                        <p className='machiningPopupAlertText'>{angleCutInputErrors.input2}</p>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='machiningPopupAngleCutDoubleInputDiv'>
                                        <div className='machiningPopupAngleCutApplyBtn' onClick={() => makeNewCut()}>
                                            <p>Apply cut</p>
                                        </div>
                                        <div className='machiningPopupAngleCutRemoveBtn' onClick={() => removeAngleCut()}>
                                            <p>Remove cut</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='machiningPopupAngleCutEdgebandingContainer'>
                                    <p>Edgebanding on the cut:</p>
                                    <div className='machiningPopupAvailableEdgebandingsList'>
                                        {edgebandingOptions !== null && rowConfig !== null && edgebandingOptions.map((option, index) => (
                                            <div key={index} className='machiningPopupAvailableEdgebanding' 
                                                style={{
                                                    ...(angleCutSide === 'l1w1' && machiningConfig?.W1P !== null && isEqual(tempEdgebandingOptions.l1w1, option) ) ||
                                                    (angleCutSide === 'l1w2' && machiningConfig?.L1P !== null && isEqual(tempEdgebandingOptions.l1w2, option) ) ||
                                                    (angleCutSide === 'l2w1' && machiningConfig?.L2P !== null && isEqual(tempEdgebandingOptions.l2w1, option) ) ||
                                                    (angleCutSide === 'l2w2' && machiningConfig?.W2P !== null && isEqual(tempEdgebandingOptions.l2w2, option) )
                                                        ? { background: '#e4d5c7' }
                                                        : null,
                                                    opacity:
                                                        (angleCutSide === 'l1w1' && machiningConfig?.W1P !== null) ||
                                                        (angleCutSide === 'l1w2' && machiningConfig?.L1P !== null) ||
                                                        (angleCutSide === 'l2w1' && machiningConfig?.L2P !== null) ||
                                                        (angleCutSide === 'l2w2' && machiningConfig?.W2P !== null)
                                                            ? 1: 0.5,}} 
                                                onClick={() => edgebandingOptionClicked(option)}
                                                >
                                                <p>{option.edgebanding_type} / {rowConfig.productCode}</p>
                                                <p>{option.edgebanding_description}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='machiningPopupEdgebandingFinishList'>
                                        <div className='machiningPopupEdgebandingFinish' 
                                            style={{
                                                ...(angleCutSide == 'l1w1' && machiningConfig?.l1w1_edgebanding != null && machiningConfig?.l1w1_edgebanding.finish == 'radius') ||
                                                (angleCutSide =='l1w2' && machiningConfig?.l1w2_edgebanding != null && machiningConfig?.l1w2_edgebanding.finish == 'radius') ||
                                                (angleCutSide =='l2w1' && machiningConfig?.l2w1_edgebanding != null && machiningConfig?.l2w1_edgebanding.finish == 'radius') ||
                                                (angleCutSide =='l2w2' && machiningConfig?.l2w2_edgebanding != null && machiningConfig?.l2w2_edgebanding.finish == 'radius')
                                                ? { background: '#e4d5c7' } : null, 
                                                opacity: (
                                                    (angleCutSide == 'l1w1' && machiningConfig?.W1P != null && tempEdgebandingOptions.l1w1 != null && tempEdgebandingOptions.l1w1.edgebanding_radius.toLowerCase() == 'yes') 
                                                    || (angleCutSide =='l1w2' && machiningConfig?.L1P != null && tempEdgebandingOptions.l1w2 != null && tempEdgebandingOptions.l1w2.edgebanding_radius.toLowerCase() == 'yes')
                                                    || (angleCutSide == 'l2w1' && machiningConfig?.L2P != null && tempEdgebandingOptions.l2w1 != null && tempEdgebandingOptions.l2w1.edgebanding_radius.toLowerCase() == 'yes')
                                                    || (angleCutSide == 'l2w2' && machiningConfig?.W2P != null && tempEdgebandingOptions.l2w2 != null && tempEdgebandingOptions.l2w2.edgebanding_radius.toLowerCase() == 'yes')
                                                ) ? 1 : 0.5 }}
                                            onClick={() => edgebandingFinishClicked('radius')}
                                        >
                                            <img src='/res/images/corner1_selected.png' alt='Radius' className='edgebandingPopupFinishImage'/>
                                            <p>Radius edge finish</p>
                                        </div>
                                        <div className='machiningPopupEdgebandingFinish'
                                            style={{
                                                ...(angleCutSide == 'l1w1' && machiningConfig?.l1w1_edgebanding != null && machiningConfig?.l1w1_edgebanding.finish == 'square') ||
                                                (angleCutSide =='l1w2' && machiningConfig?.l1w2_edgebanding != null && machiningConfig?.l1w2_edgebanding.finish == 'square') ||
                                                (angleCutSide =='l2w1' && machiningConfig?.l2w1_edgebanding != null && machiningConfig?.l2w1_edgebanding.finish == 'square') ||
                                                (angleCutSide =='l2w2' && machiningConfig?.l2w2_edgebanding != null && machiningConfig?.l2w2_edgebanding.finish == 'square')
                                                ? { background: '#e4d5c7' } : null, 
                                                opacity: (
                                                    (angleCutSide == 'l1w1' && machiningConfig?.W1P != null && tempEdgebandingOptions.l1w1 != null && tempEdgebandingOptions.l1w1.edgebanding_square.toLowerCase() == 'yes') 
                                                    || (angleCutSide =='l1w2' && machiningConfig?.L1P != null && tempEdgebandingOptions.l1w2 != null && tempEdgebandingOptions.l1w2.edgebanding_square.toLowerCase() == 'yes')
                                                    || (angleCutSide == 'l2w1' && machiningConfig?.L2P != null && tempEdgebandingOptions.l2w1 != null && tempEdgebandingOptions.l2w1.edgebanding_square.toLowerCase() == 'yes')
                                                    || (angleCutSide == 'l2w2' && machiningConfig?.W2P != null && tempEdgebandingOptions.l2w2 != null && tempEdgebandingOptions.l2w2.edgebanding_square.toLowerCase() == 'yes')
                                                ) ? 1 : 0.5 }}
                                            onClick={() => edgebandingFinishClicked('square')}
                                        >
                                            <img src='/res/images/corner3_selected.png' alt='Square' className='edgebandingPopupFinishImage'/>
                                            <p>Square edge finish</p>
                                        </div>
                                    </div>
                                    <div className='machiningPopupAngleCutDoubleInputDiv'>
                                        <div className='machiningPopupAngleCutRemoveBtn' 
                                            style={{
                                                ...(angleCutSide == 'l1w1' && machiningConfig?.l1w1_edgebanding != null) ||
                                                (angleCutSide =='l1w2' && machiningConfig?.l1w2_edgebanding != null) ||
                                                (angleCutSide =='l2w1' && machiningConfig?.l2w1_edgebanding != null) ||
                                                (angleCutSide =='l2w2' && machiningConfig?.l2w2_edgebanding != null) 
                                                ? {display: 'flex'} : {display: 'none'}
                                            }}
                                            onClick={() => removeAngleCutEdgebanding()}
                                        >
                                            <p>Remove edgebanding</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='machiningPopupPanelRotationGifDiv'>
                                    <img src='/res/images/drilling_a.gif' alt='Side'/>
                                </div>
                            </div>
                        ) : null}

                        {/* HINGE DRILLING */}
                        {selectionConfigValue === 'hingeDrilling' && (
                            machiningConfig?.clusters != null ? (
                                <div className='machiningPopupShelfDrillingCantPlaceDiv' style={{marginTop: '50px'}}>
                                    <div className='machiningPopupCantPlaceBox'>
                                        <FiInfo className='machiningPopupAlertIcon' />
                                        <p>Hinge Drilling and Shelf Drilling can't be applied at the same time.</p>
                                    </div>
                                </div>
                            ) : (
                                machiningConfig.hingeHoles != null && machiningConfig.hingesType !== hingeType ? (
                                    <div className='machiningPopupShelfDrillingCantPlaceDiv' style={{marginTop: '50px'}}>
                                        <div className='machiningPopupCantPlaceBox'>
                                            <FiInfo className='machiningPopupAlertIcon' />
                                            <p>Can't apply two different types of hinge drilling at the same time.</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='machiningPopupHingeDrillingSection'>
                                        <div className='machiningPopupHingeDrillingContainer'>
                                            <p>{selectionValue}:</p>
                                            <div className='machiningPopupHingeDrillingSidesList'>
                                                <div className='machiningPopupHingeDrillingSideBtn' onClick={() => changeHingeDrillSide('l1')} style={{background: hingeDrillSide == 'l1' ? '#e4d5c7' : 'none'}}>
                                                    <p>L1</p>
                                                </div>
                                                <div className='machiningPopupHingeDrillingSideBtn' onClick={() => changeHingeDrillSide('l2')} style={{background: hingeDrillSide == 'l2' ? '#e4d5c7' : 'none'}}>
                                                    <p>L2</p> 
                                                </div>
                                                <div className='machiningPopupHingeDrillingSideBtn' onClick={() => changeHingeDrillSide('w1')} style={{background: hingeDrillSide == 'w1' ? '#e4d5c7' : 'none'}}>
                                                    <p>W1</p>
                                                </div>
                                                <div className='machiningPopupHingeDrillingSideBtn' onClick={() => changeHingeDrillSide('w2')} style={{background: hingeDrillSide == 'w2' ? '#e4d5c7' : 'none'}}>
                                                    <p>W2</p>
                                                </div>
                                            </div>
                                            <div className='machiningPopupHingeDrillingInputContainer'>
                                                <p>Number of hinge holes:</p>
                                                <div className='machiningPopuphingeDrillingInputDiv'>
                                                    <input className='machiningPopupHingeDrillingInput' style={{borderColor: hingeDrillingError != null ? '#fb0429' : '#e4d5c7'}}
                                                        value={hingeDrillingInputValue} 
                                                        onChange={hingeDrillingInputChange}
                                                    />
                                                    {hingeDrillingError !== null ? (
                                                        <div className='machiningPopupAngleCutInputError'>
                                                            <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                            <p className='machiningPopupAlertText'>{hingeDrillingError}</p>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='machiningPopupHingeDrillingDoubleBtnDiv'>
                                                <div className='machiningPopupHingeDrillingApplyBtn' onClick={() => applyHingeHoles()}>
                                                    <p>Apply hinge drilling</p>
                                                </div>
                                                <div className='machiningPopupHingeDrillingRemoveBtn' onClick={() => removeHingeDrilling()}>
                                                    <p>Remove</p>
                                                </div>
                                            </div>
                                            <div className='machiningPopupHingeDrillingHolesPositionsList'>
                                                {machiningConfig.hingeHolesPos.map((hole, index) => (
                                                    <div key={`hingeHolePositionConfig${index}`} className='machiningPopupHingeDrillingHolePosDiv'>
                                                        <p>Hole {index+1}:</p>
                                                        <input className='machiningPopupHingeDrillingHolePosInput'
                                                            value={hingeDrillingPosInputs[index].toString()} 
                                                            onChange={(event) => updateHingeHolePosition(index, event)}
                                                            style={hingeDrillingPosInputsErrors[index] === false ? {borderColor: '#ccc'} : {borderColor: '#fb0429'}}
                                                        />
                                                        <div className='machiningPopupHingeDrillingHolePosLimits' style={hingeDrillingPosInputsErrors[index] === false ? {background: '#e4d5c7', color: 'black'} : {background: '#fb0429', color: 'white'}}>
                                                            {hingeDrillingPosInputsErrors[index] === false ? 
                                                                <>
                                                                    <FiInfo className='machiningPopupAlertIcon' />
                                                                </> : <>
                                                                    <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                                </>
                                                            }
                                                            <p style={{color: hingeDrillingPosInputsErrors[index] === false ? 'black' : 'white'}}>Min: {hingeDrillingPosLimits != null ? hingeDrillingPosLimits[index].min : 'null'}, Max: {hingeDrillingPosLimits != null ? hingeDrillingPosLimits[index].max : 'null'}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        )}

                        {/* SHELF DRILLING */}
                        {selectionConfigValue === 'shelfDrilling' ? 
                            (machiningConfig?.hingeHoles != null ? (
                                <div className='machiningPopupShelfDrillingCantPlaceDiv' style={{marginTop: '50px'}}>
                                    <div className='machiningPopupCantPlaceBox'>
                                        <FiInfo className='machiningPopupAlertIcon' />
                                        <p>Hinge Drilling and Shelf Drilling can't be applied at the same time.</p>
                                    </div>
                                </div>
                            ) : (
                                machiningConfig.clusters != null && machiningConfig.clustersType != shelfType ? (
                                    <div className='machiningPopupShelfDrillingCantPlaceDiv' style={{marginTop: '50px'}}>
                                        <div className='machiningPopupCantPlaceBox'>
                                            <FiInfo className='machiningPopupAlertIcon' />
                                            <p>Can't apply two different types of adjustable shelf drilling at the same time.</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='machiningPopupShelfDrillingSection'>
                                        <div className='machiningPopupShelfDrillingContainer'>
                                            <p>{selectionValue}:</p>
                                            <p>Holes drilled along edge:</p>
                                            <div className='machiningPopupShelfDrillingEdgeContainer'>
                                                <div className='machiningPopupShelfDrillingEdgeBtn' onClick={() => changeShelfDrillingEdge('l1-l2')} style={{ background: shelfDrillingEdge == 'l1-l2' ? '#e4d5c7' : 'none'}}>
                                                    <p>L1-L2</p>
                                                </div>
                                                <div className='machiningPopupShelfDrillingEdgeBtn' onClick={() => changeShelfDrillingEdge('w1-w2')} style={{ background: shelfDrillingEdge == 'w1-w2' ? '#e4d5c7' : 'none'}}>
                                                    <p>W1-W2</p>
                                                </div>
                                            </div>
                                            {canClustersBePlaced === false ? (
                                                <div className='machiningPopupShelfDrillingCantPlaceDiv'>
                                                    <div className='machiningPopupCantPlaceBox'>
                                                        <FiInfo className='machiningPopupAlertIcon' />
                                                        <p>Adjustable shelf drilling can't be applied to this edge.</p>
                                                    </div>
                                                </div>
                                            ) : (
                                                machiningConfig?.clusters == null ? (
                                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                                        <div className='machiningPopupShelfDrillingAddBtn' onClick={() => addShelfDrilling()}>
                                                            <p>Add adjustable shelf drilling</p>
                                                        </div>
                                                    </div>
                                                ) :  (
                                                        machiningConfig.clustersEdge === shelfDrillingEdge ? (
                                                        <>
                                                            <div className='machiningPopupShelfInputContainer'>
                                                                <p>Distance:</p>
                                                                <div className='machiningPopupShelfDoubleInput'>
                                                                    <div className='machiningPopupShelfInputBox'>
                                                                        <p className='machiningPopupShelfInputLabel'>Row one from {shelfDrillingEdge == 'l1-l2' ? 'L1' : 'W1'}</p>
                                                                        <input className='machiningPopupShelfInput'
                                                                            value={shelfDrillingInputs.distance1.toString()} 
                                                                            onChange={(event) => changeShelfDrillingInputs('distance1', event.target.value)}
                                                                            style={shelfDrillingInputsErrors.distance1 == null ? {border: '1px solid #e4d5c7'} : {border: '1px solid #fb0429'}}
                                                                        />
                                                                        {shelfDrillingInputsErrors.distance1 != null ? (
                                                                            <div className='machiningPopupShelfInputAlert'>
                                                                                <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                                                <p>{shelfDrillingInputsErrors.distance1}</p>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className='machiningPopupShelfInputBox'>
                                                                        <p className='machiningPopupShelfInputLabel'>Row one from {shelfDrillingEdge == 'l1-l2' ? 'L2' : 'W2'}</p>
                                                                        <input className='machiningPopupShelfInput'
                                                                            value={shelfDrillingInputs.distance2.toString()} 
                                                                            onChange={(event) => changeShelfDrillingInputs('distance2', event.target.value)}
                                                                            style={shelfDrillingInputsErrors.distance2 == null ? {border: '1px solid #e4d5c7'} : {border: '1px solid #fb0429'}}
                                                                        />
                                                                        {shelfDrillingInputsErrors.distance2 != null ? (
                                                                            <div className='machiningPopupShelfInputAlert'>
                                                                                <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                                                <p>{shelfDrillingInputsErrors.distance2}</p>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='machiningPopupShelfInputContainer'>
                                                                <p>Cluster size:</p>
                                                                <div className='machiningPopupShelfDoubleInput'>
                                                                    <div className='machiningPopupShelfInputBox'>
                                                                        <p className='machiningPopupShelfInputLabel'>Step</p>
                                                                        <input className='machiningPopupShelfInput'
                                                                            value={shelfDrillingInputs.step.toString()} 
                                                                            onChange={(event) => changeShelfDrillingInputs('step', event.target.value)}
                                                                            style={shelfDrillingInputsErrors.step == null ? {border: '1px solid #e4d5c7'} : {border: '1px solid #fb0429'}}
                                                                        />
                                                                        {shelfDrillingInputsErrors.step != null ? (
                                                                            <div className='machiningPopupShelfInputAlert'>
                                                                                <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                                                <p>{shelfDrillingInputsErrors.step}</p>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className='machiningPopupShelfInputBox'>
                                                                        <p className='machiningPopupShelfInputLabel'>Positions</p>
                                                                        <input className='machiningPopupShelfInput'
                                                                            value={shelfDrillingInputs.positions.toString()} 
                                                                            onChange={(event) => changeShelfDrillingInputs('positions', event.target.value)}
                                                                            style={shelfDrillingInputsErrors.positions == null ? {border: '1px solid #e4d5c7'} : {border: '1px solid #fb0429'}}
                                                                        />
                                                                        {shelfDrillingInputsErrors.positions != null ? (
                                                                            <div className='machiningPopupShelfInputAlert'>
                                                                                <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                                                <p>{shelfDrillingInputsErrors.positions}</p>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='machiningPopupShelfInputContainer'>
                                                                <p>Number of clusters:</p>
                                                                <div className='machiningPopupShelfDoubleInput'>
                                                                    <div className='machiningPopupShelfInputBox'>
                                                                        <input className='machiningPopupShelfInput'
                                                                            value={shelfDrillingInputs.number_of_clusters.toString()} 
                                                                            onChange={(event) => changeShelfDrillingInputs('number_of_clusters', event.target.value)}
                                                                            style={shelfDrillingInputsErrors.number_of_clusters == false ? {border: '1px solid #e4d5c7'} : {border: '1px solid #fb0429'}}
                                                                        />
                                                                    </div>
                                                                    <div className='machiningPopupShelfInputBox'>
                                                                        <div className='machiningPopupNumberOfClustersInfo' style={(shelfDrillingInputsErrors.number_of_clusters == true ? {background: '#fb0429', color: 'white', borderColor: '#fb0429'} : {background: '#f8f6f5', color: 'black', borderColor: '#ccc'})}>
                                                                            {shelfDrillingInputsErrors.number_of_clusters == true ? (
                                                                                <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                                            ) : (
                                                                                <FiInfo className='machiningPopupAlertIcon' />
                                                                            )}
                                                                            <p>Min: {shelfDrillingInputsLimits.number_of_clusters_min}, Max: {shelfDrillingInputsLimits.number_of_clusters_max}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='machiningPopupFaceSelectContainer'>
                                                                <p>Holes drilled on:</p>
                                                                <div className='machiningPopupFacesList'>
                                                                    <div className='machiningPopupFace' 
                                                                        onClick={() => changeShelfDrillingFace('A')}
                                                                        style={{backgroundColor: shelfDrillingFace == 'A' ? '#e4d5c7' : '#f8f6f5'}}
                                                                    >
                                                                        <p>A side</p>
                                                                        <p style={{fontSize: '10px'}}>Front face</p>
                                                                    </div>
                                                                    <div className='machiningPopupFace' 
                                                                        onClick={() => changeShelfDrillingFace('B')}
                                                                        style={{backgroundColor: shelfDrillingFace == 'B' ? '#e4d5c7' : '#f8f6f5'}}
                                                                    >
                                                                        <p>B side</p>
                                                                        <p style={{fontSize: '10px'}}>Back face</p>
                                                                    </div>
                                                                    <div className='machiningPopupFace' 
                                                                        onClick={() => changeShelfDrillingFace('Both')}
                                                                        style={{backgroundColor: shelfDrillingFace == 'Both' ? '#e4d5c7' : '#f8f6f5'}}    
                                                                    >
                                                                        <p>Both</p>
                                                                        <p style={{fontSize: '10px'}}>Front & back</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                                                <div className='machiningPopupRemoveShelfDrillingBtn' onClick={() => removeShelfDrilling()}>
                                                                    <p>Remove adjustable shelf drilling</p>
                                                                </div>
                                                            </div>
                                                            <div className='machiningPopupClustersPositionsList'>
                                                                {clustersPosInputs.map((hole, index) => (
                                                                    <div key={`clusterPositionConfig${index}`} className='machiningPopupClusterPosDiv'>
                                                                        <p>Cluster {index+1}:</p>
                                                                        <input className='machiningPopupClusterPosInput'
                                                                            value={clustersPosInputs[index].toString()} 
                                                                            onChange={(event) => updateClusterPositon(index, event)}
                                                                            onFocus={() => handleActiveClusterInput(index)}
                                                                            onBlur={handleBlurClusterInput}
                                                                            style={clustersPosInputsErrors[index] === false ? {borderColor: '#ccc'} : {borderColor: '#fb0429'}}
                                                                        />
                                                                        <div className='machiningPopupClusterPosLimits' style={clustersPosInputsErrors[index] === false ? {background: '#e4d5c7', color: 'black'} : {background: '#fb0429', color: 'white'}}>
                                                                            {clustersPosInputsErrors[index] === false ? 
                                                                                <>
                                                                                    <FiInfo className='machiningPopupAlertIcon' />
                                                                                </> : <>
                                                                                    <FiAlertTriangle className='machiningPopupAlertIcon'/>
                                                                                </>
                                                                            }
                                                                            <p style={{color: clustersPosInputsErrors[index] === false ? 'black' : 'white'}}>Min: {clusterPosLimits != null ? clusterPosLimits[index].min : 'null'}, Max: {clusterPosLimits != null ? clusterPosLimits[index].max : 'null'}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className='machiningPopupShelfDrillingCantPlaceDiv'>
                                                            <div className='machiningPopupCantPlaceBox'>
                                                                <FiInfo className='machiningPopupAlertIcon' />
                                                                <p>Adjustable shelf drilling can't be applied to L1-L2 and W1-W2 at the same time.</p>
                                                            </div>
                                                        </div>
                                                    )   
                                                )
                                            )}
                                        </div>
                                    </div>
                                )
                            ))
                        : null}

                    </div>
                    <div className='machiningPopupDrawingContainer'>
                        <div className='machiningPopupDrawingSide' 
                            style={{background: 
                                (selectionConfigValue === 'angleCut' && (angleCutSide === 'l1w1' || angleCutSide === 'l1w2')) || 
                                (selectionConfigValue === 'hingeDrilling' && hingeDrillSide === 'l1') ||
                                (selectionConfigValue === 'shelfDrilling' && shelfDrillingEdge === 'l1-l2')
                                ? '#e4d5c7' : 'none'}} 
                        >
                            <p>L1</p>
                        </div>
                        <div className='machiningPopupDrawingMiddle'>
                            <div className='machiningPopupDrawingSide' 
                                style={{background: 
                                    (selectionConfigValue === 'angleCut' && (angleCutSide === 'l1w1' || angleCutSide === 'l2w1')) || 
                                    (selectionConfigValue === 'hingeDrilling' && hingeDrillSide === 'w1') ||
                                    (selectionConfigValue === 'shelfDrilling' && shelfDrillingEdge === 'w1-w2')
                                    ? '#e4d5c7' : 'none'}} 
                            >
                                <p>W1</p>
                            </div>
                            <div className='machiningPopupDrawing' ref={machiningPopupDrawingRef}>
                                <svg height={svgDimensions.svgHeight} width={svgDimensions.svgWidth} 
                                    style={selectionConfigValue === 'hingeDrilling' ? { transform: 'scaleY(-1)' } : (selectionConfigValue === 'shelfDrilling' && shelfDrillingFace === 'B') ? { transform: 'scaleY(-1)' } : null}
                                >
                                    
                                    {/* panel drawing */}
                                    <polygon points={drawingProps.points} fill="#e4d5c7" stroke={'#6ab29b'} strokeWidth={1}/>
                                    {/* angle cut arrows */}       
                                    {selectionConfigValue === 'angleCut' && angleCutSide === 'l1w1' && drawingProps.drawingWidth !== null && (
                                        <>
                                            <path
                                                d={drawingProps.l1w1_arrows.from_w2}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeLinecap="round"
                                            />
                                            <text fill="black" fontWeight="400" fontSize="10" x={drawingProps.drawingLength + drawingPadding} y="8" textAnchor="end">From W2</text>
                                            <path
                                                d={drawingProps.l1w1_arrows.from_l2}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeLinecap="round"
                                            />
                                            <text fill="black" fontWeight="400" fontSize="10" x="8" y={drawingProps.drawingWidth + drawingPadding} textAnchor="start" transform={drawingProps.drawingWidth != null ? `rotate(-90, 8, ${parseFloat(drawingProps.drawingWidth + drawingPadding)})` : undefined}>From L2</text>
                                        </>
                                    )}
                                    {selectionConfigValue === 'angleCut' && angleCutSide === 'l1w2' && drawingProps.drawingWidth !== null && (
                                        <>
                                            <path
                                                d={drawingProps.l1w2_arrows.from_w1}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeLinecap="round"
                                            />
                                            <text fill="black" fontWeight="400" fontSize="10" x={drawingPadding} y="8" textAnchor="start">From W1</text>
                                            <path
                                                d={drawingProps.l1w2_arrows.from_l2}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeLinecap="round"
                                            />
                                            <text
                                                fill="black"
                                                fontWeight="400"
                                                fontSize="10"
                                                x={drawingProps.drawingLength + drawingPadding + 19}
                                                y={drawingProps.drawingWidth + drawingPadding}
                                                textAnchor="start"
                                                transform={drawingProps.drawingWidth !== null ? `rotate(-90 ${parseFloat(drawingProps.drawingLength + drawingPadding + 19)} ${parseFloat(drawingProps.drawingWidth + drawingPadding)})` : undefined}
                                            >
                                                From L2
                                            </text>
                                        </>
                                    )}
                                    {selectionConfigValue === 'angleCut' && angleCutSide === 'l2w1' && drawingProps.drawingWidth !== null && (
                                        <>
                                            <path
                                                d={drawingProps.l2w1_arrows.from_w2}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeLinecap="round"
                                            />
                                            <text
                                                fill="black"
                                                fontWeight="400"
                                                fontSize="10"
                                                x={drawingProps.drawingLength + drawingPadding}
                                                y={drawingProps.drawingWidth + drawingPadding + 19}
                                                textAnchor="end"
                                            >
                                                From W2
                                            </text>
                                            <path
                                                d={drawingProps.l2w1_arrows.from_l1}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeLinecap="round"
                                            />
                                            <text
                                                fill="black"
                                                fontWeight="400"
                                                fontSize="10"
                                                x={8}
                                                y={drawingPadding}
                                                textAnchor="end"
                                                transform={drawingProps.drawingWidth !== null ? `rotate(-90 8 ${parseFloat(drawingPadding)})` : undefined}
                                            >
                                                From L1
                                            </text>
                                        </>
                                    )}
                                    {selectionConfigValue === 'angleCut' && angleCutSide === 'l2w2' && drawingProps.drawingWidth !== null && (
                                        <>
                                            <path
                                                d={drawingProps.l2w2_arrows.from_w1}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeLinecap="round"
                                            />
                                            <text
                                                fill="black"
                                                fontWeight="400"
                                                fontSize="10"
                                                x={drawingPadding}
                                                y={drawingProps.drawingWidth + drawingPadding + 19}
                                                textAnchor="start"
                                            >
                                                From W1
                                            </text>
                                            <path
                                                d={drawingProps.l2w2_arrows.from_l1}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeLinecap="round"
                                            />
                                            <text
                                                fill="black"
                                                fontWeight="400"
                                                fontSize="10"
                                                x={drawingProps.drawingLength + drawingPadding + 19}
                                                y={drawingPadding}
                                                textAnchor="end"
                                                transform={drawingProps.drawingWidth !== null ? `rotate(-90 ${parseFloat(drawingProps.drawingLength + drawingPadding + 19)} ${parseFloat(drawingPadding)})` : undefined}
                                            >
                                                From L1
                                            </text>
                                        </>
                                    )}
                                    {/* edgebanding lines */}
                                    {machiningConfig?.l1w1_edgebanding !== null && drawingProps.l1w1_edgebanding !== null && (
                                        <>
                                            <path
                                                d={drawingProps.l1w1_edgebanding}
                                                stroke="#6ab29b"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                            />
                                        </>
                                    )}
                                    {machiningConfig?.l1w2_edgebanding !== null && drawingProps.l1w2_edgebanding !== null && (
                                        <>
                                            <path
                                                d={drawingProps.l1w2_edgebanding}
                                                stroke="#6ab29b"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                            />
                                        </>
                                    )}
                                    {machiningConfig?.l2w2_edgebanding !== null && drawingProps.l2w2_edgebanding !== null && (
                                        <>
                                            <path
                                                d={drawingProps.l2w2_edgebanding}
                                                stroke="#6ab29b"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                            />
                                        </>
                                    )}
                                    {machiningConfig?.l2w1_edgebanding !== null && drawingProps.l2w1_edgebanding !== null && (
                                        <>
                                            <path
                                                d={drawingProps.l2w1_edgebanding}
                                                stroke="#6ab29b"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                            />
                                        </>
                                    )}


                                    {/* hinge drilling */}
                                    {machiningConfig?.hingeHoles != null && machiningConfig?.hingeHolesPos.length > 0 && drawingProps.hingeHoles.length > 0 ?
                                        machiningConfig?.hingeHolesPos.map((hole, index) => (
                                            <React.Fragment key={`holeDraw${index}`}>
                                                <circle cx={drawingProps.hingeHoles[index].x} cy={drawingProps.hingeHoles[index].y} r="3" fill="#253237" />
                                                {selectionConfigValue === 'hingeDrilling' && machiningConfig?.hingeHolesSide === 'l1' ? (
                                                    <>
                                                        <path
                                                            d={`M${drawingProps.hingeHoles[index].x} ${drawingProps.hingeHoles[index].y - 5} L${drawingProps.hingeHoles[index].x} ${drawingProps.topLeft.y - 5}`}
                                                            stroke="black"
                                                            strokeWidth="1"
                                                            strokeLinecap="round"
                                                        />
                                                        <text fill="black" fontWeight="400" fontSize="10" x={drawingProps.hingeHoles[index].x} y={-(drawingProps.topLeft.y - 15)} textAnchor="middle" style={{ transform: 'scaleY(-1)' }}>{index + 1}</text>
                                                    </>
                                                ) : selectionConfigValue === 'hingeDrilling' && machiningConfig?.hingeHolesSide === 'l2' ? (
                                                    <>
                                                        <path
                                                            d={`M${drawingProps.hingeHoles[index].x} ${drawingProps.hingeHoles[index].y + 5} L${drawingProps.hingeHoles[index].x} ${drawingProps.bottomRight.y + 5}`}
                                                            stroke="black"
                                                            strokeWidth="1"
                                                            strokeLinecap="round"
                                                        />
                                                        <text fill="black" fontWeight="400" fontSize="10" x={drawingProps.hingeHoles[index].x} y={-(drawingProps.bottomRight.y + 10)} textAnchor="middle" style={{ transform: 'scaleY(-1)' }}>{index + 1}</text>
                                                    </>
                                                ) : selectionConfigValue === 'hingeDrilling' && machiningConfig?.hingeHolesSide === 'w1' ? (
                                                    <>
                                                        <path
                                                            d={`M${drawingProps.hingeHoles[index].x - 5} ${drawingProps.hingeHoles[index].y} L${drawingProps.hingeHoles[index].x - 10} ${drawingProps.hingeHoles[index].y}`}
                                                            stroke="black"
                                                            strokeWidth="1"
                                                            strokeLinecap="round"
                                                        />
                                                        <text fill="black" fontWeight="400" fontSize="10" x={drawingProps.hingeHoles[index].x - 15} y={-(drawingProps.hingeHoles[index].y - 2.5)} textAnchor="middle" style={{ transform: 'scaleY(-1)' }}>{index + 1}</text>
                                                    </>
                                                ) : selectionConfigValue === 'hingeDrilling' && machiningConfig?.hingeHolesSide === 'w2' ? (
                                                    <>
                                                        <path
                                                            d={`M${drawingProps.hingeHoles[index].x + 5} ${drawingProps.hingeHoles[index].y} L${drawingProps.hingeHoles[index].x + 10} ${drawingProps.hingeHoles[index].y}`}
                                                            stroke="black"
                                                            strokeWidth="1"
                                                            strokeLinecap="round"
                                                        />
                                                        <text fill="black" fontWeight="400" fontSize="10" x={drawingProps.hingeHoles[index].x + 15} y={-(drawingProps.hingeHoles[index].y - 2.5)} textAnchor="middle" style={{ transform: 'scaleY(-1)' }}>{index + 1}</text>
                                                    </>
                                                ) : null}
                                            </React.Fragment>
                                        ))
                                    : null}

                                    {/* shelf drilling */}
                                    {machiningConfig?.clusters != null && machiningConfig?.clustersPos.length > 0 && drawingProps?.clustersHoles.length > 0 && machiningConfig?.clustersEdge == shelfDrillingEdge?
                                        drawingProps.clustersHoles.map((hole, index) => (
                                            <React.Fragment key={`holeDraw${index}`}>
                                                {(() => {
                                                    const circles = [];
                                                    if(shelfDrillingEdge == 'l1-l2'){
                                                        for (let i = 0; i < machiningConfig?.clustersPositions; i++) {
                                                            console.log(`Drawing hole ${i + 1} for cluster ${index + 1}: x = ${drawingProps.clustersHoles[index].x + i * drawingProps.clusterStep}; y = ${drawingProps.clustersHoles[index].y1}`);
                                                            circles.push(
                                                                <circle
                                                                    key={`circle${i}`}
                                                                    cx={drawingProps.clustersHoles[index].x + i * drawingProps.clusterStep}
                                                                    cy={drawingProps.clustersHoles[index].y1}
                                                                    r="1"
                                                                    fill="#253237"
                                                                />
                                                            );
                                                            circles.push(
                                                                <circle
                                                                    key={`circleMirror${i}`}
                                                                    cx={drawingProps.clustersHoles[index].x + i * drawingProps.clusterStep}
                                                                    cy={drawingProps.clustersHoles[index].y2}
                                                                    r="1"
                                                                    fill="#253237"
                                                                />
                                                            );
                                                        }
                                                        if(selectionConfigValue == 'shelfDrilling'){
                                                            circles.push(
                                                                <path
                                                                    key={`clusterNotation${index}`}
                                                                    d={`
                                                                        M${drawingProps.clustersHoles[index].x} ${drawingProps.clustersHoles[index].y1 + 5} 
                                                                        L${drawingProps.clustersHoles[index].x} ${drawingProps.clustersHoles[index].y1 + 7.5} 
                                                                        M${drawingProps.clustersHoles[index].x} ${drawingProps.clustersHoles[index].y1 + 7.5} 
                                                                        L${drawingProps.clustersHoles[index].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep} ${drawingProps.clustersHoles[index].y1 + 7.5}
                                                                        M${drawingProps.clustersHoles[index].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep} ${drawingProps.clustersHoles[index].y1 + 7.5}
                                                                        L${drawingProps.clustersHoles[index].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep} ${drawingProps.clustersHoles[index].y1 + 5}
                                                                    `}
                                                                    stroke="black"
                                                                    strokeWidth="1"
                                                                    strokeLinecap="round"
                                                                />
                                                            );
                                                            circles.push(
                                                                <text
                                                                    key={`clusterNumber${index}${activeClustersInput}`}
                                                                    fill="black"
                                                                    fontWeight="400"
                                                                    fontSize="10"
                                                                    x={drawingProps.clustersHoles[index].x + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep) / 2}
                                                                    y={shelfDrillingFace === 'B' ? (drawingProps.clustersHoles[index].y1 + 10) * (-1) : (drawingProps.clustersHoles[index].y1 + 17.5)}
                                                                    textAnchor="middle"
                                                                    transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}
                                                                >
                                                                    {index + 1}
                                                                </text>
                                                            );
                                                        }
                                                        if(activeClustersInput != null){
                                                            if(machiningConfig?.clusters > 1){
                                                                const scalePercentageX = drawingProps.drawingLength / rowConfig.materialLength;
                                                                if(activeClustersInput == 0){
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceLeft${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.bottomLeft.x} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text
                                                                            key={`clusterInputLeft${index}${activeClustersInput}`}
                                                                            fill="blue"
                                                                            fontWeight="400"
                                                                            fontSize="10"
                                                                            x={drawingProps.clustersHoles[activeClustersInput].x - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.bottomLeft.x) / 2) * scalePercentageX}
                                                                            y={shelfDrillingFace === 'B' ? (drawingProps.clustersHoles[activeClustersInput].y1 - 12.5) * (-1) : (drawingProps.clustersHoles[activeClustersInput].y1 - 10)}
                                                                            textAnchor="middle"
                                                                            style={{ transform: shelfDrillingFace === 'B' ? 'scaleY(-1)' : 'scaleY(1)' }}
                                                                        >
                                                                            {Math.ceil(machiningConfig.clustersPos[activeClustersInput] - machiningConfig.bottomLeft.x)}
                                                                        </text>
                                                                    );
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceRight${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput + 1].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput + 1].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1}
                                                                                L${drawingProps.clustersHoles[activeClustersInput + 1].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput + 1].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text key={`clusterInputRight${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + ((machiningConfig.clustersPos[activeClustersInput + 1] - machiningConfig.clustersPos[activeClustersInput])/2) * scalePercentageX} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y1 - 12.5)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y1 - 10)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.floor(machiningConfig.clustersPos[activeClustersInput + 1] - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep - machiningConfig.clustersPos[activeClustersInput])}</text>
                                                                    );
                                                                }else if(activeClustersInput == (machiningConfig?.clusters - 1)){
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceLeft${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput - 1].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput - 1].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput - 1].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput - 1].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text key={`clusterInputLeft${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.clustersPos[activeClustersInput - 1])/2) * scalePercentageX} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y1 - 12.5)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y1 - 10)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.ceil(machiningConfig.clustersPos[activeClustersInput] - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep - machiningConfig.clustersPos[activeClustersInput - 1])}</text>
                                                                    );
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceRight${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.topRight.x} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text key={`clusterInputRight${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + ((machiningConfig.topRight.x - machiningConfig.clustersPos[activeClustersInput])/2) * scalePercentageX} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y1 - 12.5)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y1 - 10)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.floor(machiningConfig.topRight.x - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep - machiningConfig.clustersPos[activeClustersInput])}</text>
                                                                    );
                                                                }else{
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceLeft${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput - 1].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput - 1].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput - 1].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput - 1].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text key={`clusterInputLeft${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.clustersPos[activeClustersInput - 1])/2) * scalePercentageX} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y1 - 12.5)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y1 - 10)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.ceil(machiningConfig.clustersPos[activeClustersInput] - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep - machiningConfig.clustersPos[activeClustersInput - 1])}</text>
                                                                    );
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceRight${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput + 1].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput + 1].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1}
                                                                                L${drawingProps.clustersHoles[activeClustersInput + 1].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput + 1].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text key={`clusterInputRight${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + ((machiningConfig.clustersPos[activeClustersInput + 1] - machiningConfig.clustersPos[activeClustersInput])/2) * scalePercentageX} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y1 - 12.5)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y1 - 10)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.floor(machiningConfig.clustersPos[activeClustersInput + 1] - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep - machiningConfig.clustersPos[activeClustersInput])}</text>
                                                                    );
                                                                }
                                                            }else{
                                                                const scalePercentageX = drawingProps.drawingLength / rowConfig.materialLength;
                                                                circles.push(
                                                                    <path
                                                                        key={`clusterDistanceLeft${activeClustersInput}`}
                                                                        d={`
                                                                            M${drawingProps.bottomLeft.x} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                            M${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1}
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x - 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                        `}
                                                                        stroke="blue"
                                                                        strokeWidth="1"
                                                                        strokeLinecap="round"
                                                                    />
                                                                );
                                                                circles.push(
                                                                    <text key={`clusterInputLeft${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.bottomLeft.x)/2) * scalePercentageX} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y1 - 12.5)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y1 - 10)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.ceil(machiningConfig.clustersPos[activeClustersInput] - machiningConfig.bottomLeft.x)}</text>
                                                                );
                                                                circles.push(
                                                                    <path
                                                                        key={`clusterDistanceRight${activeClustersInput}`}
                                                                        d={`
                                                                            M${drawingProps.topRight.x} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1} 
                                                                            M${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1}
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 - 2.5} 
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} ${drawingProps.clustersHoles[activeClustersInput].y1 + 2.5}
                                                                        `}
                                                                        stroke="blue"
                                                                        strokeWidth="1"
                                                                        strokeLinecap="round"
                                                                    />
                                                                );
                                                                circles.push(
                                                                    <text key={`clusterInputRight${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + ((machiningConfig.topRight.x - machiningConfig.clustersPos[activeClustersInput])/2) * scalePercentageX} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y1 - 12.5)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y1 - 10)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.floor(machiningConfig.topRight.x - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep - machiningConfig.clustersPos[activeClustersInput])}</text>
                                                                );
                                                            }
                                                        }
                                                    }else if(shelfDrillingEdge == 'w1-w2'){
                                                        for (let i = 0; i < machiningConfig?.clustersPositions; i++) {
                                                            console.log(`Drawing hole ${i + 1} for cluster ${index + 1}: x = ${drawingProps.clustersHoles[index].x1}; y = ${drawingProps.clustersHoles[index].y + i * drawingProps.clusterStep}`);
                                                            circles.push(
                                                                <circle
                                                                    key={`circle${i}`}
                                                                    cx={drawingProps.clustersHoles[index].x1}
                                                                    cy={drawingProps.clustersHoles[index].y + i * drawingProps.clusterStep}
                                                                    r="1"
                                                                    fill="#253237"
                                                                />
                                                            );
                                                            circles.push(
                                                                <circle
                                                                    key={`circleMirror${i}`}
                                                                    cx={drawingProps.clustersHoles[index].x2}
                                                                    cy={drawingProps.clustersHoles[index].y + i * drawingProps.clusterStep}
                                                                    r="1"
                                                                    fill="#253237"
                                                                />
                                                            );
                                                        }
                                                        if(selectionConfigValue == 'shelfDrilling'){
                                                            circles.push(
                                                                <path
                                                                    key={`clusterNotation${index}`}
                                                                    d={`
                                                                        M${drawingProps.clustersHoles[index].x1 + 5} ${drawingProps.clustersHoles[index].y}
                                                                        L${drawingProps.clustersHoles[index].x1 + 7.5} ${drawingProps.clustersHoles[index].y}
                                                                        M${drawingProps.clustersHoles[index].x1 + 7.5} ${drawingProps.clustersHoles[index].y}
                                                                        L${drawingProps.clustersHoles[index].x1 + 7.5} ${drawingProps.clustersHoles[index].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep}
                                                                        M${drawingProps.clustersHoles[index].x1 + 7.5} ${drawingProps.clustersHoles[index].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep}
                                                                        L${drawingProps.clustersHoles[index].x1 + 5} ${drawingProps.clustersHoles[index].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep}
                                                                    `}
                                                                    stroke="black"
                                                                    strokeWidth="1"
                                                                    strokeLinecap="round"
                                                                />
                                                            );  
                                                            circles.push(
                                                                <text key={`clusterNumber${index}${activeClustersInput}`} fill="black" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[index].x1 + 10 } y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[index].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep)/2 - 2.5)*(-1) : (drawingProps.clustersHoles[index].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep)/2 + 2.5)} textAnchor="start" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'} >{index + 1}</text>
                                                            );
                                                        }
                                                        if(activeClustersInput != null){
                                                            if(machiningConfig?.clusters > 1){
                                                                const scalePercentageY = drawingProps.drawingWidth / rowConfig.width;
                                                                if(activeClustersInput == 0){
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceRight${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5}
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput + 1].y - 5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput + 1].y - 5}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput + 1].y - 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput + 1].y - 5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round" 
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text
                                                                            key={`clusterInputRight${index}${activeClustersInput}`}
                                                                            fill="blue"
                                                                            fontWeight="400"
                                                                            fontSize="10"
                                                                            x={drawingProps.clustersHoles[activeClustersInput].x1 + 12.5}
                                                                            y={shelfDrillingFace === 'B' ? 
                                                                                (drawingProps.clustersHoles[activeClustersInput].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep) + ((machiningConfig.clustersPos[activeClustersInput + 1] - machiningConfig.clustersPos[activeClustersInput]) / 2) * scalePercentageY - 5) * (-1) :
                                                                                (drawingProps.clustersHoles[activeClustersInput].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep) + ((machiningConfig.clustersPos[activeClustersInput + 1] - machiningConfig.clustersPos[activeClustersInput]) / 2) * scalePercentageY)
                                                                            }
                                                                            textAnchor="middle"
                                                                            transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}
                                                                        >
                                                                            {Math.ceil(machiningConfig.clustersPos[activeClustersInput + 1] - machiningConfig.clustersPos[activeClustersInput] - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep)}
                                                                        </text>
                                                                    );
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceLeft${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.topLeft.y}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y - 5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y - 5}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput].y - 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput].y - 5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text key={`clusterInputLeft${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x1 + 12.5} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.topLeft.y)/2) * scalePercentageY - 5)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.topLeft.y)/2) * scalePercentageY)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.ceil(machiningConfig.clustersPos[activeClustersInput] - machiningConfig.bottomLeft.x)}</text>
                                                                    );
                                                                }else if(activeClustersInput == (machiningConfig.clusters - 1)){
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceLeft${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput - 1].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput - 1].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput - 1].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput - 1].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y - 5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y - 5}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput].y - 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput].y - 5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text key={`clusterInputLeft${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x1 + 12.5} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.clustersPos[activeClustersInput - 1])/2) * scalePercentageY)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.clustersPos[activeClustersInput - 1])/2) * scalePercentageY)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.ceil(machiningConfig.clustersPos[activeClustersInput] - machiningConfig.clustersPos[activeClustersInput - 1] - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep)}</text>
                                                                    );
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceRight${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.bottomRight.y} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text key={`clusterInputRight${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x1 + 12.5} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep) + ((machiningConfig.bottomRight.y - machiningConfig.clustersPos[activeClustersInput])/2) * scalePercentageY)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep) + ((machiningConfig.bottomRight.y - machiningConfig.clustersPos[activeClustersInput])/2) * scalePercentageY)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.floor(machiningConfig.bottomRight.y - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep - machiningConfig.clustersPos[activeClustersInput])}</text>
                                                                    );
                                                                }else{
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceLeft${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput - 1].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput - 1].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput - 1].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput - 1].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y - 5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y - 5}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput].y - 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput].y - 5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text key={`clusterInputLeft${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x1 + 12.5} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.clustersPos[activeClustersInput - 1])/2) * scalePercentageY)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.clustersPos[activeClustersInput - 1])/2) * scalePercentageY)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.ceil(machiningConfig.clustersPos[activeClustersInput] - machiningConfig.clustersPos[activeClustersInput - 1] - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep)}</text>
                                                                    );
                                                                    circles.push(
                                                                        <path
                                                                            key={`clusterDistanceRight${activeClustersInput}`}
                                                                            d={`
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5}
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput + 1].y - 5} 
                                                                                M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput + 1].y - 5}
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput + 1].y - 5} 
                                                                                L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput + 1].y - 5}
                                                                            `}
                                                                            stroke="blue"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    );
                                                                    circles.push(
                                                                        <text
                                                                            key={`clusterInputRight${activeClustersInput}`}
                                                                            fill="blue"
                                                                            fontWeight="400"
                                                                            fontSize="10"
                                                                            x={drawingProps.clustersHoles[activeClustersInput].x1 + 12.5}
                                                                            y={shelfDrillingFace === 'B' ? 
                                                                                (drawingProps.clustersHoles[activeClustersInput].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep) + ((machiningConfig.clustersPos[activeClustersInput + 1] - machiningConfig.clustersPos[activeClustersInput]) / 2) * scalePercentageY) * (-1) :
                                                                                (drawingProps.clustersHoles[activeClustersInput].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep) + ((machiningConfig.clustersPos[activeClustersInput + 1] - machiningConfig.clustersPos[activeClustersInput]) / 2) * scalePercentageY)
                                                                            }
                                                                            textAnchor="middle"
                                                                            transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}
                                                                        >
                                                                            {Math.ceil(machiningConfig.clustersPos[activeClustersInput + 1] - machiningConfig.clustersPos[activeClustersInput] - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep)}
                                                                        </text>                                                                    );
                                                                }
                                                            }else{
                                                                const scalePercentageY = drawingProps.drawingWidth / rowConfig.width;
                                                                circles.push(
                                                                    <path
                                                                        key={`clusterDistanceLeft${activeClustersInput}`}
                                                                        d={`
                                                                            M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.topLeft.y}
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y - 5} 
                                                                            M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y - 5}
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput].y - 5} 
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput].y - 5}
                                                                        `}
                                                                        stroke="blue"
                                                                        strokeWidth="1"
                                                                        strokeLinecap="round"
                                                                    />
                                                                );
                                                                circles.push(
                                                                    <text key={`clusterInputLeft${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x1 + 12.5} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.topLeft.y)/2) * scalePercentageY)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y - ((machiningConfig.clustersPos[activeClustersInput] - machiningConfig.topLeft.y)/2) * scalePercentageY)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.ceil(machiningConfig.clustersPos[activeClustersInput] - machiningConfig.bottomLeft.x)}</text>
                                                                );
                                                                circles.push(
                                                                    <path
                                                                        key={`clusterDistanceRight${activeClustersInput}`}
                                                                        d={`
                                                                            M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.bottomRight.y} 
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                            M${drawingProps.clustersHoles[activeClustersInput].x1} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5}
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x1 + 2.5} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                            L${drawingProps.clustersHoles[activeClustersInput].x1 - 2.5} ${drawingProps.clustersHoles[activeClustersInput].y + (machiningConfig.clustersPositions - 1) * drawingProps.clusterStep + 5} 
                                                                        `}
                                                                        stroke="blue"
                                                                        strokeWidth="1"
                                                                        strokeLinecap="round"
                                                                    />
                                                                );
                                                                circles.push(
                                                                    <text key={`clusterInputRight${index}${activeClustersInput}`} fill="blue" fontWeight="400" fontSize="10" x={drawingProps.clustersHoles[activeClustersInput].x1 + 12.5} y={shelfDrillingFace == 'B' ? (drawingProps.clustersHoles[activeClustersInput].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep) + ((machiningConfig.bottomRight.y - machiningConfig.clustersPos[activeClustersInput])/2) * scalePercentageY)*(-1) : (drawingProps.clustersHoles[activeClustersInput].y + ((machiningConfig.clustersPositions - 1) * drawingProps.clusterStep) + ((machiningConfig.bottomRight.y - machiningConfig.clustersPos[activeClustersInput])/2) * scalePercentageY)} textAnchor="middle" transform={shelfDrillingFace === 'B' ? 'scale(1,-1)' : 'scale(1,1)'}>{Math.floor(machiningConfig.bottomRight.y - (machiningConfig.clustersPositions - 1) * machiningConfig.clustersStep - machiningConfig.clustersPos[activeClustersInput])}</text>
                                                                );
                                                            }
                                                        }
                                                    }

                                                    return circles;
                                                })()}
                                            </React.Fragment>
                                        ))
                                    : null}

                                </svg>
                            </div>
                            <div className='machiningPopupDrawingSide' 
                                style={{background: 
                                    (selectionConfigValue === 'angleCut' && (angleCutSide === 'l1w2' || angleCutSide === 'l2w2')) || 
                                    (selectionConfigValue === 'hingeDrilling' && hingeDrillSide === 'w2') ||
                                    (selectionConfigValue === 'shelfDrilling' && shelfDrillingEdge === 'w1-w2')
                                    ? '#e4d5c7' : 'none'}} 
                            >
                                <p>W2</p>
                            </div>
                        </div>
                        <div className='machiningPopupDrawingSide' 
                            style={{background: 
                                    (selectionConfigValue === 'angleCut' && (angleCutSide === 'l2w1' || angleCutSide === 'l2w2')) || 
                                    (selectionConfigValue === 'hingeDrilling' && hingeDrillSide === 'l2') ||
                                    (selectionConfigValue === 'shelfDrilling' && shelfDrillingEdge === 'l1-l2')
                                    ? '#e4d5c7' : 'none'}} 
                        >
                            <p>L2</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MachiningPopup