import React, { useState, useEffect } from 'react'
import './forgotPasswordPage.css'
import axios from 'axios';
import { Link } from 'react-router-dom'
import { FiAlertTriangle } from "react-icons/fi";
import Loader from "react-js-loader";
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { APIurl } from '../../ApiService';

function ForgotPasswordPage() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [emailInput, setEmailInput] = useState('');
    const [inputError, setInputError] = useState('');
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const handleEmailInputChange = (event) => {
        const newInput = event.target.value;
        setEmailInput(newInput);
    };

    const validateEmail = async() =>{
        setShowLoader(true);
        setShowSuccessMsg(false);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(emailRegex.test(emailInput)){
            setInputError('');
            const emailData = {
                email: emailInput.toLowerCase()
            };
            const response = await axios.post(`${APIurl}/api/requestpasswordreset`, emailData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Server response:');
            console.log(response);
            const responseStatus = response.data.status;
            const dataMessage = response.data.body;
            if(responseStatus == 'error'){  //  if server gave error
                setShowLoader(false);
                setInputError('Something went wrong in the process..');
                return;
            }else{  //  if server responded ok
                if(dataMessage == 'Email sent'){
                    setInputError('');
                    setShowLoader(false);
                    setShowSuccessMsg(true);
                    return;
                }
            }
        }else{
            setInputError('Please enter a valid email address.');
            setShowLoader(false);
        }
    }

    const checkToken = async(savedToken) =>{
        const tokenData = {
            token: savedToken,
        };

        const response = await axios.post(`${APIurl}/api/checkToken`, tokenData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const responseStatus = response.data.status;
        const dataMessage = response.data.data;

        //  verify the response
        if(responseStatus == 'error'){  //  if server gave error
            return;
        }else{  //  if server responded ok
            if(dataMessage == 'Good token'){
                const userMail = response.data.email;
                const phone = response.data.phone;
                cookies.set("email", userMail);
                cookies.set("phone", phone);
                navigate('/neworder');
                return;
            }else{
                return;
            }
        }
    }

    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are trying to log in with this token 🔐 \n" + savedToken);
            checkToken(savedToken);
        }
    }, []);

    return (
        <div className='forgotPassSection'>
            <div className='forgotPassContainer'>
                <div className='forgotPassContainerLogo'>
                    <img src='res\images\myBoardsLogoShadow.png' alt='Logo' className='forgotPassLogoImg'/>
                </div>
                <div className='forgotPassContainerContent'>
                    <div className='forgotPassCard'>
                        <h3 style={{paddingBottom: '25px', fontWeight: '500'}}>Forgot password</h3>
                        <div className='forgotPassForm'>
                            <div className='forgotPassFormSection'>
                                <div className='forgotPassFormLabelBox'>
                                    <p>Your email address</p>
                                </div>
                                <div className='forgotPassFormInputFieldBox'>
                                    <input className='forgotPassFormInputField' 
                                        value={emailInput}
                                        onChange={handleEmailInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {inputError !== '' ? (
                            <div className='forgotPassErrorMsgDiv'>
                                <FiAlertTriangle style={{minWidth: '16px'}}/>
                                <p>{inputError}</p>
                            </div>
                        ) : null}
                        {showLoader === true ? (
                            <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                        ) : showSuccessMsg === true ? (
                            <div className='forgotPassSuccessMsgDiv'>
                                <p>Check your email's inbox and spam for the password reset email.</p>
                            </div>
                        ) : (
                            <div className='forgotPassBtnBox'>
                                <div className='forgotPassBtn' onClick={() => validateEmail()}>
                                    <p>SEND RECOVERY EMAIL</p>
                                </div>
                            </div>
                        )}
                        <Link to="/login" style={{textDecoration: 'none', color: 'black'}}><span className='forgotPassToSignUpBtn'>Back to Login</span></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordPage