import React, { useState, useEffect } from 'react'
import './sprayPopup.css'
import { FiChevronDown, FiX } from "react-icons/fi";
import { getRowConfig, getRowWithOpenSelection, updateSprayConfig } from '../../orderConfig';

function SprayPopup(props) {
    const [rowConfig, setRowConfig] = useState(() => {
        const currRow = getRowWithOpenSelection();
        const config = getRowConfig(currRow);
        return config;
    });

    const [sprayConfig, setSprayConfig] = useState(() =>{
        if(rowConfig.sprayConfig != null){
            return rowConfig.sprayConfig;
        }else{
            const obj = {
                spray: null,
                finish: null,
                backside: null,
                paintBrand: null,
                colorName: null,
                price: null,
            }
            return obj;
        }
    });

    const handleContainerClick = (e) => {
        // Check if the click occurred outside the container
        if (e.target.classList.contains('sprayPopup')) {
            closeSprayConfig(); // Close the popup
        }
    };
    const closePopup = () =>{
        props.closeSprayPopup();
    }

    //  spray coating dropdown
    const [sprayDropdownOpen, setSprayDropdownOpen] = useState(false);
    const toggleSprayDropdown = () =>{
        setSprayDropdownOpen(!sprayDropdownOpen);
    }
    const closeSprayDropdown = () =>{
        setSprayDropdownOpen(false);
    }
    const sprayCoatingOptions = [
        {id: 0, label: 'White primer', value: 'whitePrimer'},
        {id: 1, label: 'Solid colour', value: 'solidColor'},
        {id: 2, label: 'Clear lacquer', value: 'clearLac'},
    ];
    const [sprayLabel, setSprayLabel] = useState('White primer');
    const [sprayOption, setSprayOption] = useState('whitePrimer');
    const handleSprayOptionChange = (brandID) =>{
        setSprayOption(sprayCoatingOptions[brandID].value);
        setSprayLabel(sprayCoatingOptions[brandID].label)
        closeSprayDropdown();
    };

    // -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| CONFIG FOR WHITE PRIMER SECTION |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const [whitePrimerConfig, setWhitePrimerConfig] = useState(() => {
        if(sprayConfig.spray != null && sprayConfig.spray == 'whitePrimer'){
            const obj = {
                backside: sprayConfig.backside == 'white primer' ? 'white primer' : null
            };
            return obj;
        }else{
            const obj = {
                backside: null
            };
            return obj;
        }
    })
    const whitePrimerBacksidePressed = () =>{
        let newBacksideValue = whitePrimerConfig.backside;
        if(newBacksideValue != null){
            newBacksideValue = null;
        }else{
            newBacksideValue = 'white primer';
        }
        setWhitePrimerConfig(() => ({
            backside: newBacksideValue
        }));
        setSprayConfig((prevValues) => ({
            ...prevValues,
            spray: null
        }));
    };

// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| CONFIG FOR COLOR SECTION |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const [valuePaint, setValuePaint] = useState(() => {
        if(sprayConfig.paintBrand != null){
            return sprayConfig.paintBrand;
        }else{
            return 'RAL';
        }
    });    

    const [colorConfig, setColorConfig] = useState(() => {
        if(sprayConfig.spray != null && sprayConfig.spray == 'solidColor'){
            const obj = {
                backside: sprayConfig.backside == 'color' ? sprayConfig.backside : null,
                optionalBackside: sprayConfig.backside == 'white primer' ? true : false,
                finish: sprayConfig.finish,
                paintBrand: sprayConfig.paintBrand,
                colorName: sprayConfig.colorName
            };
            return obj;
        }else{
            const obj = {
                backside: null,
                optionalBackside: false,
                finish: 'satin',
                paintBrand: null,
                colorName: ''
            };
            return obj;
        }
    })
    const [tempColorOptionalBackside, setColorOptionalBackside] = useState(false);

    useEffect(() => {
        if(sprayConfig.spray != null && sprayConfig.spray == 'solidColor'){
            if(sprayConfig.backside == 'white primer'){
                setColorOptionalBackside(true);
            }else{
                setColorOptionalBackside(false);
            }
        }else{
            setColorOptionalBackside(false);
        }
    }, []);

    const handleColorBackOptionalChange = () =>{
        const newTempColorOptionalBackside = !tempColorOptionalBackside;
        let backsideValue = colorConfig.backside;
        if(newTempColorOptionalBackside == true){
            if(backsideValue != null){
                backsideValue = null;
            }
        }
        setColorConfig((prevValues) => ({
            ...prevValues,
            optionalBackside: newTempColorOptionalBackside
        }));
        if(sprayConfig.spray == 'solidColor'){
            let sprayConfigOptionalBackside = false;
            if(sprayConfig.backside == 'white primer'){
                sprayConfigOptionalBackside = true;
            }
            if(newTempColorOptionalBackside != sprayConfigOptionalBackside){
                setSprayConfig((prevValues) => ({
                    ...prevValues,
                    spray: null
                }));
                setColorConfig((prevValues) => ({
                    ...prevValues,
                    backside: backsideValue
                }));
                return;
            }else{
                return;
            }
        }else{
            setColorConfig((prevValues) => ({
                ...prevValues,
                backside: backsideValue
            }));
        }
        setColorOptionalBackside(newTempColorOptionalBackside);
    }


    useEffect(() => {
        setColorConfig((prevValues) => ({
            ...prevValues,
            paintBrand: valuePaint
        }));
        if(sprayConfig.spray != null && sprayConfig.spray == 'solidColor'){
            const new_spray_config = sprayConfig;
            new_spray_config.paintBrand = valuePaint;
            updateSprayConfig(new_spray_config);
        }
        console.log('-> paintBrand changed to: ' + valuePaint);
    }, [valuePaint]);

    //  paint brand dropdown
    const [paintBrandDropdownOpen, setPaintBrandDropdownOpen] = useState(false);
    const togglePaintBrandDropdown = () =>{
        setPaintBrandDropdownOpen(!paintBrandDropdownOpen);
    }
    const closePaintBrandDropdown = () =>{
        setPaintBrandDropdownOpen(false);
    }
    const paintBrandOptions = [
        {id: 0, value: 'RAL'},
        {id: 1, value: 'NCS'},
        {id: 2, value: 'Pantone'},
        {id: 3, value: 'Farrow & Ball'},
        {id: 4, value: 'Little Green'},
        {id: 5, value: 'Zoffany'},
        {id: 6, value: 'Designers Guild'},
        {id: 7, value: 'Dulux'},
        {id: 8, value: 'Crown'},
        {id: 9, value: 'Earthborn'},
        {id: 10, value: 'Fired Earth'},
        {id: 11, value: 'Laura Ashley'},
        {id: 12, value: 'Paint & Paper library'},
        {id: 13, value: 'Other'},
    ];
    const [paintBrand, setPaintBrand] = useState(() => {
        if(sprayConfig.paintBrand != null){
            return sprayConfig.paintBrand;
        }else{
            return 'RAL';
        }
    });
    const handlePaintBrandOptionChange = (brandID) =>{
        setPaintBrand(paintBrandOptions[brandID].value);
        setValuePaint(paintBrandOptions[brandID].value);
        closePaintBrandDropdown();
    };

    // handle the changes made to the description input
    const handleColorNameChange = (event) => {
        // Extract the value from the event
        const { value } = event.target;
        console.log('color name changed: ' + value);
        setColorConfig((prevValues) => ({
            ...prevValues,
            colorName: value,
        }));
        if(sprayConfig.spray != null && sprayConfig.spray == 'solidColor'){
            const new_spray_config = sprayConfig;
            new_spray_config.colorName = value;
            updateSprayConfig(new_spray_config);
        }
    };

    const colorBacksidePressed = () =>{
        let backsideValue = colorConfig.backside;
        if(backsideValue != null){
            backsideValue = null;
        }else{
            backsideValue = 'color';
            setColorOptionalBackside(false);
        }
        setColorConfig((prevValues) => ({
            ...prevValues,
            backside: backsideValue,
            optionalBackside: false
        }));
        setSprayConfig((prevValues) => ({
            ...prevValues,
            spray: null
        }));
    };

    const colorFinishPressed = (finishValue) =>{
        setColorConfig((prevValues) => ({
            ...prevValues,
            finish: finishValue
        }));
        setSprayConfig((prevValues) => ({
            ...prevValues,
            spray: null
        }));
    };

// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| CONFIG FOR LAC SECTION |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const [lacConfig, setLacConfig] = useState(() => {
        if(sprayConfig.spray != null && sprayConfig.spray == 'clearLac'){
            const obj = {
                backside: sprayConfig.backside == 'lac' ? sprayConfig.backside : null,
                optionalBackside: sprayConfig.backside == 'clear sealant' ? true : false,
                finish: sprayConfig.finish,
            };
            return obj;
        }else{
            const obj = {
                backside: null,
                optionalBackside: false,
                finish: 'satin',
            };
            return obj;
        }
    })

    const lacBacksidePressed = () =>{
        let backsideValue = lacConfig.backside;
        if(backsideValue != null){
            backsideValue = null;
        }else{
            backsideValue = 'lac';
            setLacOptionalBackside(false);
        }
        setLacConfig((prevValues) => ({
            ...prevValues,
            backside: backsideValue,
            optionalBackside: false
        }));
        setSprayConfig((prevValues) => ({
            ...prevValues,
            spray: null
        }));
    };

    const [lacOptionalBackside, setLacOptionalBackside] = useState(false);

    useEffect(() => {
        if(sprayConfig.spray != null && sprayConfig.spray == 'clearLac'){
            if(sprayConfig.backside == 'clear sealant'){
                setLacOptionalBackside(true);
            }else{
                setLacOptionalBackside(false);
            }
        }else{
            setLacOptionalBackside(false);
        }
    }, []);

    const handleLacBackOptionalChange = () =>{
        const newTempLacOptionalBackside = !lacOptionalBackside;
        let backsideValue = lacConfig.backside;
        if(newTempLacOptionalBackside == true){
            if(backsideValue != null){
                backsideValue = null;
            }
        }
        setLacConfig((prevValues) => ({
            ...prevValues,
            optionalBackside: newTempLacOptionalBackside
        }));
        if(sprayConfig.spray == 'clearLac'){
            let sprayConfigOptionalBackside = false;
            if(sprayConfig.backside == 'clear sealant'){
                sprayConfigOptionalBackside = true;
            }
            if(newTempLacOptionalBackside != sprayConfigOptionalBackside){
                setSprayConfig((prevValues) => ({
                    ...prevValues,
                    spray: null
                }));
                setLacConfig((prevValues) => ({
                    ...prevValues,
                    backside: backsideValue,
                }));
                return;
            }else{
                return;
            }
        }else{
            setLacConfig((prevValues) => ({
                ...prevValues,
                backside: backsideValue,
            }));
        }
        setLacOptionalBackside(newTempLacOptionalBackside);
    }

    const lacFinishPressed = (finishValue) =>{
        setLacConfig((prevValues) => ({
            ...prevValues,
            finish: finishValue
        }));
        setSprayConfig((prevValues) => ({
            ...prevValues,
            spray: null
        }));
    };

// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| PRICE CALCULATION |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const whitePrimerPrice = 19.40;
    const satinColorPrice = 54.86;
    const mattColorPrice = 57.23;
    const satinLacPrice = 38.12;
    const mattLacPrice = 36.96;
    const clearSealantPice = 13.89;

    const [configPrices, setConfigPrices] = useState(() => {
        if(sprayConfig.spray != null && sprayConfig.price != null){
            let whitePrimerPrice = 0;
            let colorPrice = 0;
            let lacPrice = 0;
            if(sprayConfig.spray == 'whitePrimer'){
                whitePrimerPrice = sprayConfig.price;
            }else if(sprayConfig.spray == 'color'){
                colorPrice = sprayConfig.price;
            }else if(sprayConfig.spray == 'lac'){
                colorPrice = sprayConfig.price;
            }
            const obj = {
                whitePrimer: whitePrimerPrice,
                color: colorPrice,
                lac: lacPrice
            }
            return obj;
        }else{
            const obj = {
                whitePrimer: 0.00,
                color: 0.00,
                lac: 0.00
            }
            return obj;
        }
    });

    const calculatePrice = () =>{
        const sprayType = sprayOption;
        const squareMeters = (parseInt(rowConfig.materialLength)/1000) * (parseInt(rowConfig.width)/1000);
        console.log('Panel sq.m = ' + squareMeters);
        if(sprayType == 'whitePrimer'){
            let price = squareMeters * whitePrimerPrice;
            if(whitePrimerConfig.backside != null){
                price = price * 2;
            }
            setConfigPrices((prevValues) => ({
                ...prevValues,
                whitePrimer: price
            }));
        }else if(sprayType == 'solidColor'){
            let price = 0;
            if(colorConfig.finish == 'satin'){
                price = squareMeters * satinColorPrice;
                if(colorConfig.backside != null){
                    price = price * 2;
                }else if(colorConfig.optionalBackside == true){
                    price = price + (squareMeters * whitePrimerPrice);
                }
            }else if(colorConfig.finish == 'matt'){
                price = squareMeters * mattColorPrice;
                if(colorConfig.backside != null){
                    price = price * 2;
                }else if(colorConfig.optionalBackside == true){
                    price = price + (squareMeters * whitePrimerPrice);
                }
            }
            setConfigPrices((prevValues) => ({
                ...prevValues,
                color: price
            }));
        }else if(sprayType == 'clearLac'){
            let price = 0;
            if(lacConfig.finish == 'satin'){
                price = squareMeters * satinLacPrice;
                if(lacConfig.backside != null){
                    price = price * 2;
                }else if(lacConfig.optionalBackside == true){
                    price = price + (squareMeters * clearSealantPice);
                }
            }else if(lacConfig.finish == 'matt'){
                price = squareMeters * mattLacPrice;
                if(lacConfig.backside != null){
                    price = price * 2;
                }else if(lacConfig.optionalBackside == true){
                    price = price + (squareMeters * clearSealantPice);
                }
            }
            setConfigPrices((prevValues) => ({
                ...prevValues,
                lac: price
            }));
        }
    };

    useEffect(() => {
        calculatePrice();
    }, [whitePrimerConfig, colorConfig, lacConfig, sprayOption]);

// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| SAVE SPRAY CONFIG AND CLOSE |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const saveAndClose = (sprayType) =>{
        let newSprayConfig = {
            spray: null,
            finish: null,
            backside: null,
            paintBrand: null,
            colorName: null,
            price: null,
        }
        if(sprayType == 'whitePrimer'){
            newSprayConfig.spray = 'whitePrimer';
            newSprayConfig.finish = null;
            newSprayConfig.backside = whitePrimerConfig.backside;
            newSprayConfig.price = configPrices.whitePrimer;
        }else if(sprayType == 'color'){
            newSprayConfig.spray = 'solidColor';
            newSprayConfig.finish = colorConfig.finish;
            if(colorConfig.backside != null){
                newSprayConfig.backside = colorConfig.backside;
            }else if(colorConfig.optionalBackside == true){
                newSprayConfig.backside = 'white primer';
            }
            newSprayConfig.paintBrand = colorConfig.paintBrand;
            newSprayConfig.colorName = colorConfig.colorName;
            newSprayConfig.price = configPrices.color;
        }else if(sprayType == 'lac'){
            newSprayConfig.spray = 'clearLac';
            newSprayConfig.finish = lacConfig.finish;
            if(lacConfig.backside != null){
                newSprayConfig.backside = lacConfig.backside;
            }else if(lacConfig.optionalBackside == true){
                newSprayConfig.backside = 'clear sealant';
            }
            newSprayConfig.price = configPrices.lac;
        }
        console.log('saved spray config:');
        console.log(newSprayConfig);
        console.log('sprayType: ' + sprayType);
        updateSprayConfig(newSprayConfig);
        closePopup();
    }

    const removeAppliedSpray = () =>{
        setSprayConfig({
            spray: null,
            finish: null,
            backside: null,
            paintBrand: null,
            colorName: null,
            price: null,
        })
        updateSprayConfig(null);
    };

    const closeSprayConfig = () =>{
        if(sprayConfig.spray == null){
            updateSprayConfig(null);
        }
        closePopup();
    }

    
    return (
        <div className='sprayPopup' onClick={handleContainerClick}>
            <div className='sprayPopupContainer'>
                <div className='sprayCloseBtn' onClick={() => closeSprayConfig()}>
                    <FiX />
                    <p>Save & close</p>
                </div>
                <p style={{fontSize: '16px'}}>Spray coating</p>
                <p>Select spray coating option:</p>
                <div className='sprayDropdown'>
                    <div className='sprayDropdownTop' style={{cursor: 'pointer'}} onClick={() => toggleSprayDropdown()}>
                        <div className='sprayDropdownTopValue'>
                            <p>{sprayLabel}</p>
                        </div>
                        <FiChevronDown />
                    </div>
                    <div className='sprayDropdownMenu' style={{display: sprayDropdownOpen === true ? 'flex' : 'none'}}>
                        {sprayCoatingOptions.map((brand, index) => (
                            <div key={index} className='sprayDropdownMenuItem' onClick={() => handleSprayOptionChange(index)}><p>{brand.label}</p></div>
                        ))}
                    </div>
                </div>

                {/* White primer */}
                {sprayOption == 'whitePrimer' ? (
                    <div className='sprayWhitePrimerContainer'>
                        <p>Select panel sides for spraying</p>
                        <div className='spraySideSelection'>
                            <div className='spraySide' style={{background: '#e4d5c7'}}>
                                <p>A side</p>
                                <p style={{fontSize: '9px'}}>Includes edgebanding edges</p>
                            </div>
                            <div className='spraySide' 
                                onClick={() => whitePrimerBacksidePressed()}
                                style={whitePrimerConfig.backside != null ? {background: '#e4d5c7'} : {background: '#f8f6f5'}}
                            >
                                <p>B side</p>
                            </div>
                        </div>
                        <p>Select finish</p>
                        <div className='spraySideSelection'>
                            <div className='spraySide' style={{background: '#e4d5c7'}}>
                                <p>White primer</p>
                                <p style={{fontSize: '16px'}}>£{whitePrimerPrice.toFixed(2)}</p>
                                <p style={{fontSize: '9px'}}>sq.m.</p>
                            </div>
                        </div>
                        <div className='sprayApplyRemoveBtn' 
                            style={sprayConfig.spray != null && sprayConfig.spray == 'whitePrimer' ? { background: '#fb0429', color: 'white' } : { background: '#e4d5c7' }}
                            onClick={() => {
                                if (sprayConfig.spray != null && sprayConfig.spray === 'whitePrimer') {
                                    removeAppliedSpray();
                                } else {
                                    saveAndClose('whitePrimer');
                                }
                            }}
                        >
                            {sprayConfig.spray != null && sprayConfig.spray === 'whitePrimer' ? (
                                <p>Remove white primer spray coating</p>
                            ) : (
                                <>
                                    <p>Apply spray coating for: </p>
                                    <p style={{fontWeight: '500'}}>£{configPrices.whitePrimer.toFixed(2)}</p>
                                </>
                            )}
                        </div>
                    </div>
                ) : null}
                
                {/* Solid color */}
                {sprayOption == 'solidColor' ? (
                    <div className='sprayWhitePrimerContainer'>
                        <p>Select panel sides for spraying</p>
                        <div className='spraySideSelection'>
                            <div className='spraySide' style={{background: '#e4d5c7'}}>
                                <p>A side</p>
                                <p style={{fontSize: '9px'}}>Includes edgebanding edges</p>
                            </div>
                            <div className='spraySide' 
                                onClick={() => colorBacksidePressed()}
                                style={colorConfig.backside != null ? {background: '#e4d5c7'} : {background: '#f8f6f5'}}
                            >
                                <p>B side</p>
                            </div>
                        </div>
                        <p>Select finish</p>
                        <div className='spraySideSelection'>
                            <div className='spraySide' 
                                style={colorConfig.finish == 'satin' ? {background: '#e4d5c7'} : {background: '#f8f6f5'}}
                                onClick={() => colorFinishPressed('satin')}
                            >
                                <p>Satin finish</p>
                                <p style={{fontSize: '9px'}}>25% sheen</p>
                                <p style={{fontSize: '16px'}}>£{satinColorPrice.toFixed(2)}</p>
                                <p style={{fontSize: '9px'}}>sq.m.</p>
                            </div>
                            <div className='spraySide' 
                                style={colorConfig.finish == 'matt' ? {background: '#e4d5c7'} : {background: '#f8f6f5'}}
                                onClick={() => colorFinishPressed('matt')}
                            >
                                <p>Satin finish</p>
                                <p style={{fontSize: '9px'}}>5% sheen</p>
                                <p style={{fontSize: '16px'}}>£{mattColorPrice.toFixed(2)}</p>
                                <p style={{fontSize: '9px'}}>sq.m.</p>
                            </div>
                        </div>
                        <p>Select paint brand and color</p>
                        <div className='spraySideSelection'>
                            <div className='paintBrandDropdown'>
                                <div className='paintBrandDropdownTop' style={{cursor: 'pointer'}} onClick={() => togglePaintBrandDropdown()}>
                                    <div className='paintBrandDropdownTopValue'>
                                        <p>{paintBrand}</p>
                                    </div>
                                    <FiChevronDown />
                                </div>
                                <div className='paintBrandDropdownMenu' style={{display: paintBrandDropdownOpen === true ? 'flex' : 'none'}}>
                                    {paintBrandOptions.map((brand, index) => (
                                        <div key={index} className='paintBrandDropdownMenuItem' onClick={() => handlePaintBrandOptionChange(index)}><p>{brand.value}</p></div>
                                    ))}
                                </div>
                            </div>
                            <div className='paintColorInputDiv'>
                                <input placeholder='color' className='paintColorInput' value={colorConfig.colorName} onChange={(event) => handleColorNameChange(event)}/>
                            </div>
                        </div>
                        <div className='spraySideSelection'>
                            <div className='sprayBacksideOnly'>
                                <p>Spray Back side with white primer only</p>
                                <p>Price: £{whitePrimerPrice.toFixed(2)} sq.m.</p>
                            </div>
                            <input type="checkbox" name="myCheckbox" className='checkbox' style={{cursor: 'pointer'}} 
                                checked={tempColorOptionalBackside} 
                                onChange={handleColorBackOptionalChange}
                            />
                        </div>
                        <div className='sprayApplyRemoveBtn' 
                            style={sprayConfig.spray != null && sprayConfig.spray == 'solidColor' ? { background: '#fb0429', color: 'white' } : { background: '#e4d5c7' }}
                            onClick={() => {
                                if (sprayConfig.spray != null && sprayConfig.spray === 'solidColor') {
                                    removeAppliedSpray();
                                } else {
                                    saveAndClose('color');
                                }
                            }}
                        >
                            {sprayConfig.spray != null && sprayConfig.spray === 'solidColor' ? (
                                <p>Remove solid colour spray coating</p>
                            ) : (
                                <>
                                    <p>Apply spray coating for: </p>
                                    <p style={{fontWeight: '500'}}>£{configPrices.color.toFixed(2)}</p>
                                </>
                            )}
                        </div>
                    </div>
                ) : null}

                {/* Clear lacquer */}
                {sprayOption == 'clearLac' ? (
                    <div className='sprayWhitePrimerContainer'>
                        <p>Select panel sides for spraying</p>
                        <div className='spraySideSelection'>
                            <div className='spraySide' style={{background: '#e4d5c7'}}>
                                <p>A side</p>
                                <p style={{fontSize: '9px'}}>Includes edgebanding edges</p>
                            </div>
                            <div className='spraySide' 
                                onClick={() => lacBacksidePressed()}
                                style={lacConfig.backside != null ? {background: '#e4d5c7'} : {background: '#f8f6f5'}}
                            >
                                <p>B side</p>
                            </div>
                        </div>
                        <p>Select finish</p>
                        <div className='spraySideSelection'>
                            <div className='spraySide' 
                                style={lacConfig.finish == 'satin' ? {background: '#e4d5c7'} : {background: '#f8f6f5'}}
                                onClick={() => lacFinishPressed('satin')}
                            >
                                <p>Satin finish</p>
                                <p style={{fontSize: '9px'}}>clear lacquer</p>
                                <p style={{fontSize: '9px'}}>25% sheen</p>
                                <p style={{fontSize: '16px'}}>£{satinLacPrice.toFixed(2)}</p>
                                <p style={{fontSize: '9px'}}>sq.m.</p>
                            </div>
                            <div className='spraySide' 
                                style={lacConfig.finish == 'matt' ? {background: '#e4d5c7'} : {background: '#f8f6f5'}}
                                onClick={() => lacFinishPressed('matt')}
                            >
                                <p>Satin finish</p>
                                <p style={{fontSize: '9px'}}>clear lacquer</p>
                                <p style={{fontSize: '9px'}}>5% sheen</p>
                                <p style={{fontSize: '16px'}}>£{mattLacPrice.toFixed(2)}</p>
                                <p style={{fontSize: '9px'}}>sq.m.</p>
                            </div>
                        </div>
                        <p>Select paint brand and color</p>
                        <div className='spraySideSelection'>
                            <div className='sprayBacksideOnly'>
                                <p>Spray Back side with clear sealant only</p>
                                <p>Price: £{clearSealantPice.toFixed(2)} sq.m.</p>
                            </div>
                            <input type="checkbox" name="myCheckbox" className='checkbox' style={{cursor: 'pointer'}} 
                                checked={lacOptionalBackside} 
                                onChange={handleLacBackOptionalChange}
                            />
                        </div>
                        <div className='sprayApplyRemoveBtn' 
                            style={sprayConfig.spray != null && sprayConfig.spray == 'clearLac' ? { background: '#fb0429', color: 'white' } : { background: '#e4d5c7' }}
                            onClick={() => {
                                if (sprayConfig.spray != null && sprayConfig.spray === 'clearLac') {
                                    removeAppliedSpray();
                                } else {
                                    saveAndClose('lac');
                                }
                            }}
                        >
                            {sprayConfig.spray != null && sprayConfig.spray === 'clearLac' ? (
                                <p>Remove clear lacquer spray coating</p>
                            ) : (
                                <>
                                    <p>Apply spray coating for: </p>
                                    <p style={{fontWeight: '500'}}>£{configPrices.lac.toFixed(2)}</p>
                                </>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default SprayPopup