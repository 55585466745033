import React, { useState, useEffect } from 'react'
import './ordersHistory.css'
import Header from '../header/header'
import { PiSmileySad } from "react-icons/pi";
import { IoIosAdd, IoIosSearch } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import { Link, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import Loader from "react-js-loader";

import PdfOrderSumary from '../pdfOrderSummary/pdfOrderSumary';
import { PDFDownloadLink } from '@react-pdf/renderer';


import { APIurl } from '../../ApiService';


function OrdersHistory() {

    const cookies = new Cookies();
    const navigate = useNavigate();

    const [ordersHistoryList, setOrdersHistoryList] = useState([]);
    const [showLoader, setShowLoader] = useState(true);

    const fetchOrders = async(userToken) =>{
        setShowLoader(true);
        const tokenData = {
            token: userToken
        };
        try {
            const response = await axios.post(`${APIurl}/api/ordershistory`, tokenData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
    
            if (responseStatus === 'error') {
                setShowLoader(false);
                navigate('/neworder');
                return;
            } else {
                if(dataMessage === 'Invalid token'){
                    navigate('/login');
                }else{
                    const orders = response.data.ordersList;
                    setOrdersHistoryList(orders);
                    setShowLoader(false);
                    return;
                }
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle or log the error
            setShowLoader(false);
            navigate('/neworder');
        }
    }

    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            // get account details
            fetchOrders(savedToken);
        }else{
            console.log('You must be logged in to access this page!');
            navigate('/login');
        }
    }, []);

    function formatPrice(price) {
        const priceNumber = parseFloat(price);
        return priceNumber.toLocaleString('en-GB', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const displayOrderStatus = (status, checkout_status) =>{
        if(status === 'unpaid' && checkout_status === 'complete'){
            return 'Processing';
        }else if(status === 'paid'){
            return 'Paid';
        }
    }

    function formatNumberToSixDigits(number) {
        // Convert the number to a string
        let numberString = String(number);
        
        // Pad the string with leading zeros to make it 6 characters long
        while (numberString.length < 6) {
            numberString = '0' + numberString;
        }
        
        return numberString;
    }

    return (
        <div className='historySection'>
            <Header />
            {showLoader === true ? (
                <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
            ) : (
                ordersHistoryList.length > 0 ? (
                    <div className='ordersHistoryListContainer'>
                        <div className='historyNrOrdersFoundDiv'>
                            <IoIosSearch />
                            <p>You have made {ordersHistoryList.length} orders:</p>
                        </div>
                        {ordersHistoryList.map((order, index) => (
                            <div className='ordersHistoryListItem' key={index}>
                                <p>Order ID: <b>{formatNumberToSixDigits(order.id)}</b></p>
                                <p>Date: <b>{order.date}</b></p>
                                <p>Price (With VAT): <b>£{formatPrice(order.price)}</b></p>
                                <PDFDownloadLink document={<PdfOrderSumary
                                    order_pricing = {JSON.parse(order.order_pricing)}
                                    order_id = {formatNumberToSixDigits(order.id)}
                                    order_date = {order.date}
                                    order_price = {formatPrice(order.price)}
                                    delivery_info = {JSON.parse(order.delivery_info)}
                                    offcuts_selections = {JSON.parse(order.offcuts_selections)}
                                />} fileName={`MyBoards order summary ${order.date}`} className='ordersHistoryDownloadBtn'>
                                    <FiDownload />
                                    <p>Download order summary</p>
                                </PDFDownloadLink>
                                <div className={displayOrderStatus(order.status, order.checkout_status) === 'Paid' ? 'ordersHistoryOrderStatusPaid' : 'ordersHistoryOrderStatus' }>
                                    <p>{displayOrderStatus(order.status, order.checkout_status)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='noHistoryDiv'>
                        <PiSmileySad style={{width: '35px', height: '35px', color: '#6ab29b'}}/>
                        <p>You haven't made any orders yet.</p>
                        <Link to={'/neworder'} className='historyNewOrderBtn'>
                            <IoIosAdd style={{width: '30px', height: '30px'}}/>
                            <p>Make an order</p>
                        </Link>
                    </div>
                )
            )}
        </div>
    );
    
}

export default OrdersHistory