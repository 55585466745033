import React, { useState, useEffect } from 'react'
import './deliveryInfo.css'
import { FiAlertTriangle } from "react-icons/fi";
import { FaArrowLeftLong, FaArrowRightLong  } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import Loader from "react-js-loader";
import { getOrderTotalPrice, getOrderTotalPriceVAT, getRowsConfig, getFullSheetsConfig, getEdgingTapeConfig, getPanelsArray, getOffcutsSelections, getBins, getPlacedCutsArrayAsync, getOffcutsArrayAsync } from '../new_order/orderConfig';

import { APIurl } from '../../ApiService';

function DeliveryInfo() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [rows_config, setRowsConfig] = useState([]);
    const [full_sheets_config, setFullSheetsConfig] = useState([]);
    const [edge_tape_config, setEdgeTapeConfig] = useState([]);

    //  check if the order config is ok, else redirect to new order page
    useEffect(() => {
        const checkOrderConfig = async() =>{
            const rowsConfig = await getRowsConfig();
            setRowsConfig(rowsConfig);
            const fullSheetsConfig = await getFullSheetsConfig();
            setFullSheetsConfig(fullSheetsConfig);
            const newOrderTotal = await getOrderTotalPrice();
            const newOrderTotalVat = await getOrderTotalPriceVAT();
            if((rowsConfig.length === 0 && fullSheetsConfig.length === 0) || newOrderTotal === 0 || newOrderTotalVat === 0){
                navigate('/neworder');
            }else{
                const edgingTapeConfig = await getEdgingTapeConfig();
                setEdgeTapeConfig(edgingTapeConfig);
            }
        }
        checkOrderConfig();
    }, []);

    const [deliveryOption, setDeliveryOption] = useState('delivery');

    const changeDeliveryOption = (optionName) =>{
        setDeliveryOption(optionName);
    }

    const [orderTotal, setOrderTotal] = useState(0);
    const [orderTotalVAT, setOrderTotalVAT] = useState(0);
    const [deliveryInputFields, setDeliveryinputFields] = useState({
        first_name: '',
        last_name: '',
        email: '',
        address: '',
        city: '',
        postcode: '',
        phone_number: '',
        additional_info: ''
    });
    const [deliveryInputsAlerts, setDeliveryInputsAlerts] = useState({
        first_name: '',
        last_name: '',
        email: '',
        address: '',
        city: '',
        postcode: '',
        phone_number: '',
        additional_info: ''
    });

    useEffect(() =>{
        const newOrderTotal = getOrderTotalPrice();
        const newOrderTotalVat = getOrderTotalPriceVAT();
        setOrderTotal(newOrderTotal);
        setOrderTotalVAT(newOrderTotalVat);
    }, []);

    const handleDeliveryInputFieldsChanges = (event, fieldName) =>{
        const inputValue = event.target.value;
        if(fieldName === 'first_name'){
            let newState = {...deliveryInputFields};
            newState.first_name = inputValue;
            setDeliveryinputFields(newState);
        }else if(fieldName === 'last_name'){
            let newState = {...deliveryInputFields};
            newState.last_name = inputValue;
            setDeliveryinputFields(newState);
        }else if(fieldName === 'email'){
            let newState = {...deliveryInputFields};
            newState.email = inputValue;
            setDeliveryinputFields(newState);
        }else if(fieldName === 'address'){
            let newState = {...deliveryInputFields};
            newState.address = inputValue;
            setDeliveryinputFields(newState);
        }else if(fieldName === 'city'){
            let newState = {...deliveryInputFields};
            newState.city = inputValue;
            setDeliveryinputFields(newState);
        }else if(fieldName === 'postcode'){
            let newState = {...deliveryInputFields};
            newState.postcode = inputValue;
            setDeliveryinputFields(newState);
        }else if(fieldName === 'phone_number'){
            let newState = {...deliveryInputFields};
            newState.phone_number = inputValue;
            setDeliveryinputFields(newState);
        }else if(fieldName === 'additional_info'){
            let newState = {...deliveryInputFields};
            newState.additional_info = inputValue;
            setDeliveryinputFields(newState);
        }
    }

    //  validate delivery input fields
    const validateDeliveryInputs = () =>{
        const firstNameRegex = /^[a-zA-Z]{1,30}$/;
        let alertPresent = false;
        let newState = {...deliveryInputsAlerts};
        if(deliveryInputFields.first_name === null || deliveryInputFields.first_name === '' || !firstNameRegex.test(deliveryInputFields.first_name)){
            alertPresent = true;
            newState.first_name = 'Type in your first name!';
        }else{
            newState.first_name = '';
        }
        if(deliveryInputFields.last_name === null || deliveryInputFields.last_name === '' || !firstNameRegex.test(deliveryInputFields.last_name)){
            alertPresent = true;
            newState.last_name = 'Type in your last name!';
        }else{
            newState.last_name = '';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(deliveryInputFields.email === null || deliveryInputFields.email === '' || !emailRegex.test(deliveryInputFields.email)){
            alertPresent = true;
            newState.email = 'Type a valid email account!';
        }else{
            newState.email = '';
        }
        const addressRegex = /^[a-zA-Z0-9\s,'.-]+$/;
        if(deliveryInputFields.address === null || deliveryInputFields.address === '' || !addressRegex.test(deliveryInputFields.address)){
            alertPresent = true;
            newState.address = 'Type in your delivery address!';
        }else{
            newState.address = '';
        }
        const ukCityNameRegex = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
        if(deliveryInputFields.city === null || deliveryInputFields.city === '' || !ukCityNameRegex.test(deliveryInputFields.city)){
            alertPresent = true;
            newState.city = 'Type in your city!';
        }else{
            newState.city = '';
        }
        const ukPostalCodeRegex = /^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKPS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})$/i;
        if(deliveryInputFields.postcode === null || deliveryInputFields.address === '' || !ukPostalCodeRegex.test(deliveryInputFields.postcode)){
            alertPresent = true;
            newState.postcode = 'Type in your postcode!';
        }else{
            newState.postcode = '';
        }
        const genericPhoneNumberRegex = /^\+?[0-9]+$/;
        if(deliveryInputFields.phone_number === null || deliveryInputFields.phone_number === '' || !genericPhoneNumberRegex.test(deliveryInputFields.phone_number)){
            alertPresent = true;
            newState.phone_number = 'Type in your phone number!';
        }else{
            newState.phone_number = '';
        }
        if(deliveryInputFields.additional_info.length > 250){
            alertPresent = true;
            newState.additional_info = 'We allow additional information only up to 250 characters, please shorten your message!'
        }else{
            newState.additional_info = '';
        }
        setDeliveryInputsAlerts(newState);
        if(deliveryOption === 'delivery' && alertPresent === false){
            //  do the API call
            console.log('Input is good');
            toCheckout();
        }else if(newState.email === '' && newState.phone_number === ''){
            console.log('Input is good');
            toCheckout();
        }
    };

    const [checkoutBtnLoader, setCheckoutBtnLoader] = useState(false);

    const toCheckout = async() =>{
        setCheckoutBtnLoader(true);
        const panelsArray = await getPanelsArray();
        const offcutsSelections = await getOffcutsSelections();
        const bins = await getBins();
        const placed_cuts = await getPlacedCutsArrayAsync();
        const offcuts_array = await getOffcutsArrayAsync();
        console.log('offcuts selection:');
        console.log(offcutsSelections);
        console.log(panelsArray);
        const orderData = {
            token: '',
            email: deliveryInputFields.email,
            first_name: deliveryInputFields.first_name,
            last_name: deliveryInputFields.last_name,
            address: deliveryInputFields.address,
            city: deliveryInputFields.city,
            postcode: deliveryInputFields.postcode,
            phone_number: deliveryInputFields.phone_number,
            additional_info: deliveryInputFields.additional_info,
            panels_array: panelsArray,
            rows_config: rows_config,
            full_sheets_config: full_sheets_config,
            edgetape_config: edge_tape_config,
            delivery: deliveryOption === 'delivery' ? true : false,
            offcuts_selections: offcutsSelections,
            bins: bins,
            placed_cuts: placed_cuts,
            offcuts_array: offcuts_array,
        };
        const savedToken = cookies.get("authToken");
        if(savedToken){
            orderData.token = savedToken;
        }
        console.log('Making API request to checkout');
        const response = await axios.post(`${APIurl}/api/checkoutrequest`, orderData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const responseStatus = response.data.status;
        const dataMessage = response.data.data;
        //  verify the response
        if(responseStatus == 'error'){  //  if server gave error
            console.log('server error');
            setCheckoutBtnLoader(false);
            return;
        }else{  //  if server responded ok
            if(dataMessage == 'Order ready for payment'){
                const checkout_url = response.data.checkout_url;
                window.location.href = checkout_url;
                return;
            }else{
                console.log('server error');
                setCheckoutBtnLoader(false);
                return;
            }
        }
    }

    return (
        <div className='deliveryInfoSection'>
            <div className='deliveryInfoContainer'>
                <p style={{fontSize: '18px', fontWeight: '500'}}>Delivery details</p>
                <div className='deliveryInfoFormBox'>
                    <p style={{fontWeight: '500'}}>Delivery or Pickup?</p>
                    <div className='deliveryInfoDeliveryOrPickupDiv'>
                        <div className={deliveryOption === 'delivery' ? 'deliveryInfoDeliverySelectBtnActive' : 'deliveryInfoDeliverySelectBtn'} onClick={() => changeDeliveryOption('delivery')}>
                            <p>Delivery</p>
                        </div>
                        <div className={deliveryOption === 'pickup' ? 'deliveryInfoDeliverySelectBtnActive' : 'deliveryInfoDeliverySelectBtn'} onClick={() => changeDeliveryOption('pickup')}>
                            <p>Pickup</p>
                        </div>
                    </div>
                </div>
                {deliveryOption === 'delivery' ? (
                    <div className='deliveryInfoDeliveryForm'>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>First name</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.first_name !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.first_name}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.first_name}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'first_name')}
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>Last name</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.last_name !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.last_name}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.last_name}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'last_name')}
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>Email</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.email !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.email}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.email}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'email')}
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>Address</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.address !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.address}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    alue={deliveryInputFields.address}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'address')}
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>City</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.city !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.city}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.city}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'city')}
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>Postcode</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.postcode !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.postcode}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.postcode}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'postcode')}
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>Phone number</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.phone_number !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.phone_number}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.phone_number}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'phone_number')}
                                    
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>Additional information</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.additional_info !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.additional_info}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.additional_info}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'additional_info')}
                                    
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='deliveryInfoDeliveryForm'>
                        <p>Fill out the following fields with your email address and phone number. Once your order is ready for pickup we will inform you.</p>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>First name</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.first_name !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.first_name}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.first_name}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'first_name')}
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>Last name</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.last_name !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.last_name}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.last_name}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'last_name')}
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>Email</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.email !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.email}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.email}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'email')}
                                    
                                />
                            </div>
                        </div>
                        <div className='deliveryInfoDeliveryFormSection'>
                            <div className='deliveryInfoDeliveryFormLabel'>
                                <p>Phone number</p>
                            </div>
                            <div className='deliveryInfoDeliveryFormFieldBox'>
                                {deliveryInputsAlerts.phone_number !== '' ? (
                                    <div className='deliveryInfoInputAlert'>
                                        <FiAlertTriangle />
                                        <p>{deliveryInputsAlerts.phone_number}</p>
                                    </div>
                                ) : null}
                                <input className='deliveryInfoDeliveryFormInput'
                                    value={deliveryInputFields.phone_number}
                                    onChange={(event) => handleDeliveryInputFieldsChanges(event, 'phone_number')}
                                    
                                />
                            </div>
                        </div>
                        <p>Our pickup address is: <b style={{fontWeight: '500'}}>Unit 12, 1-11 Willow Lane, CR4 4NA</b></p>
                        <p>Open hours: <b style={{fontWeight: '500'}}>08:00-17:00 Monday-Friday</b></p>
                    </div>
                )}
                
                <div className='deliveryInfoOrderCosts'>
                    <p>Order total: <b>£{orderTotal}</b></p>
                    <p>Order total with VAT: <b>£{orderTotalVAT}</b></p>
                </div>
                <div className='deliveryInfoActionsBtns'>
                    <Link to={'/pricing'} className='deliveryInfoBackBtn'>
                        <FaArrowLeftLong />
                        <p>Back</p>
                    </Link>
                    <div className='deliveryInfoCheckoutBtn' onClick={() => validateDeliveryInputs()}>
                        {checkoutBtnLoader === false ? (
                            <>
                                <p>Checkout</p>
                                <FaArrowRightLong />
                            </>
                        ) : (
                            <>
                                <Loader type="spinner-default" bgColor='white' color={'transparent'} size={25} style={{margin: 0, padding: 0}} />
                            </>
                        )}
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default DeliveryInfo